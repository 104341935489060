html {
  -webkit-text-size-adjust: 100%;
  -webkit-app-region: no-drag
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #434651;
  font-family: Trebuchet MS,roboto,ubuntu,sans-serif;
  font-size: 14px
}

html.theme-dark body {
  color: #d1d4dc
}

html[lang=vi] body {
  font-family: Arial,sans-serif
}

body,html {
  box-sizing: border-box;
  height: 100%
}

body,dir,h1,h2,h3,h4,h5,h6,html,li,menu,ol,p,ul {
  margin: 0;
  padding: 0
}

a {
  text-decoration: none
}

a:active {
  outline: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  a:hover {
      outline:0
  }
}

h1 {
  font-size: 2em
}

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial
}

sup {
  top: -.25em
}

sub {
  bottom: -.25em
}

figure {
  margin: 0
}

pre {
  overflow: auto
}

code,kbd,pre,samp {
  font-family: monospace,monospace;
  font-size: 1em
}

button,input,optgroup,select,textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button,select {
  text-transform: none
}

button,input[type=button],input[type=reset],input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled],input[disabled] {
  cursor: default
}

button::-moz-focus-inner,input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: initial
}

input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: none;
  margin: 0;
  padding: 0
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td,th {
  padding: 0
}

:root {
  --v-rhythm-header-1-space-phone: 56px;
  --v-rhythm-header-1-space-tablet: 80px;
  --v-rhythm-header-1-space-laptop: 100px;
  --v-rhythm-header-1-space-desktop: 120px;
  --v-rhythm-header-2-space-phone: 16px;
  --v-rhythm-header-2-space-tablet: 24px;
  --v-rhythm-header-2-space-laptop: 32px;
  --v-rhythm-header-2-space-desktop: 40px;
  --v-rhythm-header-3-space-phone: 16px;
  --v-rhythm-header-3-space-tablet: 16px;
  --v-rhythm-header-3-space-laptop: 24px;
  --v-rhythm-header-3-space-desktop: 24px;
  --v-rhythm-footer-1-space-phone: 56px;
  --v-rhythm-footer-1-space-tablet: 80px;
  --v-rhythm-footer-1-space-laptop: 100px;
  --v-rhythm-footer-1-space-desktop: 120px;
  --v-rhythm-footer-2-space-phone: 16px;
  --v-rhythm-footer-2-space-tablet: 24px;
  --v-rhythm-footer-2-space-laptop: 32px;
  --v-rhythm-footer-2-space-desktop: 40px;
  --v-rhythm-footer-3-space-phone: 16px;
  --v-rhythm-footer-3-space-tablet: 16px;
  --v-rhythm-footer-3-space-laptop: 24px;
  --v-rhythm-footer-3-space-desktop: 24px;
  --v-rhythm-spacing-1-phone: 80px;
  --v-rhythm-spacing-1-tablet: 120px;
  --v-rhythm-spacing-1-laptop: 160px;
  --v-rhythm-spacing-1-desktop: 200px;
  --v-rhythm-spacing-2-phone: 64px;
  --v-rhythm-spacing-2-tablet: 88px;
  --v-rhythm-spacing-2-laptop: 120px;
  --v-rhythm-spacing-2-desktop: 160px;
  --v-rhythm-spacing-3-phone: 48px;
  --v-rhythm-spacing-3-tablet: 64px;
  --v-rhythm-spacing-3-laptop: 88px;
  --v-rhythm-spacing-3-desktop: 120px;
  --v-rhythm-spacing-4-phone: 48px;
  --v-rhythm-spacing-4-tablet: 48px;
  --v-rhythm-spacing-4-laptop: 64px;
  --v-rhythm-spacing-4-desktop: 80px;
  --v-rhythm-spacing-5-phone: 32px;
  --v-rhythm-spacing-5-tablet: 32px;
  --v-rhythm-spacing-5-laptop: 40px;
  --v-rhythm-spacing-5-desktop: 48px;
}

.js-hidden {
  display: none!important
}

.js-no-pointer-events {
  pointer-events: none!important
}

body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  min-width: 320px
}

body.i-no-scroll {
  overflow: hidden!important
}

body.i-no-padding {
  padding: 0!important
}

::selection {
  background: #e3effd99
}

html.theme-dark ::selection {
  background: #143a8799
}

.symbol-change-direction.up {
  color: #3cbc98
}

.symbol-change-direction.down {
  color: #ff4a68
}

body.fullscreen-mode {
  overflow: hidden
}

.tv-layout-width {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  width: 1020px
}

.tv-layout-width--no-padding {
  padding: 0;
  width: 980px
}

.tv-layout-width--simple {
  padding: 0
}

.tv-dialog .tv-layout-width {
  max-width: 100%
}

.chart-page .tv-layout-width {
  width: 100%
}

@media screen and (max-width: 1019px) {
  .tv-layout-width {
      width:auto
  }

  .tv-layout-width .tv-feed {
      margin-left: -20px;
      margin-right: -20px
  }

  .tv-layout-width .tv-feed--tablet-top-indent {
      margin-top: 48px
  }
}

.tv-layout-padding {
  padding: 0 20px
}

body.page-wide .tv-layout-width,body.popup-wide .fancybox-overlay .tv-layout-width {
  width: 1520px
}

.tv-main,body.page-fullwidth .tv-layout-width {
  width: 100%
}

.tv-main {
  display: flex;
  flex-direction: column;
  min-height: 100%
}

.tv-side-toolbar {
  float: left;
  height: 100%;
  overflow-x: visible;
  overflow-y: hidden;
  position: static
}

.tv-main-panel {
  background-color: #f1f3f6;
  overflow: hidden;
  position: relative
}

.tv-side-panel {
  float: right;
  height: 100%;
  position: relative;
  z-index: 15
}

.tv-panels {
  height: 100%
}

.chart-page .tv-content {
  padding: 0
}

.clear {
  clear: both
}

.clearfix:after {
  clear: both;
  content: "";
  display: block
}

.hide {
  display: none!important
}

.show {
  display: block!important
}

.tv-right {
  float: right!important
}

.tv-left {
  float: left!important
}

#_atssh {
  margin-top: -20px;
  position: absolute
}

.js-right-boundary {
  margin-right: 0
}

.icon {
  display: inline-block;
  position: relative;
  vertical-align: middle
}

textarea.tv-textarea {
  border: 1px solid #c9cbcd;
  color: #5a5a5a;
  margin: 0;
  padding: 0 5px
}

.icon-plus {
  background-position: -40px 0;
  height: 14px;
  left: -5px;
  width: 14px
}

.icon-share {
  background-position: -80px -60px;
  height: 14px;
  left: -3px;
  top: -1px;
  vertical-align: middle;
  width: 14px
}

.icon-action-zoom {
  background-position: -100px -80px
}

.icon-action-realtime,.icon-action-zoom {
  height: 15px;
  left: -1px;
  vertical-align: top;
  width: 15px
}

.icon-action-realtime {
  background-position: -120px -80px
}

.icon-snapshot-small {
  background-position: -80px -240px;
  height: 9px;
  top: 5px;
  vertical-align: top;
  width: 11px
}

.icon-snapshot-delete {
  background-position: -20px -260px;
  cursor: pointer;
  height: 7px;
  margin: 0 0 0 5px;
  width: 7px
}

.people-container {
  margin: 15px 0 0
}

#loading-indicator-small,.loading-indicator-small {
  clear: both;
  height: 24px
}

#fdbk_tab {
  display: none
}

.tv-index-content {
  background: #fff;
  color: #131722;
  font-size: 13px;
  position: relative
}

html.theme-dark .tv-index-content {
  background: #131722;
  color: #d1d4dc
}

.tv-index-content h1 {
  font-size: 24px
}

.tv-index-content h2 {
  font-size: 25px
}

.tv-index-content h3 {
  font-size: 18px;
  margin: 15px 0
}

.tv-index-content h4 {
  font-size: 15px;
  margin: 10px 0
}

.tv-index-content ol,.tv-index-content p {
  padding-left: 0
}

.tv-index-content ol.roomy>li {
  line-height: 28px;
  margin: 0 0 15px
}

.tv-index-content ol>li>ol {
  line-height: 20px;
  margin: 10px 0
}

.tv-index-content em {
  font-size: 1.1em;
  font-weight: 700
}

.tv-index-content pre {
  white-space: normal
}

.tv-index-content pre.code {
  background: #eaeff4;
  color: #6c6f72;
  font-size: 13px;
  line-height: normal;
  margin: 1px;
  padding: 3px;
  white-space: pre-wrap
}

.tv-index-content ul:not(.sbOptions):not(.no-bullets):not(.tv-list-plain) {
  margin: 20px 0 35px;
  padding: 0
}

.tv-index-content ul:not(.sbOptions):not(.no-bullets):not(.tv-list-plain) li {
  line-height: 28px;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 22px
}

.tv-index-content-wrap {
  padding: 20px 7px 0
}

.tv-index-content-wrap.profile {
  padding: 10px 7px 20px
}

.tv-index-header-login .tv-index-content-wrap.profile {
  padding: 10px 7px
}

.tv-index-content-wrap.stream {
  padding: 0 7px 20px
}

.tv-index-content .notop {
  margin-top: 0
}

.tv-index-content li {
  list-style-type: none
}

.tv-index-content ol {
  counter-reset: list1
}

.tv-index-content ol>li:before {
  content: counter(list1) ". ";
  counter-increment: list1;
  display: inline
}

.tv-index-content ol ol {
  counter-reset: list2;
  padding-left: 0
}

.tv-index-content ol ol li:before {
  content: counter(list1) "." counter(list2) ". ";
  counter-increment: list2
}

.tv-index-content ol li>h4 {
  display: inline-block
}

.tv-index-content .nested-list li ul.nested-item {
  margin: 5px 0
}

.tv-index-content .nested-list li ul.nested-item li {
  margin-bottom: 5px
}

.ch-item-userpic.online:after {
  background: #69cb19;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  content: "";
  display: block;
  height: 8px;
  left: 32px;
  position: absolute;
  top: 32px;
  width: 8px
}

.is-not-trial-available .hide-if-not-trial-available,.is-not-trial .hide-if-not-trial {
  display: none
}

.dl-delayed,.symbol-list-item .delayed,.symbol-list-item .delayed_streaming,.symbol-list-item .endofday {
  border-radius: 50%;
  cursor: default;
  display: inline-block;
  height: 10px;
  line-height: 100px;
  overflow: hidden;
  width: 10px
}

.symbol-list-item .delayed_streaming {
  background-color: #ff9247
}

.symbol-list-item .name {
  vertical-align: top
}

.symbol-list-item .textnote svg {
  fill: gray;
  transition: all .5s ease
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .symbol-list-item .textnote:hover svg {
      fill:#5ec6e8
  }
}

.symbol-list-item .textnote:active svg {
  fill: #cecece
}

.symbol-list-item .estimate .value {
  border: 1px solid #c9cbcd;
  color: #c9cbcd;
  cursor: default;
  display: inline-block;
  font-size: 10px;
  height: 13px;
  margin: 8px 0 0;
  overflow: hidden;
  text-align: center;
  width: 13px
}

.symbol-list-item .estimate .value.soon {
  border: 1px solid #dc5538;
  color: #dc5538
}

.symbol-list-item .endofday {
  background-image: url(data:image/gif;base64,R0lGODlhBAAGAPABAOzw8////yH5BAUKAAEALAAAAAAEAAYAAAIGhB15qBlbADs=)
}

.dl-delayed {
  margin: 0 0 0 1ex
}

.symbol-list-item .delayed,.symbol-list-item .delayed_streaming,.symbol-list-item .endofday {
  margin: 3px 0 2px 3px
}

body {
  background-color: #fff
}

html.theme-dark body {
  background-color: #131722
}

body.tv-body--bg_superdark,html.theme-dark body.tv-body--bg_superdark {
  background: #131722
}

body.tv-body--bg_contrast {
  background-color: #fff
}

html.theme-dark body.tv-body--bg_contrast {
  background-color: #1e222d
}

body.extension {
  min-width: 0;
  overflow: hidden
}

img {
  border: none
}

textarea {
  resize: none
}

:focus {
  outline: none
}

input,textarea {
  border-radius: 0
}

.tv-profile .tags,.unselectable {
  -webkit-user-select: none;
  user-select: none
}

.selectable,input,textarea {
  -webkit-user-select: text;
  user-select: text
}

.text-center {
  text-align: center
}

#footer {
  background: #dbdbdb;
  border-top: 1px solid #999;
  color: #626262;
  font-size: small;
  height: 24px
}

#chart-area {
  background: #0000
}

#chart-area,.chart-container {
  position: relative
}

#chart-area.fullscreen {
  height: 100%!important;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%!important;
  z-index: 15
}

.chart-page .chart-container {
  contain: strict;
  position: relative
}

.chart-page .chart-container-border {
  background-color: #fff;
  border: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0
}

html.theme-dark .chart-page .chart-container-border {
  background-color: #131722
}

.chart-page .chart-container.multiple.active:after {
  border: 2px solid #2962ff;
  bottom: 0;
  box-sizing: border-box;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0
}

html.theme-dark .chart-page .chart-container.multiple.active:after {
  border-color: #2962ff
}

.chart-page .chart-container.inactive .back-to-present {
  display: none
}

.chart-page .chart-container.no-header-toolbar .chart-container-border {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.chart-page .chart-container.no-bottom-toolbar .chart-container-border {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.ui-tabs .ui-tabs-nav li a {
  font-size: x-small
}

.input-reset {
  border-radius: 50%;
  cursor: pointer;
  display: none;
  height: 16px;
  position: absolute;
  right: 7px;
  top: 7px;
  width: 16px
}

.input-reset:before {
  content: " ";
  display: block;
  height: 10px;
  left: 50%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 50%;
  width: 10px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .input-reset:hover {
      background-color:#f2f5f8
  }
}

.input-reset:active {
  opacity: .6
}

.loading-indicator {
  background: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 150
}

html.theme-dark .loading-indicator {
  background: #131722
}

.loading-indicator img {
  left: 50%;
  margin: -58px 0 0 -58px;
  position: absolute;
  top: 50%
}

.loading-indicator img.small {
  margin: -12px 0 0 -12px
}

.ui-slider-horizontal {
  background-color: #f4f7f9;
  border: 1px solid #c9cbcd!important;
  box-shadow: inset 0 1px 2px #00000026;
  height: 5px!important;
  margin: 7px 0 0
}

html.theme-dark .ui-slider-horizontal {
  background-color: #171b29;
  border-color: #2f3241!important
}

.ui-slider-handle {
  border: none!important;
  height: 15px!important;
  margin-left: -7px!important;
  top: -5px!important;
  width: 15px!important
}

.ui-slider-horizontal.linewidth-slider {
  border: none!important;
  box-shadow: none;
  display: inline-block;
  height: 12px!important;
  margin: 7px 10px;
  vertical-align: middle;
  width: 101px
}

.ui-slider-horizontal.linewidth-slider:after,.ui-slider-horizontal.linewidth-slider:before {
  content: none
}

.ui-slider-horizontal.transparency-slider {
  height: 8px!important;
  margin: 7px 10px;
  width: 99px
}

.ui-slider-horizontal.transparency-slider.wide-slider {
  margin: 7px 1px;
  width: 176px
}

.ui-slider-horizontal.transparency-slider .gradient {
  background: linear-gradient(90deg,#0000 0,#7db9e8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 92%
}

.ui-slider-horizontal.linewidth-slider .ui-slider-handle,.ui-slider-horizontal.transparency-slider .ui-slider-handle {
  height: 20px!important;
  margin: -10px 0 0 -10px!important;
  top: 50%!important;
  width: 20px!important
}

.ui-slider-horizontal.linewidth-slider .ui-slider-handle.without-shift-handle-left,.ui-slider-horizontal.transparency-slider .ui-slider-handle.without-shift-handle-left {
  margin: -10px 3px 0 -3px!important
}

.falling,.growing,.search-results .symbol-last.falling,.search-results .symbol-last.growing {
  color: #fff
}

.growing {
  background: #3cbc98
}

.falling {
  background: #ff4a68
}

.font-awesome {
  font-family: FontAwesome;
  font-weight: 700
}

#chart-area .chart-promo,#chart-container .chart-promo,.tv-chart-view__chart-inner .chart-promo {
  background: #fff;
  bottom: 55px;
  display: none;
  height: 80px;
  left: 10px;
  position: absolute;
  transition: width .5s ease-in-out;
  width: 0;
  z-index: 5
}

#chart-area .chart-promo.expanded,#chart-container .chart-promo.expanded,.tv-chart-view__chart-inner .chart-promo.expanded {
  display: block;
  opacity: 1;
  width: 330px
}

#chart-area .chart-promo a.chart-promo-link,#chart-container .chart-promo a.chart-promo-link,.tv-chart-view__chart-inner .chart-promo a.chart-promo-link {
  border: 1px solid #dadee1;
  border-radius: 4px;
  box-shadow: 0 0 30px 0 #00000026;
  color: #000;
  display: block;
  height: 80px;
  width: 330px
}

#chart-area .chart-promo a.chart-promo-link .image-promo,#chart-container .chart-promo a.chart-promo-link .image-promo,.tv-chart-view__chart-inner .chart-promo a.chart-promo-link .image-promo {
  height: 80px;
  width: 330px
}

#chart-area .chart-promo a.chart-promo-link .comment,#chart-container .chart-promo a.chart-promo-link .comment,.tv-chart-view__chart-inner .chart-promo a.chart-promo-link .comment {
  color: #555;
  display: block;
  font-size: 12px;
  line-height: 60px;
  margin-top: -2px;
  padding: 10px 128px 10px 22px
}

#chart-area .chart-promo a.chart-promo-link .comment .comment-alignment,#chart-container .chart-promo a.chart-promo-link .comment .comment-alignment,.tv-chart-view__chart-inner .chart-promo a.chart-promo-link .comment .comment-alignment {
  display: inline-block;
  line-height: 15px;
  max-height: 60px;
  overflow: hidden;
  vertical-align: middle;
  white-space: pre-wrap
}

#chart-area .chart-promo a.chart-promo-link .logo,#chart-container .chart-promo a.chart-promo-link .logo,.tv-chart-view__chart-inner .chart-promo a.chart-promo-link .logo {
  float: right;
  height: 80px;
  line-height: 80px;
  text-align: center;
  width: 128px
}

#chart-area .chart-promo a.chart-promo-link .logo img,#chart-container .chart-promo a.chart-promo-link .logo img,.tv-chart-view__chart-inner .chart-promo a.chart-promo-link .logo img {
  max-height: 100%;
  max-width: 85px;
  vertical-align: middle
}

#chart-area .chart-promo a.remove-ad,#chart-container .chart-promo a.remove-ad,.tv-chart-view__chart-inner .chart-promo a.remove-ad {
  background: #ecf1f4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #5cbfe7;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  opacity: .8;
  padding: 0 11px;
  position: absolute;
  right: 0;
  top: -18px
}

#chart-container .chart-promo,.tv-chart-view__chart-inner .chart-promo {
  bottom: 30px
}

#myswf {
  left: 0;
  position: fixed;
  top: 0
}

.tv-blue-background-block,html.theme-dark .tv-blue-background-block {
  background: #2962ff
}

.tv-blue-background-block {
  padding: 100px 0 110px
}

.tv-blue-background-block__header {
  color: #fff;
  font-size: 45px;
  letter-spacing: 1px;
  line-height: 59px;
  margin-bottom: 27px;
  margin-top: 30px
}

@media screen and (max-width: 1019px) {
  .tv-blue-background-block__header {
      font-size:38px
  }
}

@media screen and (max-width: 767px) {
  .tv-blue-background-block__header {
      font-size:32px
  }
}

@media screen and (max-width: 479px) {
  .tv-blue-background-block__header {
      font-size:28px
  }
}

.tv-blue-background-block__text {
  color: #131722;
  color: #fffc!important;
  font-size: 14px;
  font-size: 19px;
  line-height: 1.6;
  margin-bottom: 12px
}

html.theme-dark .tv-blue-background-block__text {
  color: #d1d4dc
}

@media screen and (max-width: 767px) {
  .tv-blue-background-block__text {
      font-size:18px
  }
}

.tv-blue-background-block__text+.tv-blue-background-block__text {
  margin-top: 15px
}

.tv-blue-background-block__text--width_75per {
  margin: 0 auto;
  width: 75%
}

.tv-blue-background-block__text a#blue-background-block-link,.tv-blue-background-block__text a.blue-background-block-link {
  border-bottom: 1px solid #fff;
  color: #fff;
  text-decoration: none
}

html.theme-dark .tv-blue-background-block__text a#blue-background-block-link,html.theme-dark .tv-blue-background-block__text a.blue-background-block-link {
  color: #fff
}

.tv-blue-background-block__button-wrapper {
  margin-top: 40px
}

.tv-blue-background-block__button-wrapper .tv-blue-background-block__button {
  background-color: #fff;
  border-color: #fff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-blue-background-block__button-wrapper .tv-blue-background-block__button:hover {
      background-color:#2962ff;
      border-color: #fff;
      color: #fff
  }
}

.tv-blue-background-block--semisize .tv-blue-background-block__header {
  font-size: 31px;
  letter-spacing: 1px;
  line-height: 36px;
  margin-bottom: 40px;
  margin-top: 0
}

@media screen and (max-width: 1019px) {
  .tv-blue-background-block--semisize .tv-blue-background-block__header {
      font-size:26px
  }
}

@media screen and (max-width: 479px) {
  .tv-blue-background-block--semisize .tv-blue-background-block__header {
      font-size:24px
  }
}

.tv-blue-background-block--semisize .tv-blue-background-block__text,.tv-blue-background-block--small .tv-blue-background-block__text {
  font-size: 17px
}

@media screen and (max-width: 767px) {
  .tv-blue-background-block--semisize .tv-blue-background-block__text,.tv-blue-background-block--small .tv-blue-background-block__text {
      font-size:16px
  }
}

.tv-blue-background-block--small {
  padding: 70px 0
}

.tv-blue-background-block--small .tv-blue-background-block__header {
  font-size: 31px;
  line-height: 39px;
  margin-bottom: 24px;
  margin-top: 0
}

@media screen and (max-width: 1019px) {
  .tv-blue-background-block--small .tv-blue-background-block__header {
      font-size:26px
  }
}

@media screen and (max-width: 479px) {
  .tv-blue-background-block--small .tv-blue-background-block__header {
      font-size:24px
  }
}

.tv-blue-background-block--widget-header-padding {
  padding-bottom: 65px;
  padding-top: 75px
}

.tv-caret {
  border-color: currentcolor #0000 #0000;
  border-style: solid;
  border-width: 4px 4px 0;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  transition: transform .35s ease;
  vertical-align: middle;
  width: 0
}

.active .tv-caret,.i-dropped .tv-caret:not(.tv-caret--strict),.tv-caret--strict.i-dropped {
  transform: rotate(-180deg);
  transition-duration: .33;
  will-change: transform
}

.tv-caret--small {
  border-left-width: 3px;
  border-right-width: 3px;
  border-top-width: 3px;
  margin-left: 3px;
  margin-right: -1px
}

.tv-caret--colored {
  transition: transform .35s ease,color .35s ease
}

.tv-caret--no-margin {
  margin-left: 0
}

.tv-chart-sponsor {
  bottom: 25px;
  box-sizing: border-box;
  display: none;
  left: 10px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transform: translateX(-15px);
  transition: transform .5s cubic-bezier(.215,.61,.355,1),opacity .5s cubic-bezier(.215,.61,.355,1);
  width: 0
}

.tv-chart-sponsor--on_platform {
  bottom: 50px;
  left: 15px
}

.sp-active .tv-chart-sponsor {
  display: block
}

.tv-chart-sponsor.i-visible {
  opacity: 1;
  transform: translateX(0);
  width: auto
}

.tv-chart-sponsor__text {
  color: #757575;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: -10px;
  opacity: .8;
  padding-left: 39px;
  padding-top: 5px
}

.tv-chart-sponsor__logo svg {
  fill: #154b94;
  height: 44px;
  opacity: .8;
  padding: 0 6px;
  width: 117px
}

.tv-chart-sponsor>img {
  width: 117px
}

.tv-chart-updates {
  border-collapse: none;
  display: table;
  table-layout: fixed;
  width: 100%
}

@media screen and (max-width: 767px) {
  .tv-chart-updates {
      display:block
  }
}

.tv-chart-updates__entry {
  display: table-row;
  position: relative;
  vertical-align: top
}

@media screen and (max-width: 767px) {
  .tv-chart-updates__entry {
      display:block
  }
}

.tv-chart-updates__created {
  box-sizing: border-box;
  color: #b4b4b4;
  display: table-cell;
  padding: 0 20px 16px 0;
  text-align: right;
  width: 110px
}

@media screen and (max-width: 767px) {
  .tv-chart-updates__created {
      display:block;
      padding: 0 0 10px 30px;
      text-align: left;
      width: auto
  }
}

.tv-chart-updates__created-wrap {
  font-size: 12px;
  height: 12px;
  position: relative
}

.tv-chart-updates__body {
  color: #131722;
  display: table-cell;
  font-size: 14px;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 0;
  padding: 0 20px 16px;
  position: relative
}

html.theme-dark .tv-chart-updates__body {
  color: #d1d4dc
}

.tv-chart-updates__body:before {
  background-color: #3cbc98;
  border-radius: 50%;
  content: "";
  display: block;
  height: 13px;
  left: -7px;
  position: absolute;
  top: 5px;
  transition: transform .35s cubic-bezier(.68,-.55,.265,1.55);
  width: 13px
}

@media screen and (max-width: 767px) {
  .tv-chart-updates__body:before {
      left:3px;
      top: 2px
  }
}

.tv-chart-updates__body:after {
  background-color: #3cbc98;
  content: "";
  display: block;
  height: 100%;
  left: -1px;
  position: absolute;
  top: 13px;
  transition: height .4375s linear .35s;
  width: 1px
}

@media screen and (max-width: 767px) {
  .tv-chart-updates__body:after {
      left:8px;
      top: 5px
  }
}

.tv-chart-updates__body--no-timeline {
  padding: 0
}

.tv-chart-updates__body--no-timeline:after,.tv-chart-updates__body--no-timeline:before {
  display: none
}

@media screen and (max-width: 767px) {
  .tv-chart-updates__body {
      display:block;
      font-size: 14px;
      padding-bottom: 25px;
      position: static
  }
}

.tv-chart-updates__entry:last-child .tv-chart-updates__body,.tv-chart-updates__entry:last-child .tv-chart-updates__created {
  padding-bottom: 20px
}

.tv-chart-updates__entry:last-child .tv-chart-updates__body--no-timeline {
  padding-bottom: 0
}

.tv-chart-updates__body-wrap {
  word-break: break-word
}

.tv-chart-updates__body-wrap .embeded-chart-image {
  display: block
}

.tv-chart-updates__body-wrap .embeded-chart-image img {
  max-width: 100%
}

.tv-chart-updates__body-wrap,.tv-chart-updates__created-wrap {
  transition: opacity .4375s cubic-bezier(.215,.61,.355,1) .175s
}

.tv-chart-updates__entry:last-child .tv-chart-updates__body:after {
  height: 0
}

.tv-chart-updates__entry--initial .tv-chart-updates__body:before {
  height: 17px;
  left: -9px;
  top: 3px;
  width: 17px
}

@media screen and (max-width: 767px) {
  .tv-chart-updates__entry--initial .tv-chart-updates__body:before {
      left:0;
      top: -2px
  }
}

.tv-chart-updates__entry--update .tv-chart-updates__body:before {
  background-color: #fff;
  border: 2px solid #3cbc98;
  left: -9px;
  outline: 9px solid;
  outline-color: #fff;
  top: 4px
}

html.theme-dark .tv-chart-updates__entry--update .tv-chart-updates__body:before {
  background-color: #1e222d;
  outline-color: #1e222d
}

@media screen and (max-width: 767px) {
  .tv-chart-updates__entry--update .tv-chart-updates__body:before {
      left:1px
  }

  .tv-chart-updates__entry--update .tv-chart-updates__created {
      display: none
  }
}

.tv-chart-updates__entry.i-morphing .tv-chart-updates__body-wrap,.tv-chart-updates__entry.i-morphing .tv-chart-updates__created-wrap {
  opacity: 0;
  transition-delay: .1ms
}

.tv-chart-updates__entry.i-morphing .tv-chart-updates__body:before {
  background-color: #3cbc98;
  outline: 0 solid #fff;
  transform: scale(.2);
  transition: transform .4375s cubic-bezier(.645,.045,.355,1),background-color .1s ease .175s,outline .35s ease;
  will-change: transform
}

.tv-chart-updates__entry--update .tv-chart-updates__body-wrap,.tv-chart-updates__entry--update .tv-chart-updates__created-wrap {
  position: relative;
  top: -6px
}

.tv-chart-updates__label {
  font-weight: 700
}

.tv-chart-updates__label--target_reached {
  color: #3cbc98
}

.tv-chart-updates__label--stop_reached {
  color: #ff4a68
}

.tv-chart-updates__label--active {
  color: #2962ff
}

.tv-chart-updates__label--cancelled {
  color: #ff4a68
}

.tv-chart-updates__form {
  display: block;
  max-width: 525px
}

.tv-chart-updates__form-field {
  margin-bottom: 15px
}

.tv-chart-updates__form-buttons-container:after,.tv-chart-updates__form-inner:after {
  clear: both;
  content: "";
  display: table
}

.tv-chart-updates__form-button {
  float: left
}

.tv-chart-updates__entry.i-invisible .tv-chart-updates__body:before {
  transform: scale(0)
}

.tv-chart-updates__entry.i-invisible .tv-chart-updates__body-wrap,.tv-chart-updates__entry.i-invisible .tv-chart-updates__created-wrap {
  opacity: 0
}

.da-textimage__container {
  border-bottom: 1px solid #e0e3eb!important;
  border-color: #e0e3eb currentcolor!important;
  border-top: 1px solid #e0e3eb!important;
  margin-top: -1px;
  padding-bottom: 20px!important;
  padding-top: 20px!important
}

html.theme-dark .da-textimage__container {
  border-color: #434651!important
}

.tv-charts-list__title {
  color: #3cbc98;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase
}

.tv-charts-list__title-text {
  display: inline-block;
  font-size: 14px;
  vertical-align: middle
}

.tv-charts-list__title-icon {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle
}

.tv-charts-list__title-icon svg {
  fill: #3cbc98;
  display: block
}

.tv-charts-list__title-icon--rebounds svg,.tv-charts-list__title-icon--related svg {
  height: 18px;
  width: 18px
}

.tv-charts-list__avatar {
  float: left;
  margin-right: 10px
}

.tv-charts-list__avatar-img {
  border-radius: 2px;
  display: block;
  height: 36px;
  width: 36px
}

@media screen and (max-width: 767px) {
  .tv-charts-list__avatar-img {
      height:34px;
      width: 34px
  }
}

.tv-charts-list__info {
  padding-left: 46px
}

@media screen and (max-width: 767px) {
  .tv-charts-list__info {
      padding-left:44px
  }
}

.tv-charts-list__item {
  display: block;
  margin-bottom: 20px
}

.tv-charts-list__item:after {
  clear: both;
  content: "";
  display: table
}

.tv-charts-list__item:last-child {
  margin-bottom: 0
}

.tv-charts-list__item-title {
  color: #2962ff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color .35s ease;
  white-space: nowrap
}

html.theme-dark .tv-charts-list__item-title {
  color: #2962ff
}

.tv-charts-list__item-title:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-charts-list__item-title:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-charts-list__item-title:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-charts-list__item-title:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-charts-list__item-title:focus {
  outline: auto
}

.tv-charts-list__item-title:focus:not(:-moz-focusring) {
  outline: none
}

.tv-charts-list__item-title:-moz-focusring {
  outline: auto
}

.tv-charts-list__item-title:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-charts-list__item-title:active {
  fill: #1848cc;
  color: #1848cc
}

@media screen and (max-width: 767px) {
  .tv-charts-list__item-title {
      margin-bottom:0
  }
}

.tv-charts-list__user-link {
  align-items: flex-end;
  display: inline-flex;
  font-weight: 700;
  margin-right: 10px
}

.tv-charts-list__user-name {
  display: inline-block;
  font-size: 13px;
  vertical-align: middle
}

@media screen and (max-width: 767px) {
  .tv-charts-list__stats {
      display:block;
      margin-top: 5px
  }
}

.tv-charts-list__symbol {
  color: #8a8a8a;
  display: inline-block;
  font-size: 13px
}

@media screen and (max-width: 767px) {
  .tv-charts-list__symbol {
      display:none
  }
}

.tv-charts-list__symbol-link {
  color: #2962ff;
  font-size: 13px;
  font-weight: 700;
  margin-right: 15px;
  transition: color .35s ease
}

html.theme-dark .tv-charts-list__symbol-link {
  color: #2962ff
}

.tv-charts-list__symbol-link:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-charts-list__symbol-link:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-charts-list__symbol-link:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-charts-list__symbol-link:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-charts-list__symbol-link:focus {
  outline: auto
}

.tv-charts-list__symbol-link:focus:not(:-moz-focusring) {
  outline: none
}

.tv-charts-list__symbol-link:-moz-focusring {
  outline: auto
}

.tv-charts-list__symbol-link:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-charts-list__symbol-link:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-charts-list__comments {
  color: #adaeb0;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px
}

.tv-charts-list__comments-icon {
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle
}

.tv-charts-list__comments-icon svg {
  fill: #adaeb0;
  display: block;
  height: 10px;
  margin-right: 2px;
  position: relative;
  top: -1px;
  width: 12px
}

.tv-charts-list__time {
  color: #adaeb0;
  font-size: 13px
}

.tv-content {
  flex: 1 0 auto
}

.tv-content__content-block {
  padding: 70px 0
}

.tv-content__content-block--padding_increased {
  padding: 100px 0
}

.tv-content__content-block--background_dark {
  background: #2a2c39
}

.tv-content__content-block--scrollable {
  overflow: auto
}

.tv-content__highlight-background {
  background: #f8f9fd
}

html.theme-dark .tv-content__highlight-background {
  background: #131722
}

.tv-content__layout-position {
  align-items: center;
  display: flex;
  flex-direction: column
}

.tv-content-header {
  color: #131722;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -.3px;
  line-height: 35px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis
}

html.theme-dark .tv-content-header {
  color: #d1d4dc
}

.tv-content-header__header-link:link,.tv-content-header__header-link:visited {
  color: #131722
}

html.theme-dark .tv-content-header__header-link:link,html.theme-dark .tv-content-header__header-link:visited {
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-content-header__header-link:hover,html.theme-dark .tv-content-header__header-link:hover {
      color:#1e53e5
  }
}

.tv-content-header__header-link svg,.tv-content-header__icon {
  margin-left: 8px
}

.tv-control-fieldset {
  display: block;
  margin-bottom: 30px;
  min-width: 0
}

.tv-control-fieldset:after {
  clear: both;
  content: "";
  display: table
}

.tv-control-fieldset--spacing_small {
  margin-bottom: 20px
}

.tv-control-fieldset--spacing_xsmall {
  margin-bottom: 10px
}

.tv-control-fieldset--first {
  margin-top: 12px
}

.tv-control-fieldset:last-child {
  margin-bottom: 0
}

.tv-control-fieldset--force-spacing {
  margin-bottom: 30px
}

.tv-control-fieldset--force-spacing.tv-control-fieldset--spacing_small {
  margin-bottom: 20px
}

@media screen and (max-width: 767px) {
  .tv-control-fieldset {
      margin-bottom:20px
  }
}

.tv-control-fieldset__label,.tv-control-fieldset__value {
  box-sizing: border-box;
  display: block;
  float: left
}

.tv-control-fieldset__label--vertical,.tv-control-fieldset__value--vertical {
  float: none
}

.tv-control-fieldset__label--checkbox,.tv-control-fieldset__value--checkbox {
  padding-top: 2px!important
}

@media screen and (max-width: 479px) {
  .tv-control-fieldset__label,.tv-control-fieldset__value {
      float:none
  }
}

.tv-control-fieldset__label {
  clear: left;
  color: #787b86;
  font-size: 13px;
  padding: 10px 20px 0 0;
  width: 30%
}

html.theme-dark .tv-control-fieldset__label {
  color: #787b86
}

.tv-control-fieldset__label--top {
  padding-top: 0
}

.tv-control-fieldset__label--vertical {
  padding: 0 0 4px;
  width: auto
}

.tv-control-fieldset__label--color_dark {
  color: #1c2030
}

html.theme-dark .tv-control-fieldset__label--color_dark {
  color: #f7f8fa
}

.tv-control-fieldset__label--width_small {
  width: 26%
}

@media screen and (max-width: 479px) {
  .tv-control-fieldset__label--width_small {
      width:auto
  }
}

.tv-control-fieldset__label--size_small {
  padding-top: 4px
}

@media screen and (max-width: 479px) {
  .tv-control-fieldset__label {
      padding:0 0 10px;
      width: auto
  }
}

.tv-control-fieldset__label-icon {
  display: inline-block;
  font-size: 12px;
  height: 12px;
  line-height: 1;
  vertical-align: super
}

.tv-control-fieldset__label-icon svg {
  height: 100%;
  width: auto
}

.tv-control-fieldset__value {
  width: 70%
}

.tv-control-fieldset__value--vertical {
  width: auto
}

.tv-control-fieldset__value--width_big {
  width: 74%
}

@media screen and (max-width: 479px) {
  .tv-control-fieldset__value,.tv-control-fieldset__value--width_big {
      width:auto
  }
}

.tv-control-fieldset__text {
  margin-bottom: 12px
}

.tv-control-fieldset__text:last-of-type {
  margin-bottom: 0
}

.tv-control-fieldset--block .tv-control-fieldset__label,.tv-control-fieldset--block .tv-control-fieldset__value {
  float: none;
  padding: 0;
  width: auto
}

.tv-control-fieldset--block .tv-control-fieldset__label {
  margin-bottom: 10px
}

.tv-control-fieldset__value-control {
  display: block
}

.tv-control-fieldset__hint {
  color: #434651;
  display: block;
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 10px;
  margin-top: 10px
}

html.theme-dark .tv-control-fieldset__hint {
  color: #c5cbce
}

.tv-control-fieldset__hint a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-control-fieldset__hint a {
  color: #2962ff
}

.tv-control-fieldset__hint a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-control-fieldset__hint a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-fieldset__hint a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-fieldset__hint a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-control-fieldset__hint a:focus {
  outline: auto
}

.tv-control-fieldset__hint a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-control-fieldset__hint a:-moz-focusring {
  outline: auto
}

.tv-control-fieldset__hint a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-control-fieldset__hint a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-control-fieldset .tv-control-input[readonly],html.theme-dark .tv-control-fieldset .tv-control-input[readonly] {
  color: #787b86
}

.tv-control-fieldset .tv-control-input--readonly_dark,.tv-control-fieldset .tv-control-input--readonly_dark[readonly],html.theme-dark .tv-control-fieldset .tv-control-input--readonly_dark,html.theme-dark .tv-control-fieldset .tv-control-input--readonly_dark[readonly] {
  color: #50535e
}

.tv-control-fieldset .tv-control-input.i-disabled::placeholder,.tv-control-fieldset .tv-control-input[disabled]::placeholder,.tv-control-fieldset .tv-control-number-input__input.i-disabled::placeholder,.tv-control-fieldset .tv-control-number-input__input[disabled]::placeholder,.tv-control-fieldset .tv-control-select__control.i-disabled::placeholder,.tv-control-fieldset .tv-control-select__control[disabled]::placeholder,.tv-control-fieldset select.tv-control-select.i-disabled::placeholder,.tv-control-fieldset select.tv-control-select[disabled]::placeholder,html.theme-dark .tv-control-fieldset .tv-control-input.i-disabled::placeholder,html.theme-dark .tv-control-fieldset .tv-control-input[disabled]::placeholder,html.theme-dark .tv-control-fieldset .tv-control-number-input__input.i-disabled::placeholder,html.theme-dark .tv-control-fieldset .tv-control-number-input__input[disabled]::placeholder,html.theme-dark .tv-control-fieldset .tv-control-select__control.i-disabled::placeholder,html.theme-dark .tv-control-fieldset .tv-control-select__control[disabled]::placeholder,html.theme-dark .tv-control-fieldset select.tv-control-select.i-disabled::placeholder,html.theme-dark .tv-control-fieldset select.tv-control-select[disabled]::placeholder {
  color: #787b86
}

.tv-control-group {
  -webkit-tap-highlight-color: transparent;
  border-collapse: initial;
  box-sizing: border-box;
  display: table;
  position: relative;
  width: 100%
}

.tv-control-group__item {
  border-radius: 0;
  display: table-cell;
  vertical-align: middle;
  width: 1%
}

.tv-control-group__item--first,.tv-control-group__item:first-child {
  border-radius: 2px 0 0 2px
}

.tv-control-group__item--last,.tv-control-group__item:last-child {
  border-radius: 0 2px 2px 0
}

.tv-control-group__item--main {
  float: left;
  width: 100%
}

.tv-control-group__item.tv-control-input {
  border-left-width: 0;
  border-right-width: 0
}

.tv-control-group__item--first.tv-control-input,.tv-control-group__item.tv-control-input:first-child {
  border-left-width: 1px
}

.tv-control-group__item--last.tv-control-input,.tv-control-group__item.tv-control-input:last-child {
  border-right-width: 1px
}

.tv-control-group__item:active {
  transform: translateY(0)
}

.tv-control-group--file {
  cursor: pointer
}

.tv-control-group--file input {
  pointer-events: none
}

.tv-control-input {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #e0e3eb;
  border-radius: 2px;
  box-sizing: border-box;
  color: #434651;
  display: block;
  font-size: 13px;
  height: 34px;
  padding: 0 12px;
  transition: border-color .35s ease,background-color .35s ease;
  width: 100%
}

html.theme-dark .tv-control-input {
  background-color: #1e222d;
  border-color: #363a45;
  color: #d1d4dc
}

.tv-control-input::placeholder {
  color: #a3a6af;
  opacity: 1
}

html.theme-dark .tv-control-input::placeholder {
  color: #50535e
}

.tv-control-input:-webkit-autofill {
  -webkit-text-fill-color: #535353!important;
  box-shadow: inset 0 0 0 1000px #fff!important
}

.tv-control-input--size_xsmall {
  height: 19px
}

.tv-control-input--size_small {
  height: 27px
}

.tv-control-input--size_large {
  font-size: 16px;
  height: 48px
}

.tv-control-input--connect {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.tv-control-input--connect_left {
  border-bottom-left-radius: 0;
  border-left: none;
  border-top-left-radius: 0
}

.tv-control-input--connect_right {
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input:hover {
      border-color:#c1c4cd;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-input:hover {
      border-color: #50535e
  }
}

.tv-control-input:focus {
  border-color: #2962ff!important;
  transition-duration: .06s
}

.tv-control-input[readonly] {
  border-color: #dadde0;
  color: #8a8a8a
}

.tv-control-input[readonly]:focus {
  border-color: #e0e3eb!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input[readonly]:hover {
      border-color:#e0e3eb!important
  }
}

html.theme-dark .tv-control-input[readonly]:focus {
  border-color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-input[readonly]:hover {
      border-color:#2a2e39!important
  }
}

.tv-control-input--readonly_dark,.tv-control-input--readonly_dark[readonly],html.theme-dark .tv-control-input--readonly_dark,html.theme-dark .tv-control-input--readonly_dark[readonly] {
  color: #50535e
}

.tv-control-input.i-disabled,.tv-control-input[disabled] {
  border-color: #e0e3eb!important;
  color: #e0e3eb!important
}

html.theme-dark .tv-control-input.i-disabled,html.theme-dark .tv-control-input[disabled] {
  border-color: #2a2e39!important;
  color: #2a2e39!important
}

.tv-control-input.i-disabled::placeholder,.tv-control-input[disabled]::placeholder {
  color: #e0e3eb!important
}

html.theme-dark .tv-control-input.i-disabled::placeholder,html.theme-dark .tv-control-input[disabled]::placeholder {
  color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input.i-disabled:hover,.tv-control-input[disabled]:hover {
      border-color:#e0e3eb!important
  }

  html.theme-dark .tv-control-input.i-disabled:hover,html.theme-dark .tv-control-input[disabled]:hover {
      border-color: #2a2e39!important
  }
}

.tv-control-input.i-error {
  border-color: #ff4a68!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input.i-error:hover {
      border-color:#f24965!important
  }
}

.tv-control-input.i-error:focus {
  border-color: #ff173e!important
}

.tv-control-input.i-success {
  border-color: #009688!important
}

html.theme-dark .tv-control-input.i-success {
  border-color: #00796b!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input.i-success:hover,html.theme-dark .tv-control-input.i-success:hover {
      border-color:#00897b!important
  }
}

.tv-control-input.i-success:active,.tv-control-input.i-success:focus {
  border-color: #00796b!important
}

html.theme-dark .tv-control-input.i-success:active,html.theme-dark .tv-control-input.i-success:focus {
  border-color: #009688!important
}

.tv-control-input--phone {
  direction: ltr;
  text-align: left
}

.tv-control-input--file {
  display: none
}

.tv-control-input-iconed {
  display: block;
  position: relative
}

.tv-control-input-iconed__input {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #e0e3eb;
  border-radius: 2px;
  box-sizing: border-box;
  color: #434651;
  display: block;
  font-size: 13px;
  height: 34px;
  line-height: 34px;
  padding: 0 12px;
  transition: border-color .35s ease,background-color .35s ease;
  width: 100%
}

html.theme-dark .tv-control-input-iconed__input {
  background-color: #1e222d;
  border-color: #363a45;
  color: #d1d4dc
}

.tv-control-input-iconed__input::placeholder {
  color: #a3a6af;
  opacity: 1
}

html.theme-dark .tv-control-input-iconed__input::placeholder {
  color: #50535e
}

.tv-control-input-iconed__input:-webkit-autofill {
  -webkit-text-fill-color: #535353!important;
  box-shadow: inset 0 0 0 1000px #fff!important
}

.tv-control-input-iconed__input--size_xsmall {
  height: 19px
}

.tv-control-input-iconed__input--size_small {
  height: 27px
}

.tv-control-input-iconed__input--size_large {
  font-size: 16px;
  height: 48px
}

.tv-control-input-iconed__input--connect {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.tv-control-input-iconed__input--connect_left {
  border-bottom-left-radius: 0;
  border-left: none;
  border-top-left-radius: 0
}

.tv-control-input-iconed__input--connect_right {
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input-iconed__input:hover {
      border-color:#c1c4cd;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-input-iconed__input:hover {
      border-color: #50535e
  }
}

.tv-control-input-iconed__input:focus {
  border-color: #2962ff!important;
  transition-duration: .06s
}

.tv-control-input-iconed__input[readonly] {
  border-color: #dadde0;
  color: #8a8a8a
}

.tv-control-input-iconed__input[readonly]:focus {
  border-color: #e0e3eb!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input-iconed__input[readonly]:hover {
      border-color:#e0e3eb!important
  }
}

html.theme-dark .tv-control-input-iconed__input[readonly]:focus {
  border-color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-input-iconed__input[readonly]:hover {
      border-color:#2a2e39!important
  }
}

.tv-control-input-iconed__input--readonly_dark,.tv-control-input-iconed__input--readonly_dark[readonly],html.theme-dark .tv-control-input-iconed__input--readonly_dark,html.theme-dark .tv-control-input-iconed__input--readonly_dark[readonly] {
  color: #50535e
}

.tv-control-input-iconed__input.i-disabled,.tv-control-input-iconed__input[disabled] {
  border-color: #e0e3eb!important;
  color: #e0e3eb!important
}

html.theme-dark .tv-control-input-iconed__input.i-disabled,html.theme-dark .tv-control-input-iconed__input[disabled] {
  border-color: #2a2e39!important;
  color: #2a2e39!important
}

.tv-control-input-iconed__input.i-disabled::placeholder,.tv-control-input-iconed__input[disabled]::placeholder {
  color: #e0e3eb!important
}

html.theme-dark .tv-control-input-iconed__input.i-disabled::placeholder,html.theme-dark .tv-control-input-iconed__input[disabled]::placeholder {
  color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input-iconed__input.i-disabled:hover,.tv-control-input-iconed__input[disabled]:hover {
      border-color:#e0e3eb!important
  }

  html.theme-dark .tv-control-input-iconed__input.i-disabled:hover,html.theme-dark .tv-control-input-iconed__input[disabled]:hover {
      border-color: #2a2e39!important
  }
}

.tv-control-input-iconed__input.i-error {
  border-color: #ff4a68!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input-iconed__input.i-error:hover {
      border-color:#f24965!important
  }
}

.tv-control-input-iconed__input.i-error:focus {
  border-color: #ff173e!important
}

.tv-control-input-iconed__input.i-success {
  border-color: #009688!important
}

html.theme-dark .tv-control-input-iconed__input.i-success {
  border-color: #00796b!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input-iconed__input.i-success:hover,html.theme-dark .tv-control-input-iconed__input.i-success:hover {
      border-color:#00897b!important
  }
}

.tv-control-input-iconed__input.i-success:active,.tv-control-input-iconed__input.i-success:focus {
  border-color: #00796b!important
}

html.theme-dark .tv-control-input-iconed__input.i-success:active,html.theme-dark .tv-control-input-iconed__input.i-success:focus {
  border-color: #009688!important
}

.tv-control-input-iconed__input--phone {
  direction: ltr;
  text-align: left
}

.tv-control-input-iconed__input--icon-pos_left {
  padding-left: 38px
}

.tv-control-input-iconed__input--icon-pos_right {
  padding-right: 38px
}

.tv-control-input-iconed__icon {
  display: block;
  height: 14px;
  opacity: .4;
  padding: 9px 13px 11px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity .35s ease;
  width: 14px
}

.tv-control-input-iconed__icon svg {
  fill: currentColor;
  stroke: currentColor;
  display: block;
  height: 14px;
  width: 14px
}

.tv-control-input-iconed__icon--action {
  cursor: pointer;
  pointer-events: auto
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-input-iconed__icon--action:hover {
      opacity:.5;
      transition-duration: .06s
  }
}

.tv-control-input-iconed__icon--action:active {
  opacity: .6;
  transition-duration: .06s
}

.tv-control-input-iconed__input:focus+.tv-control-input-iconed__icon {
  opacity: .5;
  transition-duration: .06s
}

.tv-control-input-iconed__icon-position_left {
  left: 0
}

.tv-control-input-iconed__icon-position_right {
  right: 0
}

.tv-control-table {
  color: #131722;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
  width: 100%
}

html.theme-dark .tv-control-table {
  color: #d1d4dc
}

.tv-control-table th {
  border-bottom: 2px solid #e0e3eb;
  color: #adaeb0;
  font-size: 13px;
  font-weight: 400;
  padding: 30px 16px 3px
}

html.theme-dark .tv-control-table th {
  border-bottom: 2px solid #434651
}

th.tv-control-table__title {
  color: #131722;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase
}

html.theme-dark th.tv-control-table__title {
  color: #d1d4dc
}

.tv-control-table td {
  color: #131722;
  padding: 18px 16px 4px
}

html.theme-dark .tv-control-table td {
  color: #d1d4dc
}

.tv-control-table td,.tv-control-table th {
  vertical-align: top
}

.tv-control-table td:first-child,.tv-control-table th:first-child {
  padding-left: 0
}

.tv-control-table td:last-child,.tv-control-table th:last-child {
  padding-right: 0
}

.tv-control-table tr:first-child td,.tv-control-table tr:first-child th {
  padding-top: 6px
}

.tv-control-textarea {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #e0e3eb;
  border-radius: 2px;
  box-sizing: border-box;
  color: #434651;
  display: block;
  font-size: 13px;
  height: 34px;
  height: auto;
  padding: 9px 12px;
  transition: border-color .35s ease,background-color .35s ease;
  width: 100%
}

html.theme-dark .tv-control-textarea {
  background-color: #1e222d;
  border-color: #363a45;
  color: #d1d4dc
}

.tv-control-textarea::placeholder {
  color: #a3a6af;
  opacity: 1
}

html.theme-dark .tv-control-textarea::placeholder {
  color: #50535e
}

.tv-control-textarea:-webkit-autofill {
  -webkit-text-fill-color: #535353!important;
  box-shadow: inset 0 0 0 1000px #fff!important
}

.tv-control-textarea--size_xsmall {
  height: 19px
}

.tv-control-textarea--size_small {
  height: 27px
}

.tv-control-textarea--size_large {
  font-size: 16px;
  height: 48px
}

.tv-control-textarea--connect {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.tv-control-textarea--connect_left {
  border-bottom-left-radius: 0;
  border-left: none;
  border-top-left-radius: 0
}

.tv-control-textarea--connect_right {
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-textarea:hover {
      border-color:#c1c4cd;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-textarea:hover {
      border-color: #50535e
  }
}

.tv-control-textarea:focus {
  border-color: #2962ff!important;
  transition-duration: .06s
}

.tv-control-textarea[readonly] {
  border-color: #dadde0;
  color: #8a8a8a
}

.tv-control-textarea[readonly]:focus {
  border-color: #e0e3eb!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-textarea[readonly]:hover {
      border-color:#e0e3eb!important
  }
}

html.theme-dark .tv-control-textarea[readonly]:focus {
  border-color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-textarea[readonly]:hover {
      border-color:#2a2e39!important
  }
}

.tv-control-textarea.i-disabled,.tv-control-textarea[disabled] {
  border-color: #e0e3eb!important;
  color: #e0e3eb!important
}

html.theme-dark .tv-control-textarea.i-disabled,html.theme-dark .tv-control-textarea[disabled] {
  border-color: #2a2e39!important;
  color: #2a2e39!important
}

.tv-control-textarea.i-disabled::placeholder,.tv-control-textarea[disabled]::placeholder {
  color: #e0e3eb!important
}

html.theme-dark .tv-control-textarea.i-disabled::placeholder,html.theme-dark .tv-control-textarea[disabled]::placeholder {
  color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-textarea.i-disabled:hover,.tv-control-textarea[disabled]:hover {
      border-color:#e0e3eb!important
  }

  html.theme-dark .tv-control-textarea.i-disabled:hover,html.theme-dark .tv-control-textarea[disabled]:hover {
      border-color: #2a2e39!important
  }
}

.tv-control-textarea.i-error {
  border-color: #ff4a68!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-textarea.i-error:hover {
      border-color:#f24965!important
  }
}

.tv-control-textarea.i-error:focus {
  border-color: #ff173e!important
}

.tv-control-textarea.i-success {
  border-color: #009688!important
}

html.theme-dark .tv-control-textarea.i-success {
  border-color: #00796b!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-textarea.i-success:hover,html.theme-dark .tv-control-textarea.i-success:hover {
      border-color:#00897b!important
  }
}

.tv-control-textarea.i-success:active,.tv-control-textarea.i-success:focus {
  border-color: #00796b!important
}

html.theme-dark .tv-control-textarea.i-success:active,html.theme-dark .tv-control-textarea.i-success:focus {
  border-color: #009688!important
}

.tv-control-textarea--phone {
  direction: ltr;
  text-align: left
}

.tv-control-textarea--readonly_dark,.tv-control-textarea--readonly_dark[readonly],html.theme-dark .tv-control-textarea--readonly_dark,html.theme-dark .tv-control-textarea--readonly_dark[readonly] {
  color: #50535e
}

.tv-embeded-image {
  margin-top: 5px
}

.tv-embeded-image:first-child {
  margin-top: 10px
}

.tv-embeded-image a {
  cursor: pointer;
  max-width: 800px
}

.tv-embeded-image__link {
  display: inline-block;
  position: relative
}

.tv-embeded-image img {
  border: 1px solid #e0e3eb;
  max-height: 500px;
  max-width: 100%
}

.tv-faq {
  padding: 70px 0
}

.tv-faq__wrapper {
  font-size: 0;
  margin: 0 auto
}

.tv-faq__title {
  color: #131722;
  font-size: 31px;
  letter-spacing: 1px;
  padding-bottom: 60px;
  text-align: center;
  text-transform: uppercase
}

html.theme-dark .tv-faq__title {
  color: #d1d4dc
}

.tv-faq__title--no-uppercase {
  text-transform: none
}

.tv-faq__title--page-title {
  padding-bottom: 30px
}

.tv-faq__page-description {
  color: #757575;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 60px
}

.tv-faq__item {
  display: inline-block;
  margin-bottom: 60px;
  width: 100%
}

.tv-faq__item--half.tv-faq__item--left {
  padding-right: 4%;
  vertical-align: top;
  width: 46%
}

.tv-faq__item--half {
  margin-bottom: 40px;
  width: 50%
}

.tv-faq__item--last {
  margin-bottom: 0
}

.tv-faq__question {
  color: #131722;
  font-size: 20px;
  font-weight: 600
}

html.theme-dark .tv-faq__question {
  color: #d1d4dc
}

.tv-faq__answer {
  color: #787b86;
  font-size: 14px;
  line-height: 24px;
  padding-top: 10px
}

html.theme-dark .tv-faq__answer {
  color: #787b86
}

.tv-faq--overflow_auto {
  overflow: auto
}

.tv-faq a {
  transition: color .35s ease
}

.tv-faq a,html.theme-dark .tv-faq a {
  color: #2962ff
}

.tv-faq a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-faq a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-faq a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-faq a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-faq a:focus {
  outline: auto
}

.tv-faq a:focus-visible {
  outline: auto
}

.tv-faq a:focus:not(:focus-visible) {
  outline: none
}

.tv-faq a:active {
  fill: #2962ff;
  color: #2962ff;
  transition-duration: 60ms
}

html.theme-dark .tv-faq a:active {
  fill: #2962ff;
  color: #2962ff
}

.tv-faq__ol-list {
  padding: 10px 0 0 25px
}

.tv-faq__ol-list-item {
  color: #787b86;
  font-size: 14px;
  line-height: 24px
}

html.theme-dark .tv-faq__ol-list-item {
  color: #787b86
}

.tv-faq__ol-list-item+.tv-faq__ol-list-item {
  margin-top: 15px
}

.tv-feed {
  margin: 48px -10px;
  min-height: 500px
}

.tv-feed:after {
  clear: both;
  content: "";
  display: table
}

.tv-feed--no-min-height {
  min-height: auto
}

.tv-feed--no-hindent {
  margin-left: 0;
  margin-right: 0
}

@media screen and (max-width: 1019px) {
  .tv-feed {
      margin:0 0 48px
  }
}

@media screen and (max-width: 767px) {
  .tv-feed {
      margin:0
  }
}

.tv-feed--no-vertical-margin {
  margin-bottom: 0;
  margin-top: 0
}

.tv-feed.i-animating,.tv-feed__page.i-animating {
  transition: opacity .6125s cubic-bezier(.215,.61,.355,1),transform .6125s cubic-bezier(.215,.61,.355,1)
}

.tv-feed.i-loading {
  pointer-events: none
}

.tv-feed.i-loading>* {
  opacity: .4;
  transition-duration: .06s
}

.tv-feed__preloader {
  position: absolute;
  z-index: 1
}

.tv-feed__preloader-wrapper {
  clear: both;
  display: block;
  height: 58px;
  margin-bottom: 20px;
  position: relative
}

.tv-feed.i-loading .tv-feed__preloader {
  opacity: 1
}

.tv-feed__column {
  float: left;
  min-height: 1px;
  width: 500px
}

@media screen and (max-width: 1019px) {
  .tv-feed__column {
      width:100%
  }
}

.tv-feed__empty {
  clear: both;
  color: #787b86;
  flex: 1 1 auto;
  font-size: 20px;
  padding: 100px 0;
  text-align: center;
  -webkit-user-select: none;
  user-select: none
}

html.theme-dark .tv-feed__empty {
  color: #787b86
}

.tv-feed__icon {
  color: #9598a1;
  padding: 13px
}

html.theme-dark .tv-feed__icon {
  color: #9598a1
}

.tv-feed__item-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1px
}

@media screen and (min-width: 1020px) {
  .tv-feed__item-list--scrollable-on-tablet {
      flex-wrap:wrap!important
  }
}

@media screen and (max-width: 1019px) {
  .tv-feed__item-list--scrollable-on-tablet {
      flex-wrap:nowrap!important
  }
}

.tv-feed__item {
  font-size: 13px;
  margin-bottom: 20px;
  padding: 0 10px;
  width: 480px
}

.tv-feed__item.js-streams-react-root {
  padding: 0
}

.tv-feed__item--width_two-columns {
  width: 980px
}

.tv-feed__item--width_full {
  box-sizing: border-box;
  width: 100%
}

.tv-feed__item--width_half {
  box-sizing: border-box;
  float: left;
  width: 50%
}

@media screen and (max-width: 1019px) {
  .tv-feed__item {
      padding:0;
      width: 100%
  }
}

.tv-feed__item--card {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  box-sizing: border-box;
  height: 100%;
  height: auto;
  margin: 0 10px 20px;
  overflow: hidden;
  padding: 0
}

html.theme-dark .tv-feed__item--card {
  background-color: #1e222d;
  box-shadow: 0 0 0 1px #0000
}

.tv-feed__item--deleted {
  position: relative
}

.tv-feed__item--deleted:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

@media screen and (max-width: 1019px) {
  .tv-feed__item {
      margin-bottom:10px
  }

  .tv-feed__item--without-margin-on-tablet {
      margin-bottom: 0
  }

  .tv-feed__item:last-of-type {
      border-bottom: none
  }
}

@media screen and (max-width: 767px) {
  .tv-feed__item--media-hidden_phone {
      display:none
  }
}

.tv-feed__item--filler {
  display: none
}

@media screen and (min-width: 1020px) {
  .tv-feed__item--filler-desktop {
      display:block
  }
}

.page-wide .tv-feed__item--filler-desktop {
  display: none
}

.page-wide .tv-feed__item--filler-wide {
  display: block
}

@media screen and (max-width: 1019px) {
  .tv-feed__item--filler-tablet {
      display:block
  }

  .tv-feed__item--tablet-horizontal-indent {
      margin-left: 8px;
      margin-right: 8px;
      width: calc(100% - 16px)
  }

  .tv-feed__item--scrollable-on-tablet {
      flex-grow: 1;
      flex-shrink: 0;
      margin-left: 0;
      margin-right: 8px;
      width: 292px
  }

  .tv-feed__item--scrollable-on-tablet:first-child {
      margin-left: 8px
  }
}

@media screen and (max-width: 767px) {
  .tv-feed__item--scrollable-on-tablet {
      max-width:348px;
      width: calc(100vw - 66px)
  }

  .tv-feed__item--scrollable-on-tablet:only-child {
      max-width: none
  }
}

@media screen and (max-width: 1019px) {
  .tv-card-container__widgets .tv-feed__item {
      background:#fff;
      border-bottom: 2px solid #e0e3eb;
      margin-bottom: 0
  }

  html.theme-dark .tv-card-container__widgets .tv-feed__item {
      background: #131722;
      border-bottom: 2px solid #434651
  }
}

.tv-feed__section-title {
  align-items: center;
  display: flex;
  justify-content: space-between
}

@media screen and (max-width: 1019px) {
  .tv-feed__section-title {
      align-items:baseline;
      margin-left: 12px
  }
}

.tv-feed__empty-space {
  float: left;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 480px
}

.tv-feed__page {
  display: none;
  position: relative
}

.tv-feed__page:after {
  clear: both;
  content: "";
  display: table
}

.tv-feed__page.i-active {
  display: block
}

.tv-feed__page--notifications {
  background-color: #fff;
  margin-bottom: 20px
}

html.theme-dark .tv-feed__page--notifications {
  background-color: #1e222d
}

@media screen and (min-width: 1020px) {
  .tv-feed__page--notifications {
      margin-left:10px;
      margin-right: 10px
  }
}

.tv-feed__page--no-vindent {
  margin-top: -48px
}

@media screen and (max-width: 1019px) {
  .tv-feed__page--no-vindent {
      margin-bottom:0;
      margin-top: 0
  }
}

.tv-feed--position_relative {
  position: relative
}

.tv-feed--no-margin {
  margin: 0
}

.tv-feed__locale-links-wrapper {
  font-size: 15px;
  line-height: 34px;
  padding: 15px 25px;
  word-spacing: 10px
}

.tv-feed__locale-links-wrapper--has-ideas {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden
}

html.theme-dark .tv-feed__locale-links-wrapper--has-ideas {
  background-color: #1e222d;
  box-shadow: 0 0 0 1px #0000
}

.tv-feed__locale-links-wrap-text {
  color: #131722;
  word-spacing: normal
}

html.theme-dark .tv-feed__locale-links-wrap-text {
  color: #d1d4dc
}

.tv-feed .tv-feed__locale-link {
  border-radius: 15px;
  display: inline-block;
  font-size: 14px;
  height: 18px;
  line-height: 16px;
  margin: 0;
  padding: 5px 13px 5px 10px;
  word-spacing: normal
}

.tv-feed__locale-link-flag,.tv-feed__locale-link-text {
  vertical-align: middle
}

.tv-feed__locale-link-count {
  color: #9598a1;
  vertical-align: middle
}

html.theme-dark .tv-feed__locale-link-count {
  color: #9598a1
}

.tv-feed__anchor {
  float: left;
  height: 0;
  overflow: hidden
}

.tv-feed-list__item {
  border-bottom: 1px solid #e0e3eb;
  padding: 20px 0
}

html.theme-dark .tv-feed-list__item {
  border-bottom: 1px solid #434651
}

.tv-feed-list__item:first-child {
  padding-top: 0
}

.tv-feed-list__item:last-child {
  border-bottom: none;
  padding-bottom: 0
}

html.theme-dark .tv-feed-list__item:last-child {
  border-bottom: none
}

.tv-feed-list__item--card {
  background-color: #fff;
  border-bottom: 1px solid #0000;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 20px
}

html.theme-dark .tv-feed-list__item--card {
  background-color: #1e222d;
  border-bottom: 1px solid #0000;
  box-shadow: 0 0 0 1px #0000
}

.tv-feed-list__item--card:first-child {
  padding-top: 20px
}

.tv-feed-list__item--card:last-child {
  padding-bottom: 20px
}

.tv-feed-list__link {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-feed-list__link {
  color: #2962ff
}

.tv-feed-list__link:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-feed-list__link:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-feed-list__link:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-feed-list__link:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-feed-list__link:focus {
  outline: auto
}

.tv-feed-list__link:focus:not(:-moz-focusring) {
  outline: none
}

.tv-feed-list__link:-moz-focusring {
  outline: auto
}

.tv-feed-list__link:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-feed-list__link:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-grid:after {
  clear: both;
  content: "";
  display: table
}

.tv-grid__col {
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative
}

.tv-grid__col--padding_0 {
  margin: auto;
  padding: 0
}

.tv-grid--spacing_big {
  margin: 0 -15px
}

.tv-grid--spacing_big .tv-grid__col {
  padding: 0 15px
}

.tv-grid__margin_24 {
  margin-bottom: 24px
}

.tv-grid__centering_text {
  display: flex
}

.tv-grid__col_xs--1,.tv-grid__col_xs--2,.tv-grid__col_xs--3,.tv-grid__col_xs--4,.tv-grid__col_xs--5,.tv-grid__col_xs--6,.tv-grid__col_xs--7,.tv-grid__col_xs--8,.tv-grid__col_xs--9,.tv-grid__col_xs--10,.tv-grid__col_xs--11,.tv-grid__col_xs--12 {
  float: left
}

.tv-grid__col_xs--12 {
  width: 100%
}

.tv-grid__col_xs--11 {
  width: 91.66666667%
}

.tv-grid__col_xs--10 {
  width: 83.33333333%
}

.tv-grid__col_xs--9 {
  width: 75%
}

.tv-grid__col_xs--8 {
  width: 66.66666667%
}

.tv-grid__col_xs--7 {
  width: 58.33333333%
}

.tv-grid__col_xs--6 {
  width: 50%
}

.tv-grid__col_xs--5 {
  width: 41.66666667%
}

.tv-grid__col_xs--4 {
  width: 33.33333333%
}

.tv-grid__col_xs--3 {
  width: 25%
}

.tv-grid__col_xs--2 {
  width: 16.66666667%
}

.tv-grid__col_xs--1 {
  width: 8.33333333%
}

.tv-grid__col_xs-pull--12 {
  right: 100%
}

.tv-grid__col_xs-pull--11 {
  right: 91.66666667%
}

.tv-grid__col_xs-pull--10 {
  right: 83.33333333%
}

.tv-grid__col_xs-pull--9 {
  right: 75%
}

.tv-grid__col_xs-pull--8 {
  right: 66.66666667%
}

.tv-grid__col_xs-pull--7 {
  right: 58.33333333%
}

.tv-grid__col_xs-pull--6 {
  right: 50%
}

.tv-grid__col_xs-pull--5 {
  right: 41.66666667%
}

.tv-grid__col_xs-pull--4 {
  right: 33.33333333%
}

.tv-grid__col_xs-pull--3 {
  right: 25%
}

.tv-grid__col_xs-pull--2 {
  right: 16.66666667%
}

.tv-grid__col_xs-pull--1 {
  right: 8.33333333%
}

.tv-grid__col_xs-pull--0 {
  right: auto
}

.tv-grid__col_xs-push--12 {
  left: 100%
}

.tv-grid__col_xs-push--11 {
  left: 91.66666667%
}

.tv-grid__col_xs-push--10 {
  left: 83.33333333%
}

.tv-grid__col_xs-push--9 {
  left: 75%
}

.tv-grid__col_xs-push--8 {
  left: 66.66666667%
}

.tv-grid__col_xs-push--7 {
  left: 58.33333333%
}

.tv-grid__col_xs-push--6 {
  left: 50%
}

.tv-grid__col_xs-push--5 {
  left: 41.66666667%
}

.tv-grid__col_xs-push--4 {
  left: 33.33333333%
}

.tv-grid__col_xs-push--3 {
  left: 25%
}

.tv-grid__col_xs-push--2 {
  left: 16.66666667%
}

.tv-grid__col_xs-push--1 {
  left: 8.33333333%
}

.tv-grid__col_xs-push--0 {
  left: auto
}

.tv-grid__col_xs-offset--12 {
  margin-left: 100%
}

.tv-grid__col_xs-offset--11 {
  margin-left: 91.66666667%
}

.tv-grid__col_xs-offset--10 {
  margin-left: 83.33333333%
}

.tv-grid__col_xs-offset--9 {
  margin-left: 75%
}

.tv-grid__col_xs-offset--8 {
  margin-left: 66.66666667%
}

.tv-grid__col_xs-offset--7 {
  margin-left: 58.33333333%
}

.tv-grid__col_xs-offset--6 {
  margin-left: 50%
}

.tv-grid__col_xs-offset--5 {
  margin-left: 41.66666667%
}

.tv-grid__col_xs-offset--4 {
  margin-left: 33.33333333%
}

.tv-grid__col_xs-offset--3 {
  margin-left: 25%
}

.tv-grid__col_xs-offset--2 {
  margin-left: 16.66666667%
}

.tv-grid__col_xs-offset--1 {
  margin-left: 8.33333333%
}

.tv-grid__col_xs-offset--0 {
  margin-left: 0
}

@media (min-width: 768px) {
  .tv-grid__col_sm--1,.tv-grid__col_sm--2,.tv-grid__col_sm--3,.tv-grid__col_sm--4,.tv-grid__col_sm--5,.tv-grid__col_sm--6,.tv-grid__col_sm--7,.tv-grid__col_sm--8,.tv-grid__col_sm--9,.tv-grid__col_sm--10,.tv-grid__col_sm--11,.tv-grid__col_sm--12 {
      float:left
  }

  .tv-grid__col_sm--12 {
      width: 100%
  }

  .tv-grid__col_sm--11 {
      width: 91.66666667%
  }

  .tv-grid__col_sm--10 {
      width: 83.33333333%
  }

  .tv-grid__col_sm--9 {
      width: 75%
  }

  .tv-grid__col_sm--8 {
      width: 66.66666667%
  }

  .tv-grid__col_sm--7 {
      width: 58.33333333%
  }

  .tv-grid__col_sm--6 {
      width: 50%
  }

  .tv-grid__col_sm--5 {
      width: 41.66666667%
  }

  .tv-grid__col_sm--4 {
      width: 33.33333333%
  }

  .tv-grid__col_sm--3 {
      width: 25%
  }

  .tv-grid__col_sm--2 {
      width: 16.66666667%
  }

  .tv-grid__col_sm--1 {
      width: 8.33333333%
  }

  .tv-grid__col_sm-pull--12 {
      right: 100%
  }

  .tv-grid__col_sm-pull--11 {
      right: 91.66666667%
  }

  .tv-grid__col_sm-pull--10 {
      right: 83.33333333%
  }

  .tv-grid__col_sm-pull--9 {
      right: 75%
  }

  .tv-grid__col_sm-pull--8 {
      right: 66.66666667%
  }

  .tv-grid__col_sm-pull--7 {
      right: 58.33333333%
  }

  .tv-grid__col_sm-pull--6 {
      right: 50%
  }

  .tv-grid__col_sm-pull--5 {
      right: 41.66666667%
  }

  .tv-grid__col_sm-pull--4 {
      right: 33.33333333%
  }

  .tv-grid__col_sm-pull--3 {
      right: 25%
  }

  .tv-grid__col_sm-pull--2 {
      right: 16.66666667%
  }

  .tv-grid__col_sm-pull--1 {
      right: 8.33333333%
  }

  .tv-grid__col_sm-pull--0 {
      right: auto
  }

  .tv-grid__col_sm-push--12 {
      left: 100%
  }

  .tv-grid__col_sm-push--11 {
      left: 91.66666667%
  }

  .tv-grid__col_sm-push--10 {
      left: 83.33333333%
  }

  .tv-grid__col_sm-push--9 {
      left: 75%
  }

  .tv-grid__col_sm-push--8 {
      left: 66.66666667%
  }

  .tv-grid__col_sm-push--7 {
      left: 58.33333333%
  }

  .tv-grid__col_sm-push--6 {
      left: 50%
  }

  .tv-grid__col_sm-push--5 {
      left: 41.66666667%
  }

  .tv-grid__col_sm-push--4 {
      left: 33.33333333%
  }

  .tv-grid__col_sm-push--3 {
      left: 25%
  }

  .tv-grid__col_sm-push--2 {
      left: 16.66666667%
  }

  .tv-grid__col_sm-push--1 {
      left: 8.33333333%
  }

  .tv-grid__col_sm-push--0 {
      left: auto
  }

  .tv-grid__col_sm-offset--12 {
      margin-left: 100%
  }

  .tv-grid__col_sm-offset--11 {
      margin-left: 91.66666667%
  }

  .tv-grid__col_sm-offset--10 {
      margin-left: 83.33333333%
  }

  .tv-grid__col_sm-offset--9 {
      margin-left: 75%
  }

  .tv-grid__col_sm-offset--8 {
      margin-left: 66.66666667%
  }

  .tv-grid__col_sm-offset--7 {
      margin-left: 58.33333333%
  }

  .tv-grid__col_sm-offset--6 {
      margin-left: 50%
  }

  .tv-grid__col_sm-offset--5 {
      margin-left: 41.66666667%
  }

  .tv-grid__col_sm-offset--4 {
      margin-left: 33.33333333%
  }

  .tv-grid__col_sm-offset--3 {
      margin-left: 25%
  }

  .tv-grid__col_sm-offset--2 {
      margin-left: 16.66666667%
  }

  .tv-grid__col_sm-offset--1 {
      margin-left: 8.33333333%
  }

  .tv-grid__col_sm-offset--0 {
      margin-left: 0
  }
}

@media (min-width: 992px) {
  .tv-grid__col_md--1,.tv-grid__col_md--2,.tv-grid__col_md--3,.tv-grid__col_md--4,.tv-grid__col_md--5,.tv-grid__col_md--6,.tv-grid__col_md--7,.tv-grid__col_md--8,.tv-grid__col_md--9,.tv-grid__col_md--10,.tv-grid__col_md--11,.tv-grid__col_md--12 {
      float:left
  }

  .tv-grid__col_md--12 {
      width: 100%
  }

  .tv-grid__col_md--11 {
      width: 91.66666667%
  }

  .tv-grid__col_md--10 {
      width: 83.33333333%
  }

  .tv-grid__col_md--9 {
      width: 75%
  }

  .tv-grid__col_md--8 {
      width: 66.66666667%
  }

  .tv-grid__col_md--7 {
      width: 58.33333333%
  }

  .tv-grid__col_md--6 {
      width: 50%
  }

  .tv-grid__col_md--5 {
      width: 41.66666667%
  }

  .tv-grid__col_md--4 {
      width: 33.33333333%
  }

  .tv-grid__col_md--3 {
      width: 25%
  }

  .tv-grid__col_md--2 {
      width: 16.66666667%
  }

  .tv-grid__col_md--1 {
      width: 8.33333333%
  }

  .tv-grid__col_md-pull--12 {
      right: 100%
  }

  .tv-grid__col_md-pull--11 {
      right: 91.66666667%
  }

  .tv-grid__col_md-pull--10 {
      right: 83.33333333%
  }

  .tv-grid__col_md-pull--9 {
      right: 75%
  }

  .tv-grid__col_md-pull--8 {
      right: 66.66666667%
  }

  .tv-grid__col_md-pull--7 {
      right: 58.33333333%
  }

  .tv-grid__col_md-pull--6 {
      right: 50%
  }

  .tv-grid__col_md-pull--5 {
      right: 41.66666667%
  }

  .tv-grid__col_md-pull--4 {
      right: 33.33333333%
  }

  .tv-grid__col_md-pull--3 {
      right: 25%
  }

  .tv-grid__col_md-pull--2 {
      right: 16.66666667%
  }

  .tv-grid__col_md-pull--1 {
      right: 8.33333333%
  }

  .tv-grid__col_md-pull--0 {
      right: auto
  }

  .tv-grid__col_md-push--12 {
      left: 100%
  }

  .tv-grid__col_md-push--11 {
      left: 91.66666667%
  }

  .tv-grid__col_md-push--10 {
      left: 83.33333333%
  }

  .tv-grid__col_md-push--9 {
      left: 75%
  }

  .tv-grid__col_md-push--8 {
      left: 66.66666667%
  }

  .tv-grid__col_md-push--7 {
      left: 58.33333333%
  }

  .tv-grid__col_md-push--6 {
      left: 50%
  }

  .tv-grid__col_md-push--5 {
      left: 41.66666667%
  }

  .tv-grid__col_md-push--4 {
      left: 33.33333333%
  }

  .tv-grid__col_md-push--3 {
      left: 25%
  }

  .tv-grid__col_md-push--2 {
      left: 16.66666667%
  }

  .tv-grid__col_md-push--1 {
      left: 8.33333333%
  }

  .tv-grid__col_md-push--0 {
      left: auto
  }

  .tv-grid__col_md-offset--12 {
      margin-left: 100%
  }

  .tv-grid__col_md-offset--11 {
      margin-left: 91.66666667%
  }

  .tv-grid__col_md-offset--10 {
      margin-left: 83.33333333%
  }

  .tv-grid__col_md-offset--9 {
      margin-left: 75%
  }

  .tv-grid__col_md-offset--8 {
      margin-left: 66.66666667%
  }

  .tv-grid__col_md-offset--7 {
      margin-left: 58.33333333%
  }

  .tv-grid__col_md-offset--6 {
      margin-left: 50%
  }

  .tv-grid__col_md-offset--5 {
      margin-left: 41.66666667%
  }

  .tv-grid__col_md-offset--4 {
      margin-left: 33.33333333%
  }

  .tv-grid__col_md-offset--3 {
      margin-left: 25%
  }

  .tv-grid__col_md-offset--2 {
      margin-left: 16.66666667%
  }

  .tv-grid__col_md-offset--1 {
      margin-left: 8.33333333%
  }

  .tv-grid__col_md-offset--0 {
      margin-left: 0
  }
}

@media (min-width: 1200px) {
  .tv-grid__col_lg--1,.tv-grid__col_lg--2,.tv-grid__col_lg--3,.tv-grid__col_lg--4,.tv-grid__col_lg--5,.tv-grid__col_lg--6,.tv-grid__col_lg--7,.tv-grid__col_lg--8,.tv-grid__col_lg--9,.tv-grid__col_lg--10,.tv-grid__col_lg--11,.tv-grid__col_lg--12 {
      float:left
  }

  .tv-grid__col_lg--12 {
      width: 100%
  }

  .tv-grid__col_lg--11 {
      width: 91.66666667%
  }

  .tv-grid__col_lg--10 {
      width: 83.33333333%
  }

  .tv-grid__col_lg--9 {
      width: 75%
  }

  .tv-grid__col_lg--8 {
      width: 66.66666667%
  }

  .tv-grid__col_lg--7 {
      width: 58.33333333%
  }

  .tv-grid__col_lg--6 {
      width: 50%
  }

  .tv-grid__col_lg--5 {
      width: 41.66666667%
  }

  .tv-grid__col_lg--4 {
      width: 33.33333333%
  }

  .tv-grid__col_lg--3 {
      width: 25%
  }

  .tv-grid__col_lg--2 {
      width: 16.66666667%
  }

  .tv-grid__col_lg--1 {
      width: 8.33333333%
  }

  .tv-grid__col_lg-pull--12 {
      right: 100%
  }

  .tv-grid__col_lg-pull--11 {
      right: 91.66666667%
  }

  .tv-grid__col_lg-pull--10 {
      right: 83.33333333%
  }

  .tv-grid__col_lg-pull--9 {
      right: 75%
  }

  .tv-grid__col_lg-pull--8 {
      right: 66.66666667%
  }

  .tv-grid__col_lg-pull--7 {
      right: 58.33333333%
  }

  .tv-grid__col_lg-pull--6 {
      right: 50%
  }

  .tv-grid__col_lg-pull--5 {
      right: 41.66666667%
  }

  .tv-grid__col_lg-pull--4 {
      right: 33.33333333%
  }

  .tv-grid__col_lg-pull--3 {
      right: 25%
  }

  .tv-grid__col_lg-pull--2 {
      right: 16.66666667%
  }

  .tv-grid__col_lg-pull--1 {
      right: 8.33333333%
  }

  .tv-grid__col_lg-pull--0 {
      right: auto
  }

  .tv-grid__col_lg-push--12 {
      left: 100%
  }

  .tv-grid__col_lg-push--11 {
      left: 91.66666667%
  }

  .tv-grid__col_lg-push--10 {
      left: 83.33333333%
  }

  .tv-grid__col_lg-push--9 {
      left: 75%
  }

  .tv-grid__col_lg-push--8 {
      left: 66.66666667%
  }

  .tv-grid__col_lg-push--7 {
      left: 58.33333333%
  }

  .tv-grid__col_lg-push--6 {
      left: 50%
  }

  .tv-grid__col_lg-push--5 {
      left: 41.66666667%
  }

  .tv-grid__col_lg-push--4 {
      left: 33.33333333%
  }

  .tv-grid__col_lg-push--3 {
      left: 25%
  }

  .tv-grid__col_lg-push--2 {
      left: 16.66666667%
  }

  .tv-grid__col_lg-push--1 {
      left: 8.33333333%
  }

  .tv-grid__col_lg-push--0 {
      left: auto
  }

  .tv-grid__col_lg-offset--12 {
      margin-left: 100%
  }

  .tv-grid__col_lg-offset--11 {
      margin-left: 91.66666667%
  }

  .tv-grid__col_lg-offset--10 {
      margin-left: 83.33333333%
  }

  .tv-grid__col_lg-offset--9 {
      margin-left: 75%
  }

  .tv-grid__col_lg-offset--8 {
      margin-left: 66.66666667%
  }

  .tv-grid__col_lg-offset--7 {
      margin-left: 58.33333333%
  }

  .tv-grid__col_lg-offset--6 {
      margin-left: 50%
  }

  .tv-grid__col_lg-offset--5 {
      margin-left: 41.66666667%
  }

  .tv-grid__col_lg-offset--4 {
      margin-left: 33.33333333%
  }

  .tv-grid__col_lg-offset--3 {
      margin-left: 25%
  }

  .tv-grid__col_lg-offset--2 {
      margin-left: 16.66666667%
  }

  .tv-grid__col_lg-offset--1 {
      margin-left: 8.33333333%
  }

  .tv-grid__col_lg-offset--0 {
      margin-left: 0
  }
}

.tv-help-button {
  display: inline-block;
  vertical-align: middle
}

.tv-help-button svg {
  fill: #4a4a4a;
  height: 16px;
  width: 16px
}

.tv-idea-label {
  border-radius: 22px;
  color: #fff;
  cursor: default;
  flex-flow: row nowrap;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap
}

.tv-idea-label,.tv-idea-label__icon {
  align-items: center;
  display: inline-flex
}

.tv-idea-label,.tv-idea-label--medium {
  font-size: 11px;
  height: 20px;
  line-height: 20px;
  padding: 0 8px
}

.tv-idea-label--medium .tv-idea-label__icon,.tv-idea-label .tv-idea-label__icon {
  margin-right: 4px
}

.tv-idea-label--medium .tv-idea-label__icon,.tv-idea-label--medium .tv-idea-label__icon svg,.tv-idea-label .tv-idea-label__icon,.tv-idea-label .tv-idea-label__icon svg {
  height: 10px;
  width: 10px
}

.tv-idea-label--small {
  font-size: 9px;
  height: 16px;
  line-height: 16px;
  padding: 0 6px
}

.tv-idea-label--small .tv-idea-label__icon {
  margin-right: 4px
}

.tv-idea-label--small .tv-idea-label__icon,.tv-idea-label--small .tv-idea-label__icon svg {
  height: 8px;
  width: 8px
}

.tv-idea-label--large {
  font-size: 14px;
  height: 28px;
  line-height: 16px;
  padding: 6 8px
}

.tv-idea-label--large .tv-idea-label__icon {
  margin-right: 5px
}

.tv-idea-label--large .tv-idea-label__icon,.tv-idea-label--large .tv-idea-label__icon svg {
  height: 14px;
  width: 14px
}

.tv-idea-label,.tv-idea-label--neutral {
  background-color: #b2b5be
}

.tv-idea-label--long {
  background-color: #26a69a
}

.tv-idea-label--short {
  background-color: #ef5350
}

.tv-idea-label--education,html.theme-dark .tv-idea-label--education {
  background-color: #2962ff
}

.tv-idea-label--ad {
  background-color: #fbc02d
}

.tv-inline-error {
  display: block;
  display: inline-block;
  position: relative
}

.tv-inline-error:after {
  clear: both;
  content: "";
  display: table
}

.tv-inline-error__icon {
  display: block;
  padding: 7px;
  pointer-events: none;
  position: absolute;
  right: 1px;
  top: 1px;
  transition: opacity .35s ease
}

.tv-inline-error__icon svg {
  fill: #ff4a68;
  display: block;
  height: 18px;
  width: 18px
}

.tv-inline-error__icon--material-input {
  top: 5px
}

.tv-control-select__wrap+.tv-inline-error__icon {
  right: 33px
}

.tv-inline-error--size_small .tv-inline-error__icon {
  padding: 5px
}

.tv-inline-error--size_small .tv-inline-error__icon svg {
  height: 15px;
  width: 15px
}

.tv-inline-error--size_large .tv-inline-error__icon {
  padding: 13px
}

.tv-inline-error--size_large .tv-inline-error__icon svg {
  height: 21px;
  width: 21px
}

.tv-inline-error--for-number-input .tv-inline-error__icon {
  right: 16px
}

.tv-inline-error--fix_size .tv-inline-error__width-control-wrapper {
  min-width: 170px
}

.tv-inline-error__width-control-wrapper {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100
}

.tv-inline-error__width-control-wrapper--from-left {
  left: 0;
  right: auto
}

.tv-inline-error__messages {
  background-color: #2a2c39;
  border-radius: 3px;
  bottom: 100%;
  color: #fff;
  margin-bottom: 1px;
  max-width: 100%;
  opacity: 0;
  padding: 10px 15px;
  pointer-events: none;
  position: absolute;
  right: 0;
  transform: scale(.85) translateY(3px);
  transform-origin: bottom right;
  transition: opacity .35s cubic-bezier(.215,.61,.355,1),transform .35s cubic-bezier(.215,.61,.355,1)
}

.tv-inline-error__messages:empty {
  display: none
}

.tv-inline-error__width-control-wrapper--from-left .tv-inline-error__messages {
  left: 0;
  right: auto;
  transform-origin: bottom left
}

.tv-inline-error__message {
  display: block;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 10px;
  text-transform: none
}

.tv-inline-error__message:last-child {
  margin-bottom: 0
}

.tv-inline-error__message a {
  color: #fff;
  transition: color .35s ease
}

.tv-inline-error__message a:visited {
  fill: #fff;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-inline-error__message a:hover {
      fill:#eaecef;
      color: #eaecef;
      transition-duration: .06s
  }
}

.tv-inline-error__message a:focus {
  outline: auto
}

.tv-inline-error__message a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-inline-error__message a:-moz-focusring {
  outline: auto
}

.tv-inline-error__message a:active {
  fill: #e9eff2;
  color: #e9eff2;
  transition-duration: .06s
}

.tv-inline-error.i-error .tv-inline-error__icon {
  opacity: 1;
  transition-duration: .06s
}

.tv-inline-error.i-error.i-focus .tv-inline-error__width-control-wrapper {
  opacity: 1
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-inline-error.i-error:hover .tv-inline-error__width-control-wrapper {
      opacity:1
  }
}

.tv-inline-error.i-error.i-focus .tv-inline-error__messages {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition-duration: .06s;
  transition-timing-function: cubic-bezier(.215,.61,.355,1)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-inline-error.i-error:hover .tv-inline-error__messages {
      opacity:1;
      transform: scale(1) translateY(0);
      transition-duration: .06s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1)
  }
}

.tv-inline-error.i-error.i-focus-touch .tv-inline-error__messages,.tv-inline-error.i-error.i-focus-touch .tv-inline-error__width-control-wrapper {
  opacity: 1
}

.tv-inline-error--msg_left .tv-inline-error__width-control-wrapper {
  left: 0;
  right: auto
}

.tv-inline-error--msg_left .tv-inline-error__messages {
  transform-origin: bottom left
}

.tv-inline-error--material-input .tv-inline-error__icon {
  top: 5px
}

.tv-inline-error__brand-dark-bg-cascade .tv-inline-error__messages {
  background-color: #2a2c39;
  border: 1px solid #4e5866;
  color: #ff4a68
}

.tv-inline-error:after {
  clear: none;
  display: none
}

.tv-inline-error__icon {
  display: inline;
  opacity: 0;
  padding: 0;
  position: static;
  vertical-align: middle
}

.tv-inline-error__icon svg {
  display: inline
}

.tv-inline-error__messages {
  min-width: 150px
}

.tv-inline-quote-ticker {
  white-space: nowrap
}

.tv-inline-quote-ticker__source-text {
  border-bottom: 1px dashed;
  border-color: #434651;
  color: #434651;
  text-decoration: none
}

html.theme-dark .tv-inline-quote-ticker__source-text {
  border-color: #d1d4dc;
  color: #d1d4dc
}

.tv-inline-quote-ticker__source-text:active {
  border-bottom: 1px dashed;
  border-color: #262b3e;
  color: #262b3e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-inline-quote-ticker__source-text:hover {
      border-bottom:1px dashed;
      border-color: #262b3e;
      color: #262b3e
  }
}

html.theme-dark .tv-inline-quote-ticker__source-text:active {
  border-color: #e1ecf2
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-inline-quote-ticker__source-text:hover {
      border-color:#e1ecf2
  }
}

html.theme-dark .tv-inline-quote-ticker__source-text:active {
  color: #e1ecf2
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-inline-quote-ticker__source-text:hover {
      color:#e1ecf2
  }
}

.tv-list {
  font-size: 18px;
  list-style: none;
  margin-bottom: 12px
}

.tv-list .tv-list {
  margin-left: 22px
}

.tv-list__item+.tv-list__item {
  margin-top: 20px
}

.tv-list__item+.tv-list__item--no-margin,.tv-list__item--no-margin {
  margin-top: 0
}

.tv-list__item+.tv-list__item--short,.tv-list__item--short {
  margin-top: 10px
}

.tv-list__item--display_inline {
  display: inline-block;
  vertical-align: top
}

.tv-list__item--display_inline+.tv-list__item--display_inline {
  margin-left: 70px;
  margin-top: 0
}

.tv-list__item-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding-left: 20px;
  position: relative
}

.tv-list__item-title:before {
  border-color: #2962ff
}

.tv-list__item-title a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-list__item-title a {
  color: #2962ff
}

.tv-list__item-title a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-list__item-title a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-list__item-title a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-list__item-title a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-list__item-title a:focus {
  outline: auto
}

.tv-list__item-title a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-list__item-title a:-moz-focusring {
  outline: auto
}

.tv-list__item-title a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-list__item-title a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-list__item-title--text {
  color: #131722
}

html.theme-dark .tv-list__item-title--text {
  color: #d1d4dc
}

.tv-list__item-title--color_black-110 {
  color: #757575
}

.tv-list__item-title--font-decreased {
  font-size: 15px
}

.tv-list__item-title--weight_normal {
  font-weight: 400
}

.tv-list--markered {
  list-style: disc
}

.tv-list--font-decreased {
  font-size: 14px
}

.tv-list--font-popup {
  font-size: 13px
}

.tv-list--color_black-110 {
  color: #757575
}

.tv-list--left-offset {
  margin-left: 20px
}

.tv-list__item-description {
  color: #131722;
  font-size: 15px;
  line-height: 23px;
  margin-top: 12px
}

html.theme-dark .tv-list__item-description {
  color: #d1d4dc
}

@media screen and (max-width: 767px) {
  .tv-list__item-description {
      font-size:14px
  }
}

.tv-list__item-description a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-list__item-description a {
  color: #2962ff
}

.tv-list__item-description a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-list__item-description a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-list__item-description a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-list__item-description a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-list__item-description a:focus {
  outline: auto
}

.tv-list__item-description a:focus-visible {
  outline: auto
}

.tv-list__item-description a:focus:not(:focus-visible) {
  outline: none
}

.tv-list__item-description a:active {
  fill: #2962ff;
  color: #2962ff;
  transition-duration: 60ms
}

html.theme-dark .tv-list__item-description a:active {
  fill: #2962ff;
  color: #2962ff
}

.tv-list__item-description--bold {
  font-weight: 700
}

.tv-list__item-description--italic {
  font-style: italic
}

.tv-list--latin .tv-list__item-title,.tv-list--numeric .tv-list__item-title {
  display: inline;
  padding-left: 0
}

.tv-list--numeric {
  list-style: decimal inside
}

.tv-list--lower-latin {
  list-style: lower-latin inside
}

.tv-list--only-titles .tv-list__item+.tv-list__item {
  margin-top: 12px
}

.tv-list--empty-dot .tv-list__item-title:before {
  border: 2px solid #2962ff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 4px
}

.tv-list--dot-color_dim .tv-list__item-title:before {
  border-color: #758696
}

.tv-list--dot-size_big .tv-list__item-title:before {
  height: 6px;
  top: 6px;
  width: 6px
}

.tv-list--display_inline-block {
  display: inline-block
}

.tv-list--midst-margin_one-and-half .tv-list__item+.tv-list__item {
  margin-top: 30px
}

.tv-list--indented {
  padding-left: 20px
}

.tv-list-plain {
  line-height: 1.8;
  list-style-position: inside
}

ul.tv-list-plain {
  list-style: none
}

ul.tv-list-plain li {
  padding-left: 1.3em;
  position: relative
}

ul.tv-list-plain li:before {
  content: "â€¢";
  display: block;
  left: 0;
  position: absolute
}

ul.tv-sub-list {
  margin-top: 12px
}

@keyframes tv-load-more-pulse {
  0% {
      transform: scale(.8)
  }

  50% {
      opacity: 1
  }

  70%,to {
      transform: scale(1)
  }
}

.tv-load-more {
  clear: both;
  margin: 40px auto;
  text-align: center
}

@media screen and (max-width: 1019px) {
  .tv-load-more {
      margin-left:-12px;
      margin-right: -12px;
      margin-top: 12px
  }
}

.tv-load-more--sticky {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin: 0
}

.tv-load-more__gap,.tv-load-more__page {
  background-color: initial;
  border-radius: 50%;
  color: #8797a5;
  display: inline-block;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  margin-left: -1px;
  overflow: hidden;
  position: relative;
  transition: all .35s ease;
  width: 50px
}

html.theme-dark .tv-load-more__gap,html.theme-dark .tv-load-more__page {
  color: #787b86
}

.tv-load-more__gap+.tv-load-more__gap,.tv-load-more__gap+.tv-load-more__page,.tv-load-more__page+.tv-load-more__gap,.tv-load-more__page+.tv-load-more__page {
  margin-left: 11px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  a.tv-load-more__gap:hover,a.tv-load-more__page:hover {
      background-color:#ebf7fc;
      color: #758696;
      transition-duration: .06s
  }

  html.theme-dark a.tv-load-more__gap:hover,html.theme-dark a.tv-load-more__page:hover {
      background-color: #2a2e39;
      color: #b2b5be
  }
}

.tv-load-more__page.tv-load-more__loading {
  background-color: #e1ecf2;
  font-size: 0
}

.tv-load-more__current {
  background-color: #fff;
  color: #2962ff;
  font-weight: 700
}

html.theme-dark .tv-load-more__current {
  background-color: #132042;
  color: #2962ff
}

.tv-load-more__pagination {
  display: inline-block;
  margin-top: 38px
}

.tv-load-more__pagination--sticky {
  margin-top: 41px
}

@media screen and (max-width: 767px) {
  .tv-load-more__pagination {
      display:none
  }
}

.tv-load-more__btn,.tv-load-more__pagination {
  box-sizing: border-box;
  padding: 0 61px;
  position: relative;
  width: 100%
}

.tv-load-more__btn {
  background-color: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  color: #2962ff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  line-height: 18px;
  overflow: hidden;
  padding: 17px 0;
  position: relative;
  transition: color .35s ease
}

html.theme-dark .tv-load-more__btn {
  background-color: #1e222d;
  box-shadow: 0 0 0 1px #0000;
  color: #2962ff
}

.tv-load-more__btn:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-load-more__btn:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-load-more__btn:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-load-more__btn:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-load-more__btn:focus {
  outline: auto
}

.tv-load-more__btn:focus-visible {
  outline: auto
}

.tv-load-more__btn:focus:not(:focus-visible) {
  outline: none
}

.tv-load-more__btn:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: 60ms
}

html.theme-dark .tv-load-more__btn:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-load-more__btn:active {
  background-color: #f2f3f5
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-load-more__btn:hover {
      background-color:#f2f3f5
  }
}

html.theme-dark .tv-load-more__btn:active {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-load-more__btn:hover {
      background-color:#2a2e39
  }
}

.tv-load-more__btn:active {
  transform: translateY(1px)
}

.tv-load-more__btn--sticky {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center
}

.tv-load-more__btn .tv-button__loader-item {
  background-color: #2962ff!important
}

.tv-load-more__btn-arrow {
  padding-left: 4px
}

.tv-load-more__btn-arrow svg {
  height: 10px
}

.tv-load-more__arrow {
  display: inline-block;
  height: 15px;
  padding: 17px 0;
  position: absolute;
  top: 0;
  width: 50px
}

.tv-load-more__arrow--left {
  left: 3px;
  text-align: left
}

.tv-load-more__arrow--right {
  right: 3px;
  text-align: right
}

.tv-load-more__arrow svg {
  fill: #b2b5be;
  height: 15px;
  max-width: 100%
}

html.theme-dark .tv-load-more__arrow svg {
  fill: #50535e
}

a.tv-load-more__arrow {
  cursor: pointer;
  transition: all .35s ease
}

a.tv-load-more__arrow svg,html.theme-dark a.tv-load-more__arrow svg {
  fill: #2962ff
}

a.tv-load-more__arrow:active {
  animation: tv-load-more-pulse .35s
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  a.tv-load-more__arrow--left:hover {
      left:0;
      transition-duration: .06s
  }

  a.tv-load-more__arrow--right:hover {
      right: 0;
      transition-duration: .06s
  }
}

.tv-load-more__arrow.tv-load-more__loading svg {
  display: none
}

.tv-load-more--screener {
  display: flex;
  margin-bottom: 0
}

.tv-feed:not(.tv-feed--no-hindent) .tv-load-more:not(.tv-load-more--sticky) {
  padding: 0 10px
}

@media screen and (max-width: 1019px) {
  .tv-feed:not(.tv-feed--no-hindent) .tv-load-more:not(.tv-load-more--sticky) {
      margin-left:-10px;
      margin-right: -10px
  }

  .tv-feed--no-hindent .tv-load-more:not(.tv-load-more--sticky) {
      margin-left: 0;
      margin-right: 0
  }
}

.tv-page-content {
  padding: 20px
}

.tv-page-content--text {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  box-sizing: border-box;
  color: #131722;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
  padding: 60px
}

html.theme-dark .tv-page-content--text {
  background-color: #1e222d;
  box-shadow: 0 0 0 1px #0000;
  color: #d1d4dc
}

@media screen and (max-width: 1019px) {
  .tv-page-content--text {
      margin-bottom:0;
      margin-top: 0;
      padding: 40px
  }
}

@media screen and (max-width: 479px) {
  .tv-page-content--text {
      padding:20px
  }
}

@media screen and (max-width: 767px) {
  .tv-page-content--adaptive {
      padding:0
  }
}

.tv-page-header {
  background: #2a2c39;
  border-top: 2px solid #252733;
  padding: 85px 0;
  text-align: center
}

.tv-page-header__page-title {
  color: #fff;
  font-size: 45px;
  letter-spacing: 3px;
  word-spacing: 4px
}

.tv-page-header__page-title--uppercase {
  text-transform: uppercase
}

.tv-page-header__page-title--font_increased {
  font-size: 62px
}

.tv-page-header__sub-text {
  color: #94959c;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 33px;
  margin-top: 20px
}

.tv-page-header__sub-text a {
  border-bottom: 1px solid #94959c;
  color: #94959c;
  opacity: 1;
  text-decoration: none;
  transition: opacity .35s
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-page-header__sub-text a:hover {
      opacity:.8
  }
}

.tv-search-highlight {
  color: #ff9850
}

.tv-site-table {
  display: table;
  table-layout: fixed;
  width: 100%
}

.tv-site-table--layout_auto {
  table-layout: auto
}

.tv-site-table--no-side-padding .tv-site-table__column:first-child:after {
  left: 0
}

.tv-site-table--no-side-padding .tv-site-table__column:last-child:after {
  right: 0
}

.tv-site-table--no-side-padding .tv-site-table__column:first-child {
  padding-left: 0
}

@media screen and (max-width: 1019px) {
  .tv-site-table--no-side-padding .tv-site-table__column:first-child {
      padding-left:0
  }
}

.tv-site-table--no-side-padding .tv-site-table__column:last-child {
  padding-right: 0;
  text-align: right
}

@media screen and (max-width: 1019px) {
  .tv-site-table--no-side-padding .tv-site-table__column:last-child {
      padding-right:0
  }
}

.tv-site-table__row {
  display: table-row;
  transition: background-color .35s ease
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table__row--highlighted:hover,.tv-site-table__row--hoverable:hover,.tv-site-table__row--interactive:hover {
      background-color:#f0f3fa;
      transition-duration: .06s
  }

  html.theme-dark .tv-site-table__row--highlighted:hover,html.theme-dark .tv-site-table__row--hoverable:hover,html.theme-dark .tv-site-table__row--interactive:hover {
      background-color: #2a2e39
  }
}

.tv-site-table__row--interactive {
  cursor: pointer
}

.tv-site-table__row--interactive:active {
  background-color: #f0f3fa;
  transition-duration: .06s
}

html.theme-dark .tv-site-table__row--interactive:active {
  background-color: #2a2e39
}

.tv-site-table__row--highlighted {
  background-color: #bbd9fb
}

.tv-site-table__row--dummy {
  visibility: hidden
}

.tv-site-table__row--updated {
  background-color: #e3effd;
  border-color: #e3effd;
  position: relative;
  z-index: 1
}

html.theme-dark .tv-site-table__row--updated {
  background-color: #132042;
  border-color: #132042
}

.tv-site-table__column {
  box-sizing: border-box;
  display: table-cell;
  line-height: 1.5;
  padding: 8px 10px;
  position: relative;
  transition: background-color .35s ease;
  vertical-align: middle
}

.tv-site-table__column:after {
  background-color: #f0f3fa;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
  transition: background-color .35s ease
}

html.theme-dark .tv-site-table__column:after {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table__column:hover:after {
      transition-duration:.06s
  }
}

.tv-site-table__column:first-child:after {
  left: 20px
}

.tv-site-table__column:last-child:after {
  right: 20px
}

@media screen and (max-width: 1019px) {
  .tv-site-table__column:first-child:after {
      left:12px
  }

  .tv-site-table__column:last-child:after {
      right: 12px
  }
}

@media screen and (max-width: 329px) {
  .tv-site-table__column:first-child:after {
      left:10px
  }

  .tv-site-table__column:last-child:after {
      right: 10px
  }
}

.tv-site-table__column:first-child {
  padding-left: 20px
}

@media screen and (max-width: 1019px) {
  .tv-site-table__column:first-child {
      padding-left:12px
  }
}

@media screen and (max-width: 329px) {
  .tv-site-table__column:first-child {
      padding-left:10px
  }
}

.tv-site-table__column:last-child {
  padding-right: 20px;
  text-align: right
}

@media screen and (max-width: 1019px) {
  .tv-site-table__column:last-child {
      padding-right:12px
  }
}

@media screen and (max-width: 329px) {
  .tv-site-table__column:last-child {
      padding-right:10px
  }
}

.tv-site-table__column--align_left {
  text-align: left
}

.tv-site-table__column--align_left:last-child {
  padding-left: 0;
  padding-right: 20px;
  text-align: left
}

@media screen and (max-width: 1019px) {
  .tv-site-table__column--align_left:last-child {
      padding-right:12px
  }
}

@media screen and (max-width: 329px) {
  .tv-site-table__column--align_left:last-child {
      padding-right:10px
  }
}

.tv-site-table__column--align_right {
  text-align: right
}

.tv-site-table__column--align_right:last-child {
  padding-left: 0;
  padding-right: 20px;
  text-align: right
}

@media screen and (max-width: 1019px) {
  .tv-site-table__column--align_right:last-child {
      padding-right:12px
  }
}

@media screen and (max-width: 329px) {
  .tv-site-table__column--align_right:last-child {
      padding-right:10px
  }
}

.tv-site-table__column--align_top {
  vertical-align: top
}

.tv-site-table__column--no-padding_top {
  padding-top: 0
}

@media screen and (max-width: 479px) {
  .tv-site-table__column--no-phone-vertical {
      display:none
  }

  .tv-site-table__column--last-phone-vertical {
      padding-left: 0;
      padding-right: 12px;
      text-align: right
  }

  .tv-site-table__column--last-phone-vertical:after {
      right: 12px
  }
}

.tv-site-table__row--highlighted+.tv-site-table__row .tv-site-table__column:after,.tv-site-table__row--highlighted .tv-site-table__column:after,.tv-site-table__row--no-border .tv-site-table__column:after,.tv-site-table__row:first-child .tv-site-table__column:after {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table__row--hoverable:hover+.tv-site-table__row--hoverable .tv-site-table__column:after,.tv-site-table__row--hoverable:hover .tv-site-table__column:after,.tv-site-table__row--interactive:hover+.tv-site-table__row--interactive .tv-site-table__column:after,.tv-site-table__row--interactive:hover .tv-site-table__column:after {
      background-color:initial
  }
}

html.theme-dark .tv-site-table__row--highlighted+.tv-site-table__row .tv-site-table__column:after,html.theme-dark .tv-site-table__row--highlighted .tv-site-table__column:after,html.theme-dark .tv-site-table__row--no-border .tv-site-table__column:after,html.theme-dark .tv-site-table__row:first-child .tv-site-table__column:after {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-site-table__row--hoverable:hover+.tv-site-table__row--hoverable .tv-site-table__column:after,html.theme-dark .tv-site-table__row--hoverable:hover .tv-site-table__column:after,html.theme-dark .tv-site-table__row--interactive:hover+.tv-site-table__row--interactive .tv-site-table__column:after,html.theme-dark .tv-site-table__row--interactive:hover .tv-site-table__column:after {
      background-color:initial
  }
}

.tv-site-table__inline-block-wrapper {
  display: inline-block
}

.tv-site-table--start-border .tv-site-table__row:first-child .tv-site-table__column:after {
  background-color: #f0f3fa
}

html.theme-dark .tv-site-table--start-border .tv-site-table__row:first-child .tv-site-table__column:after {
  background-color: #2a2e39
}

.tv-site-table--start-border .tv-site-table__row--highlighted:first-child .tv-site-table__column:after {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table--start-border .tv-site-table__row--hoverable:first-child:hover .tv-site-table__column:after {
      background-color:initial
  }
}

html.theme-dark .tv-site-table--start-border .tv-site-table__row--highlighted:first-child .tv-site-table__column:after {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-site-table--start-border .tv-site-table__row--hoverable:first-child:hover .tv-site-table__column:after {
      background-color:initial
  }
}

.tv-site-table--no-start-border .tv-site-table__row:first-child .tv-site-table__column:after,html.theme-dark .tv-site-table--no-start-border .tv-site-table__row:first-child .tv-site-table__column:after {
  background-color: initial
}

.tv-site-table--line-bottom .tv-site-table__column:after {
  bottom: -1px;
  top: auto
}

.tv-site-table--line-bottom .tv-site-table__row:first-child .tv-site-table__column:before {
  background-color: #f0f3fa;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
  transition: background-color .35s ease
}

html.theme-dark .tv-site-table--line-bottom .tv-site-table__row:first-child .tv-site-table__column:before {
  background-color: #2a2e39
}

.tv-site-table--line-bottom.tv-site-table--no-start-border .tv-site-table__row:first-child .tv-site-table__column:before,.tv-site-table--line-bottom .tv-site-table__row--highlighted:first-child .tv-site-table__column:before {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table--line-bottom .tv-site-table__row:first-child:hover .tv-site-table__column:before {
      background-color:initial
  }
}

html.theme-dark .tv-site-table--line-bottom.tv-site-table--no-start-border .tv-site-table__row:first-child .tv-site-table__column:before,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--highlighted:first-child .tv-site-table__column:before {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-site-table--line-bottom .tv-site-table__row:first-child:hover .tv-site-table__column:before {
      background-color:initial
  }
}

.tv-site-table--line-bottom.tv-site-table--with-end-border .tv-site-table__row:last-child .tv-site-table__column:after,.tv-site-table--line-bottom .tv-site-table__row--highlighted+.tv-site-table__row .tv-site-table__column:after,.tv-site-table--line-bottom .tv-site-table__row:first-child .tv-site-table__column:after {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table--line-bottom .tv-site-table__row--hoverable:hover+.tv-site-table__row--hoverable .tv-site-table__column:after,.tv-site-table--line-bottom .tv-site-table__row--interactive:hover+.tv-site-table__row--interactive .tv-site-table__column:after {
      background-color:#f0f3fa
  }
}

html.theme-dark .tv-site-table--line-bottom.tv-site-table--with-end-border .tv-site-table__row:last-child .tv-site-table__column:after,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--highlighted+.tv-site-table__row .tv-site-table__column:after,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row:first-child .tv-site-table__column:after {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--hoverable:hover+.tv-site-table__row--hoverable .tv-site-table__column:after,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--interactive:hover+.tv-site-table__row--interactive .tv-site-table__column:after {
      background-color:#2a2e39
  }
}

.tv-site-table--line-bottom.tv-site-table--with-end-border .tv-site-table__row--highlighted:last-child .tv-site-table__column:after,.tv-site-table--line-bottom .tv-site-table__row--highlighted .tv-site-table__column:after,.tv-site-table--line-bottom .tv-site-table__row--highlighted:first-child .tv-site-table__column:after {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table--line-bottom.tv-site-table--with-end-border .tv-site-table__row--hoverable:last-child:hover .tv-site-table__column:after,.tv-site-table--line-bottom .tv-site-table__row--hoverable:hover .tv-site-table__column:after,.tv-site-table--line-bottom .tv-site-table__row--interactive:hover .tv-site-table__column:after {
      background-color:initial
  }
}

html.theme-dark .tv-site-table--line-bottom.tv-site-table--with-end-border .tv-site-table__row--highlighted:last-child .tv-site-table__column:after,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--highlighted .tv-site-table__column:after,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--highlighted:first-child .tv-site-table__column:after {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-site-table--line-bottom.tv-site-table--with-end-border .tv-site-table__row--hoverable:last-child:hover .tv-site-table__column:after,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--hoverable:hover .tv-site-table__column:after,html.theme-dark .tv-site-table--line-bottom .tv-site-table__row--interactive:hover .tv-site-table__column:after {
      background-color:initial
  }
}

.tv-site-table--with-end-border .tv-site-table__row:last-child .tv-site-table__column:before {
  background-color: #f0f3fa;
  bottom: -1px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  transition: background-color .35s ease
}

html.theme-dark .tv-site-table--with-end-border .tv-site-table__row:last-child .tv-site-table__column:before {
  background-color: #2a2e39
}

.tv-site-table--with-end-border .tv-site-table__row--highlighted:last-child .tv-site-table__column:before {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-table--with-end-border .tv-site-table__row--hoverable:last-child:hover .tv-site-table__column:before {
      background-color:initial
  }
}

html.theme-dark .tv-site-table--with-end-border .tv-site-table__row--highlighted:last-child .tv-site-table__column:before {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-site-table--with-end-border .tv-site-table__row--hoverable:last-child:hover .tv-site-table__column:before {
      background-color:initial
  }
}

.tv-site-widget {
  background-color: #fff;
  box-sizing: border-box;
  position: relative
}

html.theme-dark .tv-site-widget {
  background-color: #1e222d
}

.tv-site-widget:after,.tv-site-widget:before {
  clear: both;
  content: "";
  display: table
}

.tv-site-widget--bg_none,html.theme-dark .tv-site-widget--bg_none {
  background-color: initial
}

.tv-site-widget--scrollable {
  overflow: hidden
}

.tv-site-widget--full-width {
  width: 100%
}

.tv-site-widget__body,.tv-site-widget__footer,.tv-site-widget__head,.tv-site-widget__separator {
  margin: 20px 0;
  padding: 0 20px
}

@media screen and (max-width: 1019px) {
  .tv-site-widget__body,.tv-site-widget__footer,.tv-site-widget__head,.tv-site-widget__separator {
      padding:0 12px
  }
}

.tv-site-widget__body--tabs-padding,.tv-site-widget__footer--tabs-padding,.tv-site-widget__head--tabs-padding,.tv-site-widget__separator--tabs-padding {
  padding-top: 13px
}

.tv-site-widget__background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.tv-site-widget__background--6mf {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover
}

.tv-site-widget__content {
  position: relative
}

.tv-site-widget__content--centered {
  text-align: center
}

.tv-site-widget__head {
  display: block;
  position: relative
}

.tv-site-widget__head--bordered {
  border-bottom: 2px solid #e9eff2;
  margin-bottom: 0;
  padding-bottom: 20px
}

@media screen and (max-width: 1019px) {
  .tv-site-widget__head--bordered {
      padding-bottom:0
  }
}

.tv-site-widget__head:after {
  clear: both;
  content: "";
  display: table
}

.tv-site-widget__body--padding_bottom {
  padding-bottom: 20px
}

.tv-site-widget__body--padding_top {
  padding-top: 20px
}

.tv-site-widget__body.i-loading {
  pointer-events: none
}

.tv-site-widget__body.i-loading>* {
  opacity: .4;
  transition-duration: .06s
}

.tv-site-widget__body--chartevents-embedded,.tv-site-widget__body--trimmed {
  margin: 0;
  padding: 0
}

.tv-site-widget__body--trimmed_vertical {
  margin: 0
}

.tv-site-widget__body--chart {
  border-top: 1px solid #e0e3eb;
  height: 530px;
  margin: 0;
  padding: 0;
  position: relative
}

.tv-site-widget__footer:after {
  clear: both;
  content: "";
  display: table
}

.tv-site-widget__footer--bordered {
  border-top: 2px solid #e9eff2;
  margin-top: 0;
  padding-top: 20px
}

.tv-site-widget__actions {
  text-align: right
}

.tv-site-widget__actions>* {
  margin-left: 15px
}

.tv-site-widget__actions--centered {
  float: none;
  text-align: center
}

@media screen and (max-width: 1019px) {
  .tv-site-widget__actions {
      margin-bottom:-20px
  }

  .tv-site-widget__actions>* {
      margin-bottom: 20px
  }

  .tv-site-widget__actions--tablet_margin {
      margin-bottom: 0
  }
}

@media screen and (max-width: 767px) {
  .tv-site-widget__actions {
      margin-left:-15px;
      text-align: left
  }
}

.tv-site-widget__head .tv-site-widget__actions .tv-button {
  position: relative;
  top: -3px
}

@media screen and (max-width: 1019px) {
  .tv-site-widget__head .tv-site-widget__actions .tv-button {
      top:0
  }
}

.tv-site-widget__action-item {
  color: #2962ff;
  cursor: pointer;
  transition: color .35s ease
}

html.theme-dark .tv-site-widget__action-item {
  color: #2962ff
}

.tv-site-widget__action-item:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-site-widget__action-item:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-widget__action-item:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-site-widget__action-item:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-site-widget__action-item:focus {
  outline: auto
}

.tv-site-widget__action-item:focus:not(:-moz-focusring) {
  outline: none
}

.tv-site-widget__action-item:-moz-focusring {
  outline: auto
}

.tv-site-widget__action-item:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-site-widget__action-item:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-site-widget__actions-control-input {
  margin-top: -7px;
  width: 150px
}

.tv-site-widget__control-icons {
  cursor: pointer;
  float: right;
  height: 25px;
  line-height: 25px;
  -webkit-user-select: none;
  user-select: none
}

.tv-site-widget__control-icon {
  color: #758696;
  display: inline-block;
  height: 25px;
  transition: color .35s ease;
  width: 25px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-widget__control-icon:hover {
      color:#8a8a8a;
      transition-duration: .06s
  }
}

.tv-site-widget__control-icon:active {
  color: #757575;
  transition-duration: .06s
}

.tv-site-widget__control-icon--arrow-down svg {
  fill: currentColor;
  height: 12px;
  margin: 6.5px;
  width: 12px
}

.tv-site-widget__control-icon--hline:before {
  background: currentColor;
  content: "";
  display: block;
  height: 2px;
  margin: 17px 7px 0;
  width: 11px
}

.tv-site-widget__control-icon--calendar-config {
  float: left;
  margin-right: 14px
}

.tv-site-widget__control-icon--calendar-config svg {
  display: block;
  height: 20px;
  margin: 3px 0 0 2px;
  width: 20px
}

.tv-site-widget__title {
  color: #434651;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase
}

html.theme-dark .tv-site-widget__title {
  color: #d1d4dc
}

.tv-site-widget__title a {
  color: #434651;
  transition: color .35s ease
}

html.theme-dark .tv-site-widget__title a {
  color: #d1d4dc
}

.tv-site-widget__title a:visited {
  fill: #434651;
  color: #434651
}

html.theme-dark .tv-site-widget__title a:visited {
  fill: #d1d4dc;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-widget__title a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-site-widget__title a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-site-widget__title a:focus {
  outline: auto
}

.tv-site-widget__title a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-site-widget__title a:-moz-focusring {
  outline: auto
}

.tv-site-widget__title a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-site-widget__title a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-site-widget__title:last-child {
  margin-bottom: 0
}

.tv-site-widget__title--branded,html.theme-dark .tv-site-widget__title--branded {
  color: #2962ff
}

.tv-site-widget__title--half-width {
  width: 50%
}

@media screen and (max-width: 1019px) {
  .tv-site-widget__title--half-width {
      width:auto
  }
}

.tv-site-widget__title-info-icon {
  display: inline-block;
  height: 18px;
  margin-left: 10px;
  vertical-align: text-top;
  width: 18px
}

.tv-site-widget__title-info-icon svg {
  fill: #434651;
  height: 18px;
  width: 18px
}

html.theme-dark .tv-site-widget__title-info-icon svg {
  fill: #d1d4dc
}

.tv-site-widget__subtitle {
  color: #787b86;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 5px;
  margin-top: 6px;
  text-transform: uppercase
}

html.theme-dark .tv-site-widget__subtitle {
  color: #787b86
}

.tv-site-widget__subtitle--half-width {
  width: 50%
}

@media screen and (max-width: 1019px) {
  .tv-site-widget__subtitle--half-width {
      width:auto
  }
}

.tv-site-widget__subtitle:last-child {
  margin-bottom: 0
}

.tv-site-widget__tabs {
  font-size: 14px;
  margin-top: 10px
}

.tv-site-widget__after-tabs-container {
  padding-top: 15px
}

.tv-site-widget__scroll-wrapper {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  position: relative
}

.tv-site-widget__scroll-inner {
  box-sizing: border-box;
  position: absolute;
  width: 100%
}

.tv-site-widget__scroll-inner.i-no-scroll {
  position: relative
}

.tv-site-widget__height_default {
  height: 480px
}

.tv-site-widget__height_increased {
  height: 700px
}

.tv-site-widget__height_hotlist {
  height: 275px
}

.tv-site-widget__height_hotlist--interactive {
  height: 495px
}

.tv-site-widget__no-horz-indent {
  margin-left: -20px;
  margin-right: -20px
}

@media screen and (max-width: 1019px) {
  .tv-site-widget__no-horz-indent {
      margin-left:-12px;
      margin-right: -12px
  }
}

.tv-site-widget__show-more {
  background-color: #fff;
  border-top: 1px solid #e9eff2;
  bottom: 0;
  color: #2962ff;
  cursor: pointer;
  font-size: 13px;
  left: 0;
  opacity: .97;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  transition: color .35s ease;
  -webkit-user-select: none;
  user-select: none
}

html.theme-dark .tv-site-widget__show-more {
  color: #2962ff
}

.tv-site-widget__show-more:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-site-widget__show-more:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-site-widget__show-more:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-site-widget__show-more:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-site-widget__show-more:focus {
  outline: auto
}

.tv-site-widget__show-more:focus:not(:-moz-focusring) {
  outline: none
}

.tv-site-widget__show-more:-moz-focusring {
  outline: auto
}

.tv-site-widget__show-more:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-site-widget__show-more:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-site-widget__show-more--tech {
  position: relative
}

.tv-site-widget--dark {
  background-color: #2a2c39
}

.tv-site-widget__head--dark {
  color: #adaeb0
}

.tv-site-widget__title--dark {
  color: #fff
}

.tv-site-widget__body--dark {
  color: #adaeb0
}

.tv-site-widget__footer--dark {
  border-color: #ffffff1a;
  color: #adaeb0
}

.tv-site-widget__title-inline-select-wrapper {
  color: #535353;
  display: inline-block;
  font-size: medium;
  font-weight: 400;
  margin-left: 20px;
  position: relative;
  top: -3px;
  vertical-align: middle
}

.tv-site-widget__hotlist-option-title {
  margin-left: 5px;
  position: relative;
  text-transform: none;
  top: 1px;
  vertical-align: middle
}

.tv-site-widget__select-button.i-disabled {
  cursor: default;
  opacity: .7
}

.tv-site-widget__hotlist-exchange--selected {
  background: #d8f0fa
}

.tv-site-widget__calendar-filter-icon {
  color: #758696;
  cursor: pointer;
  float: right;
  height: 25px
}

.tv-site-widget__calendar-filter-icon>svg {
  display: block;
  height: 20px;
  margin-top: 3px;
  width: 20px
}

.tv-site-widget__importance-switcher {
  align-items: center;
  display: inline-flex;
  flex: none;
  float: right;
  height: 25px;
  justify-content: center
}

.tv-site-widget__importance-switcher.i-active {
  color: #2962ff
}

.tv-site-widget__separator:before {
  background: #e0e3eb;
  content: "";
  display: block;
  height: 1px
}

html.theme-dark .tv-site-widget__separator:before {
  background: #434651
}

.tv-stan-contacts {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  box-sizing: border-box;
  box-sizing: initial;
  height: 100%;
  margin-top: 10px;
  overflow: hidden;
  padding: 25px 30px 30px;
  width: 220px
}

html.theme-dark .tv-stan-contacts {
  background-color: #1e222d;
  box-shadow: 0 0 0 1px #0000
}

.tv-stan-contacts__title {
  color: #adaeb0;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase
}

.tv-stan-contacts__info {
  margin-top: 20px
}

.tv-stan-contacts__text {
  color: #131722;
  font-size: 17px;
  line-height: 24px
}

html.theme-dark .tv-stan-contacts__text {
  color: #d1d4dc
}

.tv-stan-contacts__email {
  color: #2962ff;
  font-size: 17px;
  line-height: 24px;
  transition: color .35s ease
}

html.theme-dark .tv-stan-contacts__email {
  color: #2962ff
}

.tv-stan-contacts__email:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-stan-contacts__email:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-stan-contacts__email:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-stan-contacts__email:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-stan-contacts__email:focus {
  outline: auto
}

.tv-stan-contacts__email:focus-visible {
  outline: auto
}

.tv-stan-contacts__email:focus:not(:focus-visible) {
  outline: none
}

.tv-stan-contacts__email:active {
  fill: #2962ff;
  color: #2962ff;
  transition-duration: 60ms
}

html.theme-dark .tv-stan-contacts__email:active {
  fill: #2962ff;
  color: #2962ff
}

.tv-stan-contacts--height_100per {
  height: calc(100% - 55px);
  margin-top: 0
}

.tv-stan-contacts__vertical-balancer {
  position: relative;
  top: 50%;
  transform: translateY(-50%)
}

.tv-stan-contacts__no-support {
  margin-top: 10px
}

.tv-symbol-block {
  display: table;
  position: relative;
  width: 100%
}

.tv-symbol-block__info {
  display: table-cell;
  padding-right: 25px
}

.tv-symbol-block__name {
  color: #2962ff;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 12px;
  transition: color .35s ease
}

html.theme-dark .tv-symbol-block__name {
  color: #2962ff
}

.tv-symbol-block__name:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-symbol-block__name:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-symbol-block__name:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-symbol-block__name:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-symbol-block__name:focus {
  outline: auto
}

.tv-symbol-block__name:focus:not(:-moz-focusring) {
  outline: none
}

.tv-symbol-block__name:-moz-focusring {
  outline: auto
}

.tv-symbol-block__name:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-symbol-block__name:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-symbol-block__desc {
  color: #adaeb0;
  font-size: 14px;
  line-height: 1
}

.tv-symbol-block__actions {
  display: table-cell;
  font-size: 0;
  text-align: right;
  vertical-align: middle
}

.tv-tag-label {
  background-color: #f0f3fa;
  color: #787b86;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-right: 8px;
}

html.theme-dark .tv-tag-label {
  background-color: #2a2e39;
  color: #787b86
}

.tv-tag-label.i-hover {
  background-color: #e0e3eb;
  color: #787b86;
  transition-duration: 60ms
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-tag-label:hover {
      background-color:#e0e3eb;
      color: #787b86;
      transition-duration: 60ms
  }
}

html.theme-dark .tv-tag-label.i-hover {
  color: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-tag-label:hover {
      color:#787b86
  }
}

html.theme-dark .tv-tag-label.i-hover {
  background-color: #363a45
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-tag-label:hover {
      background-color:#363a45
  }
}

.tv-tag-label.i-active,.tv-tag-label:active {
  background-color: #d1d4dc;
  color: #787b86;
  transform: translateY(1px);
  transition-duration: 60ms
}

html.theme-dark .tv-tag-label.i-active,html.theme-dark .tv-tag-label:active {
  background-color: #363a45;
  color: #787b86
}

.tv-tag-label:visited,html.theme-dark .tv-tag-label:visited {
  color: #787b86
}

.tv-tag-label--rounded {
  border-radius: 24px;
  padding-left: 12px;
  padding-right: 12px
}

.tv-tag-label.tv-tag-label--secondary {
  background-color: #9db2bd;
  color: #f7f8fa
}

.tv-tag-label.tv-tag-label--secondary.i-active,.tv-tag-label.tv-tag-label--secondary.i-hover,.tv-tag-label.tv-tag-label--secondary:active {
  background-color: "color(color-gull-gray shade(4%))";
  color: #f7f8fa;
  transition-duration: 60ms
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-tag-label.tv-tag-label--secondary:hover {
      background-color: "color(color-gull-gray shade(4%))";
      color: #f7f8fa;
      transition-duration: 60ms
  }
}

.tv-user-block {
  display: flex;
  justify-content: space-between
}

@media screen and (max-width: 1019px) {
  .tv-user-block {
      flex-wrap:wrap
  }
}

.tv-user-block__general {
  display: inline-flex;
  max-width: 420px;
  min-width: 280px;
  position: relative;
  width: 50%
}

@media screen and (max-width: 1019px) {
  .tv-user-block__general {
      flex-wrap:nowrap;
      max-width: 100%;
      width: 100%
  }
}

.tv-user-block__section-right {
  align-items: center;
  display: inline-flex;
  text-align: right
}

@media screen and (max-width: 1019px) {
  .tv-user-block__section-right {
      flex-wrap:wrap;
      padding-left: 70px;
      text-align: left
  }
}

.tv-user-block__avatar-wrap {
  display: block;
  position: relative
}

.tv-user-block__avatar {
  border-radius: 3px;
  display: block;
  height: 60px;
  width: 60px
}

@media screen and (max-width: 1019px) {
  .tv-user-block__avatar {
      height:55px;
      width: 55px
  }
}

.tv-user-block__avatar-badge {
  bottom: -8px;
  display: block;
  position: absolute;
  right: -8px
}

.tv-user-block__info {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-left: 20px;
  width: calc(100% - 80px)
}

@media screen and (max-width: 1019px) {
  .tv-user-block__info {
      flex-flow:row wrap;
      justify-content: flex-start;
      padding-left: 15px
  }
}

.tv-user-block__info-main {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: center;
  margin-right: 14px;
  min-height: 55px;
  overflow: hidden;
  white-space: nowrap
}

@media screen and (max-width: 1019px) {
  .tv-user-block__info-main {
      flex-grow:0
  }
}

.tv-user-block__info-head {
  align-items: center;
  display: flex
}

.tv-user-block__info-body {
  margin-top: 8px
}

.tv-user-block__info-body:empty {
  display: none
}

@media screen and (max-width: 1019px) {
  .tv-user-block__info-body {
      margin-top:4px
  }
}

.tv-user-block__info-text {
  color: #787b86;
  display: inline-block;
  font-size: 13px;
  line-height: 15px;
  margin: 0 8px 0 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color .35s ease;
  vertical-align: middle;
  white-space: nowrap
}

html.theme-dark .tv-user-block__info-text {
  color: #787b86
}

.tv-user-block__info-text:last-child {
  margin-right: 0
}

.tv-user-block__info-text:active,.tv-user-block__info-text:active svg {
  transition-duration: .06s
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-block__info-text:hover,.tv-user-block__info-text:hover svg {
      transition-duration:.06s
  }

  .tv-user-block__info-text:hover {
      color: #1e53e5
  }

  .tv-user-block__info-text:hover svg {
      fill: #1e53e5
  }
}

.tv-user-block__info-text:active {
  color: #1848cc
}

.tv-user-block__info-text:active svg {
  fill: #1848cc
}

.tv-user-block__info-text--online_true {
  color: #009688
}

html.theme-dark .tv-user-block__info-text--online_true {
  color: #00796b
}

.tv-user-block__info-text--not-hoverable,.tv-user-block__info-text--not-hoverable:active {
  color: #adaeb0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-block__info-text--not-hoverable:hover {
      color:#adaeb0
  }
}

.tv-user-block__info-text--not-hoverable:active svg,.tv-user-block__info-text--not-hoverable svg {
  fill: #adaeb0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-block__info-text--not-hoverable:hover svg {
      fill:#adaeb0
  }
}

.tv-user-block__info-text-divider {
  display: inline-block;
  padding: 0 8px
}

.tv-user-block__info-icons {
  display: inline-flex;
  flex-shrink: 0;
  width: 145px
}

@media screen and (max-width: 479px) {
  .tv-user-block__info-icons {
      margin-bottom:8px;
      margin-top: 8px
  }
}

.tv-user-block__info-icon {
  align-items: center;
  background-color: #f8f9fd;
  border-radius: 50%;
  display: inline-flex;
  height: 39px;
  justify-content: center;
  width: 39px
}

html.theme-dark .tv-user-block__info-icon {
  background-color: #131722
}

.tv-user-block__info-icon:not(:last-child) {
  margin-right: 14px
}

.tv-user-block__info-icon svg {
  fill: #787b86;
  display: block;
  position: relative;
  transition: fill .35s ease
}

.tv-user-block__info-icon--web svg {
  height: 15px;
  width: 15px
}

.tv-user-block__info-icon--social-link svg {
  height: 21px;
  width: 21px
}

.tv-user-block__info-icon--social-link svg path {
  fill: #787b86;
  transition: fill .35s ease
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-block__info-icon:hover,html.theme-dark .tv-user-block__info-icon:hover {
      background-color:#2962ff
  }

  .tv-user-block__info-icon:hover svg,.tv-user-block__info-icon:hover svg path {
      fill: #fff;
      transition-duration: .06s
  }

  html.theme-dark .tv-user-block__info-icon:hover svg,html.theme-dark .tv-user-block__info-icon:hover svg path {
      fill: #fff
  }
}

.tv-user-block__info-icon:active {
  background: #1848cc
}

.tv-user-block__info-icon:active svg {
  fill: #fff;
  transition-duration: .06s
}

html.theme-dark .tv-user-block__info-icon:active svg {
  fill: #fff
}

.feature-mobiletouch .tv-user-block__info-icon:active svg path {
  fill: #fff;
  transition-duration: .06s
}

html.theme-dark .feature-mobiletouch .tv-user-block__info-icon:active svg path {
  fill: #fff
}

.tv-user-block__actions {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle
}

.tv-user-block__actions--invisible {
  visibility: hidden
}

@media screen and (max-width: 1019px) {
  .tv-user-block__actions {
      display:block;
      margin-left: 0;
      margin-top: 10px
  }

  .tv-user-block__actions--invisible {
      display: none
  }
}

.tv-user-block__actions .tv-user-block__action:not(:last-child) {
  margin-right: 15px
}

@media screen and (max-width: 1019px) {
  .tv-user-block__actions .tv-user-block__action {
      margin-bottom:6px;
      margin-top: 6px
  }
}

@media screen and (max-width: 329px) {
  .tv-user-block__actions .tv-user-block__action {
      display:block;
      text-align: center;
      width: 100%
  }
}

.tv-user-block__action+.tv-user-block__action {
  margin-left: 0
}

.tv-user-block__name {
  color: #131722;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  margin-right: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color .35s ease;
  vertical-align: middle
}

html.theme-dark .tv-user-block__name {
  color: #d1d4dc
}

.tv-user-block__name:visited {
  fill: #131722;
  color: #131722
}

html.theme-dark .tv-user-block__name:visited {
  fill: #d1d4dc;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-block__name:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-user-block__name:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-user-block__name:focus {
  outline: auto
}

.tv-user-block__name:focus-visible {
  outline: auto
}

.tv-user-block__name:focus:not(:focus-visible) {
  outline: none
}

.tv-user-block__name:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: 60ms
}

html.theme-dark .tv-user-block__name:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-user-block__stats {
  font-size: 0
}

@media screen and (max-width: 1019px) {
  .tv-user-block__stats {
      height:28px;
      line-height: 28px;
      width: 100%
  }
}

.tv-user-block__stats-item {
  display: inline-block;
  line-height: 1;
  vertical-align: middle
}

.tv-user-block__stats-item:not(:last-child) {
  margin-right: 20px
}

.tv-user-link {
  align-items: center;
  display: inline-flex;
  font-size: 0
}

.tv-user-link__wrap {
  color: #434651;
  display: inline-block;
  margin-right: 6px;
  transition: color .35s ease
}

html.theme-dark .tv-user-link__wrap {
  color: #d1d4dc
}

.tv-user-link__wrap:visited {
  fill: #434651;
  color: #434651
}

html.theme-dark .tv-user-link__wrap:visited {
  fill: #d1d4dc;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-link__wrap:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-user-link__wrap:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-user-link__wrap:focus {
  outline: auto
}

.tv-user-link__wrap:focus:not(:-moz-focusring) {
  outline: none
}

.tv-user-link__wrap:-moz-focusring {
  outline: auto
}

.tv-user-link__wrap:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-user-link__wrap:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-user-link__wrap:last-child {
  margin-right: 0
}

.tv-user-link__avatar-wrap,.tv-user-link__name,.tv-user-link__status {
  display: inline-block;
  vertical-align: middle
}

.tv-user-link__avatar {
  border-radius: 2px;
  display: block;
  height: 26px;
  width: 26px
}

.tv-user-link__avatar--size_medium {
  height: 30px;
  width: 30px
}

.tv-user-link__avatar-wrap {
  display: inline-block;
  margin-right: 15px;
  position: relative
}

.tv-user-link__avatar-wrap--online:after {
  background: #3cbc98;
  border: 3px solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 8px
}

.tv-user-link__name {
  font-size: 14px
}

a.tv-user-link__name {
  color: #4a4a4a;
  transition: color .35s ease
}

a.tv-user-link__name:visited {
  fill: #4a4a4a;
  color: #4a4a4a
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  a.tv-user-link__name:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark a.tv-user-link__name:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

a.tv-user-link__name:focus {
  outline: auto
}

a.tv-user-link__name:focus:not(:-moz-focusring) {
  outline: none
}

a.tv-user-link__name:-moz-focusring {
  outline: auto
}

a.tv-user-link__name:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark a.tv-user-link__name:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-user-link__name--size_medium {
  font-size: 16px
}

.tv-user-link__name--size_small {
  font-size: 13px
}

.tv-user-link__name+.tv-badge {
  margin-left: 12px
}

.tv-user-link__star {
  color: #adaeb0
}

.tv-user-link__star svg {
  fill: #ffc84a;
  height: 15px;
  width: 15px
}

.tv-user-stat__icon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle
}

.tv-user-stat__icon svg {
  fill: #c8c8c8;
  display: block
}

.tv-user-stat__icon--user svg {
  width: 15px
}

.tv-user-stat__icon--chart svg {
  width: 28px
}

.tv-user-stat__icon--histogram svg {
  width: 18px
}

.tv-user-stat__histogram-icon-wrap {
  display: inline-block;
  float: left;
  height: 100%
}

.tv-user-stat__histogram-icon-wrap:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.tv-user-stat__value {
  color: #787b86;
  display: inline-block;
  font-size: 14px;
  line-height: 39px;
  vertical-align: middle
}

html.theme-dark .tv-user-stat__value {
  color: #787b86
}

.tv-user-stat__value--composite {
  display: inline-block;
  line-height: 1px;
  vertical-align: middle
}

.tv-user-stat__value--small {
  display: block;
  font-size: 12px;
  line-height: 18px;
  margin: 1px 0
}

.tv-user-stat__value--positive {
  color: #009688
}

html.theme-dark .tv-user-stat__value--positive {
  color: #00796b
}

.tv-user-stat--link {
  display: inline-block;
  margin: -5px;
  padding: 5px
}

.tv-user-stat--link .tv-user-stat__value {
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-stat--link:hover .tv-user-stat__value {
      color:#1e53e5;
      transition-duration: .06s
  }
}

.tv-user-stat--link:active .tv-user-stat__value {
  color: #1848cc;
  transition-duration: .06s
}

.tv-user-stat--link .tv-user-stat__icon svg {
  transition: fill .35s ease
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-user-stat--link:hover .tv-user-stat__icon svg {
      fill:#757575;
      transition-duration: .06s
  }
}

.tv-user-stat--link:active .tv-user-stat__icon svg {
  fill: #4a4a4a;
  transition-duration: .06s
}

.tv-why-free {
  overflow: auto;
  padding: 100px 0
}

.tv-why-free__header {
  font-size: 28px;
  margin-bottom: 50px
}

.tv-why-free__text {
  color: #131722;
  font-size: 14px;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 12px
}

html.theme-dark .tv-why-free__text {
  color: #d1d4dc
}

@media screen and (max-width: 767px) {
  .tv-why-free__text {
      font-size:14px
  }
}

.tv-why-free__contacts-wrapper {
  float: right;
  margin-left: 100px
}

.tv-why-free__link {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-why-free__link {
  color: #2962ff
}

.tv-why-free__link:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-why-free__link:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-why-free__link:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-why-free__link:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-why-free__link:focus {
  outline: auto
}

.tv-why-free__link:focus:not(:-moz-focusring) {
  outline: none
}

.tv-why-free__link:-moz-focusring {
  outline: auto
}

.tv-why-free__link:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-why-free__link:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-widget-chart {
  box-sizing: border-box;
  height: 100%;
  position: relative
}

.tv-widget-chart__nav {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  position: relative
}

@media screen and (max-width: 1019px) {
  .tv-widget-chart__nav {
      padding-left:20px;
      padding-right: 20px
  }
}

.tv-widget-chart__indicators {
  display: inline-block
}

.tv-widget-chart__head {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 10px 0 17px
}

.tv-widget-chart__price {
  margin-bottom: 12px
}

.tv-widget-chart__price-value {
  display: inline-block;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  transition: color .11666667s ease
}

.tv-widget-chart__price-value-changes--falling {
  color: #ef5350
}

html.theme-dark .tv-widget-chart__price-value-changes--falling {
  color: #e53935
}

.tv-widget-chart__price-value-changes--growing {
  color: #26a69a
}

html.theme-dark .tv-widget-chart__price-value-changes--growing {
  color: #00897b
}

.tv-widget-chart__price-delta {
  display: inline-block;
  font-size: 18px;
  margin-left: 12px;
  transition: color .11666667s ease
}

.tv-widget-chart__price-delta--positive,.tv-widget-chart__price-delta.growing {
  color: #009688;
  transition-duration: 58.33333ms
}

html.theme-dark .tv-widget-chart__price-delta--positive,html.theme-dark .tv-widget-chart__price-delta.growing {
  color: #00796b
}

.tv-widget-chart__price-delta--negative,.tv-widget-chart__price-delta.falling {
  color: #ef5350;
  transition-duration: 58.33333ms
}

html.theme-dark .tv-widget-chart__price-delta--negative,html.theme-dark .tv-widget-chart__price-delta.falling {
  color: #e53935
}

.tv-widget-chart__price-delta-value {
  display: inline-block
}

.tv-widget-chart__timeframes {
  height: 28px;
  margin-left: -8px;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap
}

.tv-widget-chart__container-wrappper {
  display: flex;
  justify-content: space-between;
  width: 100%
}

@media screen and (max-width: 767px) {
  .tv-widget-chart__container-wrappper {
      display:block
  }
}

.tv-widget-chart__container {
  flex-grow: 1;
  padding: 15px 25px 0
}

@media screen and (max-width: 1019px) {
  .tv-widget-chart__container {
      padding:15px 20px 0
  }
}

@media screen and (max-width: 767px) {
  .tv-widget-chart__container {
      display:block
  }
}

.tv-widget-chart__chart-wrap {
  box-sizing: border-box;
  position: relative
}

.tv-widget-chart__chart-wrap canvas {
  display: block;
  width: 100%
}

.tv-widget-chart__icon {
  display: inline-block;
  flex-shrink: 0;
  margin-right: 8px;
  vertical-align: top
}

@media screen and (max-width: 1019px) {
  .tv-widget-chart__icon {
      margin-right:12px
  }
}

.tv-widget-chart__title {
  color: #131722;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap
}

html.theme-dark .tv-widget-chart__title {
  color: #d1d4dc
}

.tv-widget-chart__subtitle {
  clear: both;
  color: #787b86;
  float: left;
  font-size: 12px;
  line-height: 1;
  margin: 6px 0 5px;
  text-transform: uppercase
}

html.theme-dark .tv-widget-chart__subtitle {
  color: #787b86
}

.tv-widget-chart__subtitle:last-child {
  margin-bottom: 0
}

.tv-widget-chart--with-border {
  border: 1px solid #e0e3eb
}

html.theme-dark .tv-widget-chart--with-border {
  border: 1px solid #434651
}

.tv-widget-description {
  color: #4c525e
}

html.theme-dark .tv-widget-description {
  color: #787b86
}

.tv-widget-description__company-info {
  margin-bottom: 12px
}

.tv-widget-description__row {
  display: block
}

.tv-widget-description__label,.tv-widget-description__value {
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  vertical-align: middle
}

.tv-widget-description__value {
  color: #262b3e;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis
}

html.theme-dark .tv-widget-description__value {
  color: #b2b5be
}

.tv-widget-description__text {
  color: #131722;
  font-size: 15px;
  line-height: 1.6
}

html.theme-dark .tv-widget-description__text {
  color: #d1d4dc
}

.tv-widget-description__text ol,.tv-widget-description__text ul {
  list-style-position: inside
}

.tv-widget-description__text a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-widget-description__text a {
  color: #2962ff
}

.tv-widget-description__text a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-widget-description__text a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-widget-description__text a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-widget-description__text a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-widget-description__text a:focus {
  outline: auto
}

.tv-widget-description__text a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-widget-description__text a:-moz-focusring {
  outline: auto
}

.tv-widget-description__text a:active {
  fill: #2962ff;
  color: #2962ff;
  transition-duration: .06s
}

html.theme-dark .tv-widget-description__text a:active {
  fill: #2962ff;
  color: #2962ff
}

.tv-widget-description__text p {
  margin-bottom: 8px
}

.tv-widget-fundamentals {
  display: flex;
  font-size: 12px;
  line-height: 19px;
  margin: 0 12px 20px
}

.tv-widget-fundamentals--card-view {
  display: inline-flex;
  font-size: 15px;
  line-height: 22px;
  margin: 0 8px 20px
}

.tv-widget-fundamentals__title {
  color: #131722;
  font-size: 14px;
  font-weight: 700;
  margin: 0 10px 5px;
  padding: 0
}

html.theme-dark .tv-widget-fundamentals__title {
  color: #b2b5be
}

.tv-widget-fundamentals__title-link {
  cursor: pointer
}

.tv-widget-fundamentals--card-view .tv-widget-fundamentals__title {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;
  padding: 0
}

.tv-widget-fundamentals__column {
  box-sizing: border-box;
  width: 50%
}

.tv-widget-fundamentals__item {
  box-sizing: border-box
}

.tv-widget-fundamentals__item:not(:last-child) {
  margin-bottom: 24px
}

.tv-widget-fundamentals--card-view .tv-widget-fundamentals__item {
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  display: flex;
  flex: 0 0 292px;
  flex-direction: column;
  height: 376px;
  margin: 4px 0;
  overflow: hidden;
  padding: 13px 12px 35px;
  width: 292px
}

html.theme-dark .tv-widget-fundamentals--card-view .tv-widget-fundamentals__item {
  box-shadow: 0 0 0 1px #0000
}

.tv-widget-fundamentals--card-view .tv-widget-fundamentals__item:not(:last-child) {
  margin-right: 8px
}

@media screen and (max-width: 767px) {
  .tv-widget-fundamentals--card-view .tv-widget-fundamentals__item {
      flex:0 0 calc(100vw - 66px);
      max-width: 348px;
      width: calc(100vw - 66px)
  }
}

.tv-widget-fundamentals--card-view:not(.tv-widget-fundamentals--transparent) .tv-widget-fundamentals__item {
  background-color: #fff
}

html.theme-dark .tv-widget-fundamentals--card-view:not(.tv-widget-fundamentals--transparent) .tv-widget-fundamentals__item {
  background-color: #1e222d
}

.tv-widget-fundamentals--card-view:not(.tv-widget-fundamentals--transparent) .tv-widget-fundamentals__item--legacy-bg {
  background-color: #fff
}

html.theme-dark .tv-widget-fundamentals--card-view:not(.tv-widget-fundamentals--transparent) .tv-widget-fundamentals__item--legacy-bg {
  background-color: #131722
}

.tv-widget-fundamentals__row {
  border-radius: 6px;
  box-sizing: border-box;
  color: #787b86;
  cursor: default;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  line-height: 19px;
  padding: 0 18px 0 10px;
  transition: background-color .35s ease;
  white-space: nowrap
}

html.theme-dark .tv-widget-fundamentals__row {
  color: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-widget-fundamentals:not(.tv-widget-fundamentals--transparent) .tv-widget-fundamentals__row:hover {
      background-color:#f0f3fa;
      transition-duration: .06s
  }

  html.theme-dark .tv-widget-fundamentals:not(.tv-widget-fundamentals--transparent) .tv-widget-fundamentals__row:hover {
      background-color: #2a2e39
  }
}

.tv-widget-fundamentals--card-view .tv-widget-fundamentals__row {
  margin: 0 -8px 8px;
  padding: 0 8px
}

.tv-widget-fundamentals__label,.tv-widget-fundamentals__value {
  box-sizing: border-box;
  display: block;
  line-height: 24px
}

.tv-widget-fundamentals--card-view .tv-widget-fundamentals__label,.tv-widget-fundamentals--card-view .tv-widget-fundamentals__value {
  padding: 0
}

.tv-widget-fundamentals__label {
  overflow: hidden;
  padding-right: 15px;
  text-overflow: ellipsis;
  transition: color .35s ease;
  white-space: nowrap
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-widget-fundamentals__row:hover .tv-widget-fundamentals__label {
      transition-duration:.06s
  }
}

.tv-widget-fundamentals__value {
  color: #131722;
  font-weight: 700;
  text-align: right
}

html.theme-dark .tv-widget-fundamentals__value {
  color: #d1d4dc
}

@media screen and (max-width: 767px) {
  .tv-widget-fundamentals__quick-link-wrap {
      padding:0
  }
}

.tv-widget-fundamentals__quick-link-wrap>a {
  cursor: pointer
}

.tv-widget-technicals {
  margin-bottom: 0;
  margin-top: 25px;
  padding: 0
}

.tv-widget-technicals__content {
  contain: content;
  margin: 20px 0;
  overflow: hidden;
  padding: 0 25px
}

.tv-widget-placard {
  background-position: 50%;
  background-size: cover;
  display: block;
  height: 225px;
  margin-bottom: 20px;
  position: relative
}

.tv-widget-placard__label {
  background-color: #00000080;
  bottom: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  line-height: 1.3;
  padding: 18px 20px 20px;
  position: absolute;
  right: 0
}

.tv-card-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%
}

.tv-card-container__chart {
  flex: 0 0 auto;
  margin-bottom: 36px;
  min-width: 100%
}

@media screen and (max-width: 1019px) {
  .tv-card-container__chart {
      background-color:#fff;
      margin: 0
  }

  html.theme-dark .tv-card-container__chart {
      background-color: #131722
  }
}

.tv-card-container__columns {
  align-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  display: flex
}

@media screen and (max-width: 1019px) {
  .tv-card-container__columns {
      flex-wrap:wrap
  }
}

.tv-card-container__column-row {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  margin: 0 10px 20px;
  max-width: calc(100% - 20px)
}

@media screen and (max-width: 1019px) {
  .tv-card-container__column-row {
      margin:24px 10px 17px 20px;
      max-width: calc(100% - 20px)
  }
}

.tv-card-container__column-row--pagination {
  margin-top: 0
}

@media screen and (max-width: 767px) {
  .tv-card-container__column-row--pagination {
      margin:0 10px 56px
  }
}

@media screen and (max-width: 479px) {
  .tv-card-container__column-row--pagination {
      margin-bottom:20px
  }
}

.tv-card-container__column-filter {
  margin-left: auto
}

.tv-card-container__main {
  contain: layout style
}

.tv-card-container__ideas,.tv-card-container__main {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  max-width: 100%
}

.tv-card-container__ideas {
  position: relative
}

@media screen and (max-width: 1019px) {
  .tv-card-container__ideas--with-padding {
      padding-top:10px
  }
}

.tv-card-container__ideas--loading:after {
  background-color: #fff;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: .7;
  position: absolute;
  right: 0;
  top: 0
}

html.theme-dark .tv-card-container__ideas--loading:after {
  background-color: #131722
}

.tv-card-container__widgets {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 500px
}

@media screen and (max-width: 1019px) {
  .tv-card-container__widgets {
      max-width:none;
      order: -1;
      width: 100%
  }
}

.tv-card-container__widgets .tv-tabs__left-arrow {
  background: linear-gradient(90deg,#fff,#ffffffd9,#fff0)
}

html.theme-dark .tv-card-container__widgets .tv-tabs__left-arrow {
  background: linear-gradient(90deg,#131722,#131722d9,#13172200)
}

.tv-card-container__widgets .tv-tabs__right-arrow {
  background: linear-gradient(270deg,#fff,#ffffffd9,#fff0)
}

html.theme-dark .tv-card-container__widgets .tv-tabs__right-arrow {
  background: linear-gradient(270deg,#131722,#131722d9,#13172200)
}

.tv-card-container__followers,.tv-card-container__following-people {
  width: 100%
}

.tv-empty-card {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e3eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 592px;
  overflow: hidden;
  padding-bottom: 40px
}

html.theme-dark .tv-empty-card {
  background-color: #1e222d;
  box-shadow: 0 0 0 1px #0000
}

@media screen and (max-width: 1019px) {
  .tv-empty-card {
      margin-bottom:10px;
      margin-left: 8px;
      margin-right: 8px
  }
}

.tv-empty-card__text {
  color: #131722;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -.2px;
  line-height: 32px;
  margin-bottom: 29px;
  margin-top: 19px;
  text-align: center;
  width: 282px
}

html.theme-dark .tv-empty-card__text {
  color: #d1d4dc
}

.tv-empty-card:active .tv-empty-card__button {
  background-color: #1e53e5;
  border-color: #1e53e5;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-empty-card:hover .tv-empty-card__button {
      background-color:#1e53e5;
      border-color: #1e53e5;
      color: #fff
  }
}

html.theme-dark .tv-empty-card:active .tv-empty-card__button {
  background-color: #1e53e5
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-empty-card:hover .tv-empty-card__button {
      background-color:#1e53e5
  }
}

html.theme-dark .tv-empty-card:active .tv-empty-card__button {
  border-color: #1e53e5
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-empty-card:hover .tv-empty-card__button {
      border-color:#1e53e5
  }
}

.tv-empty-card__icon,html.theme-dark .tv-empty-card__icon {
  color: #787b86
}

.i-hidden {
  display: none!important
}

.i-invisible {
  visibility: hidden!important
}

.i-clearfix:after {
  clear: both;
  content: "";
  display: table
}

.i-align_left {
  text-align: left!important
}

.i-align_right {
  text-align: right!important
}

.i-align_center {
  text-align: center!important
}

.i-float_left {
  float: left!important
}

.i-float_right {
  float: right!important
}

.i-float_none {
  float: none!important
}

@media screen and (min-width: 1020px) {
  .i-device-only {
      display:none!important
  }
}

@media screen and (max-width: 1019px) {
  .i-desktop-only {
      display:none!important
  }
}

@media screen and (min-width: 479px) {
  .i-phones-only {
      display:none!important
  }
}

@media screen and (max-width: 479px) {
  .i-except-phones-only {
      display:none!important
  }
}

.i-no-break {
  white-space: nowrap
}

html.i-fixedlayout .tv-content,html.i-fixedlayout .tv-footer,html.i-fixedlayout .tv-header,html.i-fixedlayout .tv-header__inner:before,html.i-fixedlayout .tv-layout-width,html.i-fixedlayout .tv-search-bar {
  min-width: 1060px
}

.market-summary-quote-ticker>a {
  display: block;
  margin-top: 17px;
  padding: 0 8% 0 0;
  transition: all .4s
}

.market-summary-quote-ticker>a:last-child {
  padding-right: 0
}

.market-summary-quote-ticker.chart-legend>a {
  border-left: 2px solid #0000;
  padding: 0 18px
}

.market-summary-quote-ticker .symbol-name {
  color: #2962ff;
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  transition: color .35s ease
}

html.theme-dark .market-summary-quote-ticker .symbol-name {
  color: #2962ff
}

.market-summary-quote-ticker .symbol-name:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .market-summary-quote-ticker .symbol-name:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .market-summary-quote-ticker .symbol-name:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .market-summary-quote-ticker .symbol-name:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.market-summary-quote-ticker .symbol-name:focus {
  outline: auto
}

.market-summary-quote-ticker .symbol-name:focus:not(:-moz-focusring) {
  outline: none
}

.market-summary-quote-ticker .symbol-name:-moz-focusring {
  outline: auto
}

.market-summary-quote-ticker .symbol-name:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .market-summary-quote-ticker .symbol-name:active {
  fill: #1848cc;
  color: #1848cc
}

.market-summary-quote-ticker .symbol-tiker {
  border-radius: 1px;
  margin-top: 3px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.market-summary-quote-ticker .symbol-last {
  height: 18px
}

.market-summary-quote-ticker .symbol-last.growing {
  background: #ff260033
}

.market-summary-quote-ticker .symbol-last.falling {
  background: #6dff0033
}

.market-summary-quote-ticker .symbol-change-direction,.market-summary-quote-ticker .symbol-change-direction.down,.market-summary-quote-ticker .symbol-change-direction.up {
  color: #fff
}

.market-summary-quote-ticker .symbol-change-direction:before {
  border-color: #0000;
  border-style: inset;
  content: "";
  display: inline-block;
  height: 0;
  margin-bottom: 2px;
  margin-right: 6px;
  position: relative;
  transform: rotate(1turn);
  width: 0
}

.market-summary-quote-ticker .symbol-change-direction.up:before {
  border-color: #0000 #0000 #46a673;
  border-width: 0 3.5px 5px
}

.market-summary-quote-ticker .symbol-change-direction.down:before {
  border-color: #e95f54 #0000 #0000;
  border-width: 5px 3.5px 0
}

.widgetbar-page .market-summary .timeframe {
  height: 18px;
  margin: 22px 0;
  width: 100%
}

.widgetbar-page .market-summary .timeframe a {
  color: #999;
  display: inline-block;
  text-align: center;
  width: 16.6%
}

.widgetbar-page .market-summary .timeframe a.active {
  color: #333
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .widgetbar-page .market-summary .timeframe a:hover {
      color:#333
  }
}

.widgetbar-page .market-summary table.quote-tickers {
  border-collapse: collapse;
  width: 100%
}

.widgetbar-page .market-summary table.quote-tickers td {
  padding: 0 7px;
  text-align: center
}

.widgetbar-page .market-summary table.quote-tickers td:first-child {
  padding-left: 14px
}

.widgetbar-page .market-summary table.quote-tickers td:last-child {
  padding-right: 14px
}

.widgetbar-page .market-summary table.quote-tickers .market-summary-quote-ticker {
  margin: 19px 0
}

.widgetbar-page .market-summary table.quote-tickers .market-summary-quote-ticker>a {
  margin: 0
}

.widgetbar-page .market-summary table.quote-tickers .market-summary-quote-ticker .symbol-change-direction,.widgetbar-page .market-summary table.quote-tickers .market-summary-quote-ticker .symbol-change-direction.down,.widgetbar-page .market-summary table.quote-tickers .market-summary-quote-ticker .symbol-change-direction.up {
  color: #333
}

.orderform-page {
  display: none
}

.orderform-page.active {
  display: block
}

.orderform-inputs {
  background: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 15px;
  padding: 15px
}

.orderform-nav {
  text-align: right
}

.orderform-inputs th {
  text-align: left
}

.tv-text-input {
  border: 1px solid #e0e3eb;
  color: #434651;
  display: inline-block;
  height: 26px;
  margin: 0;
  overflow: hidden;
  padding: 0 5px;
  vertical-align: top;
  width: 100px
}

html.theme-dark .tv-text-input {
  border-color: #363a45;
  color: #d1d4dc
}

.tv-text-input:focus,html.theme-dark .tv-text-input:focus {
  border-color: #2962ff
}

.tv-text-input.inset {
  background: #fff
}

html.theme-dark .tv-text-input.inset {
  background: #131722
}

.tv-text-input.ticker {
  height: 25px;
  width: 60px
}

.tv-text-input.ticker--longer-sign_8 {
  width: 72px
}

.tv-text-input.ticker--evenlonger {
  width: 90px
}

.trading-dialog form input[type=text]:not(.tv-control-input) {
  background: #fff;
  border: 1px solid #e0e3eb;
  box-sizing: initial;
  color: #434651;
  display: inline-block;
  height: 26px;
  height: 25px;
  margin: 0;
  overflow: hidden;
  padding: 0 5px;
  vertical-align: top;
  width: 100px
}

html.theme-dark .trading-dialog form input[type=text]:not(.tv-control-input) {
  background: #131722;
  border-color: #363a45;
  color: #d1d4dc
}

.trading-dialog form input[type=text]:not(.tv-control-input).disabled,.trading-dialog form input[type=text]:not(.tv-control-input)[disabled] {
  opacity: .5
}

.trading-dialog form input[type=text]:not(.tv-control-input):focus {
  border-color: #4c82ce
}

.trading-dialog form input[type=text].invalid {
  border-color: #de5764
}

.trading-dialog button {
  background-color: #fff;
  border: 1px solid #cfd2d4;
  color: #5c5d5e;
  cursor: default;
  padding: .5em
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-dialog button:hover {
      background-color:#f8f8f8
  }
}

.trading-dialog button.active,.trading-dialog button:active {
  background-color: #f2f5f8
}

.trading-dialog select {
  background-color: #fff;
  border: 1px solid #cfd2d4;
  color: #5c5d5e;
  cursor: default;
  padding: .5em 20px .5em .5em;
  position: relative
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-dialog select:hover {
      background-color:#f8f8f8
  }
}

.trading-dialog select.active,.trading-dialog select:active {
  background-color: #f2f5f8
}

.trading-dialog select:after {
  border-color: #96989c #0000 #0000;
  border-style: solid;
  border-width: 4px 4px 0;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  right: 6px;
  top: 12px;
  width: 0
}

.trading-dialog ._tv-dialog-content {
  padding: 20px;
  width: 444px
}

.trading-dialog ._tv-dialog-content td {
  padding: 4px;
  vertical-align: middle;
  white-space: nowrap
}

.trading-dialog .if-limit,.trading-dialog .if-market,.trading-dialog .if-stop {
  display: none!important
}

.trading-dialog.is-limit .if-limit,.trading-dialog.is-market .if-market,.trading-dialog.is-stop .if-stop {
  display: inherit!important
}

.trading-dialog.is-market .if-not-market {
  display: none
}

.trading-dialog .field.row {
  display: block;
  margin: 11px 0 0;
  min-height: 30px
}

.trading-dialog .field.row .cell,.trading-dialog .field.row .value {
  display: inline-block;
  line-height: 30px;
  vertical-align: middle
}

.trading-dialog .field.row span.cell {
  width: 75px
}

.trading-dialog .field.row .value {
  font-weight: 700
}

.trading-dialog .field-type>label {
  display: block
}

.trading-dialog .field-type input {
  vertical-align: middle
}

.trading-dialog .buttons {
  margin-top: 32px;
  overflow: hidden
}

.trading-dialog .cqg-submit {
  float: right;
  min-width: 50px
}

.trading-dialog .buy,.trading-dialog .modify,.trading-dialog .sell {
  color: #fff;
  float: right;
  margin-left: 20px;
  margin-right: 0;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase
}

.trading-dialog .buy.i-hidden,.trading-dialog .modify.i-hidden,.trading-dialog .sell.i-hidden {
  display: none
}

.trading-dialog .buy {
  background: #00b9db;
  border-color: #00b9db
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-dialog .buy:hover {
      background:#00c6ea
  }
}

.trading-dialog .buy.process {
}

.trading-dialog .buy.deselect {
  background: #888
}

.trading-dialog .sell {
  background: #d9533d;
  border-color: #d9533d
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-dialog .sell:hover {
      background:#dc5e4a
  }
}

.trading-dialog .sell.process {
}

.trading-dialog .sell.deselect {
  background: #888
}

.trading-dialog .modify {
  background: #4da979;
  border-color: #4da979
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-dialog .modify:hover {
      background:#54b181
  }
}

.trading-dialog .select {
  background-color: #59b987;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-dialog .select:hover {
      background:#64be8f
  }
}

.trading-dialog.is-mode-modify .buy,.trading-dialog.is-mode-modify .sell,.trading-dialog.is-mode-place .cancel,.trading-dialog.is-mode-place .close,.trading-dialog.is-mode-place .modify {
  display: none
}

.trading-dialog .ticker {
  background: #f2f5f8;
  border: 1px solid #e5e5e5;
  height: 95px;
  padding: 6px 20px
}

.trading-dialog .ticker .description {
  color: #797272;
  display: block;
  margin: 4px 0
}

.trading-dialog .ticker .last {
  font-size: 52px;
  margin-right: 5px
}

.trading-dialog .ticker .last sup {
  font-size: 80%;
  vertical-align: 20%
}

.trading-dialog .ticker .change-small,.trading-dialog .ticker .last-small {
  color: #000
}

.trading-dialog .ticker .change {
  font-size: 16px
}

.trading-dialog .ticker .up {
  color: #238859
}

.trading-dialog .ticker .down {
  color: #dc5538
}

.trading-dialog .ticker .ask,.trading-dialog .ticker .bid {
  font-size: 16px
}

.trading-dialog .ticker .ask {
  color: #5b85bf;
  float: right
}

.trading-dialog .ticker .bid {
  color: #b35b46;
  float: left
}

.trading-dialog .columns {
  white-space: nowrap
}

.trading-dialog .columns:after {
  clear: both;
  content: "";
  display: table
}

.trading-dialog .columns .column-left {
  float: left
}

.trading-dialog .columns .column-right {
  float: right
}

.trading-dialog .type-selector {
  height: 29px;
  margin: 0 0 9px
}

.trading-dialog .type-selector a {
  background: #f9fafb;
  border: 1px solid #c9cbcd;
  color: #595959;
  cursor: default;
  display: block;
  font-size: 13px;
  height: 17px;
  line-height: 17px;
  padding: 5px 0;
  position: relative;
  text-align: center;
  z-index: 10
}

.trading-dialog .type-selector a.limit {
  overflow: hidden
}

.trading-dialog .type-selector a.stop {
  float: right;
  margin-left: -1px;
  width: 33%
}

.trading-dialog .type-selector a.market {
  float: left;
  margin-right: -1px;
  width: 33%
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-dialog .type-selector a:hover {
      border-color:#b5b7b9;
      z-index: 11
  }
}

.trading-dialog .type-selector.disabled a {
  background-color: #fcfdfd;
  border-color: #e4e5e6;
  color: #e4e5e6;
  cursor: default
}

.trading-dialog .type-selector a.active {
  background-color: #e9f2f7;
  border-color: #66bfe6;
  color: #00afd9;
  cursor: default;
  z-index: 12
}

.trading-dialog .date-container,.trading-dialog .time-container {
  display: inline-block;
  position: relative;
  vertical-align: middle
}

.trading-dialog .date-container i,.trading-dialog .time-container i {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 7px
}

.trading-dialog .date-container i {
  top: 6px
}

.trading-dialog .date-container i svg {
  fill: #787b86;
  display: block;
  height: 16px;
  width: 16px
}

html.theme-dark .trading-dialog .date-container i svg {
  fill: #787b86
}

.trading-dialog .date-container input[type=text] {
  border-right-style: dotted
}

.trading-dialog .date-container.single input[type=text],.trading-dialog .date-container input[type=text].invalid,.trading-dialog .date-container input[type=text]:focus {
  border-right-style: solid
}

.trading-dialog .time-container input[type=text] {
  background-clip: padding-box;
  border-left-color: #0000;
  margin-left: -1px;
  width: 60px
}

.trading-dialog .time-container i {
  top: 7px
}

.trading-dialog .time-container i svg {
  fill: #787b86;
  display: block;
  height: 14px;
  width: 14px
}

html.theme-dark .trading-dialog .time-container i svg {
  fill: #787b86
}

.trading-dialog table.dlg-layout {
  border-collapse: collapse
}

.trading-dialog table.dlg-layout td {
  padding: 3px 0
}

.trading-dialog table.dlg-layout td.padded {
  padding-left: 40px
}

.trading-dialog table.dlg-layout td.padded-light {
  padding-left: 5px
}

.trading-dialog table.dlg-layout input {
  vertical-align: middle
}

.trading-dialog table.dlg-layout .title {
  display: inline-block;
  min-width: 75px;
  padding-right: 5px;
  vertical-align: middle;
  white-space: nowrap
}

.trading-dialog table.dlg-layout .title input[type=checkbox] {
  margin: -5px 5px -5px 0;
  vertical-align: middle
}

.trading-dialog table.dlg-layout .interleave {
  display: inline-block;
  padding: 0 5px;
  vertical-align: middle;
  white-space: nowrap
}

.trading-dialog .resolving-status {
  margin: 1em 0;
  min-height: 1em;
  overflow: hidden;
  text-overflow: ellispsis
}

.trading-dialog .resolving-status .resolving {
  color: #888
}

.trading-dialog .resolving-status .error {
  color: crimson
}

.trading-dialog .dl-dialog-more {
  margin-top: 1em
}

.trading-dialog .padder,.trading-dialog .separator {
  height: 1px;
  margin: 8px
}

.trading-dialog .separator {
  background: #eee
}

.trading-journal-dialog table.history,.trading-journal-dialog table.journal {
  border-collapse: collapse;
  width: 100%
}

.trading-journal-dialog table.history td,.trading-journal-dialog table.history th,.trading-journal-dialog table.journal td,.trading-journal-dialog table.journal th {
  padding: 10px;
  vertical-align: top
}

.trading-journal-dialog table.history th,.trading-journal-dialog table.journal th {
  padding-left: 20px;
  text-align: right;
  white-space: nowrap
}

.trading-journal-dialog table.history td,.trading-journal-dialog table.journal td {
  padding-right: 20px
}

.trading-journal-dialog table.history tr:nth-child(2n),.trading-journal-dialog table.journal tr:nth-child(2n) {
  background: #f8f9fc
}

html.theme-dark .trading-journal-dialog table.history tr:nth-child(2n),html.theme-dark .trading-journal-dialog table.journal tr:nth-child(2n) {
  background: #171b29
}

.trading-journal-dialog .stub {
  font-style: italic;
  margin: 10px auto;
  text-align: center
}

.trading-journal-dialog .loading {
  height: 36px;
  position: relative
}

.trading-journal-dialog .page {
  display: none
}

.trading-journal-dialog .page.active {
  display: block
}

.cqg-trading-dialog ._tv-dialog-content {
  width: auto
}

.cqg-trading-dialog .ticker {
  height: 55px
}

.trading-broker-select-screen {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative
}

.trading-broker-select-screen h2 {
  color: #787b86;
  display: flex;
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  overflow: hidden;
  padding: 20px 0 10px;
  text-overflow: ellipsis;
  transition: color .3s;
  white-space: nowrap;
  width: 100%
}

html.theme-dark .trading-broker-select-screen h2 {
  color: #787b86
}

.trading-broker-select-screen--filtered {
  padding-bottom: 80px
}

.trading-broker-buttons-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center
}

.trading-broker-buttons-wrapper .trading-broker-button {
  align-items: center;
  color: #4f5966;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 10px;
  transition: width .2s,margin .2s;
  width: 202px
}

html.theme-dark .trading-broker-buttons-wrapper .trading-broker-button {
  color: #8797a5
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .trading-broker-buttons-wrapper .trading-broker-button:not(.disabled):not(.selected):hover {
      color:#2962ff
  }
}

.trading-broker-buttons-wrapper .trading-broker-button.disabled {
  cursor: default;
  margin: 0;
  opacity: .25;
  width: 0
}

.trading-broker-buttons-wrapper .trading-broker-button.selected {
  cursor: default
}

.trading-broker-buttons-wrapper .trading-broker-button .title {
  display: block;
  font-size: 18px;
  margin-top: 20px
}

.trading-broker-buttons-wrapper>svg {
  display: block;
  margin: 0 auto;
  width: 202px
}

.trading-broker-filter {
  bottom: 0;
  color: #787b86;
  left: 50%;
  margin: 15px auto;
  position: absolute;
  text-align: center;
  transform: translateX(-50%)
}

html.theme-dark .trading-broker-filter {
  color: #787b86
}

.trading-broker-filter span {
  color: #2962ff;
  cursor: pointer
}

html.theme-dark .trading-broker-filter span {
  color: #2962ff
}

.tv-dialog .trading-password-change {
  width: 100%
}

.tv-dialog .trading-password-change--row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 0
}

.popover {
  background-clip: padding-box;
  background-color: #e0e3eb;
  border: 1px solid #e0e3eb;
  box-shadow: 0 5px 10px #0003;
  display: none;
  left: 0;
  max-width: 276px;
  padding: 1px;
  position: absolute;
  text-align: left;
  top: 0;
  white-space: normal;
  z-index: 1010
}

html.theme-dark .popover {
  background-color: #363a45;
  border-color: #363a45
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e3eb;
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  padding: 8px 14px
}

html.theme-dark .popover-title {
  border-bottom-color: #363a45
}

.popover-content {
  padding: 9px 14px
}

.popover>.arrow {
  display: none
}

.btn {
  background-image: none;
  border: 1px solid #0000;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none
}

.btn.active:focus,.btn:active:focus,.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

.btn:focus {
  color: #333;
  text-decoration: none
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .btn:hover {
      color:#333;
      text-decoration: none
  }
}

.btn.active,.btn:active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  outline: 0
}

.btn-default {
  background-color: #fff;
  border-color: #ccc;
  color: #333
}

.btn-default.active,.btn-default:active,.btn-default:focus,.open .dropdown-toggle.btn-default {
  background-color: #ebebeb;
  border-color: #adadad;
  color: #333
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .btn-default:hover {
      background-color:#ebebeb;
      border-color: #adadad;
      color: #333
  }
}

.btn-default.active,.btn-default:active,.open .dropdown-toggle.btn-default {
  background-image: none
}

.btn-block {
  display: block;
  width: 100%
}

.text-primary,html.theme-dark .text-primary {
  color: #2962ff
}

.clockpicker .input-group-addon {
  cursor: pointer
}

.clockpicker-moving {
  cursor: move
}

.clockpicker-popover .popover-title {
  background-color: #fff;
  color: #999;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center
}

html.theme-dark .clockpicker-popover .popover-title {
  background-color: #131722;
  color: #4c525e
}

.clockpicker-popover .popover-title span {
  cursor: pointer
}

.clockpicker-popover .popover-content {
  background-color: #f7f8fa;
  padding: 12px
}

html.theme-dark .clockpicker-popover .popover-content {
  background-color: #2f3241
}

.popover-content:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px
}

.clockpicker-plate {
  -webkit-touch-callout: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  height: 200px;
  overflow: visible;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 200px
}

html.theme-dark .clockpicker-plate {
  background-color: #131722;
  border-color: #363c4e
}

.clockpicker-canvas,.clockpicker-dial {
  height: 200px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 200px
}

.clockpicker-minutes {
  visibility: hidden
}

.clockpicker-tick {
  border-radius: 50%;
  color: #666;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  position: absolute;
  text-align: center;
  width: 26px
}

html.theme-dark .clockpicker-tick {
  color: #758696
}

.clockpicker-tick.active {
  background-color: #c0e5f7;
  background-color: #0095dd40
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .clockpicker-tick:hover {
      background-color:#c0e5f7;
      background-color: #0095dd40
  }
}

.clockpicker-button {
  background-color: #fff;
  background-image: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 1px 0 0;
  margin: 0;
  padding: 10px 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .clockpicker-button:hover {
      background-color:#ebebeb;
      background-image: none
  }
}

.clockpicker-button:focus {
  outline: none!important
}

.clockpicker-dial {
  transition: transform .35s,opacity .35s
}

.clockpicker-dial-out {
  opacity: 0
}

.clockpicker-hours.clockpicker-dial-out {
  transform: scale(1.2)
}

.clockpicker-minutes.clockpicker-dial-out {
  transform: scale(.8)
}

.clockpicker-canvas {
  transition: opacity 175ms
}

.clockpicker-canvas-out {
  opacity: .25
}

.clockpicker-canvas-bearing,.clockpicker-canvas-fg {
  stroke: none;
  fill: #3bb3e4
}

.clockpicker-canvas-bg {
  stroke: none;
  fill: #3bb3e440
}

.clockpicker-canvas-bg-trans {
  fill: #3bb3e440
}

.clockpicker-canvas line {
  stroke: #3bb3e4;
  stroke-width: 1;
  stroke-linecap: round
}

@media screen and (max-width: 1000px) {
  .index-learnmore-header .symbol-input {
      width:90%
  }

  .search-results {
      margin-left: 0
  }

  .search-results .item {
      display: block;
      margin-left: 0;
      width: auto
  }

  .search-results .item .img-border img {
      height: auto;
      width: 100%
  }

  .search-results .item.feed__item--width_two-columns {
      width: auto
  }

  .search-results .item.feed__item--width_two-columns .img-border img {
      height: auto;
      width: 100%
  }
}

.tv-market-status__dot {
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  vertical-align: middle;
  width: 4px
}

.tv-market-status--pre-market {
  color: #ff9800
}

.tv-market-status--pre-market .tv-market-status__dot {
  background-color: #ff9800
}

.tv-market-status--post-market {
  color: #3179f5
}

.tv-market-status--post-market .tv-market-status__dot {
  background-color: #3179f5
}

.tv-market-status--market {
  color: #4db6ac
}

.tv-market-status--market .tv-market-status__dot {
  background-color: #4db6ac
}

.tv-market-status--out-of-session {
  color: #9598a1
}

.tv-market-status--out-of-session .tv-market-status__dot {
  background-color: #9598a1
}

.tv-market-status--invalid {
  color: #f44336
}

.tv-market-status--invalid .tv-market-status__dot {
  background-color: #f44336
}

.tv-market-status--replay {
  color: #ff4a68
}

.tv-market-status--replay .tv-market-status__dot {
  background-color: #ff4a68
}

.tv-market-status--holiday {
  color: #9598a1
}

.tv-market-status--holiday .tv-market-status__dot {
  background-color: #9598a1
}

.tv-market-status--for-chart {
  display: block;
  float: left;
  margin-right: 0
}

.tv-market-status--for-chart--pre-market {
  color: #ff9800
}

.tv-market-status--for-chart--pre-market .tv-market-status__dot {
  background-color: #ff9800
}

.tv-market-status--for-chart--post-market {
  color: #3179f5
}

.tv-market-status--for-chart--post-market .tv-market-status__dot {
  background-color: #3179f5
}

.tv-market-status--for-chart--market {
  color: #4db6ac
}

.tv-market-status--for-chart--market .tv-market-status__dot {
  background-color: #4db6ac
}

.tv-market-status--for-chart--out-of-session {
  color: #9598a1
}

.tv-market-status--for-chart--out-of-session .tv-market-status__dot {
  background-color: #9598a1
}

.tv-market-status--for-chart--invalid {
  color: #f44336
}

.tv-market-status--for-chart--invalid .tv-market-status__dot {
  background-color: #f44336
}

.tv-market-status--for-chart--replay {
  color: #ff4a68
}

.tv-market-status--for-chart--replay .tv-market-status__dot {
  background-color: #ff4a68
}

.tv-market-status--for-chart--holiday {
  color: #9598a1
}

.tv-market-status--for-chart--holiday .tv-market-status__dot {
  background-color: #9598a1
}

.tv-market-status--loading--for-chart {
  color: #3179f5
}

.tv-market-status--loading--for-chart .tv-market-status__dot {
  background-color: #3179f5
}

.tv-market-status--for-ticker {
  margin-left: 4px
}

.tv-market-status--for-watch-list {
  margin: auto 3px
}

.tv-market-status--for-symbol-widget {
  font-size: 10px;
  line-height: 24px
}

.tv-market-status--for-ticker-last {
  display: inline-flex;
  margin-left: 4px
}

.tv-market-status--for-sticky-symbol-header,.tv-market-status--for-ticker-tape {
  align-items: center;
  display: flex;
  transform: translateX(4px)
}

.tv-market-status--for-ticker-header {
  display: flex;
  margin-left: 4px
}

.tv-market-status__dot--for-chart {
  height: 6px;
  margin-right: 4px;
  vertical-align: 1px;
  width: 6px
}

.tv-market-status__dot--for-watch-list {
  margin-bottom: 3px
}

.tv-market-status__dot--for-symbol-widget {
  font-size: 24px;
  margin-left: 8px
}

.tv-market-status__dot--for-ticker-last {
  height: 6px;
  width: 6px
}

.tv-market-status__dot--for-sticky-symbol-header,.tv-market-status__dot--for-ticker-tape {
  height: 4px;
  width: 4px
}

.tv-market-status__label--for-chart {
  display: block;
  float: right;
  line-height: 16px;
  margin-right: 0
}

.tv-market-status__label--for-watch-list {
  display: none
}

.tv-market-status__label--for-symbol-widget {
  float: right;
  font-size: 13px;
  margin-left: 4px
}

.tv-market-status--market .tv-market-status__dot--for-sticky-symbol-header,.tv-market-status--market .tv-market-status__dot--for-symbol-widget,.tv-market-status--market .tv-market-status__dot--for-ticker,.tv-market-status--market .tv-market-status__dot--for-ticker-header,.tv-market-status--market .tv-market-status__dot--for-ticker-last,.tv-market-status--market .tv-market-status__dot--for-ticker-tape,.tv-market-status--market .tv-market-status__dot--for-watch-list,.tv-market-status__label--for-sticky-symbol-header,.tv-market-status__label--for-ticker,.tv-market-status__label--for-ticker-header,.tv-market-status__label--for-ticker-last,.tv-market-status__label--for-ticker-tape {
  display: none
}

.tv-market-status--for-chart {
  margin-left: 5px
}

.tv-market-status--market--for-chart {
  display: none
}

.tv-market-status--market--for-symbol-widget {
  visibility: hidden
}

.common-tooltip-36YLR71G {
  color: #f0f3fa;
  display: inline-flex;
  font-size: 12px;
  line-height: 18px;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  transition: opacity .15s linear;
  z-index: 1000
}

.common-tooltip--hidden-36YLR71G {
  opacity: 0
}

.common-tooltip--horizontal-36YLR71G {
  margin: 4px 0
}

.common-tooltip--horizontal-36YLR71G.common-tooltip--farther-36YLR71G {
  margin: 8px 0
}

.common-tooltip--vertical-36YLR71G {
  margin: 0 4px
}

.common-tooltip--vertical-36YLR71G.common-tooltip-farther-36YLR71G {
  margin: 0 8px
}

.common-tooltip--direction_normal-36YLR71G {
  flex-direction: row
}

.common-tooltip--direction_normal-36YLR71G .common-tooltip__body-36YLR71G {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px
}

.common-tooltip--direction_normal-36YLR71G .common-tooltip__body--no-buttons-36YLR71G,.common-tooltip--direction_normal-36YLR71G .common-tooltip__button-container-36YLR71G {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px
}

.common-tooltip--direction_normal-36YLR71G .common-tooltip__button-36YLR71G:not(:last-child) {
  margin-right: 1px
}

.common-tooltip--direction_reversed-36YLR71G {
  flex-direction: row-reverse
}

.common-tooltip--direction_reversed-36YLR71G .common-tooltip__body-36YLR71G {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px
}

.common-tooltip--direction_reversed-36YLR71G .common-tooltip__body--no-buttons-36YLR71G,.common-tooltip--direction_reversed-36YLR71G .common-tooltip__button-container-36YLR71G {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px
}

.common-tooltip--direction_reversed-36YLR71G .common-tooltip__button-36YLR71G:not(:first-child) {
  margin-left: 1px
}

.common-tooltip__ear-holder-36YLR71G {
  position: relative
}

.common-tooltip__ear-holder-36YLR71G:after {
  border: 0 solid #2a2e39;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 0
}

html.theme-dark .common-tooltip__ear-holder-36YLR71G:after {
  border-color: #363a45
}

.common-tooltip__ear-holder--above-36YLR71G:after,.common-tooltip__ear-holder--below-36YLR71G:after {
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  left: 50%;
  margin-left: -6px
}

html.theme-dark .common-tooltip__ear-holder--above-36YLR71G:after,html.theme-dark .common-tooltip__ear-holder--below-36YLR71G:after {
  border-left-color: #0000;
  border-right-color: #0000
}

.common-tooltip__ear-holder--below-36YLR71G:after {
  border-bottom-width: 4px;
  bottom: 100%
}

.common-tooltip__ear-holder--above-36YLR71G:after {
  border-top-width: 4px;
  top: 100%
}

.common-tooltip__ear-holder--after-36YLR71G:after,.common-tooltip__ear-holder--before-36YLR71G:after {
  border-bottom: 6px solid #0000;
  border-top: 6px solid #0000;
  margin-top: -6px;
  top: 50%
}

html.theme-dark .common-tooltip__ear-holder--after-36YLR71G:after,html.theme-dark .common-tooltip__ear-holder--before-36YLR71G:after {
  border-bottom-color: #0000;
  border-top-color: #0000
}

.common-tooltip__ear-holder--before-36YLR71G:after {
  border-right-width: 4px;
  right: 100%
}

.common-tooltip__ear-holder--after-36YLR71G:after {
  border-left-width: 4px;
  left: 100%
}

.common-tooltip__body-36YLR71G {
  word-wrap: break-word;
  background-color: #2a2e39;
  box-sizing: border-box;
  display: block;
  max-width: 320px;
  overflow: hidden;
  padding: 3px 8px;
  position: relative;
  text-align: left;
  white-space: pre-wrap
}

html.theme-dark .common-tooltip__body-36YLR71G {
  background-color: #363a45
}

.common-tooltip__body--with-hotkey-36YLR71G {
  display: flex;
  max-width: 420px;
  padding: 0
}

.common-tooltip__body--width_wide-36YLR71G {
  max-width: 640px
}

.common-tooltip__body--width_narrow-36YLR71G {
  max-width: 200px
}

.common-tooltip__body--no-padding-36YLR71G {
  padding: 0
}

.common-tooltip__hotkey-block-36YLR71G {
  align-items: center;
  color: #ff9800;
  display: inline-flex;
  flex: 1 0 auto;
  justify-content: center;
  line-height: 12px;
  padding: 4px 8px 5px
}

.common-tooltip__hotkey-block--divider-36YLR71G {
  border-left: 1px solid #5d606b;
  margin-left: 8px
}

html.theme-dark .common-tooltip__hotkey-block--divider-36YLR71G {
  border-left: 1px solid #363a45
}

.common-tooltip__hotkey-text-36YLR71G {
  align-items: center;
  display: inline-flex;
  margin: 3px 0 3px 8px
}

.common-tooltip__hotkey-button-36YLR71G {
  align-items: center;
  border: 1px solid;
  border-radius: 2px;
  display: inline-flex;
  height: 13px;
  justify-content: center;
  min-width: 7px;
  padding: 0 3px
}

.common-tooltip__plus-sign-36YLR71G {
  height: 15px;
  line-height: 16px;
  text-align: center;
  width: 13px
}

.common-tooltip__button-container-36YLR71G {
  display: flex;
  overflow: hidden;
  position: relative
}

.common-tooltip__button-36YLR71G {
  align-items: center;
  background-color: #2962ff;
  color: #fff;
  display: flex;
  padding: 0 10px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .common-tooltip__button-36YLR71G:hover {
      background-color:#bbd9fb
  }
}

.common-tooltip-36YLR71G.theme-white {
  color: #131722
}

html.theme-dark .common-tooltip-36YLR71G.theme-white {
  color: #d1d4dc
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__body-36YLR71G {
  background-color: #fff;
  border-radius: 0
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__body-36YLR71G {
  background-color: #1e222d
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder-36YLR71G {
  border: 1px solid #e0e3eb
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder-36YLR71G {
  border: 1px solid #363a45
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder-36YLR71G:after {
  border-color: #fff
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder-36YLR71G:after {
  border-color: #1e222d
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--above-36YLR71G:after,.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--below-36YLR71G:after {
  border-left: 6px solid #0000;
  border-right: 6px solid #0000
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--above-36YLR71G:after,html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--below-36YLR71G:after {
  border-left-color: #0000;
  border-right-color: #0000
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--after-36YLR71G:after,.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--before-36YLR71G:after {
  border-bottom: 6px solid #0000;
  border-top: 6px solid #0000
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--after-36YLR71G:after,html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--before-36YLR71G:after {
  border-bottom-color: #0000;
  border-top-color: #0000
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder-36YLR71G:before {
  border: 0 solid #e0e3eb;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  z-index: 1000
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder-36YLR71G:before {
  border-color: #363a45
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--above-36YLR71G:before,.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--below-36YLR71G:before {
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  left: 50%;
  margin-left: -7px
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--above-36YLR71G:before,html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--below-36YLR71G:before {
  border-left-color: #0000;
  border-right-color: #0000
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--below-36YLR71G:before {
  border-bottom-width: 6px;
  top: -6px
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--above-36YLR71G:before {
  border-top-width: 6px;
  bottom: -6px
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--after-36YLR71G:before,.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--before-36YLR71G:before {
  border-bottom: 7px solid #0000;
  border-top: 7px solid #0000;
  margin-top: -7px;
  top: 50%
}

html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--after-36YLR71G:before,html.theme-dark .common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--before-36YLR71G:before {
  border-bottom-color: #0000;
  border-top-color: #0000
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--before-36YLR71G:before {
  border-right-width: 6px;
  left: -6px
}

.common-tooltip-36YLR71G.theme-white .common-tooltip__ear-holder--after-36YLR71G:before {
  border-left-width: 6px;
  right: -6px
}

.common-tooltip-36YLR71G.theme-round-shadow {
  box-shadow: 0 1px 3px 0 #2a2c394a;
  color: #131722
}

html.theme-dark .common-tooltip-36YLR71G.theme-round-shadow {
  color: #d1d4dc
}

.common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__body-36YLR71G {
  background-color: #fff
}

html.theme-dark .common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__body-36YLR71G {
  background-color: #1e222d
}

.common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder-36YLR71G:after {
  border-color: #fff
}

html.theme-dark .common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder-36YLR71G:after {
  border-color: #1e222d
}

.common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--above-36YLR71G:after,.common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--below-36YLR71G:after {
  border-left: 6px solid #0000;
  border-right: 6px solid #0000
}

html.theme-dark .common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--above-36YLR71G:after,html.theme-dark .common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--below-36YLR71G:after {
  border-left-color: #0000;
  border-right-color: #0000
}

.common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--after-36YLR71G:after,.common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--before-36YLR71G:after {
  border-bottom: 6px solid #0000;
  border-top: 6px solid #0000
}

html.theme-dark .common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--after-36YLR71G:after,html.theme-dark .common-tooltip-36YLR71G.theme-round-shadow .common-tooltip__ear-holder--before-36YLR71G:after {
  border-bottom-color: #0000;
  border-top-color: #0000
}

.tv-data-mode {
  vertical-align: top
}

.tv-data-mode--realtime {
  color: #4db6ac
}

.tv-data-mode--snapshot {
  color: #3179f5
}

.tv-data-mode--endofday {
  color: #ab47bc
}

.tv-data-mode--connecting,.tv-data-mode--forbidden,.tv-data-mode--invalid,.tv-data-mode--loading {
  visibility: hidden
}

.tv-data-mode--delayed {
  color: #ff9800
}

.tv-data-mode--for-ticker-last {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  margin-left: 2px;
  position: absolute
}

.tv-data-mode--realtime--for-ticker-last {
  display: none
}

.tv-data-mode--for-sticky-symbol-header,.tv-data-mode--for-ticker-tape {
  font-size: 10px;
  transform: translateX(2px)
}

.tv-data-mode--realtime--for-sticky-symbol-header,.tv-data-mode--realtime--for-ticker-tape {
  display: none
}

.tv-data-mode--for-ticker-header {
  display: inline-block;
  font-size: 10px;
  line-height: normal;
  margin-left: 1px;
  margin-top: 4px;
  vertical-align: top
}

.tv-data-mode--realtime--for-ticker-header {
  display: none
}

.tv-data-mode--for-ticker {
  display: inline-block;
  font-size: 10px;
  margin-left: 2px
}

.tv-data-mode--realtime--for-ticker,.tv-data-mode--realtime--no-realtime {
  display: none
}

.tv-data-mode--replay {
  color: #ff4a68
}

.tv-data-mode--for-chart {
  float: right;
  line-height: 16px;
  margin-left: 6px
}

.tv-data-mode--connecting--for-chart,.tv-data-mode--loading--for-chart {
  display: none
}

.tv-data-mode--forbidden--for-chart,.tv-data-mode--invalid--for-chart {
  color: #f44336;
  visibility: visible
}

.tv-data-mode--realtime--for-chart {
  display: none
}

.tv-data-mode--for-chart.tv-data-mode--replay {
  margin-left: 0
}

.tv-data-mode--for-symbol-widget {
  display: inline;
  font-size: 15px;
  line-height: 17px;
  margin-left: 3px
}

.tv-data-mode--realtime--for-symbol-widget,.tv-data-mode--realtime.tv-data-mode--for-symbol-widget {
  display: none
}

.tv-data-mode--for-watch-list {
  font-size: 10px;
  line-height: 14px;
  overflow: visible;
  pointer-events: auto;
  white-space: nowrap
}

.tv-data-mode--realtime--for-watch-list {
  visibility: hidden
}

.tv-data-mode--size_large {
  font-size: 12px
}

.tv-data-mode--for-symbol-info {
  display: inline;
  font-size: 15px;
  line-height: 17px;
  margin-left: 3px
}

.tv-data-mode--realtime--for-symbol-info {
  display: none
}

.tv-data-mode--for-screener {
  font-size: 10px
}

.tv-data-mode--realtime--for-screener {
  display: none
}

.tv-ripple {
  background-color: #75869640;
  border-radius: 50%;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: scale(0);
  transition: all .5s ease-out;
  transition-property: transform,opacity;
  width: 20px
}

.tv-ripple--notransition {
  transition: none
}

.tv-control-material-input,.tv-control-material-input__control {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-color: initial!important;
  border: none;
  border-bottom: 1px solid #dadde0;
  border-radius: 2px;
  border-radius: 0;
  box-sizing: border-box;
  color: #434651;
  display: block;
  font-size: 13px;
  height: 34px;
  padding: 0;
  transition: border-color .35s ease,background-color .35s ease;
  width: 100%
}

html.theme-dark .tv-control-material-input,html.theme-dark .tv-control-material-input__control {
  background-color: #1e222d;
  border-color: #363a45;
  color: #d1d4dc
}

.tv-control-material-input::placeholder,.tv-control-material-input__control::placeholder {
  color: #a3a6af;
  opacity: 1
}

html.theme-dark .tv-control-material-input::placeholder,html.theme-dark .tv-control-material-input__control::placeholder {
  color: #50535e
}

.tv-control-material-input:-webkit-autofill,.tv-control-material-input__control:-webkit-autofill {
  -webkit-text-fill-color: #535353!important;
  box-shadow: inset 0 0 0 1000px #fff!important
}

.tv-control-material-input--size_xsmall,.tv-control-material-input__control--size_xsmall {
  height: 19px
}

.tv-control-material-input--size_small,.tv-control-material-input__control--size_small {
  height: 27px
}

.tv-control-material-input--size_large,.tv-control-material-input__control--size_large {
  font-size: 16px;
  height: 48px
}

.tv-control-material-input--connect,.tv-control-material-input__control--connect {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.tv-control-material-input--connect_left,.tv-control-material-input__control--connect_left {
  border-bottom-left-radius: 0;
  border-left: none;
  border-top-left-radius: 0
}

.tv-control-material-input--connect_right,.tv-control-material-input__control--connect_right {
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-input:hover,.tv-control-material-input__control:hover {
      border-color:#c1c4cd;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-material-input:hover,html.theme-dark .tv-control-material-input__control:hover {
      border-color: #50535e
  }
}

.tv-control-material-input:focus,.tv-control-material-input__control:focus {
  border-color: #2962ff!important;
  transition-duration: .06s
}

.tv-control-material-input[readonly],.tv-control-material-input__control[readonly] {
  border-color: #dadde0;
  color: #8a8a8a
}

.tv-control-material-input[readonly]:focus,.tv-control-material-input__control[readonly]:focus {
  border-color: #e0e3eb!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-input[readonly]:hover,.tv-control-material-input__control[readonly]:hover {
      border-color:#e0e3eb!important
  }
}

html.theme-dark .tv-control-material-input[readonly]:focus,html.theme-dark .tv-control-material-input__control[readonly]:focus {
  border-color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-material-input[readonly]:hover,html.theme-dark .tv-control-material-input__control[readonly]:hover {
      border-color:#2a2e39!important
  }
}

.tv-control-material-input--readonly_dark,.tv-control-material-input--readonly_dark[readonly],.tv-control-material-input__control--readonly_dark,.tv-control-material-input__control--readonly_dark[readonly],html.theme-dark .tv-control-material-input--readonly_dark,html.theme-dark .tv-control-material-input--readonly_dark[readonly],html.theme-dark .tv-control-material-input__control--readonly_dark,html.theme-dark .tv-control-material-input__control--readonly_dark[readonly] {
  color: #50535e
}

.tv-control-material-input.i-disabled,.tv-control-material-input[disabled],.tv-control-material-input__control.i-disabled,.tv-control-material-input__control[disabled] {
  border-color: #e0e3eb!important;
  color: #e0e3eb!important
}

html.theme-dark .tv-control-material-input.i-disabled,html.theme-dark .tv-control-material-input[disabled],html.theme-dark .tv-control-material-input__control.i-disabled,html.theme-dark .tv-control-material-input__control[disabled] {
  border-color: #2a2e39!important;
  color: #2a2e39!important
}

.tv-control-material-input.i-disabled::placeholder,.tv-control-material-input[disabled]::placeholder,.tv-control-material-input__control.i-disabled::placeholder,.tv-control-material-input__control[disabled]::placeholder {
  color: #e0e3eb!important
}

html.theme-dark .tv-control-material-input.i-disabled::placeholder,html.theme-dark .tv-control-material-input[disabled]::placeholder,html.theme-dark .tv-control-material-input__control.i-disabled::placeholder,html.theme-dark .tv-control-material-input__control[disabled]::placeholder {
  color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-input.i-disabled:hover,.tv-control-material-input[disabled]:hover,.tv-control-material-input__control.i-disabled:hover,.tv-control-material-input__control[disabled]:hover {
      border-color:#e0e3eb!important
  }

  html.theme-dark .tv-control-material-input.i-disabled:hover,html.theme-dark .tv-control-material-input[disabled]:hover,html.theme-dark .tv-control-material-input__control.i-disabled:hover,html.theme-dark .tv-control-material-input__control[disabled]:hover {
      border-color: #2a2e39!important
  }
}

.tv-control-material-input.i-error,.tv-control-material-input__control.i-error {
  border-color: #ff4a68!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-input.i-error:hover,.tv-control-material-input__control.i-error:hover {
      border-color:#f24965!important
  }
}

.tv-control-material-input.i-error:focus,.tv-control-material-input__control.i-error:focus {
  border-color: #ff173e!important
}

.tv-control-material-input.i-success,.tv-control-material-input__control.i-success {
  border-color: #009688!important
}

html.theme-dark .tv-control-material-input.i-success,html.theme-dark .tv-control-material-input__control.i-success {
  border-color: #00796b!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-input.i-success:hover,.tv-control-material-input__control.i-success:hover,html.theme-dark .tv-control-material-input.i-success:hover,html.theme-dark .tv-control-material-input__control.i-success:hover {
      border-color:#00897b!important
  }
}

.tv-control-material-input.i-success:active,.tv-control-material-input.i-success:focus,.tv-control-material-input__control.i-success:active,.tv-control-material-input__control.i-success:focus {
  border-color: #00796b!important
}

html.theme-dark .tv-control-material-input.i-success:active,html.theme-dark .tv-control-material-input.i-success:focus,html.theme-dark .tv-control-material-input__control.i-success:active,html.theme-dark .tv-control-material-input__control.i-success:focus {
  border-color: #009688!important
}

.tv-control-material-input--phone,.tv-control-material-input__control--phone {
  direction: ltr;
  text-align: left
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-input:hover,.tv-control-material-input__control:hover {
      border-color:#4f5966
  }
}

.tv-control-material-input:focus,.tv-control-material-input__control:focus,html.theme-dark .tv-control-material-input:focus,html.theme-dark .tv-control-material-input__control:focus {
  border-color: #2962ff
}

.tv-control-material-input--reset-password,.tv-control-material-input__control--reset-password {
  font-size: 16px;
  height: 44px;
  margin: 12px 0;
  padding: 12px 0
}

.tv-control-material-input__wrap {
  display: block;
  position: relative;
  width: 100%
}

.tv-control-material-input__label {
  color: #adaeb0;
  font-size: 13px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 9px;
  transform-origin: 0 0;
  transition: color .35s ease,transform .35s ease;
  -webkit-user-select: none;
  user-select: none
}

.tv-control-material-input__label.i-up {
  transform: translateY(-24px) scale(.85)
}

.tv-control-material-input__label--reset-password {
  bottom: 14px;
  color: #758696;
  font-size: 14px;
  top: auto
}

.tv-control-material-input__hint {
  color: #4e5866
}

.tv-control-material-input--dark {
  border-color: #363c4e;
  color: #fff
}

.tv-control-material-textarea,.tv-control-material-textarea__control {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-color: initial!important;
  border: none;
  border-bottom: 1px solid #dadde0;
  border-radius: 2px;
  box-sizing: border-box;
  color: #434651;
  display: block;
  font-size: 13px;
  height: 34px;
  padding: 0;
  transition: border-color .35s ease,background-color .35s ease;
  width: 100%
}

html.theme-dark .tv-control-material-textarea,html.theme-dark .tv-control-material-textarea__control {
  background-color: #1e222d;
  border-color: #363a45;
  color: #d1d4dc
}

.tv-control-material-textarea::placeholder,.tv-control-material-textarea__control::placeholder {
  color: #a3a6af;
  opacity: 1
}

html.theme-dark .tv-control-material-textarea::placeholder,html.theme-dark .tv-control-material-textarea__control::placeholder {
  color: #50535e
}

.tv-control-material-textarea:-webkit-autofill,.tv-control-material-textarea__control:-webkit-autofill {
  -webkit-text-fill-color: #535353!important;
  box-shadow: inset 0 0 0 1000px #fff!important
}

.tv-control-material-textarea--size_xsmall,.tv-control-material-textarea__control--size_xsmall {
  height: 19px
}

.tv-control-material-textarea--size_small,.tv-control-material-textarea__control--size_small {
  height: 27px
}

.tv-control-material-textarea--size_large,.tv-control-material-textarea__control--size_large {
  font-size: 16px;
  height: 48px
}

.tv-control-material-textarea--connect,.tv-control-material-textarea__control--connect {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.tv-control-material-textarea--connect_left,.tv-control-material-textarea__control--connect_left {
  border-bottom-left-radius: 0;
  border-left: none;
  border-top-left-radius: 0
}

.tv-control-material-textarea--connect_right,.tv-control-material-textarea__control--connect_right {
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-textarea:hover,.tv-control-material-textarea__control:hover {
      border-color:#c1c4cd;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-material-textarea:hover,html.theme-dark .tv-control-material-textarea__control:hover {
      border-color: #50535e
  }
}

.tv-control-material-textarea:focus,.tv-control-material-textarea__control:focus {
  border-color: #2962ff!important;
  transition-duration: .06s
}

.tv-control-material-textarea[readonly],.tv-control-material-textarea__control[readonly] {
  border-color: #dadde0;
  color: #8a8a8a
}

.tv-control-material-textarea[readonly]:focus,.tv-control-material-textarea__control[readonly]:focus {
  border-color: #e0e3eb!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-textarea[readonly]:hover,.tv-control-material-textarea__control[readonly]:hover {
      border-color:#e0e3eb!important
  }
}

html.theme-dark .tv-control-material-textarea[readonly]:focus,html.theme-dark .tv-control-material-textarea__control[readonly]:focus {
  border-color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-material-textarea[readonly]:hover,html.theme-dark .tv-control-material-textarea__control[readonly]:hover {
      border-color:#2a2e39!important
  }
}

.tv-control-material-textarea--readonly_dark,.tv-control-material-textarea--readonly_dark[readonly],.tv-control-material-textarea__control--readonly_dark,.tv-control-material-textarea__control--readonly_dark[readonly],html.theme-dark .tv-control-material-textarea--readonly_dark,html.theme-dark .tv-control-material-textarea--readonly_dark[readonly],html.theme-dark .tv-control-material-textarea__control--readonly_dark,html.theme-dark .tv-control-material-textarea__control--readonly_dark[readonly] {
  color: #50535e
}

.tv-control-material-textarea.i-disabled,.tv-control-material-textarea[disabled],.tv-control-material-textarea__control.i-disabled,.tv-control-material-textarea__control[disabled] {
  border-color: #e0e3eb!important;
  color: #e0e3eb!important
}

html.theme-dark .tv-control-material-textarea.i-disabled,html.theme-dark .tv-control-material-textarea[disabled],html.theme-dark .tv-control-material-textarea__control.i-disabled,html.theme-dark .tv-control-material-textarea__control[disabled] {
  border-color: #2a2e39!important;
  color: #2a2e39!important
}

.tv-control-material-textarea.i-disabled::placeholder,.tv-control-material-textarea[disabled]::placeholder,.tv-control-material-textarea__control.i-disabled::placeholder,.tv-control-material-textarea__control[disabled]::placeholder {
  color: #e0e3eb!important
}

html.theme-dark .tv-control-material-textarea.i-disabled::placeholder,html.theme-dark .tv-control-material-textarea[disabled]::placeholder,html.theme-dark .tv-control-material-textarea__control.i-disabled::placeholder,html.theme-dark .tv-control-material-textarea__control[disabled]::placeholder {
  color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-textarea.i-disabled:hover,.tv-control-material-textarea[disabled]:hover,.tv-control-material-textarea__control.i-disabled:hover,.tv-control-material-textarea__control[disabled]:hover {
      border-color:#e0e3eb!important
  }

  html.theme-dark .tv-control-material-textarea.i-disabled:hover,html.theme-dark .tv-control-material-textarea[disabled]:hover,html.theme-dark .tv-control-material-textarea__control.i-disabled:hover,html.theme-dark .tv-control-material-textarea__control[disabled]:hover {
      border-color: #2a2e39!important
  }
}

.tv-control-material-textarea.i-error,.tv-control-material-textarea__control.i-error {
  border-color: #ff4a68!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-textarea.i-error:hover,.tv-control-material-textarea__control.i-error:hover {
      border-color:#f24965!important
  }
}

.tv-control-material-textarea.i-error:focus,.tv-control-material-textarea__control.i-error:focus {
  border-color: #ff173e!important
}

.tv-control-material-textarea.i-success,.tv-control-material-textarea__control.i-success {
  border-color: #009688!important
}

html.theme-dark .tv-control-material-textarea.i-success,html.theme-dark .tv-control-material-textarea__control.i-success {
  border-color: #00796b!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-textarea.i-success:hover,.tv-control-material-textarea__control.i-success:hover,html.theme-dark .tv-control-material-textarea.i-success:hover,html.theme-dark .tv-control-material-textarea__control.i-success:hover {
      border-color:#00897b!important
  }
}

.tv-control-material-textarea.i-success:active,.tv-control-material-textarea.i-success:focus,.tv-control-material-textarea__control.i-success:active,.tv-control-material-textarea__control.i-success:focus {
  border-color: #00796b!important
}

html.theme-dark .tv-control-material-textarea.i-success:active,html.theme-dark .tv-control-material-textarea.i-success:focus,html.theme-dark .tv-control-material-textarea__control.i-success:active,html.theme-dark .tv-control-material-textarea__control.i-success:focus {
  border-color: #009688!important
}

.tv-control-material-textarea--phone,.tv-control-material-textarea__control--phone {
  direction: ltr;
  text-align: left
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-material-textarea:hover,.tv-control-material-textarea__control:hover {
      border-color:#4f5966
  }
}

.tv-control-material-textarea:focus,.tv-control-material-textarea__control:focus,html.theme-dark .tv-control-material-textarea:focus,html.theme-dark .tv-control-material-textarea__control:focus {
  border-color: #2962ff
}

.tv-control-material-textarea--reset-password,.tv-control-material-textarea__control--reset-password {
  font-size: 16px;
  height: 44px;
  margin: 12px 0;
  padding: 12px 0
}

.tv-control-material-textarea__wrap {
  display: block;
  position: relative;
  width: 100%
}

.tv-control-material-textarea__label {
  color: #adaeb0;
  font-size: 13px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 9px;
  transform-origin: 0 0;
  transition: color .35s ease,transform .35s ease;
  -webkit-user-select: none;
  user-select: none
}

.tv-control-material-textarea__label.i-up {
  transform: translateY(-24px) scale(.85)
}

.tv-control-material-textarea__label--reset-password {
  bottom: 14px;
  color: #758696;
  font-size: 14px;
  top: auto
}

.tv-control-material-textarea__hint {
  color: #4e5866
}

.tv-control-material-textarea--dark {
  border-color: #363c4e;
  color: #fff
}

.tv-control-material-textarea,.tv-control-material-textarea__control {
  border-radius: 0;
  height: auto
}

.tv-text ol,.tv-text p,.tv-text ul {
  color: #131722;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 12px
}

html.theme-dark .tv-text ol,html.theme-dark .tv-text p,html.theme-dark .tv-text ul {
  color: #d1d4dc
}

.tv-text ol.tv-text__color_bright,.tv-text p.tv-text__color_bright,.tv-text ul.tv-text__color_bright {
  color: #fff
}

.tv-text ol.tv-text__color_silver,.tv-text p.tv-text__color_silver,.tv-text ul.tv-text__color_silver {
  color: silver
}

.tv-text ol.tv-text__color_silver_30,.tv-text p.tv-text__color_silver_30,.tv-text ul.tv-text__color_silver_30 {
  color: #9b9b9b
}

.tv-text ol.tv-text__paragraph--additional-top-margin,.tv-text p.tv-text__paragraph--additional-top-margin,.tv-text ul.tv-text__paragraph--additional-top-margin {
  margin-top: 24px
}

.tv-text ol.tv-text__paragraph--additional-top-margin_double,.tv-text p.tv-text__paragraph--additional-top-margin_double,.tv-text ul.tv-text__paragraph--additional-top-margin_double {
  margin-top: 48px
}

.tv-text ol.tv-text__paragraph--additional-bottom-margin,.tv-text p.tv-text__paragraph--additional-bottom-margin,.tv-text ul.tv-text__paragraph--additional-bottom-margin {
  margin-bottom: 24px
}

.tv-text ol.tv-text__paragraph--additional-bottom-margin_double,.tv-text p.tv-text__paragraph--additional-bottom-margin_double,.tv-text ul.tv-text__paragraph--additional-bottom-margin_double {
  margin-bottom: 48px
}

.tv-text ol .tv-text--highlight-important,.tv-text p .tv-text--highlight-important,.tv-text ul .tv-text--highlight-important {
  background: #fff3e0;
  box-shadow: 0 0 0 .37em #fff3e0
}

html.theme-dark .tv-text ol .tv-text--highlight-important,html.theme-dark .tv-text p .tv-text--highlight-important,html.theme-dark .tv-text ul .tv-text--highlight-important {
  background: #142e61;
  box-shadow: 0 0 0 .37em #142e61
}

.tv-text ol .tv-text--highlight-important:empty,.tv-text p .tv-text--highlight-important:empty,.tv-text ul .tv-text--highlight-important:empty {
  background: #0000;
  box-shadow: none
}

.tv-text h1 {
  font-size: 45px;
  margin-bottom: 30px;
  margin-top: 30px
}

@media screen and (max-width: 1019px) {
  .tv-text h1 {
      font-size:38px
  }
}

@media screen and (max-width: 767px) {
  .tv-text h1 {
      font-size:32px
  }
}

@media screen and (max-width: 479px) {
  .tv-text h1 {
      font-size:28px
  }
}

.tv-text h2 {
  font-size: 31px
}

@media screen and (max-width: 1019px) {
  .tv-text h2 {
      font-size:26px
  }
}

@media screen and (max-width: 479px) {
  .tv-text h2 {
      font-size:24px
  }
}

.tv-text h3 {
  font-size: 17px
}

@media screen and (max-width: 1019px) {
  .tv-text h3 {
      font-size:16px
  }
}

.tv-text h4 {
  font-size: 15px
}

.tv-text h2,.tv-text h3,.tv-text h4 {
  margin-bottom: 20px;
  margin-top: 20px
}

.tv-text h1:first-child,.tv-text h2:first-child,.tv-text h3:first-child,.tv-text h4:first-child {
  margin-top: 0
}

.tv-text ol,.tv-text ul {
  list-style-position: inside
}

.tv-text--position-outside ol,.tv-text--position-outside ul {
  list-style-position: outside;
  padding-left: 17px
}

.tv-text a:not(.tv-badge):not(.tv-button) {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-text a:not(.tv-badge):not(.tv-button) {
  color: #2962ff
}

.tv-text a:not(.tv-badge):not(.tv-button):visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-text a:not(.tv-badge):not(.tv-button):visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-text a:not(.tv-badge):not(.tv-button):hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-text a:not(.tv-badge):not(.tv-button):hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-text a:not(.tv-badge):not(.tv-button):focus {
  outline: auto
}

.tv-text a:not(.tv-badge):not(.tv-button):focus:not(:-moz-focusring) {
  outline: none
}

.tv-text a:not(.tv-badge):not(.tv-button):-moz-focusring {
  outline: auto
}

.tv-text a:not(.tv-badge):not(.tv-button):active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-text a:not(.tv-badge):not(.tv-button):active {
  fill: #1848cc;
  color: #1848cc
}

.tv-text__font.tv-text__font--size_semilarge {
  font-size: 15px
}

@media screen and (max-width: 767px) {
  .tv-text__font.tv-text__font--size_semilarge {
      font-size:14px
  }
}

.tv-text__font.tv-text__font--size_large {
  font-size: 17px
}

@media screen and (max-width: 767px) {
  .tv-text__font.tv-text__font--size_large {
      font-size:16px
  }
}

.tv-text__font.tv-text__font--size_xlarge {
  font-size: 19px
}

@media screen and (max-width: 767px) {
  .tv-text__font.tv-text__font--size_xlarge {
      font-size:18px
  }
}

.tv-text__font--bold {
  font-weight: 700
}

.tv-text__font--italic {
  font-style: italic
}

.tv-text--darkbg,.tv-text--darkbg ol,.tv-text--darkbg p,.tv-text--darkbg ul {
  color: #fff
}

.tv-text--highlight-important {
  background: #fff3e0;
  box-shadow: 0 0 0 .07em #fff3e0
}

html.theme-dark .tv-text--highlight-important {
  background: #142e61;
  box-shadow: 0 0 0 .07em #142e61
}

.tv-text--highlight-important:empty {
  background: #0000;
  box-shadow: none
}

.tv-study-offer__content,.tv-study-offer__footer,.tv-study-offer__header {
  padding: 0 30px
}

@media screen and (max-width: 767px) {
  .tv-study-offer__content,.tv-study-offer__footer,.tv-study-offer__header {
      padding:0 20px
  }
}

.tv-study-offer__header {
  display: flex;
  flex-flow: row wrap;
  padding-top: 20px
}

.tv-study-offer__header--left {
  flex-grow: 1;
  margin-bottom: 23px;
  margin-right: 30px
}

@media screen and (max-width: 767px) {
  .tv-study-offer__header--left {
      margin-bottom:18px;
      margin-right: 20px
  }
}

.tv-study-offer__header--right {
  margin-bottom: 23px;
  margin-top: -8px
}

@media screen and (max-width: 767px) {
  .tv-study-offer__header--right {
      margin-bottom:18px
  }
}

.tv-study-offer__content {
  flex-grow: 1;
  margin-top: -23px;
  padding-bottom: 15px;
  padding-top: 30px
}

@media screen and (max-width: 767px) {
  .tv-study-offer__content {
      margin-top:-18px;
      padding-bottom: 10px;
      padding-top: 20px
  }
}

.tv-study-offer__footer {
  background-color: #ebf7fc;
  font-size: 10px;
  padding-bottom: 15px;
  padding-top: 15px
}

html.theme-dark .tv-study-offer__footer {
  background-color: #2f3241
}

@media screen and (max-width: 767px) {
  .tv-study-offer__footer {
      padding-bottom:10px;
      padding-top: 10px
  }
}

.tv-study-offer__main-header {
  display: flex;
  justify-content: space-between
}

.tv-study-offer__name {
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tv-study-offer__sub-header a {
  color: #2962ff;
  font-size: 12px;
  transition: color .35s ease
}

html.theme-dark .tv-study-offer__sub-header a {
  color: #2962ff
}

.tv-study-offer__sub-header a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-study-offer__sub-header a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-study-offer__sub-header a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-study-offer__sub-header a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-study-offer__sub-header a:focus {
  outline: auto
}

.tv-study-offer__sub-header a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-study-offer__sub-header a:-moz-focusring {
  outline: auto
}

.tv-study-offer__sub-header a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-study-offer__sub-header a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-study-offer__info-description a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-study-offer__info-description a {
  color: #2962ff
}

.tv-study-offer__info-description a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-study-offer__info-description a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-study-offer__info-description a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-study-offer__info-description a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-study-offer__info-description a:focus {
  outline: auto
}

.tv-study-offer__info-description a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-study-offer__info-description a:-moz-focusring {
  outline: auto
}

.tv-study-offer__info-description a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-study-offer__info-description a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-study-offer__screenshots-container img {
  border: 1px solid #e0e3eb;
  margin: 0 6px 6px 0;
  max-height: 115px;
  max-width: 170px
}

html.theme-dark .tv-study-offer__screenshots-container img {
  border-color: #363a45
}

.tv-study-offer__buttons .tv-button {
  margin-top: 15px
}

.tv-study-offer__screenshots-container {
  padding-top: 10px
}

.tv-study-offer__warning {
  margin-top: 7px
}

.tv-price-label {
  height: 44px;
  overflow-y: hidden;
  padding-left: 12px;
  position: relative
}

.tv-price-label__content {
  background: #f2f3f5;
  border-radius: 6px;
  color: #4c525e;
  display: inline-block;
  padding: 8px 10px 8px 12px;
  position: relative;
  vertical-align: middle
}

html.theme-dark .tv-price-label__content {
  background: #2f3241
}

.tv-price-label__cost {
  color: #4c525e;
  font-size: 24px;
  line-height: 1.167;
  vertical-align: initial
}

html.theme-dark .tv-price-label__cost {
  color: #d6d8e0
}

.tv-price-label__date {
  color: #4c525e;
  font-size: 14px;
  line-height: 1.167;
  vertical-align: initial
}

html.theme-dark .tv-price-label__date {
  color: #d6d8e0
}

.tv-price-label:before {
  background: #f2f3f5;
  border-radius: 6px;
  content: "";
  display: block;
  height: 29px;
  left: 1px;
  margin-right: -18px;
  position: absolute;
  top: 7px;
  transform: rotate(45deg) skew(8deg,8deg);
  width: 29px
}

html.theme-dark .tv-price-label:before {
  background: #2f3241
}

.tv-price-label:after {
  background-color: #fff;
  border-radius: 4px;
  content: "";
  display: block;
  height: 8px;
  left: 6px;
  position: absolute;
  top: 17px;
  width: 8px
}

html.theme-dark .tv-price-label:after {
  background-color: #1e222d
}

.sb-inner-shadow {
  background: #fff;
  bottom: -10px;
  box-shadow: 0 0 5px #00000026;
  height: 10px;
  pointer-events: none;
  position: absolute;
  transform: translateY(0);
  transition: opacity .11666667s ease,transform .11666667s ease;
  width: 100%;
  z-index: 5
}

html.theme-dark .sb-inner-shadow {
  background: #1e222d
}

.sb-inner-shadow.top {
  box-shadow: 0 0 5px #00000026;
  top: -10px
}

.sb-inner-shadow.i-invisible {
  transform: translateY(5px)
}

.sb-inner-shadow.i-invisible.top {
  transform: translateY(-5px)
}

.sb-inner-shadow.i-invisible {
  opacity: 0
}

.sb-scrollbar {
  opacity: 0;
  position: absolute!important;
  right: 1px;
  top: 73px;
  transition: opacity .3s;
  width: 7px;
  z-index: 1
}

.sb-scrollbar.active,.sb-scrollbar.active-always {
  opacity: 1
}

.sb-scrollbar__content-wrapper--scroll-inited {
  position: relative
}

.sb-scrollbar__content--scroll-inited {
  position: absolute
}

.sb-scrollbar-wrap {
  height: 100%;
  position: absolute;
  right: 1px;
  top: 0;
  width: 6px;
  z-index: 1
}

.sb-scrollbar-wrap .sb-scrollbar {
  right: 0
}

.sb-scrollbar-body {
  background: #d1d4dc;
  border: 0;
  width: 6px
}

html.theme-dark .sb-scrollbar-body {
  background: #50535e
}

.gray .sb-scrollbar-body {
  background: #75757a;
  border-color: #75757a;
  border-radius: 3px
}

.sb-scrollbar-bottom,.sb-scrollbar-top {
  display: none
}

.active-always.gray,.gray {
  opacity: .5
}

.wrapper-2eb-SI2I.touch-2eb-SI2I.wrapper-2eb-SI2I.touch-2eb-SI2I {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar;
  overflow-y: auto
}

.wrapper-2eb-SI2I.touch-2eb-SI2I.wrapper-2eb-SI2I.touch-2eb-SI2I::-webkit-scrollbar {
  height: 5px;
  width: 5px
}

.wrapper-2eb-SI2I.touch-2eb-SI2I.wrapper-2eb-SI2I.touch-2eb-SI2I::-webkit-scrollbar-thumb {
  background-color: #9598a1;
  border: 1px solid #f0f3fa;
  border-radius: 3px
}

html.theme-dark .wrapper-2eb-SI2I.touch-2eb-SI2I.wrapper-2eb-SI2I.touch-2eb-SI2I::-webkit-scrollbar-thumb {
  background-color: #363a45;
  border-color: #1e222d
}

.wrapper-2eb-SI2I.touch-2eb-SI2I.wrapper-2eb-SI2I.touch-2eb-SI2I::-webkit-scrollbar-track {
  background-color: initial;
  border-radius: 3px
}

.wrapper-2eb-SI2I.touch-2eb-SI2I.wrapper-2eb-SI2I.touch-2eb-SI2I::-webkit-scrollbar-corner {
  display: none
}

.tv-button {
  fill: currentColor;
  -webkit-tap-highlight-color: transparent;
  background-color: initial;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #757575;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 32px;
  margin: 0;
  min-width: 40px;
  outline: 0;
  overflow: hidden;
  padding: 1px 22px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .35s ease,border-color .35s ease,color .35s ease;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap
}

.tv-button.tv-button--danger_ghost,.tv-button.tv-button--default,.tv-button.tv-button--default_ghost,.tv-button.tv-button--primary_ghost,.tv-button.tv-button--secondary_ghost,.tv-button.tv-button--state,.tv-button.tv-button--success_ghost,.tv-button.tv-button--warning_ghost {
  padding: 0 21px
}

.tv-button.i-active,.tv-button.i-hover,.tv-button:active {
  transition-duration: .06s
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button:hover {
      transition-duration:.06s
  }
}

.tv-button svg {
  vertical-align: middle
}

.tv-button--block {
  display: block;
  text-align: center;
  width: 100%
}

.tv-button+.tv-button {
  margin-left: 15px
}

.tv-button.tv-button--no-left-margin {
  margin-left: 0
}

.tv-button__text {
  display: inline-block;
  position: relative
}

.tv-button__text--full-height {
  word-wrap: break-word;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  line-height: 1.2em;
  margin: 11px 5px;
  white-space: normal;
  width: 100%
}

.tv-button--default,.tv-button--default_ghost,a.tv-button--default:visited {
  background-color: #fff;
  border-color: #fff;
  color: #fff
}

html.theme-dark .tv-button--default,html.theme-dark .tv-button--default_ghost,html.theme-dark a.tv-button--default:visited {
  background-color: #1e222d;
  border-color: #1e222d
}

.tv-button--default_ghost {
  color: #fff
}

html.theme-dark .tv-button--default_ghost {
  color: #1e222d
}

.tv-button--default_ghost.i-checked {
  background-color: #fff;
  border-color: #fff;
  color: #fff
}

html.theme-dark .tv-button--default_ghost.i-checked {
  background-color: #1e222d;
  border-color: #1e222d
}

.tv-button--default.i-active,.tv-button--default:active,.tv-button--default_ghost.i-active,.tv-button--default_ghost:active {
  background-color: #ececec;
  border-color: #ececec;
  color: #fff;
  transform: translateY(1px)
}

html.theme-dark .tv-button--default.i-active,html.theme-dark .tv-button--default:active,html.theme-dark .tv-button--default_ghost.i-active,html.theme-dark .tv-button--default_ghost:active {
  background-color: #1c2030;
  border-color: #1c2030
}

.tv-button--default.i-hover,.tv-button--default_ghost.i-hover {
  background-color: #e0e3eb;
  border-color: #e0e3eb;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--default:hover,.tv-button--default_ghost:hover {
      background-color:#e0e3eb;
      border-color: #e0e3eb;
      color: #fff
  }
}

html.theme-dark .tv-button--default.i-hover,html.theme-dark .tv-button--default_ghost.i-hover {
  background-color: #1e222d
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--default:hover,html.theme-dark .tv-button--default_ghost:hover {
      background-color:#1e222d
  }
}

html.theme-dark .tv-button--default.i-hover,html.theme-dark .tv-button--default_ghost.i-hover {
  border-color: #1e222d
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--default:hover,html.theme-dark .tv-button--default_ghost:hover {
      border-color:#1e222d
  }
}

.tv-button--default_ghost .tv-button__loader-item {
  background-color: #e0e3eb
}

html.theme-dark .tv-button--default_ghost .tv-button__loader-item {
  background-color: #1e222d
}

.tv-button--default_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--default_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

.tv-button--default,.tv-button--default.i-checked,.tv-button--default_ghost,.tv-button--default_ghost.i-checked {
  border: 1px solid #b2b5be;
  color: #6a6d78
}

html.theme-dark .tv-button--default,html.theme-dark .tv-button--default.i-checked,html.theme-dark .tv-button--default_ghost,html.theme-dark .tv-button--default_ghost.i-checked {
  border-color: #363a45;
  color: #787b86
}

.tv-button--default.i-hover,.tv-button--default_ghost.i-hover {
  border-color: #b2b5be;
  color: #6a6d78
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--default:hover,.tv-button--default_ghost:hover {
      border-color:#b2b5be;
      color: #6a6d78
  }
}

html.theme-dark .tv-button--default.i-hover,html.theme-dark .tv-button--default_ghost.i-hover {
  border-color: #363a45
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--default:hover,html.theme-dark .tv-button--default_ghost:hover {
      border-color:#363a45
  }
}

html.theme-dark .tv-button--default.i-hover,html.theme-dark .tv-button--default_ghost.i-hover {
  color: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--default:hover,html.theme-dark .tv-button--default_ghost:hover {
      color:#787b86
  }
}

.tv-button--default.i-active,.tv-button--default:active,.tv-button--default_ghost.i-active,.tv-button--default_ghost:active {
  border-color: #b2b5be;
  color: #6a6d78
}

html.theme-dark .tv-button--default.i-active,html.theme-dark .tv-button--default:active,html.theme-dark .tv-button--default_ghost.i-active,html.theme-dark .tv-button--default_ghost:active {
  border-color: #363a45;
  color: #787b86
}

.tv-button--primary,.tv-button--primary_ghost,a.tv-button--primary:visited {
  background-color: #2962ff;
  border-color: #2962ff;
  color: #fff
}

html.theme-dark .tv-button--primary,html.theme-dark .tv-button--primary_ghost,html.theme-dark a.tv-button--primary:visited {
  background-color: #2962ff;
  border-color: #2962ff;
  color: #fff
}

.tv-button--primary_ghost,html.theme-dark .tv-button--primary_ghost {
  color: #2962ff
}

.tv-button--primary_ghost.i-checked {
  background-color: #2962ff;
  border-color: #2962ff;
  color: #fff
}

html.theme-dark .tv-button--primary_ghost.i-checked {
  background-color: #2962ff;
  border-color: #2962ff;
  color: #fff
}

.tv-button--primary.i-active,.tv-button--primary:active,.tv-button--primary_ghost.i-active,.tv-button--primary_ghost:active {
  background-color: #1848cc;
  border-color: #1848cc;
  color: #fff;
  transform: translateY(1px)
}

html.theme-dark .tv-button--primary.i-active,html.theme-dark .tv-button--primary:active,html.theme-dark .tv-button--primary_ghost.i-active,html.theme-dark .tv-button--primary_ghost:active {
  background-color: #1848cc;
  border-color: #1848cc;
  color: #fff
}

.tv-button--primary.i-hover,.tv-button--primary_ghost.i-hover {
  background-color: #1e53e5;
  border-color: #1e53e5;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--primary:hover,.tv-button--primary_ghost:hover {
      background-color:#1e53e5;
      border-color: #1e53e5;
      color: #fff
  }
}

html.theme-dark .tv-button--primary.i-hover,html.theme-dark .tv-button--primary_ghost.i-hover {
  background-color: #1e53e5
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--primary:hover,html.theme-dark .tv-button--primary_ghost:hover {
      background-color:#1e53e5
  }
}

html.theme-dark .tv-button--primary.i-hover,html.theme-dark .tv-button--primary_ghost.i-hover {
  border-color: #1e53e5
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--primary:hover,html.theme-dark .tv-button--primary_ghost:hover {
      border-color:#1e53e5
  }
}

html.theme-dark .tv-button--primary.i-hover,html.theme-dark .tv-button--primary_ghost.i-hover {
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--primary:hover,html.theme-dark .tv-button--primary_ghost:hover {
      color:#fff
  }
}

.tv-button--primary_ghost .tv-button__loader-item,html.theme-dark .tv-button--primary_ghost .tv-button__loader-item {
  background-color: #1e53e5
}

.tv-button--primary_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--primary_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

html.theme-dark .tv-button--primary_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--primary_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

.tv-button--secondary,.tv-button--secondary_ghost,a.tv-button--secondary:visited {
  background-color: #e9eff2;
  border-color: #e9eff2;
  color: #757575
}

.tv-button--secondary_ghost {
  color: #757575
}

.tv-button--secondary_ghost.i-checked {
  background-color: #e9eff2;
  border-color: #e9eff2;
  color: #757575
}

.tv-button--secondary.i-active,.tv-button--secondary:active,.tv-button--secondary_ghost.i-active,.tv-button--secondary_ghost:active {
  background-color: #cfdce3;
  border-color: #cfdce3;
  color: #757575;
  transform: translateY(1px)
}

.tv-button--secondary.i-hover,.tv-button--secondary_ghost.i-hover {
  background-color: #dce6ea;
  border-color: #dce6ea;
  color: #757575
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--secondary:hover,.tv-button--secondary_ghost:hover {
      background-color:#dce6ea;
      border-color: #dce6ea;
      color: #757575
  }
}

.tv-button--secondary_ghost .tv-button__loader-item {
  background-color: #dce6ea
}

.tv-button--secondary_ghost.i-hover .tv-button__loader-item {
  background-color: #757575
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--secondary_ghost:hover .tv-button__loader-item {
      background-color:#757575
  }
}

.tv-button--success,.tv-button--success_ghost,a.tv-button--success:visited {
  background-color: #009688;
  border-color: #009688;
  color: #fff
}

html.theme-dark .tv-button--success,html.theme-dark .tv-button--success_ghost,html.theme-dark a.tv-button--success:visited {
  background-color: #00796b;
  border-color: #00796b;
  color: #fff
}

.tv-button--success_ghost {
  color: #009688
}

html.theme-dark .tv-button--success_ghost {
  color: #00796b
}

.tv-button--success_ghost.i-checked {
  background-color: #009688;
  border-color: #009688;
  color: #fff
}

html.theme-dark .tv-button--success_ghost.i-checked {
  background-color: #00796b;
  border-color: #00796b;
  color: #fff
}

.tv-button--success.i-active,.tv-button--success:active,.tv-button--success_ghost.i-active,.tv-button--success_ghost:active {
  background-color: #00796b;
  border-color: #00796b;
  color: #fff;
  transform: translateY(1px)
}

html.theme-dark .tv-button--success.i-active,html.theme-dark .tv-button--success:active,html.theme-dark .tv-button--success_ghost.i-active,html.theme-dark .tv-button--success_ghost:active {
  background-color: #009688;
  border-color: #009688;
  color: #fff
}

.tv-button--success.i-hover,.tv-button--success_ghost.i-hover {
  background-color: #00897b;
  border-color: #00897b;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--success:hover,.tv-button--success_ghost:hover {
      background-color:#00897b;
      border-color: #00897b;
      color: #fff
  }
}

html.theme-dark .tv-button--success.i-hover,html.theme-dark .tv-button--success_ghost.i-hover {
  background-color: #00897b
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--success:hover,html.theme-dark .tv-button--success_ghost:hover {
      background-color:#00897b
  }
}

html.theme-dark .tv-button--success.i-hover,html.theme-dark .tv-button--success_ghost.i-hover {
  border-color: #00897b
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--success:hover,html.theme-dark .tv-button--success_ghost:hover {
      border-color:#00897b
  }
}

html.theme-dark .tv-button--success.i-hover,html.theme-dark .tv-button--success_ghost.i-hover {
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--success:hover,html.theme-dark .tv-button--success_ghost:hover {
      color:#fff
  }
}

.tv-button--success_ghost .tv-button__loader-item,html.theme-dark .tv-button--success_ghost .tv-button__loader-item {
  background-color: #00897b
}

.tv-button--success_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--success_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

html.theme-dark .tv-button--success_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--success_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

.tv-button--danger,.tv-button--danger_ghost,a.tv-button--danger:visited {
  background-color: #ef5350;
  border-color: #ef5350;
  color: #fff
}

html.theme-dark .tv-button--danger,html.theme-dark .tv-button--danger_ghost,html.theme-dark a.tv-button--danger:visited {
  background-color: #d32f2f;
  border-color: #d32f2f;
  color: #fff
}

.tv-button--danger_ghost {
  color: #ef5350
}

html.theme-dark .tv-button--danger_ghost {
  color: #d32f2f
}

.tv-button--danger_ghost.i-checked {
  background-color: #ef5350;
  border-color: #ef5350;
  color: #fff
}

html.theme-dark .tv-button--danger_ghost.i-checked {
  background-color: #d32f2f;
  border-color: #d32f2f;
  color: #fff
}

.tv-button--danger.i-active,.tv-button--danger:active,.tv-button--danger_ghost.i-active,.tv-button--danger_ghost:active {
  background-color: #d32f2f;
  border-color: #d32f2f;
  color: #fff;
  transform: translateY(1px)
}

html.theme-dark .tv-button--danger.i-active,html.theme-dark .tv-button--danger:active,html.theme-dark .tv-button--danger_ghost.i-active,html.theme-dark .tv-button--danger_ghost:active {
  background-color: #f44336;
  border-color: #f44336;
  color: #fff
}

.tv-button--danger.i-hover,.tv-button--danger_ghost.i-hover {
  background-color: #e53935;
  border-color: #e53935;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--danger:hover,.tv-button--danger_ghost:hover {
      background-color:#e53935;
      border-color: #e53935;
      color: #fff
  }
}

html.theme-dark .tv-button--danger.i-hover,html.theme-dark .tv-button--danger_ghost.i-hover {
  background-color: #e53935
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--danger:hover,html.theme-dark .tv-button--danger_ghost:hover {
      background-color:#e53935
  }
}

html.theme-dark .tv-button--danger.i-hover,html.theme-dark .tv-button--danger_ghost.i-hover {
  border-color: #e53935
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--danger:hover,html.theme-dark .tv-button--danger_ghost:hover {
      border-color:#e53935
  }
}

html.theme-dark .tv-button--danger.i-hover,html.theme-dark .tv-button--danger_ghost.i-hover {
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--danger:hover,html.theme-dark .tv-button--danger_ghost:hover {
      color:#fff
  }
}

.tv-button--danger_ghost .tv-button__loader-item,html.theme-dark .tv-button--danger_ghost .tv-button__loader-item {
  background-color: #e53935
}

.tv-button--danger_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--danger_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

html.theme-dark .tv-button--danger_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button--danger_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

.tv-button--warning,.tv-button--warning_ghost,a.tv-button--warning:visited {
  background-color: #f89e30;
  border-color: #f89e30;
  color: #fff
}

.tv-button--warning_ghost {
  color: #f89e30
}

.tv-button--warning_ghost.i-checked {
  background-color: #f89e30;
  border-color: #f89e30;
  color: #fff
}

.tv-button--warning.i-active,.tv-button--warning:active,.tv-button--warning_ghost.i-active,.tv-button--warning_ghost:active {
  background-color: #d47807;
  border-color: #d47807;
  color: #fff;
  transform: translateY(1px)
}

.tv-button--warning.i-hover,.tv-button--warning_ghost.i-hover {
  background-color: #f79217;
  border-color: #f79217;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--warning:hover,.tv-button--warning_ghost:hover {
      background-color:#f79217;
      border-color: #f79217;
      color: #fff
  }
}

.tv-button--warning_ghost .tv-button__loader-item {
  background-color: #f79217
}

.tv-button--warning_ghost.i-hover .tv-button__loader-item {
  background-color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--warning_ghost:hover .tv-button__loader-item {
      background-color:#fff
  }
}

.tv-button--link {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-button--link {
  color: #2962ff
}

.tv-button--link:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-button--link:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--link:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-button--link:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-button--link:focus {
  outline: auto
}

.tv-button--link:focus:not(:-moz-focusring) {
  outline: none
}

.tv-button--link:-moz-focusring {
  outline: auto
}

.tv-button--link:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-button--link:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-button--danger_ghost,.tv-button--default_ghost,.tv-button--primary_ghost,.tv-button--secondary_ghost,.tv-button--success_ghost,.tv-button--warning_ghost {
  background-color: initial;
  border-style: solid;
  border-width: 1px
}

html.theme-dark .tv-button--danger_ghost,html.theme-dark .tv-button--default_ghost,html.theme-dark .tv-button--primary_ghost,html.theme-dark .tv-button--secondary_ghost,html.theme-dark .tv-button--success_ghost,html.theme-dark .tv-button--warning_ghost {
  background-color: initial
}

.tv-button--danger_ghost.tv-button--size_large,.tv-button--default_ghost.tv-button--size_large,.tv-button--primary_ghost.tv-button--size_large,.tv-button--secondary_ghost.tv-button--size_large,.tv-button--success_ghost.tv-button--size_large,.tv-button--warning_ghost.tv-button--size_large {
  border-width: 2px
}

.tv-button--danger_ghost.tv-button--size_large.tv-button--thin-border,.tv-button--default_ghost.tv-button--size_large.tv-button--thin-border,.tv-button--primary_ghost.tv-button--size_large.tv-button--thin-border,.tv-button--secondary_ghost.tv-button--size_large.tv-button--thin-border,.tv-button--success_ghost.tv-button--size_large.tv-button--thin-border,.tv-button--warning_ghost.tv-button--size_large.tv-button--thin-border {
  border-width: 1px
}

.tv-button .tv-ripple {
  background-color: #ffffff40
}

.tv-button--default .tv-ripple,.tv-button--default_ghost .tv-ripple {
  background-color: #75869640
}

.tv-button.i-disabled .tv-ripple {
  background-color: initial
}

.tv-button.i-disabled,.tv-button.i-disabled:active,.tv-button:disabled,.tv-button:disabled:active {
  background-color: #e0e3eb;
  border-color: #e0e3eb;
  color: #b2b5be;
  cursor: default
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button.i-disabled:hover,.tv-button:disabled:hover {
      background-color:#e0e3eb;
      border-color: #e0e3eb;
      color: #b2b5be;
      cursor: default
  }
}

html.theme-dark .tv-button.i-disabled,html.theme-dark .tv-button.i-disabled:active,html.theme-dark .tv-button:disabled,html.theme-dark .tv-button:disabled:active {
  background-color: #363a45
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button.i-disabled:hover,html.theme-dark .tv-button:disabled:hover {
      background-color:#363a45
  }
}

html.theme-dark .tv-button.i-disabled,html.theme-dark .tv-button.i-disabled:active,html.theme-dark .tv-button:disabled,html.theme-dark .tv-button:disabled:active {
  border-color: #363a45
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button.i-disabled:hover,html.theme-dark .tv-button:disabled:hover {
      border-color:#363a45
  }
}

html.theme-dark .tv-button.i-disabled,html.theme-dark .tv-button.i-disabled:active,html.theme-dark .tv-button:disabled,html.theme-dark .tv-button:disabled:active {
  color: #50535e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-button.i-disabled:hover,html.theme-dark .tv-button:disabled:hover {
      color:#50535e
  }
}

.tv-button.i-disabled:active,.tv-button:disabled:active {
  transform: translateY(0)
}

.tv-button--size_xsmall {
  border-radius: 1px;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  padding: 2px 7px
}

.tv-button--size_xsmall.tv-button--danger_ghost,.tv-button--size_xsmall.tv-button--default,.tv-button--size_xsmall.tv-button--default_ghost,.tv-button--size_xsmall.tv-button--primary_ghost,.tv-button--size_xsmall.tv-button--secondary_ghost,.tv-button--size_xsmall.tv-button--state,.tv-button--size_xsmall.tv-button--success_ghost,.tv-button--size_xsmall.tv-button--warning_ghost {
  padding: 1px 6px
}

.tv-button--size_xsmall+.tv-button--size_xsmall {
  margin-left: 10px
}

.tv-button--size_small {
  font-size: 13px;
  line-height: 25px;
  padding: 1px 12px
}

.tv-button--size_small.tv-button--danger_ghost,.tv-button--size_small.tv-button--default,.tv-button--size_small.tv-button--default_ghost,.tv-button--size_small.tv-button--primary_ghost,.tv-button--size_small.tv-button--secondary_ghost,.tv-button--size_small.tv-button--state,.tv-button--size_small.tv-button--success_ghost,.tv-button--size_small.tv-button--warning_ghost {
  padding: 0 11px
}

.tv-button--size_small+.tv-button--size_small {
  margin-left: 10px
}

.tv-button--size_large {
  font-size: 17px;
  line-height: 44px;
  padding: 1px 30px
}

.tv-button--size_large.tv-button--danger_ghost,.tv-button--size_large.tv-button--default,.tv-button--size_large.tv-button--default_ghost,.tv-button--size_large.tv-button--primary_ghost,.tv-button--size_large.tv-button--secondary_ghost,.tv-button--size_large.tv-button--state,.tv-button--size_large.tv-button--success_ghost,.tv-button--size_large.tv-button--warning_ghost {
  padding: 0 29px
}

.tv-button--size_promo {
  border-radius: 120px;
  font-size: 24px;
  line-height: 54px;
  padding: 1px 40px
}

.tv-button--size_promo.tv-button--danger_ghost,.tv-button--size_promo.tv-button--default,.tv-button--size_promo.tv-button--default_ghost,.tv-button--size_promo.tv-button--primary_ghost,.tv-button--size_promo.tv-button--secondary_ghost,.tv-button--size_promo.tv-button--state,.tv-button--size_promo.tv-button--success_ghost,.tv-button--size_promo.tv-button--warning_ghost {
  padding: 0 39px
}

.tv-button--no-padding {
  padding: 1px
}

.tv-button--no-padding.tv-button--danger_ghost,.tv-button--no-padding.tv-button--default,.tv-button--no-padding.tv-button--default_ghost,.tv-button--no-padding.tv-button--primary_ghost,.tv-button--no-padding.tv-button--secondary_ghost,.tv-button--no-padding.tv-button--state,.tv-button--no-padding.tv-button--success_ghost,.tv-button--no-padding.tv-button--warning_ghost {
  padding: 0
}

.tv-button--state {
  background: #0000;
  border-style: solid;
  border-width: 1px;
  text-align: center
}

html.theme-dark .tv-button--state {
  background: #0000
}

.tv-button--state:after {
  content: "";
  display: inline-block
}

.tv-button--state__checked,.tv-button--state__uncheck-hint,.tv-button--state__unchecked {
  display: block;
  height: 0;
  transition: opacity .2625s ease,transform .2625s ease
}

.tv-button--state__ellipsis-text {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.i-checked:hover .tv-button--state__checked,.tv-button--state.i-checked:hover .tv-button--state__uncheck-hint,.tv-button--state.i-checked:hover .tv-button--state__unchecked {
      will-change:opacity,transform
  }
}

.tv-button--state.i-checked .tv-button--state__unchecked,.tv-button--state__checked,.tv-button--state__uncheck-hint {
  opacity: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.i-checked:hover .tv-button--state__checked {
      opacity:0
  }
}

.tv-button--state.i-checked .tv-button--state__checked,.tv-button--state__unchecked {
  opacity: 1
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.i-checked:hover .tv-button--state__uncheck-hint {
      opacity:1
  }

  .tv-button--state.i-checked:hover .tv-button--state__checked {
      transform: translateY(-5px)
  }
}

.tv-button--state.i-checked .tv-button--state__unchecked,.tv-button--state__checked,.tv-button--state__uncheck-hint {
  transform: translateY(5px)
}

.tv-button--state.i-checked .tv-button--state__checked {
  transform: translateY(0)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.i-checked:hover .tv-button--state__uncheck-hint {
      transform:translateY(0)
  }
}

.tv-button--state.tv-button--success {
  background-color: initial;
  color: #009688
}

html.theme-dark .tv-button--state.tv-button--success {
  background-color: initial;
  color: #00796b
}

.tv-button--state.tv-button--success.i-checked {
  background-color: #009688;
  color: #fff
}

html.theme-dark .tv-button--state.tv-button--success.i-checked {
  background-color: #00796b;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.tv-button--success:hover {
      background-color:#00897b;
      color: #fff
  }

  html.theme-dark .tv-button--state.tv-button--success:hover {
      background-color: #00897b;
      color: #fff
  }
}

.tv-button--state.tv-button--success:active {
  background-color: #00796b;
  color: #fff
}

html.theme-dark .tv-button--state.tv-button--success:active {
  background-color: #009688;
  color: #fff
}

.tv-button--state.tv-button--danger {
  background-color: initial;
  color: #ef5350
}

html.theme-dark .tv-button--state.tv-button--danger {
  background-color: initial;
  color: #d32f2f
}

.tv-button--state.tv-button--danger.i-checked {
  background-color: #ff4a68;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.tv-button--danger:hover {
      background-color:#f24965;
      color: #fff
  }
}

.tv-button--state.tv-button--danger:active {
  background-color: #ff173e;
  color: #fff
}

.tv-button--state.tv-button--primary {
  background-color: initial;
  color: #2962ff
}

html.theme-dark .tv-button--state.tv-button--primary {
  background-color: initial;
  color: #2962ff
}

.tv-button--state.tv-button--primary.i-checked {
  background-color: #2962ff;
  color: #fff
}

html.theme-dark .tv-button--state.tv-button--primary.i-checked {
  background-color: #2962ff;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.tv-button--primary:hover {
      background-color:#1e53e5;
      color: #fff
  }

  html.theme-dark .tv-button--state.tv-button--primary:hover {
      background-color: #1e53e5;
      color: #fff
  }
}

.tv-button--state.tv-button--primary:active {
  background-color: #1848cc;
  color: #fff
}

html.theme-dark .tv-button--state.tv-button--primary:active {
  background-color: #1848cc;
  color: #fff
}

.tv-button--state.tv-button--primary_ghost-hover.i-checked {
  background-color: initial;
  color: #2962ff
}

html.theme-dark .tv-button--state.tv-button--primary_ghost-hover.i-checked {
  background-color: initial;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.tv-button--primary_ghost-hover:hover {
      background-color:#1e53e5;
      color: #fff
  }

  html.theme-dark .tv-button--state.tv-button--primary_ghost-hover:hover {
      background-color: #1e53e5;
      color: #fff
  }
}

.tv-button--state.tv-button--primary_ghost-hover:active {
  background-color: #1848cc;
  color: #fff
}

html.theme-dark .tv-button--state.tv-button--primary_ghost-hover:active {
  background-color: #1848cc;
  color: #fff
}

.tv-button--state.tv-button--secondary {
  background-color: initial;
  color: #757575
}

.tv-button--state.tv-button--secondary.i-checked {
  background-color: #e9eff2;
  color: #757575
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.tv-button--secondary:hover {
      background-color:#dce6ea;
      color: #757575
  }
}

.tv-button--state.tv-button--secondary:active {
  background-color: #cfdce3;
  color: #757575
}

.tv-button--state.tv-button--warning {
  background-color: initial;
  color: #f89e30
}

.tv-button--state.tv-button--warning.i-checked {
  background-color: #f89e30;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-button--state.tv-button--warning:hover {
      background-color:#f79217;
      color: #fff
  }
}

.tv-button--state.tv-button--warning:active {
  background-color: #d47807;
  color: #fff
}

.tv-button--state.tv-button--icon-with-text svg {
  margin-bottom: -8px;
  margin-right: 6px;
  vertical-align: unset
}

.tv-button--icon {
  align-items: center;
  display: inline-flex;
  height: 34px;
  justify-content: center;
  min-width: auto;
  padding: 0!important;
  width: 34px
}

.tv-button--icon.tv-button--size_xsmall {
  height: 19px;
  width: 19px
}

.tv-button--icon.tv-button--size_small {
  height: 27px;
  width: 27px
}

.tv-button--icon.tv-button--size_large {
  height: 46px;
  width: 46px
}

.tv-button__icon {
  display: flex;
  margin-right: 7px
}

.tv-button--loader .tv-button__text {
  transition: opacity .175s ease,transform .175s ease
}

.tv-button--loader.i-start-load .tv-button__text {
  opacity: 0;
  transform: translateY(-5px)
}

.tv-button--loader.i-loading .tv-button__text {
  opacity: 0;
  transform: translateY(5px)
}

.tv-button--loader.i-stop-load .tv-button__text {
  opacity: 1;
  transform: translateY(0);
  transition-delay: .175s
}

.tv-button__loader {
  bottom: 0;
  font-size: 0;
  height: 100%;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity .35s ease
}

.tv-button__loader:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.tv-button--loader.i-loading .tv-button__loader,.tv-button--loader.i-start-load .tv-button__loader {
  opacity: 1
}

.tv-button--loader.i-stop-load .tv-button__loader {
  opacity: 0
}

.tv-button__loader-item {
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  margin-left: 2px;
  margin-right: 2px;
  opacity: 0;
  transform: translateY(12px) scale(.6);
  transition: transform .35s cubic-bezier(.68,-.55,.265,1.55),opacity .35s ease;
  vertical-align: middle;
  width: 10px
}

.tv-button__loader-item:nth-child(2) {
  transition-delay: .11666667s
}

.tv-button__loader-item:nth-child(3) {
  transition-delay: .23333333s
}

.tv-button--default .tv-button__loader-item {
  background-color: #757575
}

.tv-button--loader.i-loading .tv-button__loader-item,.tv-button--loader.i-start-load .tv-button__loader-item {
  opacity: 1
}

.tv-button--loader.i-stop-load .tv-button__loader-item {
  opacity: 0
}

.tv-button--loader.i-loading .tv-button__loader-item,.tv-button--loader.i-start-load .tv-button__loader-item,.tv-button--loader.i-stop-load .tv-button__loader-item {
  transform: translateY(0) scale(.6)
}

.tv-button--loader.i-loading .tv-button__loader-item,.tv-button--loader.i-stop-load .tv-button__loader-item {
  animation: tv-button-loader .96s ease-in-out infinite both
}

.tv-button--loader.i-loading .tv-button__loader-item:nth-child(2),.tv-button--loader.i-stop-load .tv-button__loader-item:nth-child(2) {
  animation-delay: .151s
}

.tv-button--loader.i-loading .tv-button__loader-item:nth-child(3),.tv-button--loader.i-stop-load .tv-button__loader-item:nth-child(3) {
  animation-delay: .32s
}

.tv-button--no-border-radius {
  border-radius: 0
}

.tv-button--no-border {
  border: none
}

.tv-button--connect {
  border-radius: 0
}

.tv-button--connect_left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.tv-button--connect_right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.tv-button--with-icon {
  align-items: center;
  display: inline-flex;
  transform: translate(0)
}

@keyframes tv-button-loader {
  0%,to {
      transform: scale(.6)
  }

  50% {
      transform: scale(.9)
  }
}

.menuWrap-g78rwseC {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px #0003;
  box-sizing: border-box;
  text-align: left;
  -webkit-user-select: none;
  user-select: none;
  z-index: 100
}

html.theme-dark .menuWrap-g78rwseC {
  background-color: #1e222d;
  box-shadow: 0 2px 4px #0006
}

.menuWrap-g78rwseC.isMeasuring-g78rwseC {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  visibility: hidden
}

.menuWrap-g78rwseC:focus {
  outline: none
}

.scrollWrap-g78rwseC {
  -ms-overflow-style: scrollbar;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto
}

.scrollWrap-g78rwseC.momentumBased-g78rwseC {
  -webkit-overflow-scrolling: touch
}

.scrollWrap-g78rwseC::-webkit-scrollbar {
  height: 5px;
  width: 5px
}

.scrollWrap-g78rwseC::-webkit-scrollbar-thumb {
  background-color: #9598a1;
  border: 1px solid #f0f3fa;
  border-radius: 3px
}

html.theme-dark .scrollWrap-g78rwseC::-webkit-scrollbar-thumb {
  background-color: #363a45;
  border-color: #1e222d
}

.scrollWrap-g78rwseC::-webkit-scrollbar-track {
  background-color: initial;
  border-radius: 3px
}

.scrollWrap-g78rwseC::-webkit-scrollbar-corner {
  display: none
}

.menuBox-g78rwseC {
  padding: 6px 0
}

.isHidden-g78rwseC {
  display: none
}

.pill-1GiNrmG7 {
  border-radius: 10px;
  font-size: 8px;
  font-weight: 700;
  height: 12px;
  line-height: 12px;
  padding: 0 4px;
  text-transform: uppercase
}

.color-1GiNrmG7 {
  color: #fff
}

.orange-1GiNrmG7 {
  background-color: #ff9800
}

.gray-1GiNrmG7 {
  background-color: #787b86
}

.green-1GiNrmG7 {
  background-color: #26a69a
}

.tv-circle-logo-pair {
  display: inline-block;
  position: relative
}

.tv-circle-logo-pair--small {
  height: 18px;
  width: 18px
}

.tv-circle-logo-pair--medium {
  height: 24px;
  width: 24px
}

.tv-circle-logo-pair--large {
  height: 56px;
  width: 56px
}

.tv-circle-logo-pair__logo {
  border-radius: 50%
}

.tv-circle-logo-pair__logo:not(img) {
  background-color: #f0f3fa
}

html.theme-dark .tv-circle-logo-pair__logo:not(img) {
  background-color: #2a2e39
}

.tv-circle-logo-pair__logo:first-child,.tv-circle-logo-pair__logo:last-child {
  position: absolute
}

.tv-circle-logo-pair__logo:first-child {
  bottom: 0;
  right: 0
}

.tv-circle-logo-pair__logo:last-child {
  border: 1px solid #fff;
  left: -1px;
  top: -1px
}

html.theme-dark .tv-circle-logo-pair__logo:last-child {
  border: 1px solid #1e222d
}

.tv-circle-logo-pair__logo--small {
  height: 13px;
  width: 13px
}

.tv-circle-logo-pair__logo--medium {
  height: 16px;
  width: 16px
}

.tv-circle-logo-pair__logo--large {
  height: 38px;
  width: 38px
}

.tv-circle-logo {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  font-style: normal;
  font-weight: 700;
  justify-content: center
}

html.theme-dark .tv-circle-logo {
  color: #5d606b
}

.tv-circle-logo:not(img) {
  background-color: #f0f3fa
}

html.theme-dark .tv-circle-logo:not(img) {
  background-color: #2a2e39
}

.tv-circle-logo--small {
  font-size: 11px;
  height: 18px;
  width: 18px
}

.tv-circle-logo--medium {
  font-size: 16px;
  height: 24px;
  width: 24px
}

.tv-circle-logo--large {
  font-size: 40px;
  height: 56px;
  width: 56px
}

.tv-circle-logo--border {
  border: 1px solid #fff
}

html.theme-dark .tv-circle-logo--border {
  border: 1px solid #1e222d
}

.item-AKDLxKRY {
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e0e3eb;
  box-sizing: border-box;
  height: 64px
}

html.theme-dark .item-AKDLxKRY {
  box-shadow: inset 0 0 0 1px #434651
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-AKDLxKRY:hover {
      background-color:#f0f3fa;
      box-shadow: none
  }

  html.theme-dark .item-AKDLxKRY:hover {
      background-color: #2a2e39
  }
}

.ticker--Fvqc-rJ {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 8px 12px
}

.text--Fvqc-rJ {
  flex-grow: 1;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  text-transform: uppercase
}

.body--Fvqc-rJ,.head--Fvqc-rJ {
  align-items: center;
  display: inline-flex;
  width: 100%
}

.body--Fvqc-rJ {
  justify-content: space-between
}

.title--Fvqc-rJ {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.logo--Fvqc-rJ {
  display: flex;
  margin-right: 8px
}

.lastWrapper--Fvqc-rJ {
  display: inline-flex;
  min-width: 0
}

.last--Fvqc-rJ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.change--Fvqc-rJ {
  margin-left: 8px
}

.change--Fvqc-rJ.up--Fvqc-rJ,.last--Fvqc-rJ.growing--Fvqc-rJ {
  color: #26a69a
}

html.theme-dark .change--Fvqc-rJ.up--Fvqc-rJ,html.theme-dark .last--Fvqc-rJ.growing--Fvqc-rJ {
  color: #00897b
}

.change--Fvqc-rJ.down--Fvqc-rJ,.last--Fvqc-rJ.falling--Fvqc-rJ {
  color: #ef5350
}

html.theme-dark .change--Fvqc-rJ.down--Fvqc-rJ,html.theme-dark .last--Fvqc-rJ.falling--Fvqc-rJ {
  color: #e53935
}

.banner-1CkSj9H1 {
  align-items: center;
  color: #2962ff;
  display: flex;
  padding: 0 12px
}

html.theme-dark .banner-1CkSj9H1 {
  color: #2962ff
}

.icon-1CkSj9H1 {
  flex-shrink: 0
}

.text-1CkSj9H1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-left: 8px
}

.menuBox-3vJEMb9o.menuBox-3vJEMb9o {
  --main-dropdown-menu-separator-direction: 1px;
  color: #131722;
  display: flex;
  padding: 0
}

html.theme-dark .menuBox-3vJEMb9o.menuBox-3vJEMb9o {
  color: #d1d4dc
}

.menuBox-3vJEMb9o.menuBox-3vJEMb9o.reversed-3vJEMb9o {
  --main-dropdown-menu-separator-direction: -1px;
  flex-direction: row-reverse
}

.menuWrap-3vJEMb9o.menuWrap-3vJEMb9o {
  border-radius: 8px
}

.scrollWrap-3vJEMb9o.scrollWrap-3vJEMb9o {
  overflow-y: auto!important
}

.categories-3vJEMb9o {
  width: 200px
}

.subcategories-3vJEMb9o {
  width: 220px
}

.categories-3vJEMb9o,.subcategories-3vJEMb9o {
  padding: 8px 0
}

.extra-3vJEMb9o {
  box-sizing: border-box;
  padding: 16px;
  width: 232px
}

.extra-3vJEMb9o>:not(:last-child) {
  margin-bottom: 8px
}


.menuItem-3vJEMb9o {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 20px
}

.menuItem-3vJEMb9o.active-3vJEMb9o {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .menuItem-3vJEMb9o:hover {
      background-color:#f0f3fa
  }
}

html.theme-dark .menuItem-3vJEMb9o.active-3vJEMb9o {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .menuItem-3vJEMb9o:hover {
      background-color:#2a2e39
  }
}

.menuItemText-3vJEMb9o {
  overflow: hidden;
  text-overflow: ellipsis
}

.badge-3vJEMb9o {
  margin-left: 6px
}

.icon-3vJEMb9o {
  display: flex;
  margin-left: auto
}

.icon-3vJEMb9o svg {
  margin: -2px -5px
}

.tv-header {
  border-bottom: 1px solid #e0e3eb;
  color: #131722;
  flex: none;
  position: relative;
  transition-property: background-color,color,border-bottom-color;
  -webkit-user-select: none;
  user-select: none;
  z-index: 2
}

html.theme-dark .tv-header {
  border-bottom: 1px solid #434651;
  color: #d1d4dc
}

.tv-header__top {
  z-index: 3
}

.tv-header--sticky {
  position: sticky;
  top: 0;
  z-index: 101
}

.tv-header--promo {
  background-color: initial;
  color: #fff
}

.tv-header--promo,html.theme-dark .tv-header--promo {
  border-bottom-color: #0000
}

html.theme-dark .tv-header--promo {
  background-color: initial;
  color: #fff
}

.tv-header__backdrop {
  background: linear-gradient(180deg,#000c1e -40%,#00071500);
  height: 120px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-property: opacity;
  width: 100%
}

.tv-header--promo .tv-header__backdrop {
  opacity: 1
}

.tv-header--animated,.tv-header--animated .tv-header__backdrop {
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.39,0,.02,.99)
}

.tv-header__inner {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 64px;
  max-width: 100%;
  padding: 0 16px;
  position: relative
}

@media screen and (min-width: 1024px) {
  .tv-header__inner {
      padding:0 32px
  }
}

.tv-header__area {
  flex-shrink: 0
}

.tv-header__area--logo-menu {
  align-items: center;
  display: flex;
  justify-content: flex-start
}

@media screen and (max-width: 767px) {
  .tv-header__area--logo-menu {
      flex-grow:1;
      padding-right: 0
  }
}

.tv-header__area--menu {
  align-items: center;
  display: none;
  justify-content: center
}

@media screen and (min-width: 1280px) {
  .tv-header__area--menu {
      display:flex
  }
}

.tv-header__area--search {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end
}

@media screen and (min-width: 1280px) {
  .tv-header__area--search {
      flex-grow:0;
      margin-right: 8px
  }
}

.tv-header--promo .tv-header__area--search {
  display: none
}

.tv-header__area--user {
  align-items: center;
  display: flex
}

@media screen and (min-width: 1920px) {
  .tv-header__area--user {
      justify-content:flex-end;
      min-width: 150px
  }
}

.tv-header__logo-text {
  display: none;
  margin-left: 6px
}

@media screen and (min-width: 480px) {
  .tv-header__logo-text {
      display:none
  }
}

@media screen and (min-width: 1024px) {
  .tv-header__logo-text {
      display:block
  }
}

@media screen and (min-width: 1280px) {
  .tv-header__logo-text {
      display:none
  }

  .tv-header--promo .tv-header__logo-text {
      display: block
  }
}

@media screen and (min-width: 1480px) {
  .tv-header__logo-text {
      display:block
  }
}

.tv-header__middle-content {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 0 16px
}

@media screen and (min-width: 768px) {
  .tv-header__middle-content {
      margin:0 48px
  }
}

@media screen and (min-width: 1480px) {
  .tv-header__middle-content {
      margin:0 24px
  }
}

@media screen and (min-width: 1920px) {
  .tv-header__middle-content {
      margin:0 54px
  }
}

@media screen and (max-width: 767px) {
  .tv-header__middle-content {
      flex-grow:0
  }
}

.tv-header__link {
  color: inherit;
  display: inline-block;
  height: 64px;
  line-height: 64px;
  vertical-align: middle
}

@media screen and (max-width: 1019px) {
  .tv-header__link {
      height:64px;
      line-height: 64px
  }
}

.tv-header__link {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-header__link:focus {
  outline: none
}

.tv-header__link:focus-visible {
  outline: none
}

.tv-header__link:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 24px);
  left: -12px;
  pointer-events: none;
  position: absolute;
  top: -12px;
  width: calc(100% + 24px);
  z-index: 1
}

.tv-header__link:focus:after {
  display: block
}

.tv-header__link:focus-visible:after {
  display: block
}

.tv-header__link:focus:not(:focus-visible):after {
  display: none
}

.tv-header__link:after {
  border-color: #2962ffcc;
  border-radius: 12px
}

.tv-header__link:last-child {
  margin-right: 0
}

.tv-header__link--logo {
  align-items: center;
  border: none;
  display: flex;
  height: 28px;
  position: relative
}

.tv-header__link,.tv-header__logo {
  float: left
}

.tv-header__hamburger-menu {
  cursor: pointer
}

.tv-header__hamburger-menu.i-dropped {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header__hamburger-menu:hover {
      background-color:#f0f3fa
  }
}

html.theme-dark .tv-header__hamburger-menu.i-dropped {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-header__hamburger-menu:hover {
      background-color:#2a2e39
  }
}

.tv-header--promo .tv-header__hamburger-menu.i-dropped {
  background-color: #fff3
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header--promo .tv-header__hamburger-menu:hover {
      background-color:#fff3
  }
}

html.theme-dark .tv-header--promo .tv-header__hamburger-menu.i-dropped {
  background-color: #fff3
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-header--promo .tv-header__hamburger-menu:hover {
      background-color:#fff3
  }
}

.tv-header__offer-button {
  background-color: #2962ff;
  border-radius: 24px;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  line-height: 24px;
  padding: 8px 16px;
  text-align: center;
  transition: background-color .35s ease;
  vertical-align: middle
}

.i-align_center .tv-header__offer-button {
  margin-left: 0
}

.tv-header__offer-button:active {
  transition-duration: 60ms
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header__offer-button:hover {
      transition-duration:60ms
  }
}

.tv-header__offer-button {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-header__offer-button:focus {
  outline: none
}

.tv-header__offer-button:focus-visible {
  outline: none
}

.tv-header__offer-button:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-header__offer-button:focus:after {
  display: block
}

.tv-header__offer-button:focus-visible:after {
  display: block
}

.tv-header__offer-button:focus:not(:focus-visible):after {
  display: none
}

.tv-header__offer-button:after {
  border-color: #2962ffcc;
  border-radius: 28px
}

.tv-header__offer-button-container {
  display: flex;
  margin-left: 16px
}

@media screen and (max-width: 479px) {
  .tv-header__offer-button-container:not(.tv-header__offer-button-container--menu) {
      display:none!important
  }
}

.tv-header__offer-button-container--menu {
  margin: 0;
  padding: 12px 20px
}

@media screen and (min-width: 480px) {
  .tv-header__offer-button-container--menu {
      display:none!important
  }
}

.is-not-authenticated .tv-header__offer-button-container--menu {
  align-items: flex-end;
  flex-grow: 1
}

.tv-header__offer-button-container--menu .tv-header__offer-button {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  line-height: 32px;
  margin: 0;
  max-width: 100%!important;
  width: 100%
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button,.tv-header__offer-button-container--lastchance .tv-header__offer-button,.tv-header__offer-button-container--oneusd .tv-header__offer-button,.tv-header__offer-button-container--trial .tv-header__offer-button,.tv-header__offer-button-container--upgrade .tv-header__offer-button,.tv-header__offer-button-container--upsell .tv-header__offer-button {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
  padding-top: 4px
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button>span,.tv-header__offer-button-container--earlyaccess .tv-header__offer-button small,.tv-header__offer-button-container--lastchance .tv-header__offer-button>span,.tv-header__offer-button-container--lastchance .tv-header__offer-button small,.tv-header__offer-button-container--oneusd .tv-header__offer-button>span,.tv-header__offer-button-container--oneusd .tv-header__offer-button small,.tv-header__offer-button-container--trial .tv-header__offer-button>span,.tv-header__offer-button-container--trial .tv-header__offer-button small,.tv-header__offer-button-container--upgrade .tv-header__offer-button>span,.tv-header__offer-button-container--upgrade .tv-header__offer-button small,.tv-header__offer-button-container--upsell .tv-header__offer-button>span,.tv-header__offer-button-container--upsell .tv-header__offer-button small {
  display: block
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button>span,.tv-header__offer-button-container--lastchance .tv-header__offer-button>span,.tv-header__offer-button-container--oneusd .tv-header__offer-button>span,.tv-header__offer-button-container--trial .tv-header__offer-button>span,.tv-header__offer-button-container--upgrade .tv-header__offer-button>span,.tv-header__offer-button-container--upsell .tv-header__offer-button>span {
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis
}

@media screen and (min-width: 1280px) {
  .tv-header__offer-button-container--earlyaccess .tv-header__offer-button>span,.tv-header__offer-button-container--lastchance .tv-header__offer-button>span,.tv-header__offer-button-container--oneusd .tv-header__offer-button>span,.tv-header__offer-button-container--trial .tv-header__offer-button>span,.tv-header__offer-button-container--upgrade .tv-header__offer-button>span,.tv-header__offer-button-container--upsell .tv-header__offer-button>span {
      font-size:13px
  }
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button small,.tv-header__offer-button-container--lastchance .tv-header__offer-button small,.tv-header__offer-button-container--oneusd .tv-header__offer-button small,.tv-header__offer-button-container--trial .tv-header__offer-button small,.tv-header__offer-button-container--upgrade .tv-header__offer-button small,.tv-header__offer-button-container--upsell .tv-header__offer-button small {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button,.tv-header__offer-button-container--lastchance .tv-header__offer-button,.tv-header__offer-button-container--oneusd .tv-header__offer-button,.tv-header__offer-button-container--trial .tv-header__offer-button,.tv-header__offer-button-container--upgrade .tv-header__offer-button,.tv-header__offer-button-container--upsell .tv-header__offer-button {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button:focus,.tv-header__offer-button-container--lastchance .tv-header__offer-button:focus,.tv-header__offer-button-container--oneusd .tv-header__offer-button:focus,.tv-header__offer-button-container--trial .tv-header__offer-button:focus,.tv-header__offer-button-container--upgrade .tv-header__offer-button:focus,.tv-header__offer-button-container--upsell .tv-header__offer-button:focus {
  outline: none
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button:focus-visible,.tv-header__offer-button-container--lastchance .tv-header__offer-button:focus-visible,.tv-header__offer-button-container--oneusd .tv-header__offer-button:focus-visible,.tv-header__offer-button-container--trial .tv-header__offer-button:focus-visible,.tv-header__offer-button-container--upgrade .tv-header__offer-button:focus-visible,.tv-header__offer-button-container--upsell .tv-header__offer-button:focus-visible {
  outline: none
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button:after,.tv-header__offer-button-container--lastchance .tv-header__offer-button:after,.tv-header__offer-button-container--oneusd .tv-header__offer-button:after,.tv-header__offer-button-container--trial .tv-header__offer-button:after,.tv-header__offer-button-container--upgrade .tv-header__offer-button:after,.tv-header__offer-button-container--upsell .tv-header__offer-button:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button:focus:after,.tv-header__offer-button-container--lastchance .tv-header__offer-button:focus:after,.tv-header__offer-button-container--oneusd .tv-header__offer-button:focus:after,.tv-header__offer-button-container--trial .tv-header__offer-button:focus:after,.tv-header__offer-button-container--upgrade .tv-header__offer-button:focus:after,.tv-header__offer-button-container--upsell .tv-header__offer-button:focus:after {
  display: block
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button:focus-visible:after,.tv-header__offer-button-container--lastchance .tv-header__offer-button:focus-visible:after,.tv-header__offer-button-container--oneusd .tv-header__offer-button:focus-visible:after,.tv-header__offer-button-container--trial .tv-header__offer-button:focus-visible:after,.tv-header__offer-button-container--upgrade .tv-header__offer-button:focus-visible:after,.tv-header__offer-button-container--upsell .tv-header__offer-button:focus-visible:after {
  display: block
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button:focus:not(:focus-visible):after,.tv-header__offer-button-container--lastchance .tv-header__offer-button:focus:not(:focus-visible):after,.tv-header__offer-button-container--oneusd .tv-header__offer-button:focus:not(:focus-visible):after,.tv-header__offer-button-container--trial .tv-header__offer-button:focus:not(:focus-visible):after,.tv-header__offer-button-container--upgrade .tv-header__offer-button:focus:not(:focus-visible):after,.tv-header__offer-button-container--upsell .tv-header__offer-button:focus:not(:focus-visible):after {
  display: none
}

.tv-header__offer-button-container--earlyaccess .tv-header__offer-button:after,.tv-header__offer-button-container--lastchance .tv-header__offer-button:after,.tv-header__offer-button-container--oneusd .tv-header__offer-button:after,.tv-header__offer-button-container--trial .tv-header__offer-button:after,.tv-header__offer-button-container--upgrade .tv-header__offer-button:after,.tv-header__offer-button-container--upsell .tv-header__offer-button:after {
  border-color: #2962ffcc;
  border-radius: 12px
}

.tv-header__earlyaccess-text,.tv-header__lastchance-text,.tv-header__oneusd-text,.tv-header__upsell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tv-header__icon {
  line-height: 64px
}

.tv-header__icon svg {
  vertical-align: middle
}

.tv-header__icon--counter-red {
  background: #ff4a68;
  border: 4px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  height: 18px;
  position: absolute;
  right: -3px;
  top: -3px;
  width: 18px
}

html.theme-dark .tv-header__icon--counter-red {
  border: 4px solid #131722
}

.tv-header__link:active .tv-header__icon svg {
  transition-duration: 60ms
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header__link:hover .tv-header__icon svg {
      transition-duration:60ms
  }
}

.is-pro:not(.is-trial) .tv-header__blackfriday {
  margin-left: 15px
}

.tv-header__user-menu-button {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0
}

.tv-header__user-menu-button.i-dropped {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header__user-menu-button:hover {
      background-color:#f0f3fa
  }
}

html.theme-dark .tv-header__user-menu-button.i-dropped {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-header__user-menu-button:hover {
      background-color:#2a2e39
  }
}

.tv-header--promo .tv-header__user-menu-button.i-dropped {
  background-color: #fff3
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header--promo .tv-header__user-menu-button:hover {
      background-color:#fff3
  }
}

html.theme-dark .tv-header--promo .tv-header__user-menu-button.i-dropped {
  background-color: #fff3
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-header--promo .tv-header__user-menu-button:hover {
      background-color:#fff3
  }
}

.tv-header__user-menu-button {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-header__user-menu-button:focus {
  outline: none
}

.tv-header__user-menu-button:focus-visible {
  outline: none
}

.tv-header__user-menu-button:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-header__user-menu-button:focus:after {
  display: block
}

.tv-header__user-menu-button:focus-visible:after {
  display: block
}

.tv-header__user-menu-button:focus:not(:focus-visible):after {
  display: none
}

.tv-header__user-menu-button:after {
  border-color: #2962ffcc;
  border-radius: 36px
}

.is-not-authenticated .tv-header__user-menu-button--logged {
  display: none
}

.tv-header__user-menu-button--anonymous {
  flex-direction: column;
  height: 40px;
  padding-bottom: 2px;
  width: 40px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header__user-menu-button--anonymous:hover {
      background-color:#f0f3fa;
      color: #2962ff
  }

  html.theme-dark .tv-header__user-menu-button--anonymous:hover {
      background-color: #2a2e39;
      color: #2962ff
  }

  .tv-header--promo .tv-header__user-menu-button--anonymous:hover {
      background-color: #fff3;
      color: #fff
  }

  html.theme-dark .tv-header--promo .tv-header__user-menu-button--anonymous:hover {
      background-color: #fff3;
      color: #fff
  }
}

.is-authenticated .tv-header__user-menu-button--anonymous {
  display: none
}

.tv-header__user-menu-button-userpic {
  border-radius: 32px;
  height: 40px;
  width: 40px
}

.tv-header__logo {
  display: inline-block;
  font-size: 0;
  height: 64px;
  vertical-align: middle
}

.tv-header__hamburger-menu {
  align-items: center;
  background: none;
  border: none;
  border-radius: 32px;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  padding: 11px 9px;
  transition: transform .35s cubic-bezier(.215,.61,.355,1),2,opacity .35s cubic-bezier(.215,.61,.355,1),2;
  width: 40px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header__hamburger-menu:hover svg,html.theme-dark .tv-header__hamburger-menu:hover svg {
      fill:#2962ff
  }
}

@media screen and (min-width: 1280px) {
  .tv-header__hamburger-menu {
      display:none
  }
}

.tv-header__hamburger-menu svg {
  display: inline-block;
  height: 14px;
  vertical-align: middle;
  width: 18px
}

.tv-header__logo {
  align-items: center;
  display: flex
}

.tv-header.tv-header--promo :after {
  border-color: #fffc
}

.tv-header__main-menu {
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px
}

.tv-header__main-menu-item {
  display: flex
}

.tv-header__main-menu-item>a {
  align-items: center;
  border: none;
  border-radius: 36px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: 40px;
  letter-spacing: .3px;
  margin: 12px 0;
  outline: none;
  overflow: visible;
  padding: 8px 16px;
  position: relative
}

.tv-header__main-menu-item>a:focus {
  outline: none
}

.tv-header__main-menu-item>a:focus-visible {
  outline: none
}

.tv-header__main-menu-item>a:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-header__main-menu-item>a:focus:after {
  display: block
}

.tv-header__main-menu-item>a:focus-visible:after {
  display: block
}

.tv-header__main-menu-item>a:focus:not(:focus-visible):after {
  display: none
}

.tv-header__main-menu-item>a:after {
  border-color: #2962ffcc;
  border-radius: 40px
}

@media screen and (min-width: 1920px) {
  .tv-header__main-menu-item>a {
      margin:12px 8px
  }
}

.tv-header__main-menu-item--highlighted>a,html.theme-dark .tv-header__main-menu-item--highlighted>a {
  color: #2962ff
}

.tv-header__main-menu-item.i-dropped>a {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header__main-menu-item>a:hover {
      background-color:#f0f3fa
  }
}

html.theme-dark .tv-header__main-menu-item.i-dropped>a {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-header__main-menu-item>a:hover {
      background-color:#2a2e39
  }
}

.tv-header--promo .tv-header__main-menu-item.i-dropped>a {
  background-color: #fff3;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header--promo .tv-header__main-menu-item>a:hover {
      background-color:#fff3;
      color: #fff
  }
}

html.theme-dark .tv-header--promo .tv-header__main-menu-item.i-dropped>a {
  background-color: #fff3
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-header--promo .tv-header__main-menu-item>a:hover {
      background-color:#fff3
  }
}

html.theme-dark .tv-header--promo .tv-header__main-menu-item.i-dropped>a {
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-header--promo .tv-header__main-menu-item>a:hover {
      color:#fff
  }
}

.tv-header__main-menu-item svg {
  margin-right: 4px
}

.tv-header-search-container {
  align-items: center;
  display: flex;
  flex-grow: 1
}

.tv-header-search-container__button {
  background: none;
  border: none;
  border-radius: 36px;
  box-sizing: border-box;
  color: #131722;
  height: 40px;
  padding: 0
}

html.theme-dark .tv-header-search-container__button {
  color: #d1d4dc
}

.tv-header-search-container__button {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-header-search-container__button:focus {
  outline: none
}

.tv-header-search-container__button:focus-visible {
  outline: none
}

.tv-header-search-container__button:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-header-search-container__button:focus:after {
  display: block
}

.tv-header-search-container__button:focus-visible:after {
  display: block
}

.tv-header-search-container__button:focus:not(:focus-visible):after {
  display: none
}

.tv-header-search-container__button:after {
  border-color: #2962ffcc;
  border-radius: 40px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header-search-container__button:hover {
      background-color:#f0f3fa;
      color: #2962ff
  }

  html.theme-dark .tv-header-search-container__button:hover {
      background-color: #2a2e39;
      color: #2962ff
  }
}

.tv-header-search-container__button--full {
  align-items: center;
  background-color: #f0f3fa;
  border-radius: 40px;
  box-sizing: border-box;
  color: #787b86;
  display: flex;
  display: none;
  height: 40px;
  justify-content: space-between;
  justify-content: flex-start;
  padding: 6px 8px
}

html.theme-dark .tv-header-search-container__button--full {
  background-color: #2a2e39;
  color: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-header-search-container__button--full:hover {
      color:#131722
  }

  html.theme-dark .tv-header-search-container__button--full:hover {
      color: #d1d4dc
  }

  .tv-header-search-container__button--full:hover .tv-header-search-container__hotkey {
      display: flex
  }
}

.tv-header-search-container__button--simple {
  background-color: initial;
  border: 0;
  display: block;
  height: 40px;
  width: 40px
}

html.theme-dark .tv-header-search-container__button--simple {
  background-color: initial
}

@media screen and (min-width: 768px) {
  .tv-header-search-container__button--full {
      display:flex;
      width: 100%
  }

  .tv-header-search-container__button--simple {
      display: none
  }
}

@media screen and (min-width: 1280px) {
  .tv-header-search-container__button--full {
      width:200px
  }
}

.tv-header-search-container__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 4px
}

.tv-header-search-container__hotkey {
  background-color: #e0e3eb;
  border: none;
  border-radius: 4px;
  color: #131722;
  display: none;
  font-size: 12px;
  line-height: 22px;
  margin-left: auto;
  margin-right: 8px;
  padding: 0 8px
}

html.theme-dark .tv-header-search-container__hotkey {
  background-color: #363a45;
  color: #d1d4dc
}

.tv-footer-copyright {
  color: #787b86;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 24px;
  text-align: center;
  width: 100%
}

html.theme-dark .tv-footer-copyright {
  color: #787b86
}

@media screen and (min-width: 1024px) {
  .tv-footer-copyright {
      margin-top:0;
      text-align: right
  }
}

.tv-footer-copyright__date {
  margin-top: 4px
}

@media screen and (min-width: 1024px) {
  .tv-footer-copyright__date {
      display:block;
      margin-top: 0
  }
}

.tv-footer-copyright__link {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-footer-copyright__link {
  color: #2962ff
}

.tv-footer-copyright__link:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-footer-copyright__link:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-footer-copyright__link:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-footer-copyright__link:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-footer-copyright__link:focus {
  outline: auto
}

.tv-footer-copyright__link:focus-visible {
  outline: auto
}

.tv-footer-copyright__link:focus:not(:focus-visible) {
  outline: none
}

.tv-footer-copyright__link:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: 60ms
}

html.theme-dark .tv-footer-copyright__link:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-footer-copyright__link {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-footer-copyright__link:focus {
  outline: none
}

.tv-footer-copyright__link:focus-visible {
  outline: none
}

.tv-footer-copyright__link:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-footer-copyright__link:focus:after {
  display: block
}

.tv-footer-copyright__link:focus-visible:after {
  display: block
}

.tv-footer-copyright__link:focus:not(:focus-visible):after {
  display: none
}

.tv-footer-copyright__link:after {
  border-color: #2962ffcc;
  border-radius: 6px
}

.tv-footer-links-column {
  display: flex;
  flex-direction: column
}

.tv-footer-links-column__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  font-weight: 700!important;
  line-height: 28px;
  margin-bottom: 12px
}

@media screen and (min-width: 768px) {
  .tv-footer-links-column__title {
      font-size:16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      line-height: 22px
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer-links-column__title {
      font-size:16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px
  }
}

.tv-footer-links-column__list {
  display: grid;
  list-style: none;
  row-gap: 8px
}

@media screen and (min-width: 1024px) {
  .tv-footer-links-column__list {
      margin-bottom:10px
  }
}

.tv-footer-links-column__link {
  border-radius: 4px;
  color: #131722;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: -2px -8px;
  padding: 2px 8px;
  transition: color .35s ease
}

html.theme-dark .tv-footer-links-column__link {
  color: #d1d4dc
}

.tv-footer-links-column__link:visited {
  fill: #131722;
  color: #131722
}

html.theme-dark .tv-footer-links-column__link:visited {
  fill: #d1d4dc;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-footer-links-column__link:hover {
      fill:#131722;
      color: #131722;
      transition-duration: 60ms
  }

  html.theme-dark .tv-footer-links-column__link:hover {
      fill: #d1d4dc;
      color: #d1d4dc
  }
}

.tv-footer-links-column__link:focus {
  outline: auto
}

.tv-footer-links-column__link:focus-visible {
  outline: auto
}

.tv-footer-links-column__link:focus:not(:focus-visible) {
  outline: none
}

.tv-footer-links-column__link:active {
  fill: #131722;
  color: #131722;
  transition-duration: 60ms
}

html.theme-dark .tv-footer-links-column__link:active {
  fill: #d1d4dc;
  color: #d1d4dc
}

@media screen and (min-width: 768px) {
  .tv-footer-links-column__link {
      font-size:14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      line-height: 22px
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer-links-column__link {
      font-size:14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      line-height: 22px
  }
}

.tv-footer-links-column__link.tv-footer-links-column__link--active,html.theme-dark .tv-footer-links-column__link.tv-footer-links-column__link--active {
  color: #1848cc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-footer-links-column__link:hover {
      background-color:#e0e3eb
  }

  html.theme-dark .tv-footer-links-column__link:hover {
      background-color: #434651
  }
}

.tv-footer-links-column__link {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-footer-links-column__link:focus {
  outline: none
}

.tv-footer-links-column__link:focus-visible {
  outline: none
}

.tv-footer-links-column__link:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-footer-links-column__link:focus:after {
  display: block
}

.tv-footer-links-column__link:focus-visible:after {
  display: block
}

.tv-footer-links-column__link:focus:not(:focus-visible):after {
  display: none
}

.tv-footer-links-column__link:after {
  border-color: #2962ffcc;
  border-radius: 6px
}

.tv-footer-links-column__link,.tv-footer-links-column__title {
  clear: left;
  float: left
}

@media screen and (min-width: 480px) {
  .tv-footer-links {
      grid-template-columns:repeat(auto-fit,minmax(320px,1fr))
  }
}

@media screen and (min-width: 768px) {
  .tv-footer-links {
      grid-template-columns:repeat(2,1fr)
  }
}

.tv-footer-logo {
  color: #131722;
  display: block;
  height: 54px;
  width: 162px
}

html.theme-dark .tv-footer-logo {
  color: #fff
}

.tv-footer-markets {
  --tv-footer-markets-column-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -12px;
}

@media screen and (min-width: 768px) {
  .tv-footer-markets {
      --tv-footer-markets-column-gap:16px;
      flex-wrap: nowrap
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer-markets {
      margin-bottom:0
  }
}

.tv-footer-markets__row-group {
  display: flex;
  margin-bottom: 12px;
}

@media screen and (min-width: 1024px) {
  .tv-footer-markets__row-group {
      margin-bottom:0
  }
}

.tv-footer-markets__link {
  background-color: #f0f3fa;
  border-radius: 6px;
  height: 40px
}

html.theme-dark .tv-footer-markets__link {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-footer-markets__link:hover {
      background-color:#363a45
  }

  .tv-footer-markets__link:hover {
      background-color: #e0e3eb
  }

  html.theme-dark .tv-footer-markets__link:hover {
      background-color: #434651
  }
}

.tv-footer-markets__link {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-footer-markets__link:focus {
  outline: none
}

.tv-footer-markets__link:focus-visible {
  outline: none
}

.tv-footer-markets__link:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-footer-markets__link:focus:after {
  display: block
}

.tv-footer-markets__link:focus-visible:after {
  display: block
}

.tv-footer-markets__link:focus:not(:focus-visible):after {
  display: none
}

.tv-footer-markets__link:after {
  border-color: #2962ffcc;
  border-radius: 8px
}

.tv-footer-markets__icon {
  display: block;
  height: 40px;
  width: 135px
}

.tv-footer-markets__icon--dark,html.theme-dark .tv-footer-markets__icon {
  display: none
}

html.theme-dark .tv-footer-markets__icon--dark {
  display: block
}

.tv-footer-markets__description {
  margin-left: 12px;
  margin-top: 7px
}

.tv-footer-markets__description--head {
  color: #131722;
  font-size: 7px;
  text-transform: uppercase
}

html.theme-dark .tv-footer-markets__description--head {
  color: #fff
}

.tv-footer-markets__description--text {
  color: #131722;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap
}

html.theme-dark .tv-footer-markets__description--text {
  color: #fff
}

.tv-footer-separator {
  border: none;
  border-top: 1px solid #e0e3eb;
  height: 0;
  margin: 0;
  width: 100%
}

html.theme-dark .tv-footer-separator {
  border-top: 1px solid #434651
}

.tv-footer-socials {
  column-gap: 28px;
  display: grid;
  grid-template-columns: repeat(auto-fill,28px);
  list-style: none;
  row-gap: 24px;
  width: 100%
}

@media screen and (min-width: 768px) {
  .tv-footer-socials {
      align-items:end;
      column-gap: 20px;
      grid-template-columns: repeat(9,28px);
      justify-content: end
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer-socials {
      grid-template-columns:repeat(auto-fit,28px);
      justify-content: start;
      margin-top: 22px;
      row-gap: 20px
  }
}

.tv-footer-socials__item {
  align-items: center;
  display: inline-flex;
  justify-content: center
}

.tv-footer-socials__icon {
  border-radius: 6px;
  color: #131722;
  height: 28px;
  margin: -6px;
  padding: 6px;
  width: 28px
}

html.theme-dark .tv-footer-socials__icon {
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-footer-socials__icon:hover {
      background-color:#e0e3eb
  }

  html.theme-dark .tv-footer-socials__icon:hover {
      background-color: #434651
  }
}

.tv-footer-socials__icon {
  outline: none;
  overflow: visible;
  position: relative
}

.tv-footer-socials__icon:focus {
  outline: none
}

.tv-footer-socials__icon:focus-visible {
  outline: none
}

.tv-footer-socials__icon:after {
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: "";
  display: none;
  height: calc(100% + 8px);
  left: -4px;
  pointer-events: none;
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  z-index: 1
}

.tv-footer-socials__icon:focus:after {
  display: block
}

.tv-footer-socials__icon:focus-visible:after {
  display: block
}

.tv-footer-socials__icon:focus:not(:focus-visible):after {
  display: none
}

.tv-footer-socials__icon:after {
  border-color: #2962ffcc;
  border-radius: 8px
}

.tv-footer {
  --adaptive-mf-container-padding: 20px;
  color: #131722;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  padding: 0;
  padding-bottom: 46px;
  padding-top: 22px
}

html.theme-dark .tv-footer {
  color: #d1d4dc
}

@media screen and (min-width: 768px) {
  .tv-footer {
      --adaptive-mf-container-padding:32px
  }
}

@media screen and (min-width: 1480px) {
  .tv-footer {
      --adaptive-mf-container-padding:40px
  }
}

@media screen and (min-width: 1920px) {
  .tv-footer {
      --adaptive-mf-container-padding:100px
  }
}

@media screen and (min-width: 768px) {
  .tv-footer {
      padding-bottom:22px;
      padding-top: 24px
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer {
      padding-bottom:20px;
      padding-top: 46px
  }
}

.tv-footer__row {
  --tv-footer-row-columns-gap: 16px;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  row-gap: 20px;
  width: 100%
}

@media screen and (min-width: 768px) {
  .tv-footer__row {
      --tv-footer-row-columns-gap:52px;
      row-gap: 34px
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer__row {
      grid-template-columns: 196px 4fr;
      justify-content: space-between
  }
}

@media screen and (min-width: 1480px) {
  .tv-footer__row {
      grid-template-columns: 230px 4fr
  }
}

.tv-footer__logo-socials {
  display: grid;
  grid-column: 1/-1;
  padding-bottom: 12px;
  row-gap: 20px
}

@media screen and (min-width: 768px) {
  .tv-footer__logo-socials {
      column-gap:40px;
      grid-template-columns: repeat(2,1fr);
      margin-right: 6px
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer__logo-socials {
      display:block;
      grid-column: 1/2;
      margin-right: 0;
      padding-bottom: 0;
      width: 196px
  }
}

@media screen and (min-width: 1480px) {
  .tv-footer__logo-socials {
      width:230px
  }
}

.tv-footer__markets-copyright {
  border-top: 1px solid #f0f3fa;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  padding-top: 24px;
  width: 100%
}

html.theme-dark .tv-footer__markets-copyright {
  border-top: 1px solid #2a2e39
}

@media screen and (min-width: 768px) {
  .tv-footer__markets-copyright {
      margin-top:32px
  }
}

@media screen and (min-width: 1024px) {
  .tv-footer__markets-copyright {
      flex-wrap:nowrap;
      margin-top: 14px
  }
}

.tv-chart-view {
  background-color: #fff
}

html.theme-dark .tv-chart-view {
  background-color: #1e222d
}

.tv-chart-view--outlined {
  border: 1px solid #e0e3eb
}

html.theme-dark .tv-chart-view--outlined {
  border-color: #0000
}

.tv-chart-view__link {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-chart-view__link {
  color: #2962ff
}

.tv-chart-view__link:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-chart-view__link:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__link:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-chart-view__link:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-chart-view__link:focus {
  outline: auto
}

.tv-chart-view__link:focus-visible {
  outline: auto
}

.tv-chart-view__link:focus:not(:focus-visible) {
  outline: none
}

.tv-chart-view__link:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: 60ms
}

html.theme-dark .tv-chart-view__link:active {
  fill: #1848cc;
  color: #1848cc
}

@media screen and (max-width: 767px) {
  .tv-chart-view__link {
      border:none
  }
}

.tv-chart-view--hidden-idea,html.theme-dark .tv-chart-view--hidden-idea {
  background-color: #e5393533
}

.tv-chart-view__dialog {
  contain: layout style;
  max-width: 1520px
}

.chart-page .tv-chart-view__dialog,.tv-chart-view__dialog,.tv-chart-view__dialog .tv-layout-width {
  width: 100%
}

.tv-chart-view__body .tv-layout-width {
  max-width: 1520px;
  min-width: 1020px;
  width: 100%
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__body .tv-layout-width {
      min-width:0
  }
}

.tv-chart-view__section {
  border-bottom: 1px solid #e0e3eb;
  box-sizing: border-box;
  padding: 25px 25px 0
}

html.theme-dark .tv-chart-view__section {
  border-bottom-color: #434651
}

.tv-chart-view__section:after {
  clear: both;
  content: "";
  display: table
}

.tv-chart-view__section:last-child {
  border-bottom: none
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__section {
      padding:20px 20px 0
  }
}

.tv-chart-view__columns {
  box-sizing: border-box;
  margin: 0 -10px 25px
}

.tv-chart-view__columns:after {
  clear: both;
  content: "";
  display: table
}

.tv-chart-view__column {
  box-sizing: border-box;
  float: left;
  padding: 0 10px;
  width: 50%
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__column {
      float:none;
      width: 100%
  }
}

.tv-chart-view__header {
  margin-bottom: 30px;
  position: relative
}

.tv-chart-view__header:after {
  clear: both;
  content: "";
  display: table
}

@media screen and (max-width: 767px) {
  .tv-chart-view__header {
      margin-bottom:0
  }
}

.tv-chart-view__title {
  direction: ltr;
  line-height: 1
}

.tv-chart-view__title:before {
  clear: both;
  content: "";
  display: table
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title {
      margin-bottom:20px
  }
}

.tv-chart-view__title-avatar,.tv-chart-view__title-avatar-img {
  display: block;
  height: 70px;
  width: 70px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title-avatar,.tv-chart-view__title-avatar-img {
      height:56px;
      width: 56px
  }
}

.tv-chart-view__title-avatar {
  float: left;
  margin-right: 15px
}

.tv-chart-view__title-avatar-img {
  border-radius: 3px
}

.tv-chart-view__title-wrap {
  padding-left: 90px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title-wrap {
      padding-left:71px
  }
}

.tv-chart-view__dialog .tv-chart-view__title-wrap {
  padding-right: 30px
}

.tv-chart-view__permission-block {
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 20px
}

.tv-chart-view__permission-block--public {
  background-color: #e3effd;
  border: 2px solid #0000
}

html.theme-dark .tv-chart-view__permission-block--public {
  background-color: #1e222d;
  border: 2px solid #1848cc
}

.tv-chart-view__permission-block--protected {
  background-color: #f0f3fa;
  border: 2px solid #0000
}

html.theme-dark .tv-chart-view__permission-block--protected {
  background-color: #1e222d;
  border: 2px solid #5d606b
}

.tv-chart-view__permission-block--invite-only-access-granted {
  background-color: #e0f2f1;
  border: 2px solid #0000
}

html.theme-dark .tv-chart-view__permission-block--invite-only-access-granted {
  background-color: #1e222d;
  border: 2px solid #004d40
}

.tv-chart-view__permission-block--invite-only-no-access {
  background-color: #ffebee;
  border: 2px solid #0000
}

html.theme-dark .tv-chart-view__permission-block--invite-only-no-access {
  background-color: #1e222d;
  border: 2px solid #d32f2f
}

.tv-chart-view__open-script {
  align-items: flex-start;
  display: flex
}

.tv-chart-view__private-script {
  display: flex;
  justify-content: space-between
}

.tv-chart-view__use-script {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px
}

.tv-chart-view__use-script-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 12px
}

.tv-chart-view__script-type {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 4px;
  margin-right: 5px
}

.tv-chart-view__script-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 16px
}

.tv-chart-view__script-description--margin-top {
  margin-top: 8px
}

.tv-chart-view__lib_copy {
  margin-top: -4px;
  min-width: 420px
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__lib_copy {
      min-width:auto
  }
}

.tv-chart-view__script-hidden {
  border: 0;
  display: none
}

.tv-chart-view__script-title {
  align-items: flex-start;
  display: flex
}

.tv-chart-view__title-row {
  position: relative
}

.tv-chart-view__title-row--name,.tv-chart-view__title-row--symbol {
  margin-bottom: 8px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title-row--name,.tv-chart-view__title-row--symbol {
      margin-bottom:6px
  }

  .tv-chart-view__title-row--symbol {
      display: none
  }
}

.tv-chart-view__title-row--user {
  align-items: center;
  display: flex;
  flex-wrap: wrap
}

.tv-chart-view__title-name-wrap {
  align-items: center;
  display: flex;
  max-width: 100%
}

.tv-chart-view__title-name {
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1;
  margin-right: 12px;
  overflow: hidden;
  vertical-align: middle;
  white-space: normal
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title-name {
      font-size:16px
  }

  .tv-chart-view__heart-icon {
      margin-top: -2px
  }
}

.tv-chart-view__title-icons {
  line-height: 22px;
  vertical-align: middle
}

.tv-chart-view__title-icons>* {
  margin-right: 12px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title-icons {
      line-height:16px
  }
}

.tv-chart-view__title-icon {
  color: #787b86;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  vertical-align: middle
}

html.theme-dark .tv-chart-view__title-icon {
  color: #787b86
}

.tv-chart-view__title-icon svg {
  display: block;
  height: 18px;
  width: 18px
}

.tv-chart-view__title-icon--private {
  margin-left: -2px
}

.tv-chart-view__title-icon--private svg {
  fill: #adaeb0
}

html.theme-dark .tv-chart-view__title-icon--private svg {
  fill: #4c525e
}

.tv-chart-view__title-user-link {
  align-items: center;
  display: inline-flex;
  line-height: 1.2;
  margin-right: 15px;
  max-width: 100%;
  overflow: hidden;
  vertical-align: middle
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title-user-link {
      margin-bottom:6px;
      margin-right: 0
  }
}

.tv-chart-view__title-user-name-wrap {
  font-size: 0;
  overflow: hidden;
  text-overflow: ellipsis
}

.tv-chart-view__title-user-name {
  display: inline-block;
  font-weight: 700;
  vertical-align: middle
}

.tv-chart-view__title-time {
  color: #787b86;
  display: inline-block;
  font-size: 13px;
  font-weight: 700
}

html.theme-dark .tv-chart-view__title-time {
  color: #787b86
}

@media screen and (max-width: 767px) {
  .tv-chart-view__title-time {
      flex-basis:100%
  }
}

.tv-chart-view__symbol {
  font-size: 14px;
  font-weight: 700
}

@media screen and (max-width: 767px) {
  .tv-chart-view__symbol {
      font-size:13px
  }
}

.tv-chart-view__symbol-link {
  color: #2962ff;
  display: inline-block;
  transition: color .35s ease
}

html.theme-dark .tv-chart-view__symbol-link {
  color: #2962ff
}

.tv-chart-view__symbol-link:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-chart-view__symbol-link:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__symbol-link:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-chart-view__symbol-link:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-chart-view__symbol-link:focus {
  outline: auto
}

.tv-chart-view__symbol-link:focus:not(:-moz-focusring) {
  outline: none
}

.tv-chart-view__symbol-link:-moz-focusring {
  outline: auto
}

.tv-chart-view__symbol-link:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-chart-view__symbol-link:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-chart-view__symbol-change,.tv-chart-view__symbol-change-pt,.tv-chart-view__symbol-last {
  contain: content;
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px
}

.tv-chart-view__symbol-last {
  margin: -2px 0;
  padding: 2px 3px;
  transition: background-color .11666667s ease,color .11666667s ease
}

.tv-chart-view__symbol-last.falling {
  background-color: #ff4a68;
  color: #fff;
  transition-duration: .05833333s
}

.tv-chart-view__symbol-last.growing {
  background-color: #3cbc98;
  color: #fff;
  transition-duration: .05833333s
}

.tv-chart-view__phone-symbol {
  display: none;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 20px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__phone-symbol {
      display:block
  }
}

.tv-chart-view__phone-symbol .tv-chart-view__symbol-ticker {
  display: block;
  margin-bottom: 4px;
  position: relative
}

.tv-chart-view__phone-symbol .tv-chart-view__symbol-ticker:last-child {
  margin-bottom: 0
}

.tv-chart-view__phone-symbol .tv-chart-view__symbol-last {
  font-size: 18px;
  margin: 0 0 0 -6px;
  padding: 0 6px
}

.tv-chart-view__phone-symbol .tv-chart-view__symbol-change,.tv-chart-view__phone-symbol .tv-chart-view__symbol-change-pt {
  font-size: 13px
}

.tv-chart-view__phone-symbol .tv-chart-view__symbol-link {
  font-weight: 700;
  margin-bottom: 1px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis
}

.tv-chart-view__phone-symbol .tv-chart-view__symbol-desc {
  color: #787b86;
  display: block;
  font-size: 13px
}

html.theme-dark .tv-chart-view__phone-symbol .tv-chart-view__symbol-desc {
  color: #787b86
}

.tv-chart-view__phone-symbol .tv-chart-view__control-icon {
  top: -1px
}

.tv-chart-view__control-icon {
  box-sizing: border-box;
  color: #758696;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  padding: 5px 10px;
  position: absolute;
  top: -7px;
  transition: color .35s ease;
  vertical-align: middle
}

.tv-chart-view__control-icon svg {
  fill: currentColor;
  position: relative
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__control-icon:hover {
      color:#465a6e;
      transition-duration: .06s
  }
}

@media screen and (max-width: 479px) {
  .tv-chart-view__control-icon--add-alert {
      display:none
  }
}

.tv-chart-view__actions {
  bottom: 0;
  position: absolute;
  right: 0;
  text-align: right
}

@media screen and (max-width: 767px) {
  .tv-chart-view__actions {
      position:static;
      text-align: left
  }
}

.tv-chart-view__actions-row {
  margin-top: 18px
}

.tv-chart-view__actions-row:first-child {
  margin-top: 0
}

.tv-chart-view__actions-row--mod:after,.tv-chart-view__actions-row--mod:before {
  bottom: -5px;
  content: "";
  display: block;
  position: absolute;
  top: -5px
}

.tv-chart-view__actions-row--mod:before {
  left: -5px;
  right: -5px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__actions-row--mod:before {
      content:none
  }
}

.tv-chart-view__actions-row--mod:after {
  background: #f8503200;
  left: -15px;
  width: 10px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__actions-row--mod:after {
      display:none
  }
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__actions-row {
      margin-top:13px
  }
}

@media screen and (max-width: 767px) {
  .tv-chart-view__actions-row {
      margin-top:0
  }

  .tv-chart-view__actions-row:last-child {
      margin-bottom: 10px
  }

  .tv-chart-view__actions-row .tv-button {
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 10px
  }
}

.tv-chart-view__dialog .tv-chart-view__actions-row--mod {
  margin-right: 40px
}

.tv-chart-view__dialog .tv-chart-view__dialog-close {
  top: 12px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__dialog .tv-chart-view__dialog-close {
      top:7px
  }
}

.tv-chart-view__editable-timer {
  color: #787b86;
  font-size: 11px;
  line-height: 12px;
  padding: 9px 0;
  position: absolute;
  right: 0;
  top: 100%
}

html.theme-dark .tv-chart-view__editable-timer {
  color: #787b86
}

.tv-chart-view__hidden-message {
  margin-bottom: 20px
}

.tv-chart-view__hidden-message a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-chart-view__hidden-message a {
  color: #2962ff
}

.tv-chart-view__hidden-message a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-chart-view__hidden-message a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__hidden-message a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-chart-view__hidden-message a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-chart-view__hidden-message a:focus {
  outline: auto
}

.tv-chart-view__hidden-message a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-chart-view__hidden-message a:-moz-focusring {
  outline: auto
}

.tv-chart-view__hidden-message a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-chart-view__hidden-message a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-chart-view__chart {
  height: 70vh;
  margin-bottom: 15px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  z-index: 0
}

@media screen and (max-width: 479px) {
  .tv-chart-view__chart {
      display:none
  }
}

.tv-chart-view__snapshot {
  border: 1px solid #e9eff2;
  display: none;
  margin-bottom: 15px;
  min-height: 170px;
  position: relative
}

@media screen and (max-width: 479px) {
  .tv-chart-view__snapshot {
      display:block
  }
}

.tv-chart-view__snapshot-img {
  margin-bottom: -4px;
  max-height: 100%;
  max-width: 100%
}

.tv-chart-view__video {
  background: #000;
  margin-bottom: 15px;
  max-height: 70vh;
  width: 100%
}

.tv-chart-view__video-mobile {
  margin-bottom: 0;
  max-height: 100vh
}

.tv-chart-view__chart-inner {
  border-radius: 3px;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .7s cubic-bezier(.215,.61,.355,1)
}

.tv-chart-view__chart.i-loading .tv-chart-view__chart-inner {
  opacity: 0
}

.tv-chart-view__info {
  display: flex;
  margin-bottom: 25px;
  width: 100%
}

@media screen and (max-width: 767px) {
  .tv-chart-view__info {
      display:block
  }
}

.tv-chart-view__info-area--tags {
  padding: 4px 20px 0 0
}

@media screen and (max-width: 767px) {
  .tv-chart-view__info-area--tags {
      display:none
  }
}

.tv-chart-view__info-area--views {
  color: #8797a5;
  display: none;
  padding: 5px 0 12px;
  text-align: right
}

@media screen and (max-width: 767px) {
  .tv-chart-view__info-area--views {
      display:block
  }
}

.tv-chart-view__description {
  word-wrap: break-word;
  color: #131722;
  font-size: 14px;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 12px
}

html.theme-dark .tv-chart-view__description {
  color: #d1d4dc
}

.tv-chart-view__description ol,.tv-chart-view__description p,.tv-chart-view__description ul {
  color: #131722;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 12px
}

html.theme-dark .tv-chart-view__description ol,html.theme-dark .tv-chart-view__description p,html.theme-dark .tv-chart-view__description ul {
  color: #d1d4dc
}

.tv-chart-view__description ol.tv-text__color_bright,.tv-chart-view__description p.tv-text__color_bright,.tv-chart-view__description ul.tv-text__color_bright {
  color: #fff
}

.tv-chart-view__description ol.tv-text__color_silver,.tv-chart-view__description p.tv-text__color_silver,.tv-chart-view__description ul.tv-text__color_silver {
  color: silver
}

.tv-chart-view__description ol.tv-text__color_silver_30,.tv-chart-view__description p.tv-text__color_silver_30,.tv-chart-view__description ul.tv-text__color_silver_30 {
  color: #9b9b9b
}

.tv-chart-view__description ol.tv-text__paragraph--additional-top-margin,.tv-chart-view__description p.tv-text__paragraph--additional-top-margin,.tv-chart-view__description ul.tv-text__paragraph--additional-top-margin {
  margin-top: 24px
}

.tv-chart-view__description ol.tv-text__paragraph--additional-top-margin_double,.tv-chart-view__description p.tv-text__paragraph--additional-top-margin_double,.tv-chart-view__description ul.tv-text__paragraph--additional-top-margin_double {
  margin-top: 48px
}

.tv-chart-view__description ol.tv-text__paragraph--additional-bottom-margin,.tv-chart-view__description p.tv-text__paragraph--additional-bottom-margin,.tv-chart-view__description ul.tv-text__paragraph--additional-bottom-margin {
  margin-bottom: 24px
}

.tv-chart-view__description ol.tv-text__paragraph--additional-bottom-margin_double,.tv-chart-view__description p.tv-text__paragraph--additional-bottom-margin_double,.tv-chart-view__description ul.tv-text__paragraph--additional-bottom-margin_double {
  margin-bottom: 48px
}

.tv-chart-view__description ol .tv-text--highlight-important,.tv-chart-view__description p .tv-text--highlight-important,.tv-chart-view__description ul .tv-text--highlight-important {
  background: #fff3e0;
  box-shadow: 0 0 0 .37em #fff3e0
}

html.theme-dark .tv-chart-view__description ol .tv-text--highlight-important,html.theme-dark .tv-chart-view__description p .tv-text--highlight-important,html.theme-dark .tv-chart-view__description ul .tv-text--highlight-important {
  background: #142e61;
  box-shadow: 0 0 0 .37em #142e61
}

.tv-chart-view__description ol .tv-text--highlight-important:empty,.tv-chart-view__description p .tv-text--highlight-important:empty,.tv-chart-view__description ul .tv-text--highlight-important:empty {
  background: #0000;
  box-shadow: none
}

.tv-chart-view__description h1 {
  font-size: 45px;
  margin-bottom: 30px;
  margin-top: 30px
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__description h1 {
      font-size:38px
  }
}

@media screen and (max-width: 767px) {
  .tv-chart-view__description h1 {
      font-size:32px
  }
}

@media screen and (max-width: 479px) {
  .tv-chart-view__description h1 {
      font-size:28px
  }
}

.tv-chart-view__description h2 {
  font-size: 31px
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__description h2 {
      font-size:26px
  }
}

@media screen and (max-width: 479px) {
  .tv-chart-view__description h2 {
      font-size:24px
  }
}

.tv-chart-view__description h3 {
  font-size: 17px
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__description h3 {
      font-size:16px
  }
}

.tv-chart-view__description h4 {
  font-size: 15px
}

.tv-chart-view__description h2,.tv-chart-view__description h3,.tv-chart-view__description h4 {
  margin-bottom: 20px;
  margin-top: 20px
}

.tv-chart-view__description h1:first-child,.tv-chart-view__description h2:first-child,.tv-chart-view__description h3:first-child,.tv-chart-view__description h4:first-child {
  margin-top: 0
}

.tv-chart-view__description ol,.tv-chart-view__description ul {
  list-style-position: inside
}

.tv-chart-view__description--position-outside ol,.tv-chart-view__description--position-outside ul {
  list-style-position: outside;
  padding-left: 17px
}

.tv-chart-view__description a:not(.tv-badge):not(.tv-button) {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-chart-view__description a:not(.tv-badge):not(.tv-button) {
  color: #2962ff
}

.tv-chart-view__description a:not(.tv-badge):not(.tv-button):visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-chart-view__description a:not(.tv-badge):not(.tv-button):visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__description a:not(.tv-badge):not(.tv-button):hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-chart-view__description a:not(.tv-badge):not(.tv-button):hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-chart-view__description a:not(.tv-badge):not(.tv-button):focus {
  outline: auto
}

.tv-chart-view__description a:not(.tv-badge):not(.tv-button):focus:not(:-moz-focusring) {
  outline: none
}

.tv-chart-view__description a:not(.tv-badge):not(.tv-button):-moz-focusring {
  outline: auto
}

.tv-chart-view__description a:not(.tv-badge):not(.tv-button):active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-chart-view__description a:not(.tv-badge):not(.tv-button):active {
  fill: #1848cc;
  color: #1848cc
}

.tv-chart-view__description__font.tv-chart-view__description__font--size_semilarge {
  font-size: 15px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__description__font.tv-chart-view__description__font--size_semilarge {
      font-size:14px
  }
}

.tv-chart-view__description__font.tv-chart-view__description__font--size_large {
  font-size: 17px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__description__font.tv-chart-view__description__font--size_large {
      font-size:16px
  }
}

.tv-chart-view__description__font.tv-chart-view__description__font--size_xlarge {
  font-size: 19px
}

@media screen and (max-width: 767px) {
  .tv-chart-view__description__font.tv-chart-view__description__font--size_xlarge {
      font-size:18px
  }
}

.tv-chart-view__description__font--bold {
  font-weight: 700
}

.tv-chart-view__description__font--italic {
  font-style: italic
}

.tv-chart-view__description--darkbg,.tv-chart-view__description--darkbg ol,.tv-chart-view__description--darkbg p,.tv-chart-view__description--darkbg ul {
  color: #fff
}

.tv-chart-view__description--highlight-important {
  background: #fff3e0;
  box-shadow: 0 0 0 .07em #fff3e0
}

html.theme-dark .tv-chart-view__description--highlight-important {
  background: #142e61;
  box-shadow: 0 0 0 .07em #142e61
}

.tv-chart-view__description--highlight-important:empty {
  background: #0000;
  box-shadow: none
}

@media screen and (max-width: 767px) {
  .tv-chart-view__description {
      font-size:14px
  }
}

.tv-chart-view__description a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-chart-view__description a {
  color: #2962ff
}

.tv-chart-view__description a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-chart-view__description a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__description a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-chart-view__description a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-chart-view__description a:focus {
  outline: auto
}

.tv-chart-view__description a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-chart-view__description a:-moz-focusring {
  outline: auto
}

.tv-chart-view__description a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-chart-view__description a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-chart-view__social-links {
  border-color: currentcolor #e0e3eb #e0e3eb;
  border-top: 1px solid #e0e3eb;
  margin-bottom: 25px;
  padding-top: 16px
}

html.theme-dark .tv-chart-view__social-links {
  border-color: #434651
}

.tv-chart-view__signature {
  border-color: currentcolor #e0e3eb #e0e3eb;
  border-top: 1px solid #e0e3eb;
  margin: -9px 0 16px;
  padding-top: 16px;
  word-break: break-word
}

html.theme-dark .tv-chart-view__signature {
  border-color: #434651
}

.tv-chart-view__signature a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-chart-view__signature a {
  color: #2962ff
}

.tv-chart-view__signature a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-chart-view__signature a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__signature a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-chart-view__signature a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-chart-view__signature a:focus {
  outline: auto
}

.tv-chart-view__signature a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-chart-view__signature a:-moz-focusring {
  outline: auto
}

.tv-chart-view__signature a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-chart-view__signature a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-chart-view__signature--blocked>div {
  display: inline-block;
  vertical-align: middle;
  width: 50%
}

.tv-chart-view__signature--blocked>div:last-child {
  text-align: right
}

.tv-chart-view__signature--blocked>div:last-child>div {
  display: inline-block
}

.tv-chart-view__signature--blocked .block_message__title {
  padding-bottom: 10px;
  text-align: left;
  text-transform: uppercase
}

.tv-chart-view__signature--blocked .block_message__description {
  text-align: left
}

.tv-chart-view__phone-tags {
  display: none;
  margin-bottom: 25px
}

.tv-chart-view__phone-tags:after {
  clear: both;
  content: "";
  display: table
}

@media screen and (max-width: 767px) {
  .tv-chart-view__phone-tags {
      display:block
  }
}

.tv-chart-view__tag-page-link {
  border-bottom: 1px dashed;
  border-color: #434651;
  color: #434651;
  text-decoration: none
}

html.theme-dark .tv-chart-view__tag-page-link {
  border-color: #d1d4dc;
  color: #d1d4dc
}

.tv-chart-view__tag-page-link:active {
  border-bottom: 1px dashed;
  border-color: #262b3e;
  color: #262b3e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__tag-page-link:hover {
      border-bottom:1px dashed;
      border-color: #262b3e;
      color: #262b3e
  }
}

html.theme-dark .tv-chart-view__tag-page-link:active {
  border-color: #e1ecf2
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-chart-view__tag-page-link:hover {
      border-color:#e1ecf2
  }
}

html.theme-dark .tv-chart-view__tag-page-link:active {
  color: #e1ecf2
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-chart-view__tag-page-link:hover {
      color:#e1ecf2
  }
}

.tv-chart-view__description-wrap {
  margin-bottom: 25px
}

.tv-chart-view__strategy {
  margin-bottom: 25px;
  overflow: auto;
  position: relative;
  width: 100%
}

.tv-chart-view__strategy-scroll-box {
  box-sizing: border-box;
  display: table;
  min-width: 800px;
  width: 100%
}

.tv-chart-view__strategy-wrap {
  box-sizing: border-box;
  height: 400px;
  position: relative;
  transition: opacity .7s cubic-bezier(.215,.61,.355,1)
}

.tv-chart-view__script {
  margin-bottom: 25px;
  position: relative
}

.tv-chart-view__script-wrap {
  padding: 20px 0;
  transition: opacity .7s cubic-bezier(.215,.61,.355,1)
}

.tv-chart-view__script,.tv-chart-view__strategy {
  border: 1px solid #eceff2;
  border-radius: 2px;
  transition: opacity .7s cubic-bezier(.215,.61,.355,1),border-color .7s cubic-bezier(.215,.61,.355,1)
}

html.theme-dark .tv-chart-view__script,html.theme-dark .tv-chart-view__strategy {
  border-color: #363c4e
}

.tv-chart-view__script.i-loading {
  height: 80px
}

.tv-chart-view__strategy.i-loading {
  border-color: #fff
}

.tv-chart-view__script.i-loading .tv-chart-view__script-wrap,.tv-chart-view__strategy.i-loading .tv-chart-view__strategy-wrap {
  opacity: 0;
  pointer-events: none
}

.tv-chart-view__closed-source-notice {
  display: none
}

.tv-chart-view__closed-source-notice.is-not-author {
  padding-bottom: 0
}

.tv-chart-view__closed-source-notice.closed-source {
  display: table
}

@media screen and (max-width: 767px) {
  .tv-chart-view__closed-source-notice {
      margin-top:0
  }
}

.tv-chart-view__closed-source-notice-icon {
  fill: #434651;
  display: table-cell;
  position: relative;
  top: 1px
}

html.theme-dark .tv-chart-view__closed-source-notice-icon {
  fill: #b2b5be
}

.tv-chart-view__closed-source-notice-icon>svg {
  height: 15px;
  width: 15px
}

.tv-chart-view__closed-source-notice-text {
  color: #434651;
  display: table-cell;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  padding-left: 10px
}

html.theme-dark .tv-chart-view__closed-source-notice-text {
  color: #b2b5be
}

.tv-chart-view__script-editor {
  background-color: #fff;
  line-height: 1.4;
  min-height: 10px
}

html.theme-dark .tv-chart-view__script-editor {
  background-color: #1c2030
}

.tv-chart-view__script-editor.closed-source.is-not-author {
  display: none
}

.tv-chart-view__script-editor.tv-chart-view__script-editor .ace_gutter {
  background-color: #fff;
  color: #adaeb0
}

html.theme-dark .tv-chart-view__script-editor.tv-chart-view__script-editor .ace_gutter {
  background-color: #1e222d;
  color: #4c525e
}

.tv-chart-view__script-actions {
  display: flex;
  right: 20px;
  top: 20px;
  -webkit-user-select: none;
  user-select: none;
  z-index: 9
}

@media screen and (max-width: 1019px) {
  .tv-chart-view__script-actions {
      display:block;
      position: static
  }
}

.tv-chart-view__script-actions-text {
  height: 100%;
  margin: auto 12px auto 0
}

.tv-chart-view__script-source {
  font-size: 13px;
  line-height: 17px;
  margin: 0
}

.tv-chart-view__script-updateinfo {
  color: #adaeb0;
  margin-bottom: 25px
}

html.theme-dark .tv-chart-view__script-updateinfo {
  color: #4c525e
}

.tv-chart-view__script-updateinfo a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .tv-chart-view__script-updateinfo a {
  color: #2962ff
}

.tv-chart-view__script-updateinfo a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-chart-view__script-updateinfo a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__script-updateinfo a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .tv-chart-view__script-updateinfo a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-chart-view__script-updateinfo a:focus {
  outline: auto
}

.tv-chart-view__script-updateinfo a:focus:not(:-moz-focusring) {
  outline: none
}

.tv-chart-view__script-updateinfo a:-moz-focusring {
  outline: auto
}

.tv-chart-view__script-updateinfo a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .tv-chart-view__script-updateinfo a:active {
  fill: #1848cc;
  color: #1848cc
}

.tv-chart-view__share-tumbs {
  margin: 0 -5px
}

.tv-chart-view__share-tumb-wrap {
  box-sizing: border-box;
  float: left;
  padding: 0 5px;
  width: 20%
}

@media screen and (max-width: 479px) {
  .tv-chart-view__share-tumb-wrap {
      margin-bottom:10px;
      width: 50%
  }

  .tv-chart-view__share-tumb-wrap--custom {
      margin-bottom: 0;
      width: 100%
  }
}

.tv-chart-view__share-tumb {
  border: 1px dashed #e0e3eb;
  border-radius: 2px;
  color: #adaeb0;
  cursor: pointer;
  display: block;
  height: 75px;
  line-height: 75px;
  position: relative;
  text-align: center;
  transition: border .35s ease,color .35s ease,background-color .35s ease;
  -webkit-user-select: none;
  user-select: none
}

html.theme-dark .tv-chart-view__share-tumb {
  border-color: #363a45;
  color: #4c525e
}

.tv-chart-view__share-tumb.i-active {
  background-color: #2962ff0a;
  border-color: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__share-tumb:hover {
      background-color:#2962ff0a;
      border-color: #2962ff;
      color: #2962ff
  }
}

.tv-chart-view__share-tumb.i-active {
  background-color: #2962ff12;
  border-style: solid
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__share-tumb:hover {
      transition-duration:.06s
  }
}

.tv-chart-view__share-custom-form {
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  line-height: 1;
  opacity: 0;
  padding: 7px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .35s cubic-bezier(.215,.61,.355,1)
}

.tv-chart-view__share-tumb.i-active .tv-chart-view__share-custom-form {
  opacity: 1;
  pointer-events: auto
}

.tv-chart-view__share-custom-label {
  transition: opacity .35s cubic-bezier(.215,.61,.355,1)
}

.tv-chart-view__share-tumb.i-active .tv-chart-view__share-custom-label {
  opacity: 0;
  transition-duration: .06s
}

.tv-chart-view__share-input {
  background-color: initial;
  border: 1px solid #2962ff66;
  border-radius: 2px;
  box-sizing: border-box;
  color: #535353;
  display: block;
  font-size: 13px;
  margin-bottom: 7px;
  padding: 5px;
  text-align: center;
  width: 100%
}

.tv-chart-view__share-input:last-child {
  margin-bottom: 0
}

.tv-chart-view__share-btns:after {
  clear: both;
  content: "";
  display: table
}

.tv-chart-view__share-btn {
  background-color: #9db2bd;
  border-radius: 2px;
  cursor: pointer;
  float: left;
  height: 40px;
  line-height: 40px;
  margin-right: 25px;
  text-align: center;
  transition: background-color .35s ease;
  -webkit-user-select: none;
  user-select: none;
  width: 40px
}

html.theme-dark .tv-chart-view__share-btn {
  background-color: #2f3241
}

.tv-chart-view__share-btn:last-child {
  margin-right: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-chart-view__share-btn:hover {
      background-color:#94959c;
      transition-duration: .06s
  }

  html.theme-dark .tv-chart-view__share-btn:hover {
      background-color: #363c4e
  }
}

.tv-chart-view__share-btn:active {
  background-color: #878890;
  transform: translateY(1px);
  transition-duration: .06s
}

html.theme-dark .tv-chart-view__share-btn:active {
  background-color: #313747
}

.tv-chart-view__share-btn svg {
  display: inline-block;
  vertical-align: middle
}

.tv-chart-view__share-btn svg path {
  fill: #fff
}

.tv-chart-view__share-btn--ns {
  color: #fff
}

.tv-chart-view__widget .tv-layout-width {
  min-width: auto;
  width: auto
}

.tv-chart-view__widget .tv-layout-width,.tv-chart-view__widget .tv-page-content {
  padding: 0
}

.is-not-author .tv-chart-view__manage-access {
  display: none
}

.chart-page .tv-chart-view .chart-container,.chart-page .tv-chart-view .chart-container.multiple,.chart-page .tv-chart-view .chart-container.multiple.active,.tv-chart-view .chart-container,.tv-chart-view .chart-container.multiple,.tv-chart-view .chart-container.multiple.active {
  background: #fff;
  border: 1px solid #e0e3eb;
  box-sizing: border-box
}

html.theme-dark .chart-page .tv-chart-view .chart-container,html.theme-dark .chart-page .tv-chart-view .chart-container.multiple,html.theme-dark .chart-page .tv-chart-view .chart-container.multiple.active,html.theme-dark .tv-chart-view .chart-container,html.theme-dark .tv-chart-view .chart-container.multiple,html.theme-dark .tv-chart-view .chart-container.multiple.active {
  background: #131722;
  border-color: #363a45
}

@keyframes highlight-animation {
  0% {
      background: #0000
  }

  to {
      background: #fff2cf
  }
}

@keyframes highlight-animation-theme-dark {
  0% {
      background: #0000
  }

  to {
      background: #123440
  }
}

.widgetbar-wrap {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.widgetbar-place .widgetbar-wrap {
  position: fixed;
  z-index: 15
}

.widgetbar-hider {
  bottom: 58px;
  display: block;
  left: -3px;
  position: absolute;
  z-index: 10
}

.widgetbar-hider--closed {
  left: -4px
}

.widgetbar-handle {
  background: #0000;
  cursor: ew-resize;
  height: 100%;
  left: -3px;
  position: absolute;
  top: 0;
  touch-action: none;
  width: 5px
}

html.feature-mobiletouch .widgetbar-handle {
  left: -8px;
  width: 15px
}

.widgetbar-tabscontent {
  position: relative;
  width: 45px
}

.widgetbar-tabscontent-wrap {
  direction: ltr;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  margin-right: -256px;
  overflow: auto
}

.widgetbar-tabs {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 45px
}

.widgetbar-tabscontrols {
  margin-top: auto;
  width: 45px
}

.widgetbar-tabscontrols-button {
  cursor: pointer;
  height: 34px;
  margin: auto;
  width: 34px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .widgetbar-tabscontrols-button:hover {
      background:#eee
  }
}

.widgetbar-tab {
  background-color: #fff;
  border-bottom: 1px solid #e0e3eb;
  cursor: pointer;
  padding: 9px 0;
  position: relative
}

html.theme-dark .widgetbar-tab {
  background-color: #131722;
  border-bottom-color: #363a45
}

.widgetbar-tab.active {
  background: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .widgetbar-tab:hover {
      background:#fff
  }
}

html.theme-dark .widgetbar-tab.active {
  background: #1e222d
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .widgetbar-tab:hover {
      background:#1e222d
  }
}

.widgetbar-tab.active .widgetbar-icon {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .widgetbar-tab.active .widgetbar-icon {
  fill: #2962ff;
  color: #2962ff
}

.widgetbar-tab-spacer {
  border-bottom: 1px solid #e0e3eb;
  color: #434651;
  cursor: default;
  font-size: 10px;
  height: 28px;
  line-height: 29px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap
}

html.theme-dark .widgetbar-tab-spacer {
  border-bottom-color: #363a45;
  color: #d1d4dc
}

.widgetbar-iconplace {
  display: block;
  margin: 1px;
  padding: 6px 0;
  position: relative
}

.widgetbar-icon {
  fill: #787b86;
  color: #787b86;
  display: block;
  margin: 0 auto
}

html.theme-dark .widgetbar-icon {
  fill: #787b86;
  color: #787b86
}

.widgetbar-chart-helpbutton .widgetbar-icon {
  height: 30px;
  width: 30px
}

.widgetpage-icon-svg-base {
  height: 22px;
  width: 27px
}

.widgetpage-icon-svg-chat {
  width: 23px
}

.widgetpage-icon-svg-calendar,.widgetpage-icon-svg-hotlists {
  height: 23px
}

.widgetpage-icon-svg-datawindow,.widgetpage-icon-svg-messages {
  width: 23px
}

.widgetpage-icon-svg-notes {
  height: 26px;
  width: 26px
}

.widgetbar-notification-wrap {
  bottom: -1px;
  display: none;
  position: absolute;
  text-align: right;
  width: 100%
}

.widgetbar-notification {
  background: #e0745f;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  min-width: 9px;
  padding: 0 3px;
  text-align: center
}

.widgetbar-pages {
  background-color: #fff;
  border-bottom-color: #e0e3eb;
  border-left: 1px solid #e0e3eb;
  border-right: 1px solid #e0e3eb;
  border-top-color: #e0e3eb;
  height: 100%;
  position: absolute;
  right: 45px;
  top: 0
}

html.theme-dark .widgetbar-pages {
  background-color: #131722;
  border-color: #2a2e39
}

.widgetbar-pages.hidden {
  border-right: none!important
}

.widgetbar-pages.hidden .widgetbar-pagescontent {
  display: none
}

.widgetbar-pagescontent {
  height: 100%;
  overflow: hidden
}

.widgetbar-pages.layered {
  box-shadow: 0 0 10px #00000026
}

.widgetbar-page {
  display: none;
  height: 100%
}

.widgetbar-page.active {
  display: block
}

.widgetbar-widget {
  background-color: #fff;
  position: relative
}

html.theme-dark .widgetbar-widget {
  background-color: #131722
}

.widgetbar-widget.widgetbar-widget-alerts_log,.widgetbar-widget.widgetbar-widget-alerts_manage {
  --tv-color-alert-item-fill: #fff
}

html.theme-dark .widgetbar-widget.widgetbar-widget-alerts_log,html.theme-dark .widgetbar-widget.widgetbar-widget-alerts_manage {
  --tv-color-alert-item-fill: #131722
}

.widgetbar-widgetheader {
  background: #f0f3fa;
  box-sizing: border-box;
  contain: strict;
  cursor: default;
  height: 34px;
  margin: 3px
}

html.theme-dark .widgetbar-widgetheader {
  background: #2a2e39
}

.widgetbar-headerspace {
  float: right;
  overflow: hidden;
  padding: 2px 2px 0 0;
  position: relative
}

.widgetbar-widgetheader-icon {
  float: left;
  margin: 7px 0 0 10px
}

.widgetbar-widgetbody {
  contain: strict;
  overflow: hidden;
  position: relative
}

.widgetbar-widgetbody .widgetbody-overlay {
  background: #fffc;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5
}

.widgetbar-widgettitle {
  color: #4f5966;
  font-size: 13px;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 10px 0 9px 11px;
  white-space: nowrap
}

html.theme-dark .widgetbar-widgettitle {
  color: #758696
}

.widgetbar-widgettitle {
  overflow: hidden;
  text-overflow: ellipsis
}

.widgetbar-widgettitle-info {
  color: #787b86;
  display: flex;
  margin-right: 8px
}

.widgetbar-widgettitle-poweredby {
  font-size: 11px;
  font-weight: 400
}

.widgetbar-widgettitle-poweredby a {
  color: #2962ff;
  transition: color .35s ease
}

html.theme-dark .widgetbar-widgettitle-poweredby a {
  color: #2962ff
}

.widgetbar-widgettitle-poweredby a:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .widgetbar-widgettitle-poweredby a:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .widgetbar-widgettitle-poweredby a:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: .06s
  }

  html.theme-dark .widgetbar-widgettitle-poweredby a:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.widgetbar-widgettitle-poweredby a:focus {
  outline: auto
}

.widgetbar-widgettitle-poweredby a:focus:not(:-moz-focusring) {
  outline: none
}

.widgetbar-widgettitle-poweredby a:-moz-focusring {
  outline: auto
}

.widgetbar-widgettitle-poweredby a:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: .06s
}

html.theme-dark .widgetbar-widgettitle-poweredby a:active {
  fill: #1848cc;
  color: #1848cc
}

.widgetbar-widgethandle {
  background: #0000;
  display: none;
  height: 5px;
  left: 0;
  position: absolute;
  top: -3px;
  touch-action: none;
  width: 100%
}

html.feature-mobiletouch .widgetbar-widgethandle {
  height: 15px;
  top: -8px
}

.widgetbar-page.widget-resize-mode,.widgetbar-page.widget-resize-mode *,.widgetbar-widgethandle {
  cursor: ns-resize
}

.widgetbar-widget+.widgetbar-widget .widgetbar-widgethandle {
  display: block
}

.widgetbar-headerspace .button {
  fill: currentColor;
  background-color: #fff;
  border: 1px solid #e0e3eb;
  box-sizing: border-box;
  color: #787b86;
  display: block;
  float: left;
  font-size: 12px;
  height: 30px;
  line-height: 15px;
  margin-left: 4px;
  padding-top: 5px;
  position: relative;
  text-align: center;
  width: 39px
}

html.theme-dark .widgetbar-headerspace .button {
  background-color: #131722;
  border-color: #363a45;
  color: #787b86
}

.widgetbar-headerspace .button.active,html.theme-dark .widgetbar-headerspace .button.active {
  color: #2962ff
}

.widgetbar-headerspace .button.active,.widgetbar-headerspace .button:active {
  background-color: #f7f8fa
}

html.theme-dark .widgetbar-headerspace .button.active,html.theme-dark .widgetbar-headerspace .button:active {
  background-color: #363c4e
}

.widgetbar-headerspace .button.button-half {
  width: 20px
}

.widgetbar-headerspace .button.button-half.button-nomarginleft {
  border-left-width: 0;
  margin-left: 0
}

.widgetbar-headerspace .button.i-loading {
  background-color: #2962ff;
  border-color: #2962ff;
  pointer-events: none
}

html.theme-dark .widgetbar-headerspace .button.i-loading {
  background-color: #2962ff;
  border-color: #2962ff
}

.widgetbar-headerspace .button.i-loading:after {
  animation-duration: 1.05s;
  animation-iteration-count: infinite;
  animation-name: tv-pulse-animation-button;
  animation-timing-function: cubic-bezier(.33,0,.11,1);
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 10px;
  left: 50%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 50%;
  width: 10px
}

html.theme-dark .widgetbar-headerspace .button.i-loading:after {
  border: 1px solid #d1d4dc
}

.widgetbar-headerspace .button.i-loading svg {
  opacity: 0
}

.widgetbar-headerspace .button.detach-chat {
  margin-left: 4px
}

.widgetbar-headerspace .button.with-arrow:after {
  content: " ";
  display: inline-block;
  height: 5px;
  margin: 0 0 0 4px;
  vertical-align: middle;
  width: 9px
}

.widgetbar-headerspace .button.svg-detach svg {
  margin: 4px 0 2px
}

.widgetbar-headerspace .button.alert-sort-button {
  padding-top: 6px
}

.widgetbar-widget-messages .input-reset:before {
  margin-top: -6px
}

.widgetbar-tabscontrols .widgetbar-bug-report-button,.widgetbar-tabscontrols .widgetbar-chart-helpbutton {
  cursor: pointer;
  height: 30px;
  margin: 5px auto;
  width: 30px
}

.widgetbar-tabscontrols .widgetbar-bug-report-button svg,.widgetbar-tabscontrols .widgetbar-chart-helpbutton svg {
  fill: #787b86;
  display: block;
  height: 100%;
  width: 100%
}

html.theme-dark .widgetbar-tabscontrols .widgetbar-bug-report-button svg,html.theme-dark .widgetbar-tabscontrols .widgetbar-chart-helpbutton svg {
  fill: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .widgetbar-tabscontrols .widgetbar-bug-report-button:hover svg,.widgetbar-tabscontrols .widgetbar-chart-helpbutton:hover svg {
      fill:#434651
  }

  html.theme-dark .widgetbar-tabscontrols .widgetbar-bug-report-button:hover svg,html.theme-dark .widgetbar-tabscontrols .widgetbar-chart-helpbutton:hover svg {
      fill: #d1d4dc
  }
}

.widgetbar-tabscontrols .widgetbar-bug-report-button.active svg,.widgetbar-tabscontrols .widgetbar-bug-report-button:active svg,.widgetbar-tabscontrols .widgetbar-chart-helpbutton.active svg,.widgetbar-tabscontrols .widgetbar-chart-helpbutton:active svg,html.theme-dark .widgetbar-tabscontrols .widgetbar-bug-report-button.active svg,html.theme-dark .widgetbar-tabscontrols .widgetbar-bug-report-button:active svg,html.theme-dark .widgetbar-tabscontrols .widgetbar-chart-helpbutton.active svg,html.theme-dark .widgetbar-tabscontrols .widgetbar-chart-helpbutton:active svg {
  fill: #2962ff
}

.widgetbar-tabscontrols .widgetbar-bug-report-button {
  margin-bottom: 7px
}

.horizontal {
  height: 100%
}

.horizontal .widgetbar-wrap {
  position: relative;
  width: 100%
}

.horizontal .widgetbar-pages {
  border: none;
  bottom: 0;
  left: 0;
  right: 0;
  top: 45px;
  width: 100%
}

.horizontal .widgetbar-tabs {
  height: auto;
  width: 100%
}

.horizontal .widgetbar-tabs .widgetbar-tab-spacer {
  display: inline-block;
  float: left;
  height: 45px;
  line-height: 46px;
  margin: 0;
  padding: 0 6px
}

.horizontal .widgetbar-tabs .widgetbar-tab {
  border-bottom: none;
  display: inline-block;
  height: 45px;
  padding: 0;
  width: 36px
}

.horizontal .widgetbar-tabs .widgetbar-tab .widgetbar-iconplace {
  padding-top: 11px
}

.extension .widgetbar-tabscontent-wrap {
  margin: 0
}

.extension .widgetbar-tabscontent {
  width: auto
}

.extension .widgetbar-tabs {
  background: #2e3540
}

.extension .widgetbar-tabs .widgetbar-tab-spacer {
  display: none
}

.extension .widgetbar-tabs .widgetbar-tab {
  background: #0000;
  box-sizing: border-box;
  width: 25%
}

.extension .widgetbar-tabs .widgetbar-tab.active {
  background: #0000
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .extension .widgetbar-tabs .widgetbar-tab:hover {
      background:#0000
  }
}

.extension .widgetbar-tabs .widgetbar-tab:last-child {
  width: 24%
}

.extension .widgetbar-widget {
  margin: 0
}

.extension .widgetbar-headerspace {
  padding: 0
}

.extension .widgetbar-widgetheader {
  contain: none;
  margin: 0
}

.extension .ch-input .wrap,.extension .msg-window-body.chat-page .ch-input .wrap,.extension .tv-messages-header {
  padding: 0
}

.extension .msg-data,.extension .pc-data {
  margin: 0
}

.extension .symbol-list-item {
  margin-left: 0;
  margin-right: 0
}

.extension .widgetbar-widgetheader .standalone-chat-select {
  height: 34px
}

.extension .widgetbar-widgetheader .standalone-chat-select:after {
  top: 15px
}

.extension .widgetbar-widgetheader .standalone-chat-list {
  left: 0
}

.extension .widgetbar-widgetheader .button {
  background: #0000;
  border: none;
  height: 34px;
  line-height: 33px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 30px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .extension .widgetbar-widgetheader .button:hover {
      background:#0000;
      border: none
  }
}

.extension .widgetbar-widgetheader .button .icon {
  display: inline-block
}

.extension .widgetbar-widgetheader .button .icon-plus {
  background-position: -180px -162px;
  height: 14px;
  width: 14px
}

.extension .widgetbar-widgetheader .button svg {
  margin-top: 9px
}

.extension .widgetbar-widgetheader .msg-empty {
  line-height: 34px
}

.extension .widgetbar-widgetheader .wl-symbol-edit {
  background: #0000;
  border: none;
  font-weight: 400;
  height: 34px;
  line-height: 34px;
  text-transform: none
}

.extension .widgetbar-widgetheader .wl-symbol-edit:focus {
  background: #fff9
}

.widgetbar-devices-close-btn {
  display: block;
  height: 14px;
  left: 0;
  padding: 15px 0;
  position: absolute;
  top: 0;
  width: 45px
}

.widgetbar-devices-close-btn svg {
  fill: #7d7d7d;
  display: block;
  height: 14px;
  margin: 0 auto;
  width: 14px
}

.widgetbar-devices-close-btn-placeholder {
  height: 45px
}

.keyboard-help .columns+.columns {
  margin-top: 17px
}

.keyboard-help:after,.keyboard-help>.column:nth-child(2n):after {
  clear: both;
  content: "";
  display: block
}

.keyboard-help .columns {
  font-size: 13px
}

.keyboard-help .columns:after {
  clear: both;
  content: "";
  display: table
}

.keyboard-help .columns .column {
  box-sizing: border-box;
  float: left;
  width: 50%
}

.keyboard-help .columns .column:nth-child(odd) {
  padding-right: 10px
}

.keyboard-help .columns .column:nth-child(2n) {
  padding-left: 10px
}

.keyboard-help .columns .column:first-child {
  padding-right: 30px
}

.keyboard-help h4 {
  color: #8a8a8a;
  font-weight: 400;
  margin: 20px 0 15px;
  text-transform: uppercase
}

.keyboard-help h4:first-child {
  margin-top: 0
}

.keyboard-help p {
  display: flex;
  line-height: 1.85;
  margin: 10px 0
}

@media screen and (max-width: 767px) {
  .keyboard-help p {
      flex-direction:column
  }
}

.keyboard-help .value {
  align-content: flex-end;
  color: #8a8a8a;
  flex: 1 0 auto;
  margin-left: auto;
  text-align: right
}

@media screen and (max-width: 767px) {
  .keyboard-help .value {
      margin-left:0;
      text-align: left
  }
}

.keyboard-help .descr {
  display: inline-block;
  flex: 0 1 auto;
  margin-right: 3px;
  max-width: 230px
}

.keyboard-help kbd {
  border: 1px solid #8a8a8a;
  border-radius: 3px;
  font-size: 11px;
  padding: 4px 9px
}

.widgetbar-widget.widget-1quyc-Kt {
  border-top: 4px solid #e0e3eb;
  margin-bottom: 0
}

html.theme-dark .widgetbar-widget.widget-1quyc-Kt {
  border-top-color: #2a2e39
}

.widgetbar-widget.widget-1quyc-Kt:first-child {
  border-top: none
}

.widgetbar-widget.widget-1quyc-Kt:last-child {
  box-shadow: none
}

.widgetHeader-1quyc-Kt {
  align-items: center;
  cursor: default;
  display: flex;
  height: 48px;
  margin: 0;
  padding: 0 16px
}

.widgetHeader-1quyc-Kt .widgetTitle-1quyc-Kt {
  color: #131722;
  font-size: 14px;
  font-weight: 700;
  height: auto;
  line-height: 28px;
  white-space: nowrap
}

html.theme-dark .widgetHeader-1quyc-Kt .widgetTitle-1quyc-Kt {
  color: #b2b5be
}

.widgetHeader-1quyc-Kt .widgetTitle-1quyc-Kt.widgetbar-widgettitle {
  padding-left: 0
}

.widgetHeader-1quyc-Kt .headerSpace-1quyc-Kt {
  margin-left: auto
}

.widgetbarButton-UlhSTSo_ {
  align-items: center;
  background-color: #2962ff;
  border-radius: 50%;
  bottom: 25px;
  box-shadow: 0 2px 5px 0 #0003;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 56px;
  justify-content: center;
  position: fixed;
  right: 25px;
  transition: background-color .35s ease;
  width: 56px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .widgetbarButton-UlhSTSo_:hover {
      background-color:#1e53e5
  }
}

.button-2OmDro4I {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 45px;
  justify-content: center;
  width: 100%
}

.button-2OmDro4I .icon-2OmDro4I {
  display: flex;
  flex: 0 0 auto
}

.wrap-379NmUSU {
  direction: ltr;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

.wrap-379NmUSU svg {
  display: block
}

.scrollWrap-379NmUSU {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%
}

.scrollWrap-379NmUSU::-webkit-scrollbar {
  height: 5px;
  width: 5px
}

.scrollWrap-379NmUSU::-webkit-scrollbar-thumb {
  background-color: #9598a1;
  border: 1px solid #f0f3fa;
  border-radius: 3px
}

html.theme-dark .scrollWrap-379NmUSU::-webkit-scrollbar-thumb {
  background-color: #363a45;
  border-color: #1e222d
}

.scrollWrap-379NmUSU::-webkit-scrollbar-track {
  background-color: initial;
  border-radius: 3px
}

.scrollWrap-379NmUSU::-webkit-scrollbar-corner {
  display: none
}

.scrollWrap-379NmUSU.noScrollBar-379NmUSU {
  -ms-overflow-style: none;
  padding-right: 100px
}

.scrollWrap-379NmUSU.noScrollBar-379NmUSU.sb-scrollbar-wrap {
  display: none
}

.scrollWrap-379NmUSU.noScrollBar-379NmUSU::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.scrollWrap-379NmUSU.noScrollBar-379NmUSU::-webkit-scrollbar-thumb,.scrollWrap-379NmUSU.noScrollBar-379NmUSU::-webkit-scrollbar-track {
  display: none
}

.scrollWrap-379NmUSU.noScrollBar-379NmUSU::-webkit-scrollbar-corner {
  display: none
}

.content-379NmUSU {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  position: relative
}

.icon-379NmUSU {
  display: block;
  transition: transform 60ms ease
}

.scrollBot-379NmUSU,.scrollTop-379NmUSU {
  align-items: center;
  background-color: #1e222d99;
  color: #fff;
  display: flex;
  height: 24px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  transition: background-color .35s ease,transform .11666667s cubic-bezier(.55,.055,.675,.19);
  width: 100%
}

html.theme-dark .scrollBot-379NmUSU,html.theme-dark .scrollTop-379NmUSU {
  background-color: #6a6d7899;
  color: #fff
}

.scrollBot-379NmUSU:active,.scrollTop-379NmUSU:active {
  transition: background-color 58.33333ms ease,transform .11666667s cubic-bezier(.215,.61,.355,1)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .scrollBot-379NmUSU:hover,.scrollTop-379NmUSU:hover {
      transition:background-color 58.33333ms ease,transform .11666667s cubic-bezier(.215,.61,.355,1)
  }
}

.scrollBot-379NmUSU:active .icon-379NmUSU,.scrollTop-379NmUSU:active .icon-379NmUSU {
  transform: translateY(1px)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .scrollBot-379NmUSU:hover .icon-379NmUSU,.scrollTop-379NmUSU:hover .icon-379NmUSU {
      transform:translateY(1px)
  }
}

.scrollBot-379NmUSU.isVisible-379NmUSU,.scrollTop-379NmUSU.isVisible-379NmUSU {
  transform: translateY(0);
  transition-timing-function: cubic-bezier(.215,.61,.355,1)
}

.scrollTop-379NmUSU {
  top: 0;
  transform: translateY(-100%)
}

.scrollTop-379NmUSU .iconWrap-379NmUSU {
  transform: rotate(180deg)
}

.scrollBot-379NmUSU {
  bottom: 0;
  transform: translateY(100%)
}

.fadeBot-379NmUSU,.fadeTop-379NmUSU {
  height: 24px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%
}

.fadeBot-379NmUSU.isVisible-379NmUSU,.fadeTop-379NmUSU.isVisible-379NmUSU {
  transform: translateY(0);
  transition-timing-function: cubic-bezier(.215,.61,.355,1)
}

.fadeTop-379NmUSU {
  background-image: linear-gradient(0deg,#fff0,#fff);
  top: 0;
  transform: translateY(-100%)
}

html.theme-dark .fadeTop-379NmUSU {
  background-image: linear-gradient(0deg,#13172200,#131722)
}

.fadeBot-379NmUSU {
  background-image: linear-gradient(180deg,#fff0,#fff);
  bottom: 0;
  transform: translateY(100%)
}

html.theme-dark .fadeBot-379NmUSU {
  background-image: linear-gradient(180deg,#13172200,#131722)
}

.button-DABaJZo4 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 50px;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 0
}

.button-DABaJZo4 .icon-DABaJZo4 {
  display: flex;
  flex: 0 0 auto
}

.button-DABaJZo4 .counterRow-DABaJZo4 {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 16px;
  left: 4px;
  line-height: 16px;
  position: absolute;
  right: 4px;
  top: 7px;
  white-space: nowrap
}

.button-DABaJZo4 .counter-DABaJZo4 {
  background-color: #ff4a68;
  border-radius: 8px;
  box-sizing: border-box;
  margin-left: auto;
  min-width: 16px;
  overflow: hidden;
  padding: 0 4px;
  text-align: center;
  text-overflow: ellipsis
}

.button-DABaJZo4 .hoverMask-DABaJZo4 {
  border-radius: 4px;
  bottom: 0;
  height: 37px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  z-index: -1
}

.separator-3YbwEKtL {
  flex: 0 0 auto;
  height: 1px;
  margin: 6px 0;
  width: 100%
}

.filler-1ggHJ4cQ {
  flex: 1 1 100%;
  min-height: 13px;
  width: 100%
}

.toolbar-OvhMiobx {
  display: inline-flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  width: 45px
}

.container-3CL4Geq2 {
  display: block;
  transition: fill 60ms ease,stroke 60ms ease
}

.container-3CL4Geq2.mirror-3CL4Geq2 {
  transform: rotate(180deg)
}

.container-3CL4Geq2 .arrow-3CL4Geq2,html.theme-dark .container-3CL4Geq2 .arrow-3CL4Geq2 {
  stroke: #9db2bd
}

.counter-1nuJd9UN {
  background-color: #ff4a68;
  border-radius: 8px;
  box-sizing: border-box;
  height: 14px;
  margin-left: auto;
  overflow: hidden;
  padding: 0 4px;
  text-align: center;
  text-overflow: ellipsis;
  width: 14px
}

.errorDialog-3n4M-SK0 {
  line-height: 1.5
}

.tv-exit-fullscreen-button {
  background: #fff;
  border: 1px solid #c9cbcd;
  border-radius: 0 0 3px 3px;
  color: #848487;
  cursor: default;
  font-size: 11px;
  font-weight: 700;
  left: 50%;
  margin: 0 0 0 -59px;
  opacity: .6;
  padding: 2px 4px;
  position: fixed;
  top: -1px;
  width: 116px;
  z-index: 16
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-exit-fullscreen-button:hover {
      opacity:1
  }
}

.tv-data-table {
  border-collapse: collapse;
  border-spacing: 0;
  color: #434651;
  position: relative;
  width: 100%
}

html.theme-dark .tv-data-table {
  color: #d1d4dc
}

.tv-data-table__header {
  padding: 10px 12px
}

.tv-data-table__thead {
  border: 3px solid #fff;
  border-bottom: none
}

html.theme-dark .tv-data-table__thead {
  border-color: #131722
}

.tv-data-table__stroke {
  height: 40px
}

.tv-data-table__stroke--hide {
  display: none
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-data-table__tbody .tv-data-table__stroke:hover {
      background-color:#f3f5f9
  }

  html.theme-dark .tv-data-table__tbody .tv-data-table__stroke:hover {
      background-color: #2f3241
  }
}

.tv-data-table__stroke--hl .tv-data-table__cell:first-child {
  position: relative
}

.tv-data-table__stroke--hl .tv-data-table__cell:first-child:before {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px
}

.tv-data-table__stroke--hl-active .tv-data-table__cell:first-child:before {
  background: #2962ff
}

.tv-data-table__stroke--hl-danger .tv-data-table__cell:first-child:before {
  background: #ef5350
}

.tv-data-table__stroke--hl-success .tv-data-table__cell:first-child:before {
  background: #37bc9b
}

.tv-data-table__stroke:last-child .tv-data-table__cell {
  border-bottom: #e6eaef
}

html.theme-dark .tv-data-table__stroke:last-child .tv-data-table__cell {
  border-bottom: #363c4e
}

.tv-data-table__stroke--grayed {
  color: #999
}

html.theme-dark .tv-data-table__stroke--grayed {
  color: #c5cbce
}

.tv-data-table__cell {
  border-bottom: 1px solid #e6eaef;
  font-size: 13px;
  padding: 10px 10px 10px 11px;
  text-transform: capitalize;
  vertical-align: middle
}

html.theme-dark .tv-data-table__cell {
  border-bottom-color: #363c4e
}

.tv-data-table__tbody .tv-data-table__cell,.tv-data-table__tbody .tv-data-table__cell * {
  -webkit-user-select: text!important;
  user-select: text!important
}

.tv-data-table__cell--bold {
  font-weight: 700
}

.tv-data-table__cell--inlined {
  display: inline-block
}

.tv-data-table__cell--right-align {
  text-align: right!important
}

.tv-data-table__cell--status {
  background: #e6e9ed;
  height: 15px;
  white-space: nowrap
}

html.theme-dark .tv-data-table__cell--status {
  background: #1c2030
}

.tv-data-table__cell--left-margin>span {
  margin-left: 10px
}

.tv-data-table__cell--red-background {
  background: #f4ecec
}

html.theme-dark .tv-data-table__cell--red-background {
  background: #2c2030
}

.tv-data-table__cell--blue-background {
  background: #eaf3fb
}

html.theme-dark .tv-data-table__cell--blue-background {
  background: #21384d
}

.tv-data-table__cell--symbol-cell {
  cursor: pointer;
  font-weight: 600;
  position: relative
}

.tv-data-table__cell--buttons-cell {
  padding: 0 5px;
  text-align: right!important;
  white-space: nowrap;
  width: 52px
}

.tv-data-table__cell--button {
  fill: #9ca0a6;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  padding-right: 5px;
  vertical-align: middle;
  width: 12px
}

.tv-data-table__cell--button.i-hover {
  fill: #4c525e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-data-table__cell--button:hover {
      fill:#4c525e
  }
}

html.theme-dark .tv-data-table__cell--button.i-hover {
  fill: #f7f8fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-data-table__cell--button:hover {
      fill:#f7f8fa
  }
}

.tv-data-table__cell--text {
  text-transform: none
}

.tv-data-table__cell--text-nowrap {
  white-space: nowrap
}

.tv-data-table__thead .tv-data-table__cell {
  color: #787b86;
  cursor: default;
  font-weight: 400;
  position: relative;
  text-align: left;
  white-space: nowrap
}

.tv-data-table__sortable {
  cursor: pointer
}

.tv-data-table__sortable--asc:after {
  border: 4px solid;
  border-color: #0000 #0000 #919691;
  content: "";
  cursor: pointer;
  margin-left: 5px;
  margin-top: -7px;
  position: absolute;
  top: 50%
}

html.theme-dark .tv-data-table__sortable--asc:after {
  border-bottom-color: #758696
}

.tv-data-table__sortable--desc:after {
  border: 4px solid #0000;
  border-top-color: #919691;
  content: "";
  cursor: pointer;
  margin-left: 5px;
  margin-top: -2px;
  position: absolute;
  top: 50%
}

html.theme-dark .tv-data-table__sortable--desc:after {
  border-top-color: #758696
}

.tv-data-table__button {
  fill: #9ca0a6;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  padding-right: 5px;
  vertical-align: middle;
  width: 12px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-data-table__button:hover {
      fill:#4c525e
  }

  html.theme-dark .tv-data-table__button:hover {
      fill: #f7f8fa
  }
}

.tv-data-table__information-button {
  cursor: default;
  margin-right: 2px
}

.tv-data-table__information-button svg {
  height: 12px;
  width: 12px
}

.tv-data-table__information-button.information {
  color: #2962ff
}

.tv-data-table__information-button.warning {
  color: #ff9800
}

.tv-data-table__information-button.error {
  color: #f44336
}

.tv-data-table__edit-button svg {
  height: 12px;
  width: 10px
}

.tv-data-table__close-button svg {
  height: 12px;
  width: 12px
}

.tv-data-table__blue-text {
  color: #2962ff
}

.tv-data-table__light-red-text {
  color: #ef5350
}

.tv-data-table__red-text {
  color: #ff173e
}

.tv-data-table__green-text {
  color: #37bc9b
}

.tv-data-table__red-green-text {
  color: #f89e30
}

.tv-data-table__grey-text,html.theme-dark .tv-data-table__grey-text {
  color: #787b86
}

.tv-data-table__dropdown-button-wrap {
  padding: 0 0 0 15px;
  width: 15px
}

.tv-data-table__dropdown-button {
  display: inline-block
}

.tv-data-table__dropdown-button svg {
  fill: #758696
}

.tv-data-table__dropdown-button-cross-align {
  padding-right: 9px
}

.tv-data-table__dropdown-search-wrap {
  border-bottom: 1px solid #e6eaef;
  height: 45px;
  position: relative
}

html.theme-dark .tv-data-table__dropdown-search-wrap {
  border-bottom-color: #363c4e
}

.tv-data-table__dropdown-search-input {
  background-color: #fff;
  border: none;
  box-sizing: border-box;
  color: #434651;
  display: block;
  height: 45px;
  margin: 0;
  padding: 10px 22px 0;
  width: 100%
}

html.theme-dark .tv-data-table__dropdown-search-input {
  background-color: #131722;
  color: #d1d4dc
}

.tv-data-table__dropdown-wrap {
  overflow: hidden;
  position: fixed!important;
  width: 240px
}

.tv-data-table__dropdown-label--breakable {
  word-break: break-word
}

.tv-control-radio {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-left: 4px
}

.tv-control-radio--in-actions {
  max-width: 50%
}

@media screen and (max-width: 479px) {
  .tv-control-radio--in-actions {
      max-width:none
  }
}

.tv-control-radio,.tv-control-radio__label {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle
}

.tv-control-radio--nowrap,.tv-control-radio__label--nowrap {
  white-space: nowrap
}

.tv-control-radio__input {
  height: 18px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 18px
}

.tv-control-radio__box {
  box-sizing: border-box;
  line-height: 1;
  pointer-events: none;
  transition: background-color .35s ease
}

.tv-control-radio__box,.tv-control-radio__box:before {
  border-radius: 3px;
  display: block;
  height: 18px;
  width: 18px
}

.tv-control-radio__box:before {
  background-color: initial;
  content: "";
  left: 50%;
  margin-left: -9px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  transform: scale(1);
  transition: transform .35s ease,background-color .35s ease,border-radius .35s ease
}

.tv-control-radio__box:after {
  border: 2px solid #758696;
  border-radius: 3px;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  transition: border-color .35s ease;
  width: 18px
}

.tv-control-radio__box svg {
  stroke: #0000;
  display: block;
  height: 12px;
  left: 50%;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  transform: scale(0);
  transition: stroke .35s ease 1ms,transform .35s ease 1ms;
  width: 12px
}

.tv-control-radio__label {
  margin-right: 10px;
  white-space: normal
}

.tv-control-radio__label--two-lines {
  width: 155px
}

.tv-control-radio__label--nowrap {
  white-space: nowrap
}

.tv-control-radio__label--lil-line-height {
  line-height: 16px;
  max-width: 80%;
  min-width: 45%
}

.tv-control-radio__label+.tv-control-radio {
  margin-left: 0
}

.tv-control-radio+.tv-control-radio__label {
  margin-left: 10px;
  margin-right: 0;
  max-width: calc(100% - 1ex - 28px)
}

.tv-control-radio:active .tv-control-radio__box,.tv-control-radio__input:focus+.tv-control-radio__box {
  will-change: background-color
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-radio:hover .tv-control-radio__box {
      will-change:background-color
  }
}

.tv-control-radio:active .tv-control-radio__box:before,.tv-control-radio__input:focus+.tv-control-radio__box:before {
  will-change: transform,border-radius
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-radio:hover .tv-control-radio__box:before {
      will-change:transform,border-radius
  }
}

.tv-control-radio:active .tv-control-radio__box:after,.tv-control-radio__input:focus+.tv-control-radio__box:after {
  will-change: border-color
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-radio:hover .tv-control-radio__box:after {
      border-color:#627384;
      will-change: border-color
  }
}

.tv-control-radio:active .tv-control-radio__box:after,.tv-control-radio__input:focus+.tv-control-radio__box:after,html.theme-dark .tv-control-radio:active .tv-control-radio__box:after,html.theme-dark .tv-control-radio__input:focus+.tv-control-radio__box:after {
  border-color: #2962ff
}

.tv-control-radio__input:checked+.tv-control-radio__box,html.theme-dark .tv-control-radio__input:checked+.tv-control-radio__box {
  background-color: #2962ff
}

.tv-control-radio__input:checked+.tv-control-radio__box,.tv-control-radio__input:checked+.tv-control-radio__box:after,.tv-control-radio__input:checked+.tv-control-radio__box:before {
  transition-timing-function: cubic-bezier(.215,.61,.355,1)
}

.tv-control-radio__input:checked+.tv-control-radio__box:before {
  border-radius: 50%;
  transform: scale(0)
}

.tv-control-radio__input:checked+.tv-control-radio__box:after,html.theme-dark .tv-control-radio__input:checked+.tv-control-radio__box:after {
  border-color: #2962ff
}

.tv-control-radio__input:checked+.tv-control-radio__box svg {
  stroke: #fff;
  transform: scale(1);
  transition-delay: .0875s;
  transition-timing-function: cubic-bezier(.215,.61,.355,1);
  will-change: stroke,transform
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-radio:hover .tv-control-radio__input:checked+.tv-control-radio__box {
      background-color:#1e53e5
  }

  .tv-control-radio:hover .tv-control-radio__input:checked+.tv-control-radio__box:after {
      border-color: #1e53e5
  }
}

.tv-control-radio:active .tv-control-radio__input:checked+.tv-control-radio__box,.tv-control-radio__input:focus:checked+.tv-control-radio__box {
  background-color: #2962ff
}

.tv-control-radio:active .tv-control-radio__input:checked+.tv-control-radio__box:after,.tv-control-radio__input:focus:checked+.tv-control-radio__box:after {
  border-color: #2962ff
}

.tv-control-radio:active .tv-control-radio__input[disabled]+.tv-control-radio__box:after,.tv-control-radio:active .tv-control-radio__input[disabled]:checked+.tv-control-radio__box:after,.tv-control-radio__input[disabled]+.tv-control-radio__box:after,.tv-control-radio__input[disabled]:checked+.tv-control-radio__box:after {
  border-color: #e0e3eb
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-radio:hover .tv-control-radio__input[disabled]+.tv-control-radio__box:after,.tv-control-radio:hover .tv-control-radio__input[disabled]:checked+.tv-control-radio__box:after {
      border-color:#e0e3eb
  }
}

html.theme-dark .tv-control-radio:active .tv-control-radio__input[disabled]+.tv-control-radio__box:after,html.theme-dark .tv-control-radio:active .tv-control-radio__input[disabled]:checked+.tv-control-radio__box:after,html.theme-dark .tv-control-radio__input[disabled]+.tv-control-radio__box:after,html.theme-dark .tv-control-radio__input[disabled]:checked+.tv-control-radio__box:after {
  border-color: #363a45
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-radio:hover .tv-control-radio__input[disabled]+.tv-control-radio__box:after,html.theme-dark .tv-control-radio:hover .tv-control-radio__input[disabled]:checked+.tv-control-radio__box:after {
      border-color:#363a45
  }
}

.tv-control-radio:active .tv-control-radio__input[disabled]+.tv-control-radio__box:before,.tv-control-radio:active .tv-control-radio__input[disabled]:checked+.tv-control-radio__box:before {
  background-color: #bbd9fb;
  transition: background-color .35s ease
}

html.theme-dark .tv-control-radio:active .tv-control-radio__input[disabled]+.tv-control-radio__box:before,html.theme-dark .tv-control-radio:active .tv-control-radio__input[disabled]:checked+.tv-control-radio__box:before {
  background-color: #2a2e39
}

.tv-control-radio:active .tv-control-radio__input[disabled]:checked+.tv-control-radio__box,.tv-control-radio__input[disabled]:checked+.tv-control-radio__box {
  background-color: #dadde0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-radio:hover .tv-control-radio__input[disabled]:checked+.tv-control-radio__box {
      background-color:#dadde0
  }
}

.tv-control-radio__ripple {
  border-radius: 50%;
  display: block;
  height: 100%;
  margin: -10px;
  -webkit-mask-image: radial-gradient(circle,#fff 100%,#000 0);
  mask-image: radial-gradient(circle,#fff 100%,#000 0);
  overflow: hidden;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.tv-control-radio__input:checked+.tv-control-radio__box+.tv-control-radio__ripple .tv-ripple {
  background-color: #2962ff40
}

.tv-control-radio__input[disabled]+.tv-control-radio__box+.tv-control-radio__ripple .tv-ripple,.tv-control-radio__input[disabled]:checked+.tv-control-radio__box+.tv-control-radio__ripple .tv-ripple {
  background-color: initial
}

.tv-control-radio.i-error .tv-control-radio__box:after {
  border-color: #ff4a68
}

.tv-control-radio.i-disabled {
  cursor: default
}

.tv-control-radio__box {
  border-radius: 50%;
  height: 10px;
  overflow: visible;
  width: 10px
}

.tv-control-radio__box:after {
  border-radius: 50%;
  left: -4px;
  top: -4px
}

.tv-control-radio__box:before {
  background-color: #2962ff;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  transform: scale(0);
  width: 10px
}

.tv-control-radio__input {
  left: -4px;
  top: -4px
}

.tv-control-radio__input:checked+.tv-control-radio__box:before {
  transform: scale(1);
  transition-duration: .06s
}

.tv-control-radio__input:checked+.tv-control-radio__box {
  background-color: #fff;
  transition-duration: .06s
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-radio:hover .tv-control-radio__input:checked+.tv-control-radio__box {
      background-color:#fff;
      transition-duration: .06s
  }
}

.tv-search-bar {
  background-color: #fff;
  color: #131722;
  line-height: 80px;
  position: relative;
  -webkit-user-select: none;
  user-select: none
}

html.theme-dark .tv-search-bar {
  background-color: #1e222d;
  color: #d1d4dc
}

@media screen and (max-width: 1019px) {
  .tv-search-bar {
      height:54px;
      line-height: 54px
  }
}

.tv-search-bar__inner {
  display: flex;
  justify-content: space-between
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__inner {
      padding:0 5px
  }
}

.tv-search-bar__inner-right-column {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: -53px
}

.tv-search-bar__dropdown {
  float: left
}

.tv-search-bar__button,.tv-search-bar__button-wrap {
  display: inline-block;
  position: relative
}

.tv-search-bar__button-wrap--line-height_inherit,.tv-search-bar__button-wrap--line-height_inherit.tv-search-bar__button-wrap--line-height_inherit {
  line-height: inherit
}

.tv-search-bar__button {
  align-items: center;
  box-sizing: border-box;
  color: #131722;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  height: 100%;
  padding: 0 15px;
  transition: background-color .35s ease;
  transition: color .35s ease
}

html.theme-dark .tv-search-bar__button {
  color: #d1d4dc
}

.tv-search-bar__button:visited {
  fill: #131722;
  color: #131722
}

html.theme-dark .tv-search-bar__button:visited {
  fill: #d1d4dc;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar__button:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-search-bar__button:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-search-bar__button:focus {
  outline: auto
}

.tv-search-bar__button:focus-visible {
  outline: auto
}

.tv-search-bar__button:focus:not(:focus-visible) {
  outline: none
}

.tv-search-bar__button:active {
  fill: #1848cc;
  color: #1848cc;
  transition-duration: 60ms
}

html.theme-dark .tv-search-bar__button:active {
  fill: #1848cc;
  color: #1848cc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar__button:hover {
      background-color:#f0f3fa;
      transition-duration: .06s
  }

  html.theme-dark .tv-search-bar__button:hover {
      background-color: #2a2e39
  }
}

.tv-search-bar__button:active {
  background-color: #16181f;
  transition-duration: .06s
}

.tv-search-bar__button.i-disabled {
  background-color: initial;
  color: #ffffffb3;
  cursor: default
}

.tv-search-bar__button:visited {
  color: #fff
}

.tv-search-bar__button-inwrap {
  display: inline-block;
  line-height: 1;
  vertical-align: middle
}

.tv-search-bar__button-title {
  color: inherit;
  display: block;
  font-size: 16px;
  letter-spacing: .12ex;
  margin-bottom: 5px;
  text-transform: uppercase
}

.tv-search-bar__button-title:last-child {
  margin-bottom: 0
}

.tv-search-bar__button-title--sub {
  line-height: 40px
}

.tv-search-bar__button-title--tabs {
  line-height: 80px
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__button-title--tabs {
      line-height:54px
  }
}

.tv-search-bar__button-subtitle {
  font-size: 13px;
  letter-spacing: .1ex;
  opacity: .65
}

.tv-search-bar__dropdown-body {
  margin-top: 23px;
  min-width: 200px
}

.tv-search-bar__area--filters {
  float: left;
  margin-left: -15px;
  margin-right: -15px
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__area--filters,.tv-search-bar__area--search_desktop {
      display:none
  }
}

.tv-search-bar__area--tabs {
  width: 100%
}

@media screen and (min-width: 1020px) {
  .tv-search-bar__area--tabs {
      margin-left:-15px;
      margin-right: -15px;
      width: calc(100% - 250px)
  }
}

.tv-search-bar__area--profile-filters {
  display: inline-flex;
  flex-wrap: nowrap;
  height: 56px;
  order: 1;
  white-space: nowrap
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__area--profile-filters {
      display:none
  }
}

.tv-search-bar__area--devices {
  display: none
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__area--devices {
      display:block
  }
}

.tv-search-bar__tags-wrap {
  padding: 43px 0 10px
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__tags-wrap {
      display:none
  }
}

.tv-search-bar__script {
  align-self: stretch;
  border-bottom: 1px solid #e0e3eb;
  display: flex;
  justify-content: flex-start
}

html.theme-dark .tv-search-bar__script {
  border-bottom-color: #434651
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__script {
      display:none
  }
}

.tv-search-bar__tags {
  box-sizing: border-box;
  display: flex;
  margin-top: -27px;
  max-height: 29px;
  overflow: hidden;
  position: relative
}

.tv-search-bar__tags.i-expanded {
  max-height: none
}

.tv-search-bar__tags:not(.i-expanded) .tv-search-bar__tags-btn--less-tags {
  display: none
}

.tv-search-bar__tags-list {
  display: flex;
  flex-wrap: wrap
}

.tv-search-bar__tags-btn {
  color: #2962ff;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  transition: color .35s ease;
  white-space: nowrap
}

html.theme-dark .tv-search-bar__tags-btn {
  color: #2962ff
}

.tv-search-bar__tags-btn:visited {
  fill: #2962ff;
  color: #2962ff
}

html.theme-dark .tv-search-bar__tags-btn:visited {
  fill: #2962ff;
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar__tags-btn:hover {
      fill:#1e53e5;
      color: #1e53e5;
      transition-duration: 60ms
  }

  html.theme-dark .tv-search-bar__tags-btn:hover {
      fill: #1e53e5;
      color: #1e53e5
  }
}

.tv-search-bar__tags-btn:focus {
  outline: auto
}

.tv-search-bar__tags-btn:focus-visible {
  outline: auto
}

.tv-search-bar__tags-btn:focus:not(:focus-visible) {
  outline: none
}

.tv-search-bar__tags-btn:active {
  fill: #2962ff;
  color: #2962ff;
  transition-duration: 60ms
}

html.theme-dark .tv-search-bar__tags-btn:active {
  fill: #2962ff;
  color: #2962ff
}

.tv-search-bar__tags-btn:active {
  transform: translateY(1px)
}

.tv-search-bar__unmoderated-notice {
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  line-height: 1.2;
  margin-left: 5px;
  max-width: 120px;
  padding: 1px 4px
}

.tv-search-bar__unmoderated-notice--warning {
  background-color: #e2e885;
  color: #666
}

.tv-search-bar__widgets-control {
  margin-right: 40px
}

.tv-search-bar__widgets-control-icon svg {
  fill: #fff
}

.tv-search-bar__search-query {
  display: inline-block;
  line-height: 1;
  position: relative;
  top: 20px;
  vertical-align: top
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__search-query {
      display:block;
      padding-right: 46px;
      top: 0
  }
}

.tv-search-bar__search-query--mobile {
  display: none
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__search-query--mobile {
      display:block
  }

  .tv-search-bar__search-query--without-filters-button {
      padding-right: 0
  }
}

.tv-search-bar__search-input {
  background-color: #b3c7d240;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 10px 36px 10px 44px;
  transition: background-color .35s ease;
  vertical-align: middle;
  width: 250px
}

.tv-search-bar__search-input::placeholder {
  color: #94959c;
  transition: color .35s ease
}

.tv-search-bar__search-input:focus::placeholder {
  color: #b7b8bd;
  transition-duration: .06s
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__search-input {
      background-color:initial;
      border-radius: 0;
      padding: 19px 36px 19px 32px;
      width: 100%
  }
}

.tv-search-bar__search-input:focus {
  transition-duration: .06s
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__search-input:focus {
      background-color:initial
  }
}

.tv-search-bar__search-icon {
  display: block;
  left: 12px;
  margin-top: -9px;
  opacity: .8;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transition: opacity .35s ease
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__search-icon {
      left:0
  }
}

.tv-search-bar__search-icon svg {
  fill: #fff;
  display: block;
  height: 18px;
  width: 18px
}

.tv-search-bar__search-input:focus+.tv-search-bar__search-icon {
  opacity: 1;
  transition-duration: .06s
}

.tv-search-bar__input-reset {
  bottom: 0;
  cursor: pointer;
  display: block;
  opacity: .8;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity .35s ease;
  width: 36px
}

.tv-search-bar__input-reset:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.tv-search-bar__input-reset svg {
  fill: #fff;
  display: inline-block;
  height: 9px;
  vertical-align: middle;
  width: 9px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar__input-reset:hover {
      opacity:1;
      transition-duration: .06s
  }
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__input-reset {
      right:112px
  }
}

.tv-search-bar__sponsored {
  display: inline-block;
  height: 50px;
  line-height: 1;
  min-width: 1px;
  text-align: right;
  vertical-align: middle;
  width: 100px
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__sponsored {
      display:none
  }
}

.tv-search-bar__sponsored-text {
  font-size: 10px;
  font-weight: 700;
  margin-bottom: -6px;
  opacity: .7
}

.tv-search-bar__sponsored-by {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 100%;
  opacity: .9;
  position: relative;
  transition: opacity .35s ease;
  width: 100%
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar__sponsored-by:hover {
      opacity:1;
      transition-duration: .06s
  }
}

.tv-search-bar__sponsored-img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

.tv-search-bar__sponsored-img svg {
  fill: #fff;
  height: 40px;
  position: relative;
  width: 100px
}

.tv-search-bar--secondary {
  background-color: #fff;
  color: #2962ff
}

html.theme-dark .tv-search-bar--secondary {
  background-color: #131722;
  color: #2962ff
}

@media screen and (max-width: 1019px) {
  .tv-search-bar--secondary_devices-reserved-height {
      height:57px
  }
}

.tv-search-bar--secondary .tv-search-bar__button,html.theme-dark .tv-search-bar--secondary .tv-search-bar__button {
  color: #2962ff
}

.tv-search-bar--secondary .tv-search-bar__button:active,.tv-search-bar--secondary .tv-search-bar__device-btn:active {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar--secondary .tv-search-bar__button:hover,.tv-search-bar--secondary .tv-search-bar__device-btn:hover {
      background-color:#f0f3fa
  }
}

html.theme-dark .tv-search-bar--secondary .tv-search-bar__button:active,html.theme-dark .tv-search-bar--secondary .tv-search-bar__device-btn:active {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-search-bar--secondary .tv-search-bar__button:hover,html.theme-dark .tv-search-bar--secondary .tv-search-bar__device-btn:hover {
      background-color:#2a2e39
  }
}

.tv-search-bar--secondary .tv-search-bar__device-btn svg,.tv-search-bar--secondary .tv-search-bar__input-reset svg,.tv-search-bar--secondary .tv-search-bar__search-icon svg {
  fill: #758696
}

.tv-search-bar--secondary .tv-search-bar__search-input {
  color: #758696
}

.tv-search-bar--secondary .tv-search-bar__search-input::placeholder {
  color: #9db2bd;
  transition: color .35s ease
}

.tv-search-bar--secondary .tv-search-bar__search-input:focus::placeholder {
  color: #94959c;
  transition-duration: .06s
}

.tv-search-bar--sub {
  background-color: #14151b;
  height: auto;
  line-height: 40px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar--sub .tv-search-bar__button:hover {
      background-color:#f0f3fa
  }

  html.theme-dark .tv-search-bar--sub .tv-search-bar__button:hover {
      background-color: #2a2e39
  }
}

.tv-search-bar--sub .tv-search-bar__button:active {
  background-color: #f0f3fa
}

html.theme-dark .tv-search-bar--sub .tv-search-bar__button:active {
  background-color: #2a2e39
}

.tv-search-bar--sub .tv-search-bar__button-wrap {
  opacity: .65;
  transition: opacity .35s ease
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar--sub .tv-search-bar__button-wrap:hover {
      opacity:.8;
      transition-duration: .06s
  }
}

.tv-search-bar--sub .tv-search-bar__button-wrap:active {
  opacity: 1;
  transition-duration: .06s
}

.tv-search-bar--sub .tv-search-bar__button-wrap.i-active {
  opacity: 1
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar--sub .tv-search-bar__button-wrap.i-active:hover,html.theme-dark .tv-search-bar--sub .tv-search-bar__button-wrap.i-active:hover {
      color:#2962ff
  }
}

.tv-search-bar--sub .tv-search-bar__button-title {
  font-size: 12px;
  font-weight: 400
}

.tv-search-bar__caret {
  margin-left: 2px
}

.tv-search-bar__device-btn {
  bottom: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: none;
  font-size: 0;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: background-color .35s ease;
  width: 66px
}

.tv-search-bar__device-btn--right_2 {
  right: 66px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-search-bar__device-btn:hover {
      background-color:#20222d;
      transition-duration: .06s
  }
}

.tv-search-bar__device-btn:active {
  background-color: #16181f;
  transition-duration: .06s
}

@media screen and (max-width: 1019px) {
  .tv-search-bar__device-btn {
      display:block
  }
}

.tv-search-bar__device-btn svg {
  fill: #fff;
  display: inline-block;
  vertical-align: middle
}

.tv-search-bar [data-division=search] {
  display: none
}

.tv-slider,.ui-slider.tv-slider {
  background: none!important;
  background-color: initial!important;
  border: none!important;
  box-shadow: none;
  height: 10px!important;
  margin: 8px 100px;
  position: relative
}

.tv-slider:after,.ui-slider.tv-slider:after {
  background: #dadde0;
  content: "";
  height: 2px;
  position: absolute;
  top: 4px;
  width: 100%
}

.tv-slider__labels,.ui-slider.tv-slider__labels {
  position: absolute;
  top: 10px;
  width: 100%
}

.tv-slider__label,.ui-slider.tv-slider__label {
  background-color: #fff;
  border: 1px solid #d1d4dc;
  border-radius: 2px;
  color: #434651;
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px!important;
  height: 25px;
  padding: 0 6px;
  position: absolute;
  top: -19px;
  width: 103px;
  width: 65px
}

html.theme-dark .tv-slider__label,html.theme-dark .ui-slider.tv-slider__label {
  background-color: #1e222d;
  border-color: #50535e;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-slider__label:hover,.ui-slider.tv-slider__label:hover {
      border-color:#a3a6af
  }

  html.theme-dark .tv-slider__label:hover,html.theme-dark .ui-slider.tv-slider__label:hover {
      border-color: #6a6d78
  }
}

.tv-slider__label:focus,.ui-slider.tv-slider__label:focus,html.theme-dark .tv-slider__label:focus,html.theme-dark .ui-slider.tv-slider__label:focus {
  border-color: #2962ff
}

.tv-slider__label--left,.ui-slider.tv-slider__label--left {
  left: -100px
}

.tv-slider__label--right,.ui-slider.tv-slider__label--right {
  right: -96px
}

.tv-slider .ui-slider-handle,.ui-slider.tv-slider .ui-slider-handle {
  background: #2962ff!important;
  border-radius: 5px;
  height: 10px!important;
  position: absolute;
  top: 0!important;
  width: 10px!important;
  z-index: 1
}

.feature-mobiletouch .tv-slider .ui-slider-handle:after,.feature-mobiletouch .ui-slider.tv-slider .ui-slider-handle:after {
  content: "";
  height: 100%;
  left: -6px;
  padding: 6px;
  position: absolute;
  top: -6px;
  width: 100%
}

.tv-slider .ui-slider-range,.ui-slider.tv-slider .ui-slider-range {
  background: #2962ff!important;
  height: 2px!important;
  position: absolute;
  top: 4px!important;
  z-index: 1
}

.tv-slider .ui-slider-range-min,.ui-slider.tv-slider .ui-slider-range-min {
  left: 0;
  right: auto
}

.tv-screener {
  position: relative
}

.tv-embed-widget-wrapper .tv-screener,.tv-feed .tv-screener {
  margin: 0
}

.tv-screener__standalone-main-container {
  min-height: 0;
  position: fixed;
  z-index: 10
}

.tv-screener--standalone {
  padding-top: 56px;
  z-index: 1
}

.tv-screener--sticky-header {
  padding-top: 57px
}

.tv-screener--hidden-toolbar {
  padding-top: 0
}

.tv-screener__content-pane {
  height: calc(100% - 184px);
  overflow: auto;
  padding-bottom: 80px;
  position: relative
}

.tv-screener__content-pane::-webkit-scrollbar {
  height: 5px;
  width: 5px
}

.tv-screener__content-pane::-webkit-scrollbar-thumb {
  background-color: #9598a1;
  border: 1px solid #f0f3fa;
  border-radius: 3px
}

html.theme-dark .tv-screener__content-pane::-webkit-scrollbar-thumb {
  background-color: #363a45;
  border-color: #1e222d
}

.tv-screener__content-pane::-webkit-scrollbar-track {
  background-color: initial;
  border-radius: 3px
}

.tv-screener__content-pane::-webkit-scrollbar-corner {
  display: none
}

.tv-screener__content-pane--fully-loaded {
  height: calc(100% - 56px);
  padding-bottom: 0
}

.tv-screener--standalone .tv-screener__content-pane {
  height: auto;
  overflow: visible;
  padding-bottom: 0
}

.tv-screener--standalone-market {
  background: #fff;
  overflow: hidden
}

html.theme-dark .tv-screener--standalone-market {
  background: #1e222d
}

@media screen and (max-width: 1019px) {
  .tv-screener--standalone-market {
      margin:0 -20px
  }
}

.tv-screener--standalone-market .tv-screener__content-pane {
  overflow: hidden
}

@media screen and (max-width: 1019px) {
  .tv-screener--standalone-market .tv-screener__content-pane {
      overflow:auto
  }
}

.tv-screener__symbol {
  color: #2962ff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  max-width: 100%;
  padding-right: 3px
}

.tv-screener--standalone-market .tv-screener__symbol,html.theme-dark .tv-screener--standalone-market .tv-screener__symbol {
  color: #2962ff
}

.tv-screener__symbol--text {
  color: #50535e;
  cursor: default
}

html.theme-dark .tv-screener__symbol--text {
  color: #d1d4dc
}

.tv-screener__symbol--secondary {
  font-weight: 400
}

.tv-screener__type {
  color: #8a8a8a;
  font-weight: 400;
  text-transform: none
}

.tv-screener--standalone-market .tv-screener__type,html.theme-dark .tv-screener--standalone-market .tv-screener__type {
  color: #787b86
}

.tv-screener__description {
  color: #50535e;
  display: inline;
  font-weight: 400;
  letter-spacing: .3px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  vertical-align: bottom
}

html.theme-dark .tv-screener__description {
  color: #d1d4dc
}

@media screen and (max-width: 767px) {
  .tv-screener__description {
      max-width:150px
  }
}

.tv-screener--standalone-market .tv-screener__description {
  color: #131722
}

html.theme-dark .tv-screener--standalone-market .tv-screener__description {
  color: #d1d4dc
}

.tv-screener__loading-screen {
  background: #ffffff80;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 105px;
  width: 100%;
  z-index: 1
}

html.theme-dark .tv-screener__loading-screen {
  background: #13172280
}

.tv-screener__loading-screen--standalone {
  position: fixed
}

.tv-screener__loading-screen--hidden-toolbar {
  top: 49px
}

.tv-screener__bottom-loading-screen {
  bottom: 40px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1
}

.tv-screener--standalone .tv-screener__bottom-loading-screen {
  bottom: 0;
  height: 70px;
  position: relative
}

.tv-screener__filter-field-title--active {
  color: #2962ff!important
}

.tv-screener__standalone-wrap {
  margin: 0;
  padding: 0
}

.tv-screener__standalone-title-wrap {
  background-color: #fff;
  border-bottom: 2px solid #e0e3eb;
  display: flex;
  justify-content: space-between;
  padding: 26px 20px 20px
}

html.theme-dark .tv-screener__standalone-title-wrap {
  background-color: #131722;
  border-bottom-color: #434651
}

.tv-screener__standalone-title {
  color: #131722;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -.3px;
  line-height: 35px
}

html.theme-dark .tv-screener__standalone-title {
  color: #d1d4dc
}

.tv-screener__maintenance {
  color: #8f8f8f;
  font-style: italic;
  margin: 10px 11px;
  text-align: center
}

.tv-screener__body-background {
  background-color: #fff
}

html.theme-dark .tv-screener__body-background {
  background-color: #131722
}

.tv-screener-toolbar {
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  padding: 12px 20px 10px;
  position: relative;
  transition: display .4s .4s
}

html.theme-dark .tv-screener-toolbar {
  background-color: #131722
}

@media screen and (max-width: 767px) {
  .tv-screener-toolbar {
      flex-wrap:wrap
  }
}

.tv-screener-toolbar--one-line {
  flex-wrap: nowrap;
  overflow: visible
}

.tv-screener-toolbar--standalone {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.tv-screener-toolbar--markets {
  background: #fff
}

html.theme-dark .tv-screener-toolbar--markets {
  background: #1e222d
}

.tv-screener-toolbar--markets_relative {
  position: relative
}

.tv-screener-toolbar--markets_sticky {
  background: #fff;
  position: fixed;
  z-index: 5
}

html.theme-dark .tv-screener-toolbar--markets_sticky {
  background: #1e222d
}

.tv-screener-toolbar--markets_absolute {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0
}

html.theme-dark .tv-screener-toolbar--markets_absolute {
  background: #1e222d
}

.tv-screener-toolbar__refresh-counter {
  bottom: 0;
  display: none;
  font-size: 10px;
  left: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 100%
}

.tv-screener-toolbar__button--with-counter .tv-screener-toolbar__refresh-counter {
  display: block
}

.tv-screener-toolbar>div {
  height: 32px;
  margin-bottom: 2px
}

.tv-screener-toolbar__button {
  background-color: #fff;
  border: 1px solid #e0e3eb;
  border-radius: 2px;
  box-sizing: border-box;
  color: #787b86;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  max-width: 250px;
  overflow: hidden;
  padding: 8px 12px 0;
  position: relative;
  text-overflow: ellipsis;
  transition: background-color .175s ease,opacity .175s ease;
  vertical-align: middle;
  white-space: nowrap
}

html.theme-dark .tv-screener-toolbar__button {
  background-color: #131722;
  border-color: #363a45;
  color: #787b86
}

.tv-screener-toolbar__button--space_right {
  margin-right: 10px
}

.tv-screener-toolbar__button--animated {
  transition: background-color .175s ease,opacity .175s ease,width .35s ease
}

.tv-screener-toolbar__button--with-options {
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0
}

.tv-screener-toolbar__button--options {
  background-color: #fff;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.tv-screener-toolbar__button--refresh {
  background-color: #fff;
  padding: 8px 0 0;
  width: 32px
}

.tv-screener-toolbar__button--refresh svg {
  left: 50%
}

.tv-screener-toolbar__button--export {
  width: 36px
}

.tv-screener-toolbar__button--export svg {
  left: 50%
}

.tv-screener-toolbar__button--square {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 32px
}

.tv-screener-toolbar__button--refresh.tv-screener-toolbar__button--with-counter svg {
  transform: scale(.9) translateY(-5px)
}

.tv-screener-toolbar__button--arrow-down {
  padding-right: 19px
}

.tv-screener-toolbar__button--arrow-down:before {
  border: 4px solid #0000;
  border-top-color: #758696;
  content: "";
  margin-left: 0;
  margin-top: -4px;
  position: absolute;
  right: 6px;
  top: 55%
}

.tv-screener-toolbar__button--with-state {
  padding-right: 23px
}

.tv-screener-toolbar__button--active {
  color: #2962ff
}

.tv-screener-toolbar__button--active svg {
  fill: #2962ff
}

.tv-screener-toolbar__button--active:after {
  border-top-color: #2962ff
}

.tv-screener-toolbar__button--create-alert {
  background-color: #fff
}

.tv-screener-toolbar__button--create-alert svg {
  transition: opacity .175s ease
}

.tv-screener-toolbar__button--unsaved:after {
  content: "*";
  position: absolute;
  right: 17px;
  top: 9px
}

.tv-screener-toolbar__button.i-loading {
  background-color: #2962ff;
  border-color: #2962ff;
  pointer-events: none
}

html.theme-dark .tv-screener-toolbar__button.i-loading {
  background-color: #2962ff;
  border-color: #2962ff
}

.tv-screener-toolbar__button.i-loading:after {
  animation-duration: 1.05s;
  animation-iteration-count: infinite;
  animation-name: tv-pulse-animation-button;
  animation-timing-function: cubic-bezier(.33,0,.11,1);
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 10px;
  left: 50%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 50%;
  width: 10px
}

html.theme-dark .tv-screener-toolbar__button.i-loading:after {
  border: 1px solid #d1d4dc
}

.tv-screener-toolbar__button.i-loading svg {
  opacity: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__button:hover {
      background-color:#f8f9fd
  }

  html.theme-dark .tv-screener-toolbar__button:hover {
      background-color: #1e222d
  }
}

.tv-screener-toolbar__button:active {
  background-color: #e0e3eb
}

html.theme-dark .tv-screener-toolbar__button:active {
  background-color: #363a45
}

.tv-screener-toolbar__button:active {
  color: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__button:hover {
      color:#787b86
  }
}

html.theme-dark .tv-screener-toolbar__button:active {
  color: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-screener-toolbar__button:hover {
      color:#787b86
  }
}

.tv-screener-toolbar__button:active svg {
  fill: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__button:hover svg {
      fill:#787b86
  }
}

html.theme-dark .tv-screener-toolbar__button:active svg {
  fill: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-screener-toolbar__button:hover svg {
      fill:#787b86
  }
}

.tv-screener-toolbar__button--enabled {
  background-color: #2962ff;
  border-color: #2962ff;
  color: #fff
}

html.theme-dark .tv-screener-toolbar__button--enabled {
  background-color: #2962ff;
  border-color: #2962ff;
  color: #d1d4dc
}

.tv-screener-toolbar__button--enabled svg {
  fill: #fff
}

html.theme-dark .tv-screener-toolbar__button--enabled svg {
  fill: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__button--enabled:hover {
      background-color:#1e53e5;
      color: #fff
  }

  html.theme-dark .tv-screener-toolbar__button--enabled:hover {
      background-color: #1e53e5;
      color: #d1d4dc
  }

  .tv-screener-toolbar__button--enabled:hover svg {
      fill: #fff
  }

  html.theme-dark .tv-screener-toolbar__button--enabled:hover svg {
      fill: #d1d4dc
  }
}

.tv-screener-toolbar__button--enabled:active,html.theme-dark .tv-screener-toolbar__button--enabled:active {
  background-color: #1848cc
}

.tv-screener-toolbar__button--alert-loading {
  background-color: #2962ff;
  border-color: #2962ff;
  pointer-events: none
}

html.theme-dark .tv-screener-toolbar__button--alert-loading {
  background-color: #2962ff;
  border-color: #2962ff
}

@keyframes tv-pulse-animation-button {
  0% {
      opacity: 0
  }

  20% {
      opacity: 1
  }

  to {
      opacity: 0;
      transform: scale(2.5)
  }
}

.tv-screener-toolbar__button--alert-loading:after {
  animation-duration: 1.05s;
  animation-iteration-count: infinite;
  animation-name: tv-pulse-animation-button;
  animation-timing-function: cubic-bezier(.33,0,.11,1);
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 10px;
  left: 50%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 50%;
  width: 10px
}

html.theme-dark .tv-screener-toolbar__button--alert-loading:after {
  border: 1px solid #d1d4dc
}

.tv-screener-toolbar__button--alert-loading svg {
  opacity: 0
}

.tv-screener-toolbar__button--enabled-alert-loading {
  background-color: #fff;
  border-color: #eceff2
}

html.theme-dark .tv-screener-toolbar__button--enabled-alert-loading {
  background-color: #1e222d;
  border-color: #363c4e
}

.tv-screener-toolbar__button--enabled-alert-loading:after {
  border-color: #758696
}

.tv-screener-toolbar__button--filters {
  background-color: #2962ff;
  border-width: 0;
  color: #fff
}

html.theme-dark .tv-screener-toolbar__button--filters {
  background-color: #2962ff;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__button--filters:hover,html.theme-dark .tv-screener-toolbar__button--filters:hover {
      background-color:#1e53e5
  }
}

.tv-screener-toolbar__button--filters:active {
  background-color: #1848cc
}

html.theme-dark .tv-screener-toolbar__button--filters:active {
  background-color: #2962ff
}

.tv-screener-toolbar__button--filters:active {
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__button--filters:hover {
      color:#fff
  }
}

html.theme-dark .tv-screener-toolbar__button--filters:active {
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-screener-toolbar__button--filters:hover {
      color:#d1d4dc
  }
}

.tv-screener-toolbar__button-icon {
  fill: #787b86;
  position: relative;
  vertical-align: middle
}

html.theme-dark .tv-screener-toolbar__button-icon {
  fill: #787b86
}

.tv-screener-toolbar__button-icon--standalone-refresh {
  margin-left: -9px;
  top: -2px
}

.tv-screener-toolbar__button-icon--export {
  margin-left: -8px;
  top: -2px
}

.tv-screener-toolbar__button-icon--filters {
  fill: #fff;
  height: 14px;
  margin-left: 7px;
  width: 12px
}

html.theme-dark .tv-screener-toolbar__button-icon--filters {
  fill: #d1d4dc
}

.tv-screener-toolbar__button-wrap,.tv-screener-toolbar__filter-count-wrap {
  position: relative
}

.tv-screener-toolbar__filter-count {
  background: #2962ff;
  border: 2px solid #fff;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 19px;
  line-height: 20px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -12px;
  width: 19px
}

html.theme-dark .tv-screener-toolbar__filter-count {
  background: #2962ff;
  border: 2px solid #131722;
  color: #d1d4dc
}

.tv-screener-toolbar__filter-count--market-count {
  border: none;
  height: 16px;
  line-height: 16px;
  right: 11px;
  top: -1px;
  width: 16px
}

.tv-screener-toolbar__switch {
  align-items: center;
  display: flex;
  margin-left: 10px
}

.tv-screener-toolbar__favorites,.tv-screener-toolbar__period-picker {
  align-items: center;
  display: flex;
  flex-grow: 1
}

.tv-screener-toolbar__favorites {
  max-width: 100%
}

.tv-screener-toolbar__period-picker {
  width: 100%
}

.tv-screener-toolbar__no-scroll-choicer-react-root {
  flex-grow: 1
}

.tv-screener-toolbar__favorites-item,.tv-screener-toolbar__period-picker-item {
  background-color: #f1f3f6;
  border-radius: 2px;
  color: #758696;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  margin-left: 9px;
  max-width: 150px;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  transition: background-color .175s ease;
  white-space: nowrap
}

html.theme-dark .tv-screener-toolbar__favorites-item,html.theme-dark .tv-screener-toolbar__period-picker-item {
  background-color: #1c2030
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__favorites-item:hover,.tv-screener-toolbar__period-picker-item:hover {
      background-color:#e1e6ec
  }

  html.theme-dark .tv-screener-toolbar__favorites-item:hover,html.theme-dark .tv-screener-toolbar__period-picker-item:hover {
      background-color: #2f3241
  }
}

.tv-screener-toolbar__favorites-item:active,.tv-screener-toolbar__period-picker-item:active {
  background-color: #758696
}

html.theme-dark .tv-screener-toolbar__favorites-item:active,html.theme-dark .tv-screener-toolbar__period-picker-item:active {
  background-color: #262b3e
}

.tv-screener-toolbar__favorites-item:first-child,.tv-screener-toolbar__period-picker-item:first-child {
  margin-left: 0
}

.tv-screener-toolbar__favorites-item--active,.tv-screener-toolbar__period-picker-item--active {
  background-color: #758696!important;
  color: #fff
}

html.theme-dark .tv-screener-toolbar__favorites-item--active,html.theme-dark .tv-screener-toolbar__period-picker-item--active {
  background-color: #262b3e!important;
  color: #c5cbce
}

.tv-screener-toolbar__favorites-item--active:active,.tv-screener-toolbar__period-picker-item--active:active {
  background-color: #758696!important;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-toolbar__favorites-item--active:hover,.tv-screener-toolbar__period-picker-item--active:hover {
      background-color:#758696!important;
      color: #fff
  }
}

html.theme-dark .tv-screener-toolbar__favorites-item--active:active,html.theme-dark .tv-screener-toolbar__period-picker-item--active:active {
  background-color: #262b3e!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-screener-toolbar__favorites-item--active:hover,html.theme-dark .tv-screener-toolbar__period-picker-item--active:hover {
      background-color:#262b3e!important
  }
}

html.theme-dark .tv-screener-toolbar__favorites-item--active:active,html.theme-dark .tv-screener-toolbar__period-picker-item--active:active {
  color: #c5cbce
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-screener-toolbar__favorites-item--active:hover,html.theme-dark .tv-screener-toolbar__period-picker-item--active:hover {
      color:#c5cbce
  }
}

.tv-screener-toolbar__favorites-more-wrap {
  display: inline-block;
  margin-left: 9px;
  position: relative
}

.tv-screener-toolbar__favorites-more {
  display: block;
  height: 15px;
  margin-left: 0;
  position: relative;
  width: 5px
}

.tv-screener-toolbar__favorites-more:after {
  border: 4px solid #0000;
  border-top-color: #758696;
  content: "";
  margin-left: -1px;
  margin-top: -4px;
  position: absolute;
  top: 55%
}

.tv-screener-toolbar__interval-sup {
  top: -.5em
}

.tv-screener-toolbar__interval-select {
  white-space: nowrap
}

.tv-screener-toolbar .tv-screener-toolbar__currency-select {
  margin-right: 10px
}

.tv-screener-toolbar .tv-screener-toolbar__currency-select .tv-dropdown__button {
  text-align: center;
  width: 140px
}

.tv-screener-toolbar .tv-screener-toolbar__currency-select .tv-screener-popup__item {
  text-align: center;
  width: 100px
}

.tv-screener-toolbar .tv-screener-popup__currency-select--active {
  background-color: #e3effd
}

html.theme-dark .tv-screener-toolbar .tv-screener-popup__currency-select--active {
  background-color: #142e61
}

.tv-flag-country--globe svg {
  color: #131722
}

html.theme-dark .tv-flag-country--globe svg {
  color: #b2b5be
}

.tv-screener-popup {
  width: 280px
}

.tv-screener-popup__item {
  color: #434651;
  cursor: pointer;
  font-size: 13px;
  line-height: 14px;
  overflow: hidden;
  padding: 10px 22px;
  position: relative;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap
}

html.theme-dark .tv-screener-popup__item {
  color: #d1d4dc
}

.tv-screener-popup__item--presets,.tv-screener-popup__item--save {
  border-bottom: 1px solid #ececec
}

html.theme-dark .tv-screener-popup__item--presets,html.theme-dark .tv-screener-popup__item--save {
  border-bottom-color: #363c4e
}

.tv-screener-popup__item--save {
  margin-bottom: 10px
}

.tv-screener-popup__item--active {
  background-color: #e3effd
}

html.theme-dark .tv-screener-popup__item--active {
  background-color: #142e61
}

.tv-screener-popup__item--presets {
  font-weight: 400;
  padding: 0
}

.tv-screener-popup__item--presets:empty {
  display: none
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item--preset:hover {
      padding-right:70px
  }
}

.tv-screener-popup__item--space-left {
  padding-left: 25px!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item:hover {
      background-color:#f0f3fa
  }

  html.theme-dark .tv-screener-popup__item:hover {
      background-color: #2a2e39
  }
}

.tv-screener-popup__item-presets-title {
  display: block;
  font-weight: 700;
  padding: 10px 22px
}

.tv-screener-popup__item-delete {
  display: none;
  position: absolute;
  right: 74px;
  top: 11px
}

.tv-screener-popup__item-delete--right {
  right: 18px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item-delete:hover svg * {
      stroke:#555
  }

  .tv-screener-popup__item:hover>.tv-screener-popup__item-delete {
      display: inline
  }
}

.tv-screener-popup__item-edit,.tv-screener-popup__item-rename {
  display: none;
  position: absolute;
  right: 45px;
  top: 11px
}

.tv-screener-popup__item-edit svg,.tv-screener-popup__item-rename svg {
  fill: #758696;
  height: 10px;
  width: 12px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item-edit:hover svg,.tv-screener-popup__item-rename:hover svg {
      fill:#555
  }

  .tv-screener-popup__item:hover>.tv-screener-popup__item-edit,.tv-screener-popup__item:hover>.tv-screener-popup__item-rename {
      display: block
  }
}

.tv-screener-popup__item-delete,.tv-screener-popup__item-rename,.tv-screener-popup__item-star,.tv-screener-popup__item-star-active {
  margin: -7px;
  padding: 7px
}

.tv-screener-popup__item-star,.tv-screener-popup__item-star-active {
  display: none;
  position: absolute;
  right: 15px;
  top: 8px
}

.tv-screener-popup__item-star-active svg,.tv-screener-popup__item-star svg {
  fill: #758696;
  height: 16px;
  width: 16px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item-star:hover svg {
      fill:#555
  }
}

.tv-screener-popup__item-star-active svg {
  fill: #ffc84a
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item-star-active:hover svg {
      fill:#f79217
  }
}

.tv-screener-popup__item--favorite .tv-screener-popup__item-star-active {
  display: inline
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item:hover>.tv-screener-popup__item-star {
      display:inline
  }

  .tv-screener-popup__item--favorite:hover>.tv-screener-popup__item-star {
      display: none
  }
}

.tv-screener-popup__refresh-intervals {
  width: auto
}

.tv-screener-popup__refresh-interval-label {
  white-space: nowrap
}

.tv-screener-popup__select-interval-active {
  color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__select-interval-active:hover {
      color:#2962ff
  }
}

.tv-screener-popup__item-alert-toggle {
  box-sizing: border-box;
  display: none;
  height: 14px;
  left: 6px;
  padding: 2px 0 0 2px;
  position: absolute;
  top: 9px;
  width: 14px
}

.tv-screener-popup__item-alert-toggle svg {
  fill: #758696;
  display: block;
  height: 10px;
  width: 10px
}

.tv-screener-popup__item-alert-toggle--active {
  display: block
}

.tv-screener-popup__item-alert-toggle--active svg {
  fill: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-popup__item-alert-toggle:hover svg {
      fill:#627384
  }

  .tv-screener-popup__item-alert-toggle--active:hover svg {
      fill: #1e53e5
  }

  .tv-screener-popup__item:hover .tv-screener-popup__item-alert-toggle {
      display: block
  }
}

.feature-mobiletouch .tv-screener-popup__item--favorite .tv-screener-popup__item-star {
  display: none
}

.feature-mobiletouch .tv-screener-popup__item-delete,.feature-mobiletouch .tv-screener-popup__item-rename,.feature-mobiletouch .tv-screener-popup__item-star {
  display: inline
}

.tv-screener-fields-popup {
  top: 1px;
  width: 280px
}

.tv-screener-fields-popup__search-wrap {
  border-bottom: 1px solid;
  border-color: #e0e3eb;
  height: 45px;
  position: relative
}

html.theme-dark .tv-screener-fields-popup__search-wrap {
  border-color: #363a45
}

.tv-screener-fields-popup__search-input {
  border: none;
  box-sizing: border-box;
  display: block;
  height: 45px;
  margin: 0;
  padding: 10px 22px 0;
  width: 100%
}

.tv-screener-fields-popup__search-no-matches {
  color: #787b86;
  font-style: italic;
  pointer-events: none
}

html.theme-dark .tv-screener-fields-popup__search-no-matches {
  color: #787b86
}

.tv-screener-fields-popup__item {
  color: #434651;
  cursor: pointer;
  display: block;
  font-size: 13px;
  line-height: 14px;
  padding: 10px 22px
}

html.theme-dark .tv-screener-fields-popup__item {
  color: #d1d4dc
}

.tv-screener-fields-popup__item--no-matches {
  color: #b4b4b4;
  cursor: default;
  font-style: italic
}

.tv-screener-fields-popup__search-input {
  background-color: #fff;
  color: #434651
}

html.theme-dark .tv-screener-fields-popup__search-input {
  background-color: #1e222d;
  color: #d1d4dc
}

@keyframes flick {
  0% {
      background-color: #fff2cf
  }

  to {
      background-color: initial
  }
}

@keyframes flick-theme-dark {
  0% {
      background-color: #123440
  }

  to {
      background-color: initial
  }
}

html.theme-dark .tv-screener-table__result-row--focused,html.theme-dark .tv-screener-table__result-row--selected {
  background: #142e61
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-screener-table__result-row--focused:hover,html.theme-dark .tv-screener-table__result-row--selected:hover {
      background:#142e61!important
  }
}

html.theme-dark .tv-screener-table__result-row--flick {
  animation-name: flick-theme-dark
}

.tv-screener-table {
  color: #131722;
  position: static
}

html.theme-dark .tv-screener-table {
  color: #d1d4dc
}

.tv-screener-table--fixed {
  table-layout: fixed
}

.tv-screener-table__thead {
  background-color: initial;
  border: none;
  pointer-events: none
}

.tv-screener-table__thead--regular,.tv-screener-table__thead--sticky {
  pointer-events: auto
}

.tv-screener-table__head {
  border-bottom: 1px solid #e0e3eb;
  border-top: 1px solid #e0e3eb;
  height: 48px
}

html.theme-dark .tv-screener-table__head {
  border-bottom-color: #434651;
  border-top-color: #434651
}

.tv-screener-table__th {
  cursor: default;
  padding: 0
}

.tv-screener-table__th:not(:first-child) {
  border-left: 1px solid #e0e3eb
}

html.theme-dark .tv-screener-table__th:not(:first-child) {
  border-left-color: #434651
}

.tv-screener-table__th--dragging {
  background-color: #f5f8f9;
  z-index: 1
}

html.theme-dark .tv-screener-table__th--dragging {
  background-color: #2f3241
}

.tv-screener-table__th:last-child:not(.tv-screener-table__th--big):not(.tv-screener-table__th--no-toolbar) {
  padding-right: 41px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-table__th:hover {
      background-color:#f0f3fa
  }

  html.theme-dark .tv-screener-table__th:hover {
      background-color: #2a2e39
  }
}

.tv-screener-table .tv-screener-table__th {
  white-space: normal
}

.tv-screener-table__head-wrap {
  align-items: center;
  display: flex;
  height: 48px;
  padding: 0 10px
}

.tv-screener-table__head-wrap--disable-interaction {
  cursor: default
}

.tv-screener-table__head-left {
  color: #787b86;
  display: flex;
  flex-wrap: wrap;
  min-width: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 100%
}

html.theme-dark .tv-screener-table__head-left {
  color: #787b86
}

.tv-screener-table__head-left--name-col,.tv-screener-table__head-left--name-col-without-description {
  display: grid;
  grid-template: "columnName searchInput" "columnValue searchInput" "columnMatches searchInput";
  min-width: 220px;
  padding-left: 20px;
  text-align: left;
  width: 100%
}

@media screen and (max-width: 1019px) {
  .tv-screener-table__head-left--name-col,.tv-screener-table__head-left--name-col-without-description {
      min-width:100px
  }
}

.tv-screener--standalone-market .tv-screener-table__head-left--name-col,.tv-screener--standalone-market .tv-screener-table__head-left--name-col-without-description {
  grid-template: "columnName searchInput" "columnValue searchInput"
}

.tv-screener-table__head-left--widget-name-col-without-description {
  display: grid;
  grid-template: "columnName" "columnValue" "columnMatches";
  min-width: 90px;
  padding-left: 20px;
  text-align: left
}

.tv-screener-table__head-left--text {
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  grid-area: columnName;
  line-height: 17px;
  position: relative;
  vertical-align: middle;
  width: 100%
}

.tv-screener-table__head-left--title-three-lines {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 12px;
  max-height: 36px;
  overflow: hidden
}

.tv-screener-table__head-left--title-two-lines {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 12px;
  max-height: 24px;
  overflow: hidden
}

.tv-screener-table__head-left .tv-screener-table__head-left--marker {
  height: 20px;
  left: -30px;
  position: absolute;
  top: -2px
}

.tv-screener-table__filter-button {
  align-items: center;
  background-color: #f0f3fa;
  color: #787b86;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 18px
}

html.theme-dark .tv-screener-table__filter-button {
  background-color: #2a2e39;
  color: #787b86
}

.tv-screener-table__filter-button svg {
  height: 14px;
  width: 12px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-table__th:hover .tv-screener-table__filter-button {
      border-left:1px solid #e0e3eb
  }

  html.theme-dark .tv-screener-table__th:hover .tv-screener-table__filter-button {
      border-left: 1px solid #434651
  }

  .tv-screener-table__th:hover .tv-screener-table__filter-button:hover {
      background-color: #e0e3eb;
      color: #131722
  }

  html.theme-dark .tv-screener-table__th:hover .tv-screener-table__filter-button:hover {
      background-color: #363a45;
      color: #d1d4dc
  }

  .tv-screener-table__th:hover .tv-screener-table__filter-button--active,html.theme-dark .tv-screener-table__th:hover .tv-screener-table__filter-button--active {
      color: #2962ff
  }

  .tv-screener-table__th:hover .tv-screener-table__filter-button--active:hover,html.theme-dark .tv-screener-table__th:hover .tv-screener-table__filter-button--active:hover {
      color: #1e53e5
  }
}

.tv-screener-table__col--dragging {
  background-color: #f5f8f9
}

html.theme-dark .tv-screener-table__col--dragging {
  background-color: #2f3241
}

.tv-screener-table__result-row {
  cursor: default;
  height: 48px
}

.tv-screener-table__result-row--flick {
  animation-duration: 1.4s;
  animation-iteration-count: 1;
  animation-name: flick;
  animation-timing-function: cubic-bezier(.755,.05,.855,.06)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener--standalone-market .tv-screener-table__result-row:hover {
      background:#f0f3fa
  }

  html.theme-dark .tv-screener--standalone-market .tv-screener-table__result-row:hover {
      background: #2a2e39
  }
}

.tv-screener-table__result-row--active {
  background: #3179f566
}

.tv-screener--standalone-market .tv-screener-table__result-row--active {
  background: #e3effd
}

html.theme-dark .tv-screener--standalone-market .tv-screener-table__result-row--active {
  background: #132042
}

.tv-screener-table__result-row--active .tv-screener-table__cell:first-child {
  border-left: 6px solid #2962ff
}

.tv-screener-table__result-row--focused,.tv-screener-table__result-row--selected {
  background: #bbd9fb
}

.tv-screener--standalone-market .tv-screener-table__result-row--focused,.tv-screener--standalone-market .tv-screener-table__result-row--selected {
  background: #e3effd
}

html.theme-dark .tv-screener--standalone-market .tv-screener-table__result-row--focused,html.theme-dark .tv-screener--standalone-market .tv-screener-table__result-row--selected {
  background: #132042
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-table__result-row--focused:hover,.tv-screener-table__result-row--selected:hover {
      background:#bbd9fb!important
  }

  .tv-screener--standalone-market .tv-screener-table__result-row--focused:hover,.tv-screener--standalone-market .tv-screener-table__result-row--selected:hover {
      background: #e3effd!important
  }

  html.theme-dark .tv-screener--standalone-market .tv-screener-table__result-row--focused:hover,html.theme-dark .tv-screener--standalone-market .tv-screener-table__result-row--selected:hover {
      background: #132042!important
  }
}

.feature-mobiletouch .tv-screener-table__result-row--focused .tv-screener-table__marker,.feature-mobiletouch .tv-screener-table__result-row--selected .tv-screener-table__marker {
  visibility: visible
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-table__result-row:hover .tv-screener__symbol {
      color:#1e53e5;
      cursor: pointer;
      transition-duration: .06s
  }

  .tv-screener-table__result-row:hover .tv-screener__symbol--text {
      color: #50535e;
      cursor: default
  }

  html.theme-dark .tv-screener-table__result-row:hover .tv-screener__symbol--text {
      color: #d1d4dc
  }
}

.tv-screener-table__result-row--active .tv-screener-table__marker {
  visibility: visible
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-table__result-row:hover .tv-screener-table__marker {
      visibility:visible
  }
}

.tv-screener-table__symbol-addition {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  justify-content: flex-end
}

.tv-screener-table__marker {
  min-height: 16px;
  min-width: 20px;
  visibility: hidden
}

.tv-screener-table__marker--active {
  visibility: visible
}

.tv-screener-table__symbol-container {
  align-items: center;
  display: flex
}

.tv-screener-table__cell {
  font-size: 14px;
  padding: 12px 10px 12px 11px;
  text-align: right;
  text-transform: none;
  white-space: nowrap
}

.tv-screener-table__cell .tv-screener__symbol {
  text-transform: none;
  transition: color .35s ease
}

.tv-screener-table__cell .tv-screener__description {
  color: #4f5966;
  display: block;
  font-size: 11px;
  letter-spacing: .3px;
  margin-top: 2px
}

.tv-screener--standalone-market .tv-screener-table__cell .tv-screener__description {
  color: #131722
}

html.theme-dark .tv-screener--standalone-market .tv-screener-table__cell .tv-screener__description {
  color: #d1d4dc
}

.tv-screener-table__cell .tv-flag-country {
  margin: 0 12px 0 4px
}

.tv-screener-table__cell--left {
  text-align: left
}

.tv-screener-table__cell--center {
  text-align: center
}

.tv-screener-table__cell--up {
  color: #00a97f
}

.tv-screener--standalone-market .tv-screener-table__cell--up {
  color: #26a69a
}

html.theme-dark .tv-screener--standalone-market .tv-screener-table__cell--up {
  color: #00897b
}

.tv-screener-table__cell--down {
  color: #ff173e
}

.tv-screener--standalone-market .tv-screener-table__cell--down {
  color: #ef5350
}

html.theme-dark .tv-screener--standalone-market .tv-screener-table__cell--down {
  color: #e53935
}

.tv-screener-table__cell--highlighted {
  text-overflow: clip
}

.tv-screener-table__cell--growing {
  background-color: #37bc9b;
  border-radius: 2px;
  color: #fff;
  margin: 0 -3px;
  padding: 1px 3px
}

.tv-screener--standalone-market .tv-screener-table__cell--growing {
  background-color: #26a69a;
  color: #fff
}

html.theme-dark .tv-screener--standalone-market .tv-screener-table__cell--growing {
  background-color: #00897b;
  color: #fff
}

.tv-screener-table__cell--falling {
  background-color: #ff4a68;
  border-radius: 2px;
  color: #fff;
  margin: 0 -3px;
  padding: 1px 3px
}

.tv-screener--standalone-market .tv-screener-table__cell--falling {
  background-color: #ef5350;
  color: #fff
}

html.theme-dark .tv-screener--standalone-market .tv-screener-table__cell--falling {
  background-color: #e53935;
  color: #fff
}

.tv-screener-table__cell--fix-width {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tv-screener-table__cell:first-child {
  max-width: 300px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-top: 5px
}

@media screen and (max-width: 1019px) {
  .tv-screener-table__cell:first-child {
      min-width:0
  }
}

.tv-screener-table__cell:last-child:not(.tv-screener-table__cell--big):not(.tv-screener-table__cell--no-toolbar) {
  padding-right: 53px
}

.tv-screener-table__cell--without-description .tv-flag-country {
  margin-top: 0
}

.tv-screener-table__cell--without-description:first-child {
  min-width: 175px
}

@media screen and (max-width: 1019px) {
  .tv-screener-table__cell--without-description:first-child {
      min-width:90px
  }
}

.tv-screener-table__cell--widget-without-description:first-child {
  min-width: 90px
}

.tv-screener-table__cell--color-normal {
  color: #131722
}

html.theme-dark .tv-screener-table__cell--color-normal {
  color: #d1d4dc
}

.tv-screener-table__cell--crypto_mkt {
  font-size: 14px
}

.tv-screener-table__cell--with-marker:first-child {
  padding-left: 0
}

.tv-screener-table__market-icon {
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  width: 20px
}

.tv-screener-table__market-icon svg {
  stroke: currentColor
}

.tv-screener-table__cell .session-status {
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin: 0 0 2px 4px;
  position: relative;
  width: 4px
}

.tv-screener-table__cell .session-status.pre_market {
  background: #f79d16
}

.tv-screener-table__sort {
  background-color: #37a6ef;
  bottom: 0;
  display: inline-block;
  height: 2px;
  position: absolute;
  right: 0;
  text-align: center;
  visibility: hidden;
  width: 100%
}

.tv-screener--standalone-market .tv-screener-table__sort,html.theme-dark .tv-screener--standalone-market .tv-screener-table__sort {
  background-color: #2962ff
}

.tv-screener-table__sort svg {
  fill: #37a6ef;
  position: absolute;
  right: calc(50% - 4px)
}

.tv-screener--standalone-market .tv-screener-table__sort svg,html.theme-dark .tv-screener--standalone-market .tv-screener-table__sort svg {
  fill: #2962ff
}

.tv-screener-table__sort--asc {
  visibility: visible
}

.tv-screener-table__sort--asc svg {
  top: -3px
}

.tv-screener-table__sort--desc {
  visibility: visible
}

.tv-screener-table__sort--desc svg {
  top: 2px
}

.tv-screener-table__th--big:last-child .tv-screener-table__sort {
  left: 0;
  right: auto;
  width: calc(100% - 2px)
}

.tv-screener-table__th:last-child:not(.tv-screener-table__th--big):not(.tv-screener-table__th--no-toolbar) .tv-screener-table__sort {
  left: 0;
  right: auto;
  width: calc(100% - 43px)
}

.tv-screener-table__th:last-child:not(.tv-screener-table__th--big):not(.tv-screener-table__th--no-toolbar) .tv-screener-table__filter-button {
  right: 43px
}

.tv-screener-table__sortable {
  box-sizing: border-box;
  color: #adaeb0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-table__sortable:hover .tv-screener-table__filter-button {
      opacity:1
  }
}

.tv-screener-table__field-value {
  color: #2962ff;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  width: 100%
}

html.theme-dark .tv-screener-table__field-value {
  color: #2962ff
}

.tv-screener-table__field-value--name {
  display: block;
  grid-area: columnMatches;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase
}

.tv-screener-table__field-value--name,.tv-screener-table__field-value--total {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .4px;
  line-height: 16px;
  line-height: 12px;
  white-space: nowrap
}

.tv-screener-table__field-value--total {
  color: #131722;
  display: inline-block;
  grid-area: columnValue;
  text-transform: none;
  text-transform: uppercase
}

html.theme-dark .tv-screener-table__field-value--total {
  color: #d1d4dc
}

.tv-screener-table__search-query {
  align-self: center;
  display: inline-block;
  font-weight: 400;
  grid-area: searchInput;
  margin-left: 32px;
  margin-right: 12px;
  min-width: 120px;
  position: relative
}

@media screen and (max-width: 1019px) {
  .tv-screener-table__search-query {
      display:none
  }
}

.tv-screener-table__search-query--without-description {
  min-width: 95px
}

.tv-screener-table__input-reset svg {
  height: 12px;
  width: 12px
}

@media screen and (max-width: 1019px) {
  .tv-screener-table__input-reset {
      right:0
  }
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-table__input-reset:hover svg path {
      stroke:#8a8a8a
  }
}

.tv-screener-table__search-icon {
  color: #d1d4dc;
  left: 10px;
  opacity: 1;
  transition: all 0s ease 0s
}

html.theme-dark .tv-screener-table__search-icon {
  color: #50535e
}

.tv-screener-table__search-input {
  background-color: #fff;
  border: 1px solid #d1d4dc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #131722;
  font-size: 11px;
  letter-spacing: .5px;
  padding: 10px 36px 8px;
  width: 100%
}

html.theme-dark .tv-screener-table__search-input {
  background-color: #1e222d;
  border: 1px solid #50535e;
  color: #d1d4dc
}

.tv-screener-table__search-input:focus {
  border: 1px solid #2962ff
}

.tv-screener-table__signal {
  display: inline-block;
  letter-spacing: .5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  text-transform: none
}

.tv-screener--standalone-market .tv-screener-table__signal--strong-buy,.tv-screener-table__signal--strong-buy {
  color: #2962ff
}

.tv-screener-table__signal--strong-buy:before {
  background-position-y: 50%;
  content: "";
  padding-left: 15px
}

.tv-screener--standalone-market .tv-screener-table__signal--buy,.tv-screener-table__signal--buy {
  color: #2962ff
}

.tv-screener-table__signal--buy:before {
  background-position-y: 50%;
  content: "";
  padding-left: 15px
}

.tv-screener-table__signal--sell {
  color: #ff4a68
}

.tv-screener--standalone-market .tv-screener-table__signal--sell {
  color: #ef5350
}

.tv-screener-table__signal--sell:before {
  background-position-y: 50%;
  content: "";
  padding-left: 15px
}

.tv-screener-table__signal--strong-sell {
  color: #ff4a68
}

.tv-screener--standalone-market .tv-screener-table__signal--strong-sell {
  color: #ef5350
}

.tv-screener-table__signal--strong-sell:before {
  background-position-y: 50%;
  content: "";
  padding-left: 15px
}

.tv-screener-table__signal--neutral {
  color: #9db2bd
}

.tv-screener--standalone-market .tv-screener-table__signal--neutral {
  color: #787b86
}

.tv-screener-table__signal--neutral:before {
  background-position-y: 50%;
  content: "";
  padding-left: 15px
}

.tv-screener-table__empty-message {
  color: #8a8a8a;
  font-style: italic;
  margin: 13px;
  text-align: center
}

.tv-screener-table__pattern_icon {
  display: inline-block
}

.tv-screener-table__pattern_empty {
  color: #8797a5
}

.tv-screener-table__pattern_tooltip_container {
  font-size: 13px;
  padding: 0 8px 8px 5px;
  text-align: center;
  width: 90px
}

.tv-screener-table__pattern_tooltip_container svg {
  height: 40px;
  padding-bottom: 6px;
  width: 40px
}

.tv-screener-table__logo-container {
  flex-shrink: 0;
  margin-right: 8px
}


.tv-screener-table__empty-result {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%
}

.tv-screener-table__empty-result.tv-screener-table__empty-result--fixed {
  position: fixed
}

.tv-screener-table__empty-result.tv-screener-table__empty-result--relative {
  position: relative;
  top: -48px
}

.tv-screener-table__empty-description {
  color: #131722;
  font-size: 16px;
  line-height: 24px;
  margin: 0 20px;
  text-align: center
}

html.theme-dark .tv-screener-table__empty-description {
  color: #b2b5be
}

.tv-screener-table__empty-content {
  display: flex
}

.tv-screener-table__symbol-container-description {
  min-width: 0
}

.tv-screener-table__cell-currency-value {
  color: #787b86;
  font-size: 10px;
  line-height: 16px;
  margin-left: 3px
}

html.theme-dark .tv-screener-table__cell-currency-value {
  color: #787b86
}

.tv-screener-inplace-editor {
  background: #fff;
  border: 1px solid #e0e3eb;
  border-radius: 2px;
  box-shadow: 0 2px 3px #252c3129;
  height: 60px;
  margin-top: -2px;
  min-width: 400px;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  z-index: 3
}

html.theme-dark .tv-screener-inplace-editor {
  background: #131722;
  border-color: #363a45
}

.tv-screener-inplace-editor--hidden {
  opacity: 0
}

.tv-screener-inplace-editor--standalone {
  margin-top: -1px
}

.tv-screener-inplace-editor--wide {
  width: 435px
}

.tv-screener-inplace-editor--slim {
  min-width: 210px
}

.tv-screener-inplace-editor__filter-field {
  background-color: #fff;
  padding: 8px 81px 8px 20px;
  white-space: nowrap
}

html.theme-dark .tv-screener-inplace-editor__filter-field {
  background-color: #131722
}

.tv-screener-inplace-editor__filter-field-title {
  color: #787b86;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .5px;
  text-transform: uppercase
}

html.theme-dark .tv-screener-inplace-editor__filter-field-title {
  color: #b2b5be
}

.tv-screener-inplace-editor__filter-field-content {
  margin-top: 12px;
  position: relative
}

.tv-screener-inplace-editor__filter-field-content--left,.tv-screener-inplace-editor__filter-field-content--middle,.tv-screener-inplace-editor__filter-field-content--right {
  display: inline-block
}

.tv-screener-inplace-editor__filter-field-content--middle,.tv-screener-inplace-editor__filter-field-content--right {
  margin-left: 20px
}

.tv-screener-inplace-editor__filter-field-content--condition,.tv-screener-inplace-editor__filter-field-content--select {
  margin-top: 4px
}

.tv-screener-inplace-editor__filter-field-content--select {
  height: 34px;
  line-height: 27px;
  max-width: 350px;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tv-screener-inplace-editor__filter-field-text {
  background-color: #fff;
  border: 1px solid #d1d4dc;
  border-radius: 2px;
  color: #434651;
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px!important;
  height: 25px;
  padding: 0 6px;
  width: 103px
}

html.theme-dark .tv-screener-inplace-editor__filter-field-text {
  background-color: #1e222d;
  border-color: #50535e;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-inplace-editor__filter-field-text:hover {
      border-color:#a3a6af
  }

  html.theme-dark .tv-screener-inplace-editor__filter-field-text:hover {
      border-color: #6a6d78
  }
}

.tv-screener-inplace-editor__filter-field-text:focus,html.theme-dark .tv-screener-inplace-editor__filter-field-text:focus {
  border-color: #2962ff
}

.tv-screener-inplace-editor__filter-between-input {
  background-color: #fff;
  border: 1px solid #d1d4dc;
  border-radius: 2px;
  color: #434651;
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px!important;
  height: 25px;
  padding: 0 6px;
  width: 103px;
  width: 50px
}

html.theme-dark .tv-screener-inplace-editor__filter-between-input {
  background-color: #1e222d;
  border-color: #50535e;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-inplace-editor__filter-between-input:hover {
      border-color:#a3a6af
  }

  html.theme-dark .tv-screener-inplace-editor__filter-between-input:hover {
      border-color: #6a6d78
  }
}

.tv-screener-inplace-editor__filter-between-input:focus,html.theme-dark .tv-screener-inplace-editor__filter-between-input:focus {
  border-color: #2962ff
}

.tv-screener-inplace-editor__filter-between-separator {
  margin-left: 20px;
  padding: 0 6px
}

.tv-screener-inplace-editor__filter-field-reset {
  border-left: 1px solid #dde3e6;
  cursor: pointer;
  height: 60px;
  line-height: 67px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 66px
}

html.theme-dark .tv-screener-inplace-editor__filter-field-reset {
  border-left-color: #363c4e
}

.tv-screener-inplace-editor__filter-field-reset svg {
  fill: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-inplace-editor__filter-field-reset:hover {
      background-color:#fbfcfc
  }

  html.theme-dark .tv-screener-inplace-editor__filter-field-reset:hover {
      background-color: #171b29
  }
}

.tv-screener-inplace-editor__selectbox-container {
  height: 27px;
  line-height: 27px;
  white-space: nowrap
}

.tv-screener-inplace-editor__selectbox-dropdown-toggle {
  display: inline-block;
  height: 16px;
  margin: 6px 0 0 5px;
  transition: transform .35s ease;
  vertical-align: top;
  width: 16px
}

.tv-screener-inplace-editor__selectbox-dropdown-toggle svg {
  stroke: #9db2bd;
  stroke-width: 1.5px;
  display: block;
  margin: 5px 0 0 2px
}

.tv-screener-inplace-editor__selectbox-dropdown-toggle--open {
  transform: rotate(180deg)
}

.tv-screener-inplace-editor__selectbox-caption--no-value,.tv-screener-inplace-editor__selectbox-option--no-value .tv-screener-inplace-editor__selectbox-option-wrap {
  color: #a3a6af!important
}

html.theme-dark .tv-screener-inplace-editor__selectbox-caption--no-value,html.theme-dark .tv-screener-inplace-editor__selectbox-option--no-value .tv-screener-inplace-editor__selectbox-option-wrap {
  color: #50535e!important
}

.tv-screener-inplace-editor__selectbox-caption {
  cursor: pointer
}

.tv-screener-inplace-editor__selectbox-caption--text-overflow {
  display: inline-block;
  max-width: 100%
}

.tv-screener-inplace-editor__selectbox-caption--text-overflow .tv-screener-inplace-editor__selectbox-caption-label {
  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  padding-right: 23px;
  text-overflow: ellipsis
}

.tv-screener-inplace-editor__selectbox-caption--text-overflow .tv-screener-inplace-editor__selectbox-dropdown-toggle {
  margin-left: -18px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-inplace-editor__selectbox-caption:hover .tv-screener-inplace-editor__selectbox-dropdown-toggle svg {
      stroke:#758696
  }
}

.tv-screener-inplace-editor__search-wrap {
  border-bottom: 1px solid;
  border-color: #e0e3eb;
  height: 45px;
  position: relative
}

html.theme-dark .tv-screener-inplace-editor__search-wrap {
  border-color: #363a45
}

.tv-screener-inplace-editor__search-input {
  border: none;
  box-sizing: border-box;
  display: block;
  height: 45px;
  margin: 0;
  padding: 10px 22px 0;
  width: 100%
}

html.theme-dark .tv-screener-inplace-editor__search-input {
  background-color: #1e222d
}

.tv-screener-inplace-editor__search-no-matches {
  color: #787b86;
  font-style: italic;
  pointer-events: none
}

html.theme-dark .tv-screener-inplace-editor__search-no-matches {
  color: #787b86
}

.tv-screener-inplace-editor__filter-dropdown-toggle {
  height: 16px;
  pointer-events: none;
  position: absolute;
  right: 42px;
  top: 32px;
  transition: transform .2s ease-in-out;
  width: 16px
}

.tv-screener-inplace-editor__filter-dropdown-toggle svg {
  stroke: #9db2bd;
  display: block;
  margin: 5px 0 0 2px
}

.tv-screener-inplace-editor__filter-dropdown-toggle--open {
  transform: rotate(180deg)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-inplace-editor__filter-field-content--select:hover~.tv-screener-inplace-editor__filter-dropdown-toggle svg {
      stroke:#758696
  }
}

.tv-screener-inplace-editor__filter-toggle {
  display: block
}

.tv-screener-inplace-editor__without-reset {
  min-width: 337px
}

.tv-screener-inplace-editor__filter-without-reset {
  padding-right: 18px
}

.tv-screener-inplace-editor__dropdown-checkbox-item {
  color: #787b86
}

html.theme-dark .tv-screener-inplace-editor__dropdown-checkbox-item {
  color: #b2b5be
}

.tv-screener-inplace-editor__search-input {
  background-color: #fff;
  color: #434651
}

html.theme-dark .tv-screener-inplace-editor__search-input {
  background-color: #131722;
  color: #d1d4dc
}

.tv-screener-sticky-header-wrapper {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 2
}

html.theme-dark .tv-screener-sticky-header-wrapper {
  background: #131722
}

.tv-screener-sticky-header-wrapper--standalone_sticky {
  position: fixed
}

.tv-screener-sticky-header-wrapper--markets_sticky {
  background: #fff;
  display: inline-block;
  left: unset;
  right: unset
}

html.theme-dark .tv-screener-sticky-header-wrapper--markets_sticky {
  background: #1e222d
}

.tv-screener-sticky-header-wrapper--markets_absolute {
  background: #fff;
  display: inline-block;
  left: 0;
  position: absolute
}

html.theme-dark .tv-screener-sticky-header-wrapper--markets_absolute {
  background: #1e222d
}

.tv-screener-sticky-header-wrapper__right-substrate {
  display: none;
  height: 48px;
  position: absolute;
  width: 100%;
  z-index: 1
}

.tv-screener-sticky-header-wrapper__right-substrate--standalone_sticky {
  position: fixed
}

.tv-screener-sticky-header-wrapper__fields-button-wrap {
  border-left: 1px solid #e0e3eb;
  border-top: 1px solid #e0e3eb80;
  position: absolute;
  right: 0;
  top: 0
}

html.theme-dark .tv-screener-sticky-header-wrapper__fields-button-wrap {
  border-left-color: #434651;
  border-top-color: #43465180
}

.tv-screener-sticky-header-wrapper__fields-button {
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 41px
}

html.theme-dark .tv-screener-sticky-header-wrapper__fields-button {
  background-color: #131722
}

.tv-screener-sticky-header-wrapper__fields-button svg {
  fill: #787b86;
  position: relative
}

html.theme-dark .tv-screener-sticky-header-wrapper__fields-button svg {
  fill: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-sticky-header-wrapper__fields-button:hover {
      background-color:#f0f3fa
  }

  html.theme-dark .tv-screener-sticky-header-wrapper__fields-button:hover {
      background-color: #2a2e39
  }
}

.tv-screener-sticky-header-wrapper__fields-button--active svg {
  fill: #2962ff
}

.tv-screener-market-select {
  align-items: center;
  display: flex;
  padding: 0 15px
}

.tv-screener-market-select.tv-dropdown {
  display: flex
}

.tv-screener-market-select--dialog {
  padding: 7px 0 0;
  top: 10px
}

.tv-screener-market-select__button svg {
  vertical-align: middle
}

.tv-screener-market-select__button-title {
  margin-left: 3px;
  position: relative;
  top: 1px;
  vertical-align: middle
}

.tv-screener-market-select__item {
  color: #434651
}

html.theme-dark .tv-screener-market-select__item {
  color: #d1d4dc
}

.tv-screener-market-select__item svg {
  vertical-align: middle
}

.tv-screener-market-select__item-title {
  margin-left: 6px;
  position: relative;
  top: 1px;
  vertical-align: middle
}

.tv-screener-market-select__item--border-top {
  border-top: 1px solid #ececec
}

.tv-screener-market-select--hidden {
  height: 0;
  padding: 0 5px;
  visibility: hidden;
  width: 0
}

.tv-flag-country {
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  overflow: hidden;
  vertical-align: middle;
  width: 20px
}

.tv-flag-country--size_block-title {
  height: 22px;
  width: 22px
}

.tv-flag-country--forex {
  border-radius: 0;
  color: #363a45;
  overflow: visible
}

html.theme-dark .tv-flag-country--forex {
  color: #fff
}

.tv-flag-country--btc {
  border-radius: 0;
  overflow: visible
}

.tv-control-checkbox {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer
}

.tv-control-checkbox--in-actions {
  max-width: 50%
}

@media screen and (max-width: 479px) {
  .tv-control-checkbox--in-actions {
      max-width:none
  }
}

.tv-control-checkbox,.tv-control-checkbox__label {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle
}

.tv-control-checkbox--nowrap,.tv-control-checkbox__label--nowrap {
  white-space: nowrap
}

.tv-control-checkbox__input {
  height: 18px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 18px
}

.tv-control-checkbox__box {
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  height: 18px;
  line-height: 1;
  pointer-events: none;
  transition: background-color .35s ease;
  width: 18px
}

.tv-control-checkbox__box:before {
  background-color: initial;
  border-radius: 3px;
  left: 50%;
  margin-left: -9px;
  margin-top: -9px;
  top: 50%;
  transform: scale(1);
  transition: transform .35s ease,background-color .35s ease,border-radius .35s ease
}

.tv-control-checkbox__box:after,.tv-control-checkbox__box:before {
  content: "";
  display: block;
  height: 18px;
  position: absolute;
  width: 18px
}

.tv-control-checkbox__box:after {
  border: 2px solid #758696;
  border-radius: 3px;
  box-sizing: border-box;
  left: 0;
  top: 0;
  transition: border-color .35s ease
}

.tv-control-checkbox__box svg {
  stroke: #0000;
  display: block;
  height: 12px;
  left: 50%;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  transform: scale(0);
  transition: stroke .35s ease 1ms,transform .35s ease 1ms;
  width: 12px
}

.tv-control-checkbox__label {
  margin-right: 10px;
  white-space: normal
}

.tv-control-checkbox__label--two-lines {
  width: 155px
}

.tv-control-checkbox__label--nowrap {
  white-space: nowrap
}

.tv-control-checkbox__label--lil-line-height {
  line-height: 16px;
  max-width: 80%;
  min-width: 45%
}

.tv-control-checkbox__label+.tv-control-checkbox {
  margin-left: 0
}

.tv-control-checkbox+.tv-control-checkbox__label {
  margin-left: 10px;
  margin-right: 0;
  max-width: calc(100% - 1ex - 28px)
}

.tv-control-checkbox:active .tv-control-checkbox__box,.tv-control-checkbox__input:focus+.tv-control-checkbox__box {
  will-change: background-color
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-checkbox:hover .tv-control-checkbox__box {
      will-change:background-color
  }
}

.tv-control-checkbox:active .tv-control-checkbox__box:before,.tv-control-checkbox__input:focus+.tv-control-checkbox__box:before {
  will-change: transform,border-radius
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-checkbox:hover .tv-control-checkbox__box:before {
      will-change:transform,border-radius
  }
}

.tv-control-checkbox:active .tv-control-checkbox__box:after,.tv-control-checkbox__input:focus+.tv-control-checkbox__box:after {
  will-change: border-color
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-checkbox:hover .tv-control-checkbox__box:after {
      border-color:#627384;
      will-change: border-color
  }
}

.tv-control-checkbox:active .tv-control-checkbox__box:after,.tv-control-checkbox__input:focus+.tv-control-checkbox__box:after,html.theme-dark .tv-control-checkbox:active .tv-control-checkbox__box:after,html.theme-dark .tv-control-checkbox__input:focus+.tv-control-checkbox__box:after {
  border-color: #2962ff
}

.tv-control-checkbox__input:checked+.tv-control-checkbox__box,html.theme-dark .tv-control-checkbox__input:checked+.tv-control-checkbox__box {
  background-color: #2962ff
}

.tv-control-checkbox__input:checked+.tv-control-checkbox__box,.tv-control-checkbox__input:checked+.tv-control-checkbox__box:after,.tv-control-checkbox__input:checked+.tv-control-checkbox__box:before {
  transition-timing-function: cubic-bezier(.215,.61,.355,1)
}

.tv-control-checkbox__input:checked+.tv-control-checkbox__box:before {
  border-radius: 50%;
  transform: scale(0)
}

.tv-control-checkbox__input:checked+.tv-control-checkbox__box:after,html.theme-dark .tv-control-checkbox__input:checked+.tv-control-checkbox__box:after {
  border-color: #2962ff
}

.tv-control-checkbox__input:checked+.tv-control-checkbox__box svg {
  stroke: #fff;
  transform: scale(1);
  transition-delay: .0875s;
  transition-timing-function: cubic-bezier(.215,.61,.355,1);
  will-change: stroke,transform
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-checkbox:hover .tv-control-checkbox__input:checked+.tv-control-checkbox__box {
      background-color:#1e53e5
  }

  .tv-control-checkbox:hover .tv-control-checkbox__input:checked+.tv-control-checkbox__box:after {
      border-color: #1e53e5
  }
}

.tv-control-checkbox:active .tv-control-checkbox__input:checked+.tv-control-checkbox__box,.tv-control-checkbox__input:focus:checked+.tv-control-checkbox__box {
  background-color: #2962ff
}

.tv-control-checkbox:active .tv-control-checkbox__input:checked+.tv-control-checkbox__box:after,.tv-control-checkbox__input:focus:checked+.tv-control-checkbox__box:after {
  border-color: #2962ff
}

.tv-control-checkbox:active .tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:after,.tv-control-checkbox:active .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:after,.tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:after,.tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:after {
  border-color: #e0e3eb
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-checkbox:hover .tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:after,.tv-control-checkbox:hover .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:after {
      border-color:#e0e3eb
  }
}

html.theme-dark .tv-control-checkbox:active .tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:after,html.theme-dark .tv-control-checkbox:active .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:after,html.theme-dark .tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:after,html.theme-dark .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:after {
  border-color: #363a45
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-checkbox:hover .tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:after,html.theme-dark .tv-control-checkbox:hover .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:after {
      border-color:#363a45
  }
}

.tv-control-checkbox:active .tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:before,.tv-control-checkbox:active .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:before {
  background-color: #bbd9fb;
  transition: background-color .35s ease
}

html.theme-dark .tv-control-checkbox:active .tv-control-checkbox__input[disabled]+.tv-control-checkbox__box:before,html.theme-dark .tv-control-checkbox:active .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box:before {
  background-color: #2a2e39
}

.tv-control-checkbox:active .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box,.tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box {
  background-color: #dadde0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-checkbox:hover .tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box {
      background-color:#dadde0
  }
}

.tv-control-checkbox__ripple {
  border-radius: 50%;
  display: block;
  height: 100%;
  margin: -10px;
  -webkit-mask-image: radial-gradient(circle,#fff 100%,#000 0);
  mask-image: radial-gradient(circle,#fff 100%,#000 0);
  overflow: hidden;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.tv-control-checkbox__input:checked+.tv-control-checkbox__box+.tv-control-checkbox__ripple .tv-ripple {
  background-color: #2962ff40
}

.tv-control-checkbox__input[disabled]+.tv-control-checkbox__box+.tv-control-checkbox__ripple .tv-ripple,.tv-control-checkbox__input[disabled]:checked+.tv-control-checkbox__box+.tv-control-checkbox__ripple .tv-ripple {
  background-color: initial
}

.tv-control-checkbox.i-error .tv-control-checkbox__box:after {
  border-color: #ff4a68
}

.tv-control-checkbox.i-disabled {
  cursor: default
}

.tv-dropdown-behavior__body {
  box-sizing: border-box;
  display: none
}

.tv-dropdown-behavior__body.i-opened {
  display: block
}

.tv-dropdown-behavior__body--width_full {
  min-width: 100%
}

.tv-dropdown-behavior__button {
  cursor: pointer
}

.tv-dropdown-behavior__inscroll,.tv-dropdown-behavior__item,.tv-dropdown-behavior__scroll {
  display: block;
  position: relative
}

.tv-dropdown-behavior__scroll {
  overflow: hidden
}

.tv-dropdown {
  position: relative;
  vertical-align: middle
}

.tv-dropdown--inline {
  display: inline-block
}

.tv-dropdown.i-inactive .tv-dropdown__item,.tv-dropdown__group.i-inactive .tv-dropdown__item {
  background-color: initial;
  color: #adaeb0;
  cursor: default
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-dropdown.i-inactive .tv-dropdown__item:hover,.tv-dropdown__group.i-inactive .tv-dropdown__item:hover {
      background-color:initial;
      color: #adaeb0;
      cursor: default
  }
}

.tv-dropdown__desc {
  color: #adaeb0;
  display: block;
  font-size: 11px;
  margin-top: 2px;
  transition: color .35s ease
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-dropdown__item:hover .tv-dropdown__desc {
      color:#757575;
      transition-duration: .06s
  }
}

.tv-dropdown.i-inactive .tv-dropdown__desc,.tv-dropdown__group.i-inactive .tv-dropdown__desc {
  color: #c8c8c8;
  transition-duration: .06s
}

.tv-dropdown__button {
  cursor: pointer
}

.tv-dropdown__button--dashed {
  border-bottom-style: dashed
}

.tv-dropdown__group--divided {
  border-bottom: 1px solid #eaecef
}

.tv-dropdown__group--divided:last-child {
  border-bottom: none
}

.tv-dropdown__body {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px #0003;
  font-size: 13px;
  left: 0;
  line-height: 1;
  padding: 15px 0;
  position: absolute;
  top: -15px;
  z-index: 99
}

html.theme-dark .tv-dropdown__body {
  background-color: #1e222d;
  box-shadow: 0 2px 4px #0006
}

.tv-dropdown__body--under-trigger {
  top: 100%;
  top: calc(100% - 1px)
}

.tv-dropdown__body--over-trigger {
  top: 0
}

.tv-dropdown__body--position_right {
  left: auto;
  right: 0
}

.tv-dropdown__body--position_outer_right {
  left: 100%
}

.tv-dropdown__title {
  color: #bdbec0;
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  padding: 10px 15px;
  text-transform: uppercase
}

.tv-dropdown__title:first-child {
  margin-top: 0
}

.tv-dropdown__item {
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  transition: color .35s ease,background-color .35s ease;
  white-space: nowrap
}

.tv-dropdown__item,.tv-dropdown__item:visited {
  color: #2f3241
}

html.theme-dark .tv-dropdown__item,html.theme-dark .tv-dropdown__item:visited {
  color: #d6d8e0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-dropdown__item:hover:not(.i-active) {
      background-color:#f0f3fa;
      color: #434651;
      transition-duration: .06s
  }

  html.theme-dark .tv-dropdown__item:hover:not(.i-active) {
      background-color: #2a2e39;
      color: #d1d4dc
  }
}

.tv-dropdown__item:active {
  background-color: #3179f52e;
  color: #434651
}

html.theme-dark .tv-dropdown__item:active {
  color: #d1d4dc
}

.tv-dropdown__item.i-active {
  color: #434651
}

html.theme-dark .tv-dropdown__item.i-active {
  color: #d1d4dc
}

.tv-dropdown__item.i-active,.tv-dropdown__item.i-active:active {
  background-color: #3179f52e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-dropdown__item.i-active:hover {
      background-color:#3179f52e
  }
}

.tv-dropdown__item.i-disabled,.tv-dropdown__item.i-disabled:active {
  background-color: initial;
  color: #e5e5e8;
  cursor: default
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-dropdown__item.i-disabled:hover {
      background-color:initial;
      color: #e5e5e8;
      cursor: default
  }
}

html.theme-dark .tv-dropdown__item.i-disabled,html.theme-dark .tv-dropdown__item.i-disabled:active {
  color: #758696
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-dropdown__item.i-disabled:hover {
      color:#758696
  }
}

.tv-dropdown__item--subitem {
  padding: 10px 15px 10px 30px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-dropdown__item--not-interactive:hover {
      background-color:initial
  }
}

.tv-dropdown__item--not-interactive.i-active,.tv-dropdown__item--not-interactive:active {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-dropdown__item--not-interactive.i-active:hover {
      background-color:initial
  }
}

.tv-dropdown__item--not-interactive.i-active:active {
  background-color: initial
}

.tv-dropdown__item-text {
  vertical-align: middle
}

.tv-control-select__wrap {
  display: block;
  min-width: 40px;
  position: relative;
  -webkit-user-select: none;
  user-select: none
}

.tv-control-select__wrap--readonly {
  border-color: #b4b4b4;
  color: #b4b4b4;
  cursor: text;
  -webkit-user-select: text;
  user-select: text
}

.tv-control-select__control,select.tv-control-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #e0e3eb;
  border-radius: 2px;
  box-sizing: border-box;
  color: #434651;
  color: #535353;
  cursor: pointer;
  display: block;
  font-size: 13px;
  height: 34px;
  line-height: 34px;
  padding: 0 12px;
  position: relative;
  transition: border-color .35s ease,background-color .35s ease;
  transition: color .35s ease;
  white-space: nowrap;
  width: 100%
}

html.theme-dark .tv-control-select__control,html.theme-dark select.tv-control-select {
  background-color: #1e222d;
  border-color: #363a45;
  color: #c5cbce;
  color: #d1d4dc
}

.tv-control-select__control::placeholder,select.tv-control-select::placeholder {
  color: #a3a6af;
  opacity: 1
}

html.theme-dark .tv-control-select__control::placeholder,html.theme-dark select.tv-control-select::placeholder {
  color: #50535e
}

.tv-control-select__control:-webkit-autofill,select.tv-control-select:-webkit-autofill {
  -webkit-text-fill-color: #535353!important;
  box-shadow: inset 0 0 0 1000px #fff!important
}

.tv-control-select__control--size_xsmall,select.tv-control-select--size_xsmall {
  height: 19px
}

.tv-control-select__control--size_small,select.tv-control-select--size_small {
  height: 27px
}

.tv-control-select__control--size_large,select.tv-control-select--size_large {
  font-size: 16px;
  height: 48px
}

.tv-control-select__control--connect,select.tv-control-select--connect {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.tv-control-select__control--connect_left,select.tv-control-select--connect_left {
  border-bottom-left-radius: 0;
  border-left: none;
  border-top-left-radius: 0
}

.tv-control-select__control--connect_right,select.tv-control-select--connect_right {
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__control:hover,select.tv-control-select:hover {
      border-color:#c1c4cd;
      transition-duration: .06s
  }
}

.tv-control-select__control:focus,select.tv-control-select:focus {
  border-color: #2962ff!important;
  transition-duration: .06s
}

.tv-control-select__control[readonly],select.tv-control-select[readonly] {
  border-color: #dadde0;
  color: #8a8a8a
}

.tv-control-select__control[readonly]:focus,select.tv-control-select[readonly]:focus {
  border-color: #e0e3eb!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__control[readonly]:hover,select.tv-control-select[readonly]:hover {
      border-color:#e0e3eb!important
  }
}

html.theme-dark .tv-control-select__control[readonly]:focus,html.theme-dark select.tv-control-select[readonly]:focus {
  border-color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-select__control[readonly]:hover,html.theme-dark select.tv-control-select[readonly]:hover {
      border-color:#2a2e39!important
  }
}

.tv-control-select__control--readonly_dark,.tv-control-select__control--readonly_dark[readonly],html.theme-dark .tv-control-select__control--readonly_dark,html.theme-dark .tv-control-select__control--readonly_dark[readonly],html.theme-dark select.tv-control-select--readonly_dark,html.theme-dark select.tv-control-select--readonly_dark[readonly],select.tv-control-select--readonly_dark,select.tv-control-select--readonly_dark[readonly] {
  color: #50535e
}

.tv-control-select__control.i-disabled,.tv-control-select__control[disabled],select.tv-control-select.i-disabled,select.tv-control-select[disabled] {
  border-color: #e0e3eb!important;
  color: #e0e3eb!important
}

html.theme-dark .tv-control-select__control.i-disabled,html.theme-dark .tv-control-select__control[disabled],html.theme-dark select.tv-control-select.i-disabled,html.theme-dark select.tv-control-select[disabled] {
  border-color: #2a2e39!important;
  color: #2a2e39!important
}

.tv-control-select__control.i-disabled::placeholder,.tv-control-select__control[disabled]::placeholder,select.tv-control-select.i-disabled::placeholder,select.tv-control-select[disabled]::placeholder {
  color: #e0e3eb!important
}

html.theme-dark .tv-control-select__control.i-disabled::placeholder,html.theme-dark .tv-control-select__control[disabled]::placeholder,html.theme-dark select.tv-control-select.i-disabled::placeholder,html.theme-dark select.tv-control-select[disabled]::placeholder {
  color: #2a2e39!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__control.i-disabled:hover,.tv-control-select__control[disabled]:hover,select.tv-control-select.i-disabled:hover,select.tv-control-select[disabled]:hover {
      border-color:#e0e3eb!important
  }

  html.theme-dark .tv-control-select__control.i-disabled:hover,html.theme-dark .tv-control-select__control[disabled]:hover,html.theme-dark select.tv-control-select.i-disabled:hover,html.theme-dark select.tv-control-select[disabled]:hover {
      border-color: #2a2e39!important
  }
}

.tv-control-select__control.i-error,select.tv-control-select.i-error {
  border-color: #ff4a68!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__control.i-error:hover,select.tv-control-select.i-error:hover {
      border-color:#f24965!important
  }
}

.tv-control-select__control.i-error:focus,select.tv-control-select.i-error:focus {
  border-color: #ff173e!important
}

.tv-control-select__control.i-success,select.tv-control-select.i-success {
  border-color: #009688!important
}

html.theme-dark .tv-control-select__control.i-success,html.theme-dark select.tv-control-select.i-success {
  border-color: #00796b!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__control.i-success:hover,html.theme-dark .tv-control-select__control.i-success:hover,html.theme-dark select.tv-control-select.i-success:hover,select.tv-control-select.i-success:hover {
      border-color:#00897b!important
  }
}

.tv-control-select__control.i-success:active,.tv-control-select__control.i-success:focus,select.tv-control-select.i-success:active,select.tv-control-select.i-success:focus {
  border-color: #00796b!important
}

html.theme-dark .tv-control-select__control.i-success:active,html.theme-dark .tv-control-select__control.i-success:focus,html.theme-dark select.tv-control-select.i-success:active,html.theme-dark select.tv-control-select.i-success:focus {
  border-color: #009688!important
}

.tv-control-select__control--phone,select.tv-control-select--phone {
  direction: ltr;
  text-align: left
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__control:hover,select.tv-control-select:hover {
      border-color:#c1c4cd
  }

  html.theme-dark .tv-control-select__control:hover,html.theme-dark select.tv-control-select:hover {
      border-color: #50535e
  }
}

.tv-control-select__control.i-disabled,.tv-control-select__control[disabled],select.tv-control-select.i-disabled,select.tv-control-select[disabled] {
  border-color: #e0e3eb;
  cursor: default
}

html.theme-dark .tv-control-select__control.i-disabled,html.theme-dark .tv-control-select__control[disabled],html.theme-dark select.tv-control-select.i-disabled,html.theme-dark select.tv-control-select[disabled] {
  border-color: #2a2e39
}

.i-error .tv-control-select__control,.i-error select.tv-control-select {
  border-color: #ff4a68!important
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .i-error .tv-control-select__control:hover,.i-error select.tv-control-select:hover {
      border-color:#f24965!important
  }
}

.i-error .tv-control-select__control:focus,.i-error select.tv-control-select:focus {
  border-color: #ff173e!important
}

.tv-control-select--size_xsmall .tv-control-select__control {
  height: 19px;
  line-height: 19px
}

.tv-control-select--size_small .tv-control-select__control {
  height: 27px;
  line-height: 27px
}

.tv-control-select--size_large .tv-control-select__control {
  height: 48px;
  line-height: 48px
}

.tv-control-select__control-inner {
  display: block;
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tv-control-select__control-inner--option-disabled {
  color: #a3a6af
}

html.theme-dark .tv-control-select__control-inner--option-disabled {
  color: #50535e
}

.tv-control-select__control-inner--readonly {
  border-color: #b4b4b4;
  color: #b4b4b4;
  cursor: text;
  -webkit-user-select: text;
  user-select: text
}

.tv-control-select__control-inner:after {
  content: "\200E"
}

select.tv-control-select.i-inited {
  display: none
}

.tv-control-select__caret {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px
}

.tv-control-select__caret:after {
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-top: 4px solid #787b86;
  content: "";
  display: block;
  left: auto;
  margin-top: -2px;
  position: absolute;
  right: 15px;
  top: 50%;
  transition: transform .35s ease,border-color .35s ease
}

html.theme-dark .tv-control-select__caret:after {
  border-top-color: #787b86
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__wrap:hover .tv-control-select__caret:after {
      border-color:#434651 #0000 #0000;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-select__wrap:hover .tv-control-select__caret:after {
      border-color: #d1d4dc #0000 #0000
  }
}

.tv-control-select__wrap.i-opened .tv-control-select__caret:after {
  transform: rotate(-180deg)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__wrap.i-opened:hover .tv-control-select__caret:after,html.theme-dark .tv-control-select__wrap.i-opened:hover .tv-control-select__caret:after {
      border-color:#787b86 #0000 #0000
  }

  .tv-control-select__wrap.i-opened .tv-control-select__caret:hover:after {
      border-color: #434651 #0000 #0000;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-select__wrap.i-opened .tv-control-select__caret:hover:after {
      border-color: #d1d4dc #0000 #0000
  }
}

.tv-control-select__wrap.i-closing .tv-control-select__caret {
  z-index: 98
}

.tv-control-select__wrap.i-closing .tv-control-select__caret:after {
  transform: rotate(0)
}

.tv-control-select__wrap.i-disabled .tv-control-select__caret {
  cursor: default
}

.tv-control-select__wrap.i-disabled .tv-control-select__caret:after {
  border-color: #e0e3eb #0000 #0000
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__wrap.i-disabled:hover .tv-control-select__caret:after {
      border-color:#e0e3eb #0000 #0000
  }
}

html.theme-dark .tv-control-select__wrap.i-disabled .tv-control-select__caret:after {
  border-color: #2a2e39 #0000 #0000
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-select__wrap.i-disabled:hover .tv-control-select__caret:after {
      border-color:#2a2e39 #0000 #0000
  }
}

.tv-control-select__dropdown {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px #0003;
  font-size: 13px;
  left: 0;
  line-height: 1;
  padding: 15px 0;
  position: absolute;
  top: -15px;
  z-index: 99
}

html.theme-dark .tv-control-select__dropdown {
  background-color: #1e222d;
  box-shadow: 0 2px 4px #0006
}

.tv-control-select__dropdown--under-trigger {
  top: 100%;
  top: calc(100% - 1px)
}

.tv-control-select__dropdown--over-trigger {
  top: 0
}

.tv-control-select__dropdown--position_right {
  left: auto;
  right: 0
}

.tv-control-select__dropdown--position_outer_right {
  left: 100%
}

.tv-control-select__dropdown.tv-control-select__dropdown {
  cursor: default;
  left: -7px;
  right: -7px;
  top: -14px
}

.tv-control-select__scroll {
  height: 100%
}

.tv-control-select__option-wrap {
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  transition: color .35s ease,background-color .35s ease;
  white-space: nowrap
}

.tv-control-select__option-wrap,.tv-control-select__option-wrap:visited {
  color: #2f3241
}

html.theme-dark .tv-control-select__option-wrap,html.theme-dark .tv-control-select__option-wrap:visited {
  color: #d6d8e0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__option-wrap:hover:not(.i-active) {
      background-color:#f0f3fa;
      color: #434651;
      transition-duration: .06s
  }

  html.theme-dark .tv-control-select__option-wrap:hover:not(.i-active) {
      background-color: #2a2e39;
      color: #d1d4dc
  }
}

.tv-control-select__option-wrap:active {
  background-color: #3179f52e;
  color: #434651
}

html.theme-dark .tv-control-select__option-wrap:active {
  color: #d1d4dc
}

.tv-control-select__option-wrap.i-active {
  color: #434651
}

html.theme-dark .tv-control-select__option-wrap.i-active {
  color: #d1d4dc
}

.tv-control-select__option-wrap.i-active,.tv-control-select__option-wrap.i-active:active {
  background-color: #3179f52e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__option-wrap.i-active:hover {
      background-color:#3179f52e
  }
}

.tv-control-select__option-wrap.i-disabled,.tv-control-select__option-wrap.i-disabled:active {
  background-color: initial;
  color: #e5e5e8;
  cursor: default
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__option-wrap.i-disabled:hover {
      background-color:initial;
      color: #e5e5e8;
      cursor: default
  }
}

html.theme-dark .tv-control-select__option-wrap.i-disabled,html.theme-dark .tv-control-select__option-wrap.i-disabled:active {
  color: #758696
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-select__option-wrap.i-disabled:hover {
      color:#758696
  }
}

.tv-control-select__option-wrap--subitem {
  padding: 10px 15px 10px 30px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__option-wrap--not-interactive:hover {
      background-color:initial
  }
}

.tv-control-select__option-wrap--not-interactive.i-active,.tv-control-select__option-wrap--not-interactive:active {
  background-color: initial
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__option-wrap--not-interactive.i-active:hover {
      background-color:initial
  }
}

.tv-control-select__option-wrap--not-interactive.i-active:active {
  background-color: initial
}

.tv-control-select__option-wrap.tv-control-select__option-wrap {
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px
}

.tv-control-select__option.i-active .tv-control-select__option-wrap {
  color: #434651
}

html.theme-dark .tv-control-select__option.i-active .tv-control-select__option-wrap {
  color: #d1d4dc
}

.tv-control-select__option.i-active .tv-control-select__option-wrap,.tv-control-select__option.i-active .tv-control-select__option-wrap:active {
  background-color: #3179f52e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__option.i-active .tv-control-select__option-wrap:hover {
      background-color:#3179f52e
  }
}

.tv-control-select__option.i-disabled .tv-control-select__option-wrap {
  background-color: initial;
  color: #c1c4cd;
  cursor: default
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-control-select__option.i-disabled .tv-control-select__option-wrap:hover {
      color:#c1c4cd
  }
}

html.theme-dark .tv-control-select__option.i-disabled .tv-control-select__option-wrap {
  color: #50535e
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .tv-control-select__option.i-disabled .tv-control-select__option-wrap:hover {
      color:#50535e
  }

  .tv-control-select__option.i-disabled:hover .tv-control-select__option-wrap {
      background-color: initial
  }
}

.tv-control-select__optgroup {
  display: block
}

.tv-control-select__optgroup-label {
  color: #bdbec0;
  display: block;
  font-size: 14px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  margin-top: 10px;
  padding: 10px 20px;
  text-transform: uppercase
}

.tv-control-select__optgroup-label:first-child {
  margin-top: 0
}

.tv-control-select__optgroup-separator {
  display: none
}

.tv-control-select__optgroup-separator:not(:last-of-type) {
  background: #e0e3eb;
  border: none;
  display: block;
  height: 1px;
  margin: 8px 0
}

html.theme-dark .tv-control-select__optgroup-separator:not(:last-of-type) {
  background: #434651
}

.tv-control-select__optgroup-items {
  display: block
}

.tv-control-select__wrap--touch .tv-control-select__caret,.tv-control-select__wrap--touch .tv-control-select__control {
  pointer-events: none
}

.tv-control-select__wrap--touch .tv-control-select.i-inited {
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0
}

.tv-screener-prompt__label {
  color: #8a8a8a;
  font-size: 13px;
  margin-bottom: 10px
}

html.theme-dark .tv-screener-prompt__label {
  color: #4c525e
}

.tv-screener-dialog {
  overflow: auto
}

.tv-screener-dialog__wrap {
  min-height: 100%
}

.tv-screener-dialog__wrap:after {
  clear: both;
  content: "";
  display: table
}

.tv-screener-dialog__wrap--2col {
  position: relative
}

.tv-screener-dialog__wrap--2col:before {
  background-color: #f0f3fa;
  content: "";
  display: block;
  height: 100%;
  left: 50%;
  margin-left: -2px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: 1
}

html.theme-dark .tv-screener-dialog__wrap--2col:before {
  background-color: #2a2e39
}

.tv-screener-dialog__wrap--2col .tv-screener-dialog__filter-field {
  width: 50%
}

.tv-screener-dialog__filter-field {
  align-items: center;
  border-bottom: 1px solid #f0f3fa;
  box-sizing: border-box;
  display: flex;
  float: left;
  height: 55px;
  overflow: hidden;
  padding: 0 28px;
  position: relative;
  width: 100%
}

html.theme-dark .tv-screener-dialog__filter-field {
  border-bottom-color: #2a2e39
}

.tv-screener-dialog__filter-field--active,.tv-screener-dialog__filter-field--active:active {
  background-color: #bbd9fb
}

html.theme-dark .tv-screener-dialog__filter-field--active,html.theme-dark .tv-screener-dialog__filter-field--active:active {
  background-color: #142e61
}

.tv-screener-dialog__filter-field--active .tv-screener-dialog__filter-field-reset {
  visibility: visible
}

.tv-screener-dialog__filter-field:last-child {
  border-bottom: none
}

.tv-screener-dialog--cat1 .tv-screener-dialog__filter-field--cat2,.tv-screener-dialog--cat1 .tv-screener-dialog__filter-field--cat3,.tv-screener-dialog--cat2 .tv-screener-dialog__filter-field--cat1,.tv-screener-dialog--cat2 .tv-screener-dialog__filter-field--cat3,.tv-screener-dialog--cat3 .tv-screener-dialog__filter-field--cat1,.tv-screener-dialog--cat3 .tv-screener-dialog__filter-field--cat2 {
  display: none
}

.tv-screener-dialog__filter-field-title {
  box-sizing: border-box;
  font-size: 13px;
  padding-right: 20px;
  position: relative;
  width: 30%
}

.tv-screener-dialog__filter-field-content {
  height: 27px;
  margin-left: 10px;
  position: relative;
  width: 63%
}

.tv-screener-dialog__filter-field-content--left,.tv-screener-dialog__filter-field-content--middle,.tv-screener-dialog__filter-field-content--right {
  display: inline-block
}

.tv-screener-dialog__filter-field-content--middle,.tv-screener-dialog__filter-field-content--right {
  margin-left: 20px
}

.tv-screener-dialog__filter-field-content-value:not(:last-child):after {
  content: ", "
}

.tv-screener-dialog__filter-field-text {
  background-color: #fff;
  border: 1px solid #d1d4dc;
  border-radius: 2px;
  color: #434651;
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px!important;
  height: 25px;
  padding: 0 6px;
  width: 103px
}

html.theme-dark .tv-screener-dialog__filter-field-text {
  background-color: #1e222d;
  border-color: #50535e;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-dialog__filter-field-text:hover {
      border-color:#a3a6af
  }

  html.theme-dark .tv-screener-dialog__filter-field-text:hover {
      border-color: #6a6d78
  }
}

.tv-screener-dialog__filter-field-text:focus,html.theme-dark .tv-screener-dialog__filter-field-text:focus {
  border-color: #2962ff
}

.tv-screener-dialog__filter-between-input {
  background-color: #fff;
  border: 1px solid #d1d4dc;
  border-radius: 2px;
  color: #434651;
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px!important;
  height: 25px;
  padding: 0 6px;
  width: 103px;
  width: 50px
}

html.theme-dark .tv-screener-dialog__filter-between-input {
  background-color: #1e222d;
  border-color: #50535e;
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-dialog__filter-between-input:hover {
      border-color:#a3a6af
  }

  html.theme-dark .tv-screener-dialog__filter-between-input:hover {
      border-color: #6a6d78
  }
}

.tv-screener-dialog__filter-between-input:focus,html.theme-dark .tv-screener-dialog__filter-between-input:focus {
  border-color: #2962ff
}

.tv-screener-dialog__filter-between-separator {
  margin-left: 20px;
  padding: 0 6px
}

.tv-screener-dialog__filter-field-reset {
  cursor: pointer;
  margin-left: 30px;
  visibility: hidden;
  white-space: nowrap
}

.tv-screener-dialog__filter-field-reset svg {
  fill: #2962ff
}

.tv-screener-dialog__selectbox-container {
  height: 27px;
  line-height: 27px;
  white-space: nowrap
}

.tv-screener-dialog__selectbox-dropdown-toggle {
  display: inline-block;
  height: 16px;
  margin: 6px 0 0 5px;
  transition: transform .35s ease;
  vertical-align: top;
  width: 16px
}

.tv-screener-dialog__selectbox-dropdown-toggle svg {
  stroke: #9db2bd;
  stroke-width: 1.5px;
  display: block;
  margin: 5px 0 0 2px
}

.tv-screener-dialog__selectbox-dropdown-toggle--open {
  transform: rotate(180deg)
}

.tv-screener-dialog__selectbox-caption--no-value,.tv-screener-dialog__selectbox-option--no-value .tv-screener-dialog__selectbox-option-wrap {
  color: #a3a6af!important
}

html.theme-dark .tv-screener-dialog__selectbox-caption--no-value,html.theme-dark .tv-screener-dialog__selectbox-option--no-value .tv-screener-dialog__selectbox-option-wrap {
  color: #50535e!important
}

.tv-screener-dialog__selectbox-caption {
  cursor: pointer
}

.tv-screener-dialog__selectbox-caption--text-overflow {
  display: inline-block;
  max-width: 100%
}

.tv-screener-dialog__selectbox-caption--text-overflow .tv-screener-dialog__selectbox-caption-label {
  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  padding-right: 23px;
  text-overflow: ellipsis
}

.tv-screener-dialog__selectbox-caption--text-overflow .tv-screener-dialog__selectbox-dropdown-toggle {
  margin-left: -18px
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-dialog__selectbox-caption:hover .tv-screener-dialog__selectbox-dropdown-toggle svg {
      stroke:#758696
  }
}

.tv-screener-dialog__search-wrap {
  border-bottom: 1px solid;
  border-color: #e0e3eb;
  height: 45px;
  position: relative
}

html.theme-dark .tv-screener-dialog__search-wrap {
  border-color: #363a45
}

.tv-screener-dialog__search-input {
  background-color: #fff;
  border: none;
  box-sizing: border-box;
  display: block;
  height: 45px;
  margin: 0;
  padding: 10px 22px 0;
  width: 100%
}

html.theme-dark .tv-screener-dialog__search-input {
  background-color: #1e222d
}

.tv-screener-dialog__search-no-matches {
  color: #787b86;
  font-style: italic;
  pointer-events: none
}

html.theme-dark .tv-screener-dialog__search-no-matches {
  color: #787b86
}

.tv-screener-dialog__filter-dropdown-toggle {
  height: 16px;
  margin-right: 7px;
  pointer-events: none;
  position: absolute;
  right: 7%;
  top: 20px;
  transition: transform .2s ease-in-out;
  width: 16px
}

.tv-screener-dialog__filter-dropdown-toggle svg {
  stroke: #9db2bd;
  display: block;
  margin: 5px 0 0 2px
}

.tv-screener-dialog__filter-dropdown-toggle--open {
  transform: rotate(180deg)
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .tv-screener-dialog__filter-field-content--select:hover~.tv-screener-dialog__filter-dropdown-toggle svg {
      stroke:#758696
  }
}

.tv-screener-dialog__filter-toggle {
  display: inline-block;
  margin-top: 4px
}

.tv-screener-dialog__filter-toggle-checkbox {
  left: 0;
  position: relative
}

.tv-screener-dialog .tv-screener-dialog__filter-toggle-checkbox-title {
  display: none
}

.tv-screener-dialog__selectbox-option-wrap {
  color: #434651
}

html.theme-dark .tv-screener-dialog__selectbox-option-wrap {
  color: #d1d4dc
}

.tv-screener-search {
  border-bottom: 1px solid #f0f3fa;
  height: 40px;
  position: relative
}

html.theme-dark .tv-screener-search {
  border-bottom-color: #2a2e39
}

.tv-screener-search__input {
  background-color: #fff;
  border: none;
  box-sizing: border-box;
  color: #434651;
  font-size: 14px;
  height: 40px;
  padding: 0 135px 0 57px;
  width: 100%
}

html.theme-dark .tv-screener-search__input {
  background-color: #1e222d;
  color: #d1d4dc
}

.tv-screener-search__input::placeholder {
  color: #a3a6af
}

html.theme-dark .tv-screener-search__input::placeholder {
  color: #50535e
}

.tv-screener-search svg {
  left: 27px;
  pointer-events: none;
  position: absolute;
  top: 12px
}

.tv-screener-search__reset {
  color: #2962ff;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 12px
}

.tv-screener-search__reset-icon svg {
  fill: #2962ff;
  left: -26px;
  pointer-events: auto;
  position: absolute;
  top: -2px
}

.tv-spinner {
  animation: tv-spinner__container-rotate .9s linear infinite;
  color: #2962ff;
  direction: ltr;
  display: none;
  height: 1em;
  left: calc(50% - .5em);
  margin: 0 auto;
  position: absolute;
  top: calc(50% - .5em);
  width: 1em;
  will-change: transform
}

.tv-spinner--size_mini {
  font-size: 16px
}

.tv-spinner--size_medium {
  font-size: 32px
}

.tv-spinner--size_large {
  font-size: 56px
}

.tv-spinner--size_mini .tv-spinner__width_element:after {
  border-width: 2px
}

.tv-spinner--size_medium .tv-spinner__width_element:after {
  border-width: 3px
}

.tv-spinner--size_large .tv-spinner__width_element:after {
  border-width: 4px
}

.tv-spinner--shown {
  display: block
}

.tv-spinner__spinner-layer {
  color: currentColor;
  height: 100%;
  position: absolute;
  transform: rotate(90deg);
  white-space: nowrap;
  width: 100%
}

.tv-spinner__spinner-layer:after {
  border-radius: 50%;
  border-top-style: solid;
  border-width: .07em;
  box-sizing: border-box;
  content: "";
  left: 45%;
  position: absolute;
  top: 0;
  width: 10%
}

.tv-spinner__background {
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%
}

.tv-spinner__background:after {
  border-color: #8797a533;
  border-radius: 50%;
  border-style: solid;
  bottom: 0;
  box-sizing: border-box;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.tv-spinner__circle-clipper {
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 50%
}

.tv-spinner__circle-clipper:after {
  animation-duration: 1.4s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.36,0,.37,.99);
  border-bottom-color: #0000;
  border-radius: 50%;
  border-style: solid;
  bottom: 0;
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 0;
  width: 200%;
  will-change: transform
}

.tv-spinner__circle-clipper--left:after {
  animation-name: tv-spinner__left-spin;
  border-right-color: #0000;
  left: 0;
  transform: rotate(0deg)
}

.tv-spinner__circle-clipper--right:after {
  animation-name: tv-spinner__right-spin;
  border-left-color: #0000;
  left: -100%;
  transform: rotate(-124deg)
}

@keyframes tv-spinner__container-rotate {
  to {
      transform: rotate(1turn)
  }
}

@keyframes tv-spinner__left-spin {
  0% {
      transform: rotate(130deg)
  }

  to {
      transform: rotate(0deg)
  }
}

@keyframes tv-spinner__right-spin {
  0% {
      transform: rotate(-130deg)
  }

  to {
      transform: rotate(-124deg)
  }
}

.separator-LcIsiH9i {
  background-color: #e0e3eb;
  height: 1px;
  margin: 6px 0
}

html.theme-dark .separator-LcIsiH9i {
  background-color: #434651
}

.loader-8x1ZxRwP {
  bottom: 0;
  font-size: 0;
  height: 100%;
  left: 0;
  margin: 0 auto;
  opacity: 1;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity .35s ease
}

.loader-8x1ZxRwP:after {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.loader-8x1ZxRwP .item-2-89r_cd {
  animation: tv-button-loader-23vqS1uY .96s ease-in-out infinite both;
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  margin-left: 2px;
  margin-right: 2px;
  opacity: 1;
  transform: translateY(0) scale(.6);
  transition: transform .35s cubic-bezier(.68,-.55,.265,1.55);
  vertical-align: middle;
  width: 10px
}

.loader-8x1ZxRwP .item-2-89r_cd:nth-child(2) {
  animation-delay: .151s;
  transition-delay: .11666667s
}

.loader-8x1ZxRwP .item-2-89r_cd:nth-child(3) {
  animation-delay: .32s;
  transition-delay: 233.33333ms
}

.loader-8x1ZxRwP .item-2-89r_cd.black-20Ytsf0V {
  background-color: #787b86
}

.loader-8x1ZxRwP .item-2-89r_cd.white-1ucCcc2I {
  background-color: #fff
}

.loader-8x1ZxRwP .item-2-89r_cd.gray-XDhHSS-T {
  background-color: #b2b5be
}

.loader-8x1ZxRwP .item-2-89r_cd.primary-i6Z_zze- {
  background-color: #131722
}

html.theme-dark .loader-8x1ZxRwP .item-2-89r_cd.primary-i6Z_zze- {
  background-color: #d1d4dc
}

.loader-8x1ZxRwP.loader-initial-1deQDeio {
  opacity: .1
}

.loader-8x1ZxRwP.loader-initial-1deQDeio .item-2-89r_cd {
  animation: none;
  transform: translateY(12px) scale(.6)
}

.loader-8x1ZxRwP.loader-appear-2krFtMrd {
  opacity: 1;
  transition: opacity .7s ease
}

.loader-8x1ZxRwP.loader-appear-2krFtMrd .item-2-89r_cd {
  animation: none;
  transform: translateY(0) scale(.6)
}

@keyframes tv-button-loader-23vqS1uY {
  0%,to {
      transform: scale(.6)
  }

  50% {
      transform: scale(.9)
  }
}

.item-f5BaKrKq {
  height: 32px;
  vertical-align: middle;
  white-space: nowrap
}

.item-f5BaKrKq.interactive-f5BaKrKq {
  color: #131722
}

html.theme-dark .item-f5BaKrKq.interactive-f5BaKrKq {
  color: #b2b5be
}

.item-f5BaKrKq.interactive-f5BaKrKq.hovered-f5BaKrKq,.item-f5BaKrKq.interactive-f5BaKrKq:active {
  color: #131722
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-f5BaKrKq.interactive-f5BaKrKq:hover {
      color:#131722
  }
}

html.theme-dark .item-f5BaKrKq.interactive-f5BaKrKq.hovered-f5BaKrKq,html.theme-dark .item-f5BaKrKq.interactive-f5BaKrKq:active {
  color: #c1c4cd
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-f5BaKrKq.interactive-f5BaKrKq:hover {
      color:#c1c4cd
  }
}

.item-f5BaKrKq.interactive-f5BaKrKq.hovered-f5BaKrKq,.item-f5BaKrKq.interactive-f5BaKrKq:active {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-f5BaKrKq.interactive-f5BaKrKq:hover {
      background-color:#f0f3fa
  }
}

html.theme-dark .item-f5BaKrKq.interactive-f5BaKrKq.hovered-f5BaKrKq,html.theme-dark .item-f5BaKrKq.interactive-f5BaKrKq:active {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-f5BaKrKq.interactive-f5BaKrKq:hover {
      background-color:#2a2e39
  }
}

.item-f5BaKrKq.disabled-f5BaKrKq {
  cursor: default;
  opacity: .3
}

.item-f5BaKrKq.disabled-f5BaKrKq,.item-f5BaKrKq.disabled-f5BaKrKq:active {
  background-color: #fff;
  color: #131722
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-f5BaKrKq.disabled-f5BaKrKq:hover {
      background-color:#fff;
      color: #131722
  }
}

html.theme-dark .item-f5BaKrKq.disabled-f5BaKrKq,html.theme-dark .item-f5BaKrKq.disabled-f5BaKrKq:active {
  background-color: #1e222d
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-f5BaKrKq.disabled-f5BaKrKq:hover {
      background-color:#1e222d
  }
}

html.theme-dark .item-f5BaKrKq.disabled-f5BaKrKq,html.theme-dark .item-f5BaKrKq.disabled-f5BaKrKq:active {
  color: #b2b5be
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-f5BaKrKq.disabled-f5BaKrKq:hover {
      color:#b2b5be
  }
}

.item-f5BaKrKq.active-f5BaKrKq,.item-f5BaKrKq.active-f5BaKrKq:active {
  background-color: #2962ff;
  color: #fff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-f5BaKrKq.active-f5BaKrKq:hover {
      background-color:#2962ff;
      color: #fff
  }
}

html.theme-dark .item-f5BaKrKq.active-f5BaKrKq,html.theme-dark .item-f5BaKrKq.active-f5BaKrKq:active {
  background-color: #2962ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-f5BaKrKq.active-f5BaKrKq:hover {
      background-color:#2962ff
  }
}

html.theme-dark .item-f5BaKrKq.active-f5BaKrKq,html.theme-dark .item-f5BaKrKq.active-f5BaKrKq:active {
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-f5BaKrKq.active-f5BaKrKq:hover {
      color:#d1d4dc
  }
}

.item-f5BaKrKq.active-f5BaKrKq .shortcut-f5BaKrKq,.item-f5BaKrKq.active-f5BaKrKq:active .shortcut-f5BaKrKq {
  color: #b2b5beb3
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-f5BaKrKq.active-f5BaKrKq:hover .shortcut-f5BaKrKq {
      color:#b2b5beb3
  }
}

html.theme-dark .item-f5BaKrKq.active-f5BaKrKq .shortcut-f5BaKrKq,html.theme-dark .item-f5BaKrKq.active-f5BaKrKq:active .shortcut-f5BaKrKq {
  color: #787b86b3
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-f5BaKrKq.active-f5BaKrKq:hover .shortcut-f5BaKrKq {
      color:#787b86b3
  }
}

.iconCell-f5BaKrKq {
  min-width: 6px
}

.icon-f5BaKrKq {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 8px;
  width: 28px
}

.icon-f5BaKrKq svg {
  height: 28px;
  width: 28px
}

.icon-f5BaKrKq.checkmark-f5BaKrKq svg {
  fill: currentColor;
  height: 14px;
  width: 18px
}

.content-f5BaKrKq {
  align-items: center;
  display: flex;
  max-width: 365px;
  padding-left: 6px;
  padding-right: 20px
}

.label-f5BaKrKq {
  flex: 1 1 auto;
  font-size: 14px;
  overflow-x: hidden;
  text-align: left
}

.toolbox-f5BaKrKq {
  flex: 0 1 auto;
  line-height: 14px;
  margin-left: 10px
}

.feature-no-mobiletouch .toolbox-f5BaKrKq.showToolboxOnHover-f5BaKrKq {
  opacity: 0
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .feature-no-mobiletouch .item-f5BaKrKq:hover .toolbox-f5BaKrKq.showToolboxOnHover-f5BaKrKq {
      opacity:1
  }
}

.arrowIcon-f5BaKrKq {
  flex: 0 1 auto;
  margin-left: 10px
}

.arrowIcon-f5BaKrKq svg {
  fill: currentColor;
  height: 10px
}

.shortcut-f5BaKrKq {
  color: #9598a1;
  flex: 0 1 auto;
  font-size: 12px;
  margin-left: 10px;
  padding-top: 2px
}

html.theme-dark .shortcut-f5BaKrKq {
  color: #5d606b
}

.subMenu-f5BaKrKq {
  height: 0
}

.invisibleHotkey-f5BaKrKq {
  visibility: hidden
}

.item-1-SF84yU {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  min-height: 40px;
  padding: 8px 8px 8px 42px;
  position: relative
}

.item-1-SF84yU.emptyIcons-1-SF84yU {
  padding-left: 20px
}

.item-1-SF84yU.loading-1-SF84yU {
  justify-content: center;
  position: relative
}

.item-1-SF84yU.disabled-1-SF84yU {
  cursor: default;
  opacity: .3
}

.item-1-SF84yU.disabled-1-SF84yU,.item-1-SF84yU.disabled-1-SF84yU:active {
  background-color: #fff;
  color: #131722
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-1-SF84yU.disabled-1-SF84yU:hover {
      background-color:#fff;
      color: #131722
  }
}

html.theme-dark .item-1-SF84yU.disabled-1-SF84yU,html.theme-dark .item-1-SF84yU.disabled-1-SF84yU:active {
  background-color: #1e222d
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-1-SF84yU.disabled-1-SF84yU:hover {
      background-color:#1e222d
  }
}

html.theme-dark .item-1-SF84yU.disabled-1-SF84yU,html.theme-dark .item-1-SF84yU.disabled-1-SF84yU:active {
  color: #b2b5be
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-1-SF84yU.disabled-1-SF84yU:hover {
      color:#b2b5be
  }
}

.item-1-SF84yU.interactive-1-SF84yU {
  color: #131722
}

html.theme-dark .item-1-SF84yU.interactive-1-SF84yU {
  color: #b2b5be
}

.item-1-SF84yU.interactive-1-SF84yU.hovered-1-SF84yU,.item-1-SF84yU.interactive-1-SF84yU:active {
  color: #131722
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-1-SF84yU.interactive-1-SF84yU:hover {
      color:#131722
  }
}

html.theme-dark .item-1-SF84yU.interactive-1-SF84yU.hovered-1-SF84yU,html.theme-dark .item-1-SF84yU.interactive-1-SF84yU:active {
  color: #c1c4cd
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-1-SF84yU.interactive-1-SF84yU:hover {
      color:#c1c4cd
  }
}

.item-1-SF84yU.interactive-1-SF84yU.hovered-1-SF84yU,.item-1-SF84yU.interactive-1-SF84yU:active {
  background-color: #f0f3fa
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .item-1-SF84yU.interactive-1-SF84yU:hover {
      background-color:#f0f3fa
  }
}

html.theme-dark .item-1-SF84yU.interactive-1-SF84yU.hovered-1-SF84yU,html.theme-dark .item-1-SF84yU.interactive-1-SF84yU:active {
  background-color: #2a2e39
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  html.theme-dark .item-1-SF84yU.interactive-1-SF84yU:hover {
      background-color:#2a2e39
  }
}

.icon-1-SF84yU {
  align-items: center;
  color: #131722;
  display: flex;
  height: 28px;
  justify-content: center;
  left: 8px;
  position: absolute;
  top: 6px;
  width: 28px
}

html.theme-dark .icon-1-SF84yU {
  color: #b2b5be
}

.label-1-SF84yU {
  color: #131722;
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
  word-break: break-word
}

html.theme-dark .label-1-SF84yU {
  color: #b2b5be
}

.title-1-SF84yU {
  font-weight: 700
}

.nested-1-SF84yU {
  align-items: center;
  align-self: center;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-left: auto;
  width: 18px
}

.shortcut-1-SF84yU {
  line-height: 24px;
  margin-left: auto;
  margin-right: 8px;
  padding: 0
}

.remove-1-SF84yU {
  align-items: center;
  color: #131722;
  display: flex;
  height: 24px;
  margin-left: auto;
  margin-right: 8px
}

html.theme-dark .remove-1-SF84yU {
  color: #b2b5be
}

.wrap-164vy-kj {
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0
}

.wrap-164vy-kj.positionBottom-164vy-kj {
  align-items: flex-end
}

.backdrop-164vy-kj {
  background-color: #9598a1;
  bottom: 0;
  left: 0;
  opacity: .7;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0)
}

html.theme-dark .backdrop-164vy-kj {
  background-color: #0c0e15
}

.drawer-164vy-kj {
  -webkit-overflow-scrolling: touch;
  background: #fff;
  box-shadow: 0 2px 4px #0003;
  box-sizing: border-box;
  padding: 6px 0;
  z-index: 1
}

html.theme-dark .drawer-164vy-kj {
  background: #1e222d;
  box-shadow: 0 2px 4px #0006
}

.drawer-164vy-kj.positionLeft-164vy-kj {
  margin-right: 40px;
  max-width: calc(100% - 40px);
  min-width: 260px
}

.drawer-164vy-kj.positionBottom-164vy-kj {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex-basis: 100%;
  margin-top: 100px;
  max-height: calc(100% - 100px);
  overflow: auto
}

.spinnerWrap-1dkAsm33 {
  height: 68px;
  position: relative
}

.wrap-2k2kaUdi {
  display: inline-flex;
  position: relative
}

.wrap-2k2kaUdi .input-2k2kaUdi {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .wrap-2k2kaUdi .input-2k2kaUdi:hover:not(:checked)+.box-2k2kaUdi .hoverDot-2k2kaUdi {
      display:block
  }
}

.box-2k2kaUdi {
  height: 16px;
  pointer-events: none;
  position: relative;
  width: 16px
}

.box-2k2kaUdi.red-2k2kaUdi,html.theme-dark .box-2k2kaUdi.red-2k2kaUdi {
  color: #ff5252
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .box-2k2kaUdi.red-2k2kaUdi:hover,html.theme-dark .box-2k2kaUdi.red-2k2kaUdi:hover {
      color:#f44336
  }
}

.box-2k2kaUdi.blue-2k2kaUdi,html.theme-dark .box-2k2kaUdi.blue-2k2kaUdi {
  color: #2979ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .box-2k2kaUdi.blue-2k2kaUdi:hover,html.theme-dark .box-2k2kaUdi.blue-2k2kaUdi:hover {
      color:#0c3299
  }
}

.box-2k2kaUdi.green-2k2kaUdi,html.theme-dark .box-2k2kaUdi.green-2k2kaUdi {
  color: #4caf50
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .box-2k2kaUdi.green-2k2kaUdi:hover,html.theme-dark .box-2k2kaUdi.green-2k2kaUdi:hover {
      color:#388e3c
  }
}

.box-2k2kaUdi.orange-2k2kaUdi,html.theme-dark .box-2k2kaUdi.orange-2k2kaUdi {
  color: #ff9100
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .box-2k2kaUdi.orange-2k2kaUdi:hover,html.theme-dark .box-2k2kaUdi.orange-2k2kaUdi:hover {
      color:#ef6c00
  }
}

.box-2k2kaUdi.purple-2k2kaUdi,html.theme-dark .box-2k2kaUdi.purple-2k2kaUdi {
  color: #ab47bc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .box-2k2kaUdi.purple-2k2kaUdi:hover,html.theme-dark .box-2k2kaUdi.purple-2k2kaUdi:hover {
      color:#7b1fa2
  }
}

.box-2k2kaUdi.cyan-2k2kaUdi,html.theme-dark .box-2k2kaUdi.cyan-2k2kaUdi {
  color: #00e5ff
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .box-2k2kaUdi.cyan-2k2kaUdi:hover,html.theme-dark .box-2k2kaUdi.cyan-2k2kaUdi:hover {
      color:#00b8d4
  }
}

.box-2k2kaUdi.pink-2k2kaUdi,html.theme-dark .box-2k2kaUdi.pink-2k2kaUdi {
  color: #ff80ab
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .box-2k2kaUdi.pink-2k2kaUdi:hover,html.theme-dark .box-2k2kaUdi.pink-2k2kaUdi:hover {
      color:#ec407a
  }
}

.box-2k2kaUdi.large-2k2kaUdi {
  height: 24px;
  width: 24px
}

.pickerDot-2k2kaUdi {
  background-color: currentColor;
  border-radius: 50%;
  height: 100%;
  width: 100%
}

.hoverDot-2k2kaUdi {
  color: #fff;
  display: none;
  opacity: .5
}

html.theme-dark .hoverDot-2k2kaUdi {
  color: #131722
}

.wrapper-1yZn3-zj {
  padding: 10px 10px 10px 6px
}

.wrapper-1yZn3-zj .selector-1yZn3-zj {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 #00000030;
  display: inline-flex;
  padding: 4px;
  position: relative
}

html.theme-dark .wrapper-1yZn3-zj .selector-1yZn3-zj {
  background-color: #50535e
}

.wrapper-1yZn3-zj .selector-1yZn3-zj .arrowLeft-1yZn3-zj {
  height: 12px;
  left: -12px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px
}

.wrapper-1yZn3-zj .selector-1yZn3-zj .arrowLeft-1yZn3-zj:after {
  background: #fff;
  box-shadow: 0 0 5px 0 #00000030;
  content: "";
  height: 12px;
  left: 9px;
  position: absolute;
  transform: rotate(45deg);
  width: 12px
}

html.theme-dark .wrapper-1yZn3-zj .selector-1yZn3-zj .arrowLeft-1yZn3-zj:after {
  background: #50535e
}

.colorSelectButton-1yZn3-zj {
  padding: 4px
}

.popup-1yZn3-zj {
  position: fixed;
  transform: translateY(-50%);
  z-index: 1000
}

.switcherWrapper-1wFH-_jm {
  --tv-switcher-wrapper-width: 38px;
  --tv-switcher-wrapper-height: 20px;
  --tv-switcher-wrapper-radius: 10px;
  --tv-switcher-thumb-size: 14px;
  --tv-switcher-thumb-padding: 3px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  flex: 0 0 auto;
  position: relative;
  vertical-align: middle;
  z-index: 0
}

.switcherWrapper-1wFH-_jm.size-large-MOSirnj_ {
  --tv-switcher-wrapper-height: 34px;
  --tv-switcher-wrapper-width: 64px;
  --tv-switcher-thumb-size: 28px;
  --tv-switcher-wrapper-radius: 28px
}

.switcherWrapper-1wFH-_jm.intent-select-2kut8F29 .switcherThumbWrapper-2u191lDO:before {
  content: none
}

.switcherWrapper-1wFH-_jm.intent-select-2kut8F29 .input-J7QIcTTo:checked+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
  background-color: #00bfa5;
  border-color: #00bfa5
}

html.theme-dark .switcherWrapper-1wFH-_jm.intent-select-2kut8F29 .input-J7QIcTTo:checked+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
  background-color: #00897b;
  border-color: #00897b
}

.switcherWrapper-1wFH-_jm.intent-select-2kut8F29 .switcherTrack-2XruDVTa {
  background-color: #3179f5;
  border-color: #3179f5
}

html.theme-dark .switcherWrapper-1wFH-_jm.intent-select-2kut8F29 .switcherTrack-2XruDVTa {
  background-color: #1e53e5;
  border-color: #1e53e5
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .switcherWrapper-1wFH-_jm.intent-select-2kut8F29:hover .input-J7QIcTTo:checked+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
      background-color:#4db6ac;
      border-color: #4db6ac
  }

  html.theme-dark .switcherWrapper-1wFH-_jm.intent-select-2kut8F29:hover .input-J7QIcTTo:checked+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
      background-color: #00796b;
      border-color: #00796b
  }

  .switcherWrapper-1wFH-_jm.intent-select-2kut8F29:hover .switcherTrack-2XruDVTa {
      background-color: #2962ff;
      border-color: #2962ff
  }

  html.theme-dark .switcherWrapper-1wFH-_jm.intent-select-2kut8F29:hover .switcherTrack-2XruDVTa {
      background-color: #2962ff;
      border-color: #2962ff
  }
}

.input-J7QIcTTo {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.input-J7QIcTTo:checked,.input-J7QIcTTo:disabled,.input-J7QIcTTo:focus {
  border: 0;
  outline: 0
}

.input-J7QIcTTo:checked+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
  background-color: #2962ff;
  border-color: #2962ff
}

html.theme-dark .input-J7QIcTTo:checked+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
  background-color: #2962ff;
  border-color: #2962ff
}


.input-J7QIcTTo:disabled+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
  background-color: #fff;
  border-color: #d6d8e0
}

html.theme-dark .input-J7QIcTTo:disabled+.switcherThumbWrapper-2u191lDO .switcherTrack-2XruDVTa {
  background-color: #131722;
  border-color: #4c525e
}

.input-J7QIcTTo:disabled+.switcherThumbWrapper-2u191lDO .switcherThumb-2yuEucci {
  background-color: #d6d8e0
}

html.theme-dark .input-J7QIcTTo:disabled+.switcherThumbWrapper-2u191lDO .switcherThumb-2yuEucci {
  background-color: #4c525e
}

.input-J7QIcTTo.focus-uZMRkCO0:focus+.switcherThumbWrapper-2u191lDO:before {
  opacity: .2
}

.switcherThumbWrapper-2u191lDO {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0
}

.switcherThumbWrapper-2u191lDO:before {
  background-color: #37a6ef;
  content: "";
  height: calc(100% + 8px);
  left: -4px;
  opacity: 0;
  position: absolute;
  top: -4px;
  transition-duration: .6s;
  transition-property: opacity;
  transition-timing-function: ease-out;
  width: calc(100% + 8px)
}

html.theme-dark .switcherThumbWrapper-2u191lDO:before {
  background-color: #2e7bb2
}

.switcherTrack-2XruDVTa {
  background: #d6d8e0;
  border: 1px solid #d6d8e0;
  height: calc(100% - 2px);
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 2px)
}

html.theme-dark .switcherTrack-2XruDVTa {
  background: #363c4e;
  border-color: #363c4e
}

.switcherThumb-2yuEucci {
  background: #fff;
  transition-duration: .25s;
  transition-property: transform;
  transition-timing-function: ease-out;
}

html.theme-dark .switcherThumb-2yuEucci {
  background: #fff
}

.modeTitle-3iGYBWzh {
  color: #787b86;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  transition-duration: .35s;
  transition-property: color;
  transition-timing-function: ease
}

html.theme-dark .modeTitle-3iGYBWzh {
  color: #787b86
}

.modeTitleActive-3iGYBWzh,html.theme-dark .modeTitleActive-3iGYBWzh {
  color: #2962ff
}

.switcherContainer-3iGYBWzh {
  margin: 0 8px
}

.elementWrap-EE_m_Lmj {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 100%
}

.itemsWrap-EE_m_Lmj {
  display: flex;
  position: relative
}

.item-EE_m_Lmj {
  color: #131722;
  display: flex;
  padding: 0 9px
}

html.theme-dark .item-EE_m_Lmj {
  color: #b2b5be
}

.item-EE_m_Lmj:first-child {
  padding-left: 0
}

.item-EE_m_Lmj:last-child {
  padding-right: 0
}

.item-EE_m_Lmj.active-EE_m_Lmj .itemContent-EE_m_Lmj {
  background-color: #2962ff;
  border-radius: 12px;
  color: #fff;
  cursor: default
}

html.theme-dark .item-EE_m_Lmj.active-EE_m_Lmj .itemContent-EE_m_Lmj {
  background-color: #2962ff
}

.item-EE_m_Lmj.active-EE_m_Lmj .itemContent-EE_m_Lmj.dropdownItem-EE_m_Lmj {
  cursor: pointer
}

.itemContent-EE_m_Lmj {
  border: 1px solid #0000;
  border-radius: 12px;
  color: #131722;
  cursor: pointer;
  font-size: 13px;
  padding: 3px 12px 4px;
  text-align: center;
  transition: border-color .35s ease,color .35s ease;
  white-space: nowrap
}

html.theme-dark .itemContent-EE_m_Lmj {
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .itemContent-EE_m_Lmj:hover:not(.active-EE_m_Lmj) {
      border-color:#1e53e5;
      color: #1e53e5
  }

  html.theme-dark .itemContent-EE_m_Lmj:hover:not(.active-EE_m_Lmj) {
      border-color: #1e53e5;
      color: #1e53e5
  }
}

.dropdownItem-EE_m_Lmj {
  box-sizing: border-box;
  width: 32px
}

.dropdownIcon-EE_m_Lmj {
  display: block;
  transform: rotate(90deg);
  transition: transform .35s ease
}

.dropdownIcon-EE_m_Lmj.active-EE_m_Lmj {
  transform: rotate(-90deg)
}

.dropdownListItem-EE_m_Lmj {
  color: #131722
}

html.theme-dark .dropdownListItem-EE_m_Lmj {
  color: #d1d4dc
}

.wrap-YTIWpbLa {
  position: relative
}

.list-YTIWpbLa {
  background-color: #fff;
  padding: 15px 0
}

html.theme-dark .list-YTIWpbLa {
  background-color: #1e222d
}

.list-YTIWpbLa .item-YTIWpbLa {
  cursor: pointer;
  overflow: hidden;
  padding: 10px 30px 10px 15px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  transition: color .35s ease,background-color .35s ease;
  white-space: nowrap
}

.list-YTIWpbLa .item-YTIWpbLa.selected-YTIWpbLa {
  background-color: #3179f52e;
  color: #434651;
  transition-duration: .06s
}

html.theme-dark .list-YTIWpbLa .item-YTIWpbLa.selected-YTIWpbLa {
  color: #d1d4dc
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .list-YTIWpbLa .item-YTIWpbLa:hover:not(.selected-YTIWpbLa) {
      background-color:#f0f3fa;
      color: #434651;
      transition-duration: .06s
  }

  html.theme-dark .list-YTIWpbLa .item-YTIWpbLa:hover:not(.selected-YTIWpbLa) {
      background-color: #2a2e39;
      color: #d1d4dc
  }
}

.list-YTIWpbLa .bluishItem-YTIWpbLa {
  cursor: pointer;
  overflow: hidden;
  padding: 10px 30px 10px 15px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  transition: color .35s ease,background-color .35s ease;
  white-space: nowrap
}

.list-YTIWpbLa .bluishItem-YTIWpbLa.selected-YTIWpbLa {
  background-color: #3179f52e;
  color: #fff;
  transition-duration: .06s
}

@media (any-hover: hover),(min--moz-device-pixel-ratio:0) {
  .list-YTIWpbLa .bluishItem-YTIWpbLa:hover:not(.selected-YTIWpbLa) {
      background-color:#ebf7fc;
      color: #434651;
      transition-duration: .06s
  }

  html.theme-dark .list-YTIWpbLa .bluishItem-YTIWpbLa:hover:not(.selected-YTIWpbLa) {
      background-color: #262b3e;
      color: #d1d4dc
  }
}

.list-YTIWpbLa .bluishItem-YTIWpbLa.noPadding-YTIWpbLa {
  padding: 0
}

.list-2R-eCARO {
  display: flex;
  flex-direction: column;
  overflow: auto
}

.list-2R-eCARO .item-2R-eCARO {
  display: inline-flex;
  flex-shrink: 0
}

.container-vMoQJXBo {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px #0003;
  box-sizing: border-box;
  line-height: normal;
  overflow-x: hidden;
  overflow-y: auto
}

html.theme-dark .container-vMoQJXBo {
  background-color: #1e222d
}

.container-vMoQJXBo::-webkit-scrollbar {
  height: 5px;
  width: 5px
}

.container-vMoQJXBo::-webkit-scrollbar-thumb {
  background-color: #9598a1;
  border: 1px solid #f0f3fa;
  border-radius: 3px
}

html.theme-dark .container-vMoQJXBo::-webkit-scrollbar-thumb {
  background-color: #363a45;
  border-color: #1e222d
}

.container-vMoQJXBo::-webkit-scrollbar-track {
  background-color: initial;
  border-radius: 3px
}

.container-vMoQJXBo::-webkit-scrollbar-corner {
  display: none
}

html.theme-dark .container-vMoQJXBo {
  box-shadow: 0 2px 4px #0006
}

.container-vMoQJXBo.top-vMoQJXBo {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px #0003
}

html.theme-dark .container-vMoQJXBo.top-vMoQJXBo {
  background-color: #1e222d;
  box-shadow: 0 2px 4px #0006
}
